<div class="alert alert-info-custom d-flex items-center" role="alert">
    <span class="material-icons status-icon mr-1"> info </span>

    <div class="align-content-center">
        Date information: <strong>{{ contractsWithoutStartDate }}</strong> applicant/s
        <strong>awaiting a {{ 'TABLES.LABEL.GENERAL_START_DATE' | translate }}</strong
        >, <strong>{{ contractsWithoutEndDate }}</strong> applicant/s
        <strong>awaiting a {{ 'TASKS.VIEW.DESCRIPTION.FINISH_CONTRACT' | translate }}</strong>
    </div>
</div>
