@if(displayInPairs) {
<span>
    @for(airplane of allAirplaneNames; track airplane; let index = $index){
    {{ airplane }}{{ allAirplaneNames.length - 1 !== index ? ',' : '' }}
    @if(index % 2 === 1) {
    <br />
    } }
</span>
} @else{
<span>
    {{ offerAirplanes }}
    {{ offer?.airplanes?.length !== 0 && offer?.otherAirplanes ? ',' : '' }}
    {{ offer?.otherAirplanes }}
</span>
}
