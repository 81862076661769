import { AbstractControlState, Boxed, disable, FormControlState, validate, ValidationErrors } from 'ngrx-forms';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { greaterThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { isUsaRegion } from '@libs/shared/bms-common/environment/environment.loader';
import { License } from '@libs/shared/models/license.model';
import {
  CUSTOM_REFERENCE_NUMBER_MAX_LENGTH,
  CUSTOM_REFERENCE_NUMBER_MIN_LENGTH
} from '@libs/shared/models/offer.model';

export const MAX_YEARS_OF_EXPERIENCE: number = 60;
export const SHORT_PRESENTATION_MIN_LENGTH: number = 1;
export const SHORT_PRESENTATION_MAX_LENGTH: number = 20000;
export const JO_TITLE_MIN_LENGTH: number = 6;
export const JO_TITLE_MAX_LENGTH: number = 60;
export const WORK_DAYS_MAX_VALUE: number = 99;
export const MAX_CHARGE_RATE: number = 999;
export const MAX_NET_SALARY: number = 9999;
export const VACANCIES_MAX_VALUE: number = 1000;
const UNLICENSED: string = 'Unlicensed';
export const MINIMUM_CLOSE_AFTER_DAYS: number = 1;
export const MAXIMUM_CLOSE_AFTER_DAYS: number = 60;

export function validatorForFormControl(validator: (input: string) => ValidationErrors): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control) return null;
    return validator(control.value);
  };
}

export function validateCustomReference(value: string): ValidationErrors {
  if (value?.length < CUSTOM_REFERENCE_NUMBER_MIN_LENGTH) {
    return {
      minLength: {
        minLength: CUSTOM_REFERENCE_NUMBER_MIN_LENGTH,
        value: value,
        actualLength: value.length
      }
    };
  }
  if (value?.length > CUSTOM_REFERENCE_NUMBER_MAX_LENGTH) {
    return {
      maxLength: {
        maxLength: CUSTOM_REFERENCE_NUMBER_MAX_LENGTH,
        value: value,
        actualLength: value.length
      }
    };
  }
  const allowedCharacters: RegExp = /^[A-Z0-9_-]+$/;
  const atLeastOneLetterOrNumber: RegExp = /^.*[A-Z0-9]+.*$/;
  if (allowedCharacters.test(value) && atLeastOneLetterOrNumber.test(value)) {
    return {};
  } else {
    const customReferenceFormat = {};
    if (!allowedCharacters.test(value)) {
      customReferenceFormat['allowedCharacters'] = { toMatch: allowedCharacters, actualValue: value };
    }
    if (!atLeastOneLetterOrNumber.test(value)) {
      customReferenceFormat['atLeastOneLetterOrNumber'] = { toMatch: atLeastOneLetterOrNumber, actualValue: value };
    }
    return { customReferenceFormat };
  }
}

export function getCustomReferenceValidator(): (state: AbstractControlState<string>) => FormControlState<string> {
  return validate<string>((inputText: string) => validateCustomReference(inputText));
}

export function validateTitle(inputText: string): ValidationErrors {
  if (!inputText || (inputText.length >= JO_TITLE_MIN_LENGTH && inputText.length <= JO_TITLE_MAX_LENGTH)) {
    return {};
  }
  return { invalidTitleLength: true };
}

export function getTitleValidator(): (state: AbstractControlState<string>) => FormControlState<string> {
  return validate<string>((inputText: string) => validateTitle(inputText));
}

export function validateShortPresentation(inputText: string): ValidationErrors {
  if (
    inputText &&
    inputText.length >= SHORT_PRESENTATION_MIN_LENGTH &&
    inputText.length <= SHORT_PRESENTATION_MAX_LENGTH
  ) {
    return {};
  }
  return { invalidLength: true };
}

export function getShortPresentationValidator(): (state: AbstractControlState<string>) => FormControlState<string> {
  return validate<string>(required, (inputText: string) => validateShortPresentation(inputText));
}

export function getMinExperienceValidator(): (state: AbstractControlState<number>) => FormControlState<number> {
  return validate<number>(required, greaterThan(-1), lessThanOrEqualTo(MAX_YEARS_OF_EXPERIENCE));
}

export function getWorkDaysOnValidator(): (state: AbstractControlState<number>) => FormControlState<number> {
  return validate<number>(required, greaterThan(0), lessThanOrEqualTo(WORK_DAYS_MAX_VALUE));
}

export function getWorkDaysOffValidator(): (state: AbstractControlState<number>) => FormControlState<number> {
  return validate<number>(required, greaterThan(-1), lessThanOrEqualTo(WORK_DAYS_MAX_VALUE));
}

export function getChargeRateValidator(): (state: AbstractControlState<number>) => FormControlState<number> {
  return validate<number>(required, greaterThan(-1), lessThanOrEqualTo(MAX_CHARGE_RATE));
}

export function getVacanciesValidator(): (state: AbstractControlState<number>) => FormControlState<number> {
  return validate<number>(required, greaterThan(0), lessThanOrEqualTo(VACANCIES_MAX_VALUE));
}

export function getLicensesValidator(state: AbstractControlState<Boxed<any>>): AbstractControlState<Boxed<any>> {
  if (isUsaRegion()) {
    const requiredValidation = validate(required)(state);
    if (!requiredValidation.isValid) {
      return requiredValidation;
    }
    return validateLicenses(requiredValidation);
  } else {
    return disable(state);
  }
}
function validateLicenses(state: AbstractControlState<any>): FormControlState<any> {
  const hasUnlicensedName = state.value.some((license: License) => license.name === UNLICENSED);
  const multipleItemsSelected: boolean = state.value.length > 1;

  if (hasUnlicensedName && multipleItemsSelected) {
    return {
      isFocused: false,
      isUnfocused: false,
      ...state,
      isValid: false,
      isInvalid: true,
      errors: {
        required: { actual: state.value }
      }
    };
  }
  return {
    isFocused: false,
    isUnfocused: false,
    ...state,
    isValid: true
  };
}
