<div class="contract-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    @if (isSettingInitialValues) {
        <div class="loader-wrapper">
            <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
        </div>
    } @else {
        <div class="create-offer-container form-container">
            <section class="create-offer-main">
                <div class="profile-container">
                    <form [formGroup]="offerForm" class="form-body">
                        <div class="form-group highlight row create-offer-header">
                            <div class="form-group offer-type-selector">
                                <label for="type">
                                    {{ 'OFFER.FORM.TYPE.LABEL' | translate }}
                                </label>
                                <br />
                                <button id="type" disabled class="btn btn-outline-primary btn-highlighted mr-4">
                                    <span class="material-icons-outlined btn-icon"> check_circle </span>
                                    {{ 'PUBLIC' | translate }}
                                </button>
                            </div>
                            <div class="form-group offer-priority-selector">
                                <label for="priority-level" [class.required]="initialPriority == null">
                                    {{ 'OFFER.FORM.PRIORITY_LEVEL.LABEL' | translate }}
                                </label>
                                <simple-select
                                    id="priority-level"
                                    [getLabel]="getLabelFunction"
                                    [items]="['HIGH', 'MEDIUM', 'LOW']"
                                    [placeholder]="'OFFER.FORM.PRIORITY.PLACEHOLDER' | translate"
                                    formControlName="priority"
                                    [valid]="offerForm.controls.priority.valid && !offerForm.controls.priority.pristine"
                                    [invalid]="offerForm.controls.priority.invalid"
                                    [hasDefaultValue]="initialPriority != null"
                                >
                                </simple-select>
                                <span class="user-guide">
                                    <small class="text-muted">
                                        {{ 'OFFER.FORM.VISIBILITY' | translate }}
                                    </small>
                                </span>
                            </div>
                            @if (facilityProfile.allowTcnApplicationsToggle) {
                                <div class="form-group highlight row w-100 mb-0 mt-0">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            [checked]="!offerForm.get('allowTcnApplications').value"
                                            (change)="onTcnApplicationToggleChange($event)"
                                            class="custom-control-input"
                                            id="allowTcnApplications"
                                            type="checkbox"
                                            [disabled]="isEdit"
                                        />
                                        <label class="custom-control-label" for="allowTcnApplications">
                                            {{ 'CREATE_OFFER.TCN_LABEL' | translate }}
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="form-group row">
                            <div class="form-group col" style="padding-right: 26px">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        id="custom-reference-number"
                                        type="checkbox"
                                        class="custom-control-input"
                                        name="custom-reference-number"
                                        formControlName="useCustomReference"
                                        (change)="toggleCustomReference($event)"
                                    />
                                    <label for="custom-reference-number" class="custom-control-label">
                                        <b>{{ 'Custom Reference' | translate }}</b>
                                    </label>
                                </div>
                                <div class="value-wrapper">
                                    <input
                                        type="text"
                                        class="form-control"
                                        autocomplete="off"
                                        placeholder="Type {{ CUSTOM_REFERENCE_MIN_LENGTH }} to {{
                                            CUSTOM_REFERENCE_MAX_LENGTH
                                        }} characters to identify your job opening"
                                        oninput="this.value = this.value.toUpperCase()"
                                        formControlName="refNumber"
                                    />
                                    @if (offerForm.controls.useCustomReference.value) {
                                        <div class="validation-error">
                                            <ul>
                                                <li *ngIf="offerForm.controls.refNumber.errors?.minLength">
                                                    Cannot have less than
                                                    {{ CUSTOM_REFERENCE_MIN_LENGTH }} characters.
                                                </li>
                                                <li *ngIf="offerForm.controls.refNumber.errors?.maxLength">
                                                    Cannot have more than
                                                    {{ CUSTOM_REFERENCE_MAX_LENGTH }} characters.
                                                </li>
                                                <li
                                                    *ngIf="
                                                        offerForm.controls.refNumber.errors?.customReferenceFormat
                                                            ?.atLeastOneLetterOrNumber
                                                    "
                                                >
                                                    Must have at least one letter (A-Z) or digit (0-9)
                                                </li>
                                                <li
                                                    *ngIf="
                                                        offerForm.controls.refNumber.errors?.customReferenceFormat
                                                            ?.allowedCharacters
                                                    "
                                                >
                                                    Allowed characters: letters (A-Z), digits (0-9), minus (-) and
                                                    underscore (_)
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="form-group col">
                                <label for="startDate" class="required">Pick a start date</label>
                                <date-picker-single
                                    id="startDate"
                                    [minDate]="getCurrentUTCDateString()"
                                    [placeholder]="'Pick a start date' | translate"
                                    formControlName="startDate"
                                    [valid]="offerForm.controls.startDate.valid"
                                    [invalid]="highlightErrors && offerForm.controls.startDate.invalid"
                                ></date-picker-single>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="title">
                                    {{ 'OFFER.FORM.TITLE.LABEL' | translate }}
                                </label>
                                <input
                                    id="title"
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    [placeholder]="
                                        'OFFER.FORM.TITLE.PLACEHOLDER'
                                            | translate
                                                : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                    "
                                    formControlName="title"
                                    [ngClass]="{
                                        'is-valid':
                                            offerForm.controls.title.valid && !offerForm.controls.title.pristine,
                                        'is-invalid': offerForm.controls.title.invalid && offerForm.controls.title.dirty
                                    }"
                                />
                                <div *ngIf="offerForm.controls.title.invalid">
                                    <span
                                        class="validation-error"
                                        *ngIf="offerForm.controls.title.errors?.invalidTitleLength"
                                    >
                                        {{
                                            'OFFER.FORM.INVALID_TITLE_LENGTH'
                                                | translate
                                                    : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="location">
                                    {{ 'OFFER.FORM.LOCATION.LABEL' | translate }}
                                </label>
                                <ng-select
                                    id="location"
                                    bindLabel="name"
                                    [placeholder]="'OFFER.FORM.LOCATION.PLACEHOLDER' | translate"
                                    [ngModelOptions]="{ standalone: true }"
                                    [closeOnSelect]="true"
                                    [clearSearchOnAdd]="true"
                                    [searchable]="true"
                                    [disabled]="!offersAreHandledCentrally"
                                    [items]="locationList"
                                    [(ngModel)]="selectedLocation"
                                    (change)="writeLocationIdToForm()"
                                    [clearable]="false"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="technician-type" class="required">
                                    {{ 'OFFER.FORM.TECHNICIAN_TYPE.LABEL' | translate }}
                                </label>
                                <select
                                    id="technician-type"
                                    class="custom-select"
                                    name="type"
                                    [ngClass]="{
                                        'is-valid': offerForm.controls.ameType.valid,
                                        'is-invalid': highlightErrors && offerForm.controls.ameType.invalid
                                    }"
                                    formControlName="ameType"
                                    (change)="getTechnicianLevelOptions($event)"
                                >
                                    <option [ngValue]="null">
                                        {{ 'Select an option' | translate }}
                                    </option>
                                    <option *ngFor="let option of technicianTypeOptions" [value]="option.name">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col" *ngIf="!isSingletonNomenclature">
                                <label for="level" class="required">
                                    {{ 'OFFER.FORM.TECHNICIAN_LEVEL.LABEL' | translate }}
                                </label>
                                <select
                                    class="custom-select"
                                    id="level"
                                    formControlName="ameTitleId"
                                    [ngClass]="{
                                        'is-valid': offerForm.controls.ameTitleId.valid,
                                        'is-invalid':
                                            offerForm.controls.ameTitleId.invalid &&
                                            (highlightErrors || offerForm.controls.ameTitleId.dirty)
                                    }"
                                >
                                    <option [ngValue]="null">
                                        {{ 'GENERAL.SELECT_AN_OPTION' | translate }}
                                    </option>
                                    @for (license of technicianLevelOptions; track license.id) {
                                        <option [ngValue]="license.id">
                                            {{ license.name }}
                                        </option>
                                    }
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col aircrafts">
                                <label for="airplanes" class="required">
                                    {{ 'OFFER.FORM.TYPE_OF_AIRCRAFT.LABEL' | translate }}
                                </label>
                                <multi-select-autocomplete
                                    id="airplanes"
                                    [placeholder]="'OFFER.FORM.TYPE_OF_AIRCRAFT.PLACEHOLDER' | translate"
                                    [options]="aircraftList"
                                    [selectedOptions]="selectedAircraftList"
                                    (selectionChange)="handleAircraftSelectionChange($event)"
                                    [valid]="offerForm.controls.airplanes.valid"
                                    [invalid]="
                                        offerForm.controls.airplanes.invalid &&
                                        (highlightErrors || offerForm.controls.airplanes.dirty)
                                    "
                                    [disabled]="offerForm.controls.airplanes.disabled"
                                >
                                </multi-select-autocomplete>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="experience" class="required">
                                    {{ 'OFFER.FORM.MIN_EXPERIENCE.LABEL' | translate }}
                                </label>
                                <div class="input-group">
                                    <input
                                        id="experience"
                                        class="form-control"
                                        type="number"
                                        min="1"
                                        max="60"
                                        autocomplete="off"
                                        [placeholder]="'OFFER.FORM.MIN_EXPERIENCE.PLACEHOLDER' | translate"
                                        formControlName="minExperience"
                                        [ngClass]="{
                                            'is-valid': offerForm.controls.minExperience.valid,
                                            'is-invalid':
                                                offerForm.controls.minExperience.invalid &&
                                                (highlightErrors || offerForm.controls.minExperience.dirty)
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="years">{{ 'years' | translate }}</span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        offerForm.controls.minExperience.dirty &&
                                        offerForm.controls.minExperience.invalid
                                    "
                                >
                                    <span class="validation-error">
                                        {{
                                            'OFFER.FORM.INVALID_MINIMUM_EXPERIENCE_LENGTH'
                                                | translate: { maxLength: MAX_YEARS_OF_EXPERIENCE }
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group col">
                                <div>
                                    <label for="vacancies" class="required">
                                        {{ 'OFFER.FORM.NUMBER_OF_TECHNICIANS.LABEL' | translate }}
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        id="vacancies"
                                        min="1"
                                        max="999"
                                        autocomplete="off"
                                        [placeholder]="'OFFER.FORM.NUMBER_OF_TECHNICIANS.PLACEHOLDER' | translate"
                                        formControlName="vacancies"
                                        [ngClass]="{
                                            'is-valid': offerForm.controls.vacancies.valid,
                                            'is-invalid':
                                                offerForm.controls.vacancies.invalid &&
                                                (highlightErrors || offerForm.controls.vacancies.dirty)
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                @if (facilityProfile.allowsHideRateInJobOffers) {
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            class="custom-control-input"
                                            id="hasHiddenRate"
                                            type="checkbox"
                                            formControlName="hasHiddenRate"
                                        />
                                        <label class="custom-control-label" for="hasHiddenRate">
                                            {{ 'CREATE_OFFER.HIDDING_SALARY_LABEL' | translate }}
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="netSalary" class="required">
                                    {{ 'OFFER.FORM.MONTHLY_SALARY.LABEL' | translate }}
                                </label>
                                <div class="input-group">
                                    <input
                                        id="netSalary"
                                        class="form-control"
                                        type="number"
                                        min="1"
                                        max="{{ MAX_NET_SALARY }}"
                                        autocomplete="off"
                                        placeholder="{{ 'OFFER.FORM.MONTHLY_SALARY.PLACEHOLDER' | translate }}"
                                        formControlName="netSalaryPerMonth"
                                        [ngClass]="{
                                            'is-valid': offerForm.controls.netSalaryPerMonth.valid,
                                            'is-invalid':
                                                offerForm.controls.netSalaryPerMonth.invalid &&
                                                (highlightErrors || offerForm.controls.netSalaryPerMonth.dirty)
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            {{ currencySymbol }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        offerForm.controls.netSalaryPerMonth.dirty &&
                                        offerForm.controls.netSalaryPerMonth.invalid
                                    "
                                >
                                    <span class="validation-error">
                                        {{ 'OFFER.FORM.INVALID_NET_SALARY' | translate: { maxSalary: MAX_NET_SALARY } }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group col">&nbsp;</div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col-4 pattern-view">
                                <label for="work-pattern">
                                    {{ 'OFFER.FORM.WORK_PATTERN.LABEL' | translate }}
                                </label>
                                <div class="custom-controls-stacked" id="work-pattern">
                                    @for (workingPattern of fixedWorkingPatterns; track index; let index = $index) {
                                        <div class="custom-control custom-radio">
                                            <input
                                                id="{{
                                                    'working-pattern' + workingPattern.daysOn + workingPattern.daysOff
                                                }}"
                                                type="radio"
                                                name="pattern"
                                                class="custom-control-input"
                                                [checked]="index === 0 || isWorkingPatternSelected(workingPattern)"
                                                (change)="setWorkingPattern(workingPattern)"
                                            />
                                            <label
                                                for="{{
                                                    'working-pattern' + workingPattern.daysOn + workingPattern.daysOff
                                                }}"
                                                class="custom-control-label"
                                            >
                                                {{
                                                    workingPattern.daysOn +
                                                        ' days on - ' +
                                                        workingPattern.daysOff +
                                                        ' days off' | translate
                                                }}
                                            </label>
                                        </div>
                                    }
                                    <div class="custom-control custom-radio">
                                        <input
                                            id="custom-pattern-radio"
                                            type="radio"
                                            name="pattern"
                                            class="custom-control-input"
                                            [checked]="isCustomWorkingPatternSelected"
                                            (change)="enableCustomWorkingPattern()"
                                        />
                                        <label for="custom-pattern-radio" class="custom-control-label">
                                            {{ 'Custom' | translate }}
                                        </label>
                                    </div>
                                    <div class="form-row mt-2">
                                        <div class="form-group col-6 padded" *ngIf="isCustomWorkingPatternSelected">
                                            <label for="work-days" class="required">
                                                {{ 'OFFER.FORM.WORK_DAYS.LABEL' | translate }}
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                id="work-days"
                                                [tooltip]="'OFFER.FORM.WORK_DAYS.LABEL' | translate"
                                                name="ondays"
                                                min="1"
                                                max="99"
                                                maxlength="2"
                                                [ngClass]="{
                                                    'is-invalid': offerForm.controls.workDaysOn.invalid
                                                }"
                                                formControlName="workDaysOn"
                                            />
                                        </div>
                                        <div class="form-group col-6" *ngIf="isCustomWorkingPatternSelected">
                                            <label>
                                                {{ 'OFFER.FORM.OFF_DAYS.LABEL' | translate }}
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    id="off-days"
                                                    [tooltip]="'OFFER.FORM.OFF_DAYS.LABEL' | translate"
                                                    name="offdays"
                                                    min="1"
                                                    max="99"
                                                    maxlength="2"
                                                    [ngClass]="{
                                                        'is-invalid': offerForm.controls.workDaysOff.invalid
                                                    }"
                                                    formControlName="workDaysOff"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-8">
                                <label for="short-presentation" class="required">
                                    {{ 'OFFER.FORM.JOB_DESCRIPTION.LABEL' | translate }}
                                </label>
                                <textarea
                                    id="short-presentation"
                                    class="form-control"
                                    name="presentation"
                                    cols="30"
                                    rows="5"
                                    [attr.maxlength]="JOB_DESCRIPTION_MAX_LENGTH"
                                    formControlName="shortPresentation"
                                    [ngClass]="{
                                        'is-valid': offerForm.controls.shortPresentation?.valid,
                                        'is-invalid':
                                            offerForm.controls.shortPresentation.invalid &&
                                            (highlightErrors || offerForm.controls.shortPresentation.dirty)
                                    }"
                                >
                                </textarea>
                                <div
                                    *ngIf="
                                        offerForm.controls.shortPresentation.invalid &&
                                        !offerForm.controls.shortPresentation.pristine
                                    "
                                >
                                    <span class="validation-error">
                                        {{
                                            'OFFER.FORM.INVALID_JOB_DESCRIPTION_LENGTH'
                                                | translate
                                                    : {
                                                          minLength: JOB_DESCRIPTION_MIN_LENGTH,
                                                          maxLength: JOB_DESCRIPTION_MAX_LENGTH
                                                      }
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="workShiftPreference">
                                    {{ 'SYSTEM.INFO.SHIFT' | translate }}
                                </label>
                                <multiple-options-select
                                    id="workShiftPreference"
                                    placeholder="{{ 'OFFER.FORM.SHIFT.PLACEHOLDER' | translate }}"
                                    [searchable]="true"
                                    [elements]="workShiftsList"
                                    [selectedElements]="selectedWorkShifts"
                                    (valueChanged)="onSelectWorkShift($event)"
                                    [disabled]="offerForm.controls.workShifts.disabled"
                                ></multiple-options-select>
                            </div>
                        </div>
                        <div formArrayName="additionalBenefits" class="form-group row mb-3">
                            <collapsible heading="Benefits" style="width: 100%" highlight="true">
                                <div style="padding: 0 20px">
                                    @for (benefit of benefits; track benefit.type) {
                                        <div class="benefit">
                                            <div class="wrapper">
                                                <input
                                                    type="checkbox"
                                                    id="{{ benefit.type }}"
                                                    [checked]="benefit?.isSelected"
                                                    (change)="benefitSelected($event, benefit)"
                                                />
                                                <label for="{{ benefit.type }}">{{
                                                    benefitLabel(benefit.type) | translate
                                                }}</label>
                                                @if (benefitIsSelected(benefit)) {
                                                    <div class="input-group detail">
                                                        @if (requiresAmount(benefit)) {
                                                            <input
                                                                class="form-control"
                                                                type="number"
                                                                min="1"
                                                                [defaultValue]="benefit.amount"
                                                                max="999"
                                                                style="max-width: 200px"
                                                                autocomplete="off"
                                                                placeholder="{{
                                                                    'Enter amount (Required)' | translate
                                                                }}"
                                                                [ngClass]="{
                                                                    'is-invalid':
                                                                        benefit.isSelected && !isBenefitValid(benefit)
                                                                }"
                                                                (change)="addAmountToBenefit($event, benefit)"
                                                            />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text">
                                                                    {{ currencySymbol }}
                                                                </span>
                                                            </div>
                                                        }
                                                        <input
                                                            class="form-control"
                                                            type="text"
                                                            [defaultValue]="benefit.description || ''"
                                                            autocomplete="off"
                                                            maxlength="100"
                                                            placeholder="{{
                                                                'Write a description (Optional)' | translate
                                                            }}"
                                                            (change)="addDescriptionToBenefit($event, benefit)"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </collapsible>
                        </div>
                        <div class="form-row justify-content-center create-offer-form-actions">
                            <general-button
                                (onClick)="cancelCreation()"
                                backgroundStyle="outline"
                                class="mr-5"
                                text="CANCEL"
                            ></general-button>
                            <general-button
                                (onClick)="handleFormSubmit()"
                                [isLoading]="isSubmitting"
                                text="CONFIRM"
                            ></general-button>
                        </div>
                        <div *ngIf="highlightErrors" class="invalid-form-error">
                            <span class="validation-error">
                                {{ 'OFFER.FORM.INVALID_SUBMITTED_FORM' | translate }}
                            </span>
                        </div>
                    </form>
                </div>
            </section>

            <section class="create-offer-user">
                <div class="user-container">
                    <div class="form-head" style="text-align: center">
                        <h1 class="h1">
                            <strong>Selected Technicians</strong>
                        </h1>
                        @if (noSelectedTechnicians()) {
                            <span>No technicians selected</span>
                        }
                        @for (technician of selectedTechnicians | keyvalue; track technician.key) {
                            <div class="technician-item">
                                @if (technician.value._links?.getProfilePicture) {
                                    <div
                                        class="profile-pic"
                                        [ngStyle]="{
                                            'background-image':
                                                'url(\'' + technician.value._links.getProfilePicture.href + '\')'
                                        }"
                                    ></div>
                                    <div class="profile-pic">
                                        <i class="material-icons"> person </i>
                                    </div>
                                }
                                <div class="name">
                                    <h4 class="h4">
                                        {{ technician.value._embedded.profile.firstName }}
                                        {{ technician.value._embedded.profile.lastName }}
                                    </h4>
                                </div>
                                <div
                                    class="clear"
                                    (click)="handleRemoveFromSelectedTechnicians(technician.value)"
                                    (keydown.enter)="handleRemoveFromSelectedTechnicians(technician.value)"
                                >
                                    <i class="material-icons"> clear </i>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    }
</div>
