import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {isFirstDateBeforeTheSecond} from "@libs/shared/helpers/date-utils";

@Component({
  selector: 'staffnow-break-dates-picker-dialog',
  templateUrl: './break-dates-picker-dialog.component.html',
  styleUrls: ['./break-dates-picker-dialog.component.scss']
})
export class BreakDatesPickerDialogComponent {
  @Input() public onAcceptCallback: Function;
  @Input() public minimumDate: string = null;
  public startDate: string = null;
  public endDate: string = null;

  constructor(public bsModalRef: BsModalRef) {
  }

  get areDatesValid(): boolean {
    if (this.startDate === null || this.endDate === null) {
      return true;
    }
    return isFirstDateBeforeTheSecond(this.endDate, this.startDate);
  }

  public submit() {
    this.onAcceptCallback(this.startDate, this.endDate);
    this.bsModalRef.hide();
  }

  public handleStartDateUpdate(date: string): void {
    this.startDate = date;
  }

  public handleEndDateUpdate(date: string): void {
    this.endDate = date;
  }

}
