import { Component, HostBinding, Input } from '@angular/core';
import { InformationBannerColor } from '@libs/common-ui/information-banner/model/information-banner-color.model';
import { InformationBannerSize } from '@libs/common-ui/information-banner/model/information-banner-size.model';
import { InformationBannerDirection } from '@libs/common-ui/information-banner/model/information-banner-direction.model';

@Component({
  selector: 'staffnow-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent {
  @Input() public message: string = null;
  @Input() public color = InformationBannerColor.GREEN;
  @Input() public size = InformationBannerSize.MEDIUM;
  @Input() public direction = InformationBannerDirection.HORIZONTAL;

  @HostBinding('class.small') get isSmall(): boolean {
    return this.size === InformationBannerSize.SMALL;
  }
  @HostBinding('class.horizontal') get isHorizontal(): boolean {
    return this.direction === InformationBannerDirection.HORIZONTAL;
  }
  @HostBinding('class.blue') get isBlue(): boolean {
    return this.color === InformationBannerColor.BLUE;
  }
  @HostBinding('class.red') get isRed(): boolean {
    return this.color === InformationBannerColor.RED;
  }
}
