<staffnow-create-offer-banner [facilityProfile]="facilityProfile"></staffnow-create-offer-banner>
<div class="contract-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    <div *ngIf="isSettingInitialValues" class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    <div *ngIf="!isSettingInitialValues" class="create-offer-container form-container">
        <!-- CREATE OFFER FORM -->
        <section class="create-offer-main">
            <div class="profile-container">
                <form
                    *ngIf="createAgencyOfferForm"
                    class="form-body"
                    novalidate
                    [ngrxFormState]="createAgencyOfferForm | async"
                >
                    <!-- TYPE AND PRIORITY -->
                    <div class="form-group highlight row create-offer-header">
                        <div class="form-group offer-priority-selector">
                            <label for="priority-level" [class.required]="agencyOfferInitialFormState.priority == null">
                                {{ 'OFFER.FORM.PRIORITY_LEVEL.LABEL' | translate }}
                            </label>
                            <simple-select
                                id="priority-level"
                                [placeholder]="'OFFER.FORM.PRIORITY.PLACEHOLDER' | translate"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="createAgencyOfferFormValue.controls.priority"
                                [valid]="
                                    createAgencyOfferFormValue.controls.priority.isValid &&
                                    createAgencyOfferFormValue.controls.priority.isDirty
                                "
                                [invalid]="createAgencyOfferFormValue.controls.priority.isInvalid"
                                [hasDefaultValue]="agencyOfferInitialFormState.priority != null"
                            >
                            </simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    {{ 'OFFER.FORM.VISIBILITY' | translate }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            <custom-reference-input
                                *ngIf="!isSettingInitialValues"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                                [formState]="createAgencyOfferFormValue"
                            ></custom-reference-input>
                        </div>
                        <div class="form-group col">
                            <label for="period">
                                {{ 'OFFER.FORM.PERIOD.LABEL' | translate }}
                            </label>
                            <date-picker-range
                                id="period"
                                [range]="agencyOfferPeriod"
                                [disabled]="true"
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="title">
                                {{ 'OFFER.FORM.TITLE.LABEL' | translate }}
                            </label>
                            <input
                                id="title"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                maxlength="60"
                                [placeholder]="
                                    'OFFER.FORM.TITLE.PLACEHOLDER'
                                        | translate : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                "
                                [ngrxFormControlState]="createAgencyOfferFormValue.controls.title"
                                [ngClass]="{
                                    'is-valid':
                                        createAgencyOfferFormValue.controls.title.isValid &&
                                        createAgencyOfferFormValue.controls.title.isDirty,
                                    'is-invalid': createAgencyOfferFormValue.controls.title.isInvalid
                                }"
                            />
                            <div *ngIf="createAgencyOfferFormValue.controls.title.isInvalid">
                                <span
                                    class="validation-error"
                                    *ngIf="createAgencyOfferFormValue.controls.title.errors?.invalidTitleLength"
                                >
                                    {{
                                        'OFFER.FORM.INVALID_TITLE_LENGTH'
                                            | translate
                                                : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">
                                {{ 'OFFER.FORM.LOCATION.LABEL' | translate }}
                            </label>
                            <input
                                id="location"
                                type="text"
                                class="form-control"
                                autocomplete="off"
                                disabled
                                [value]="location.name"
                            />
                        </div>
                    </div>
                    <!-- TECHNICIAN TYPE AND LICENSE -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="technician-type" class="required">
                                {{ 'OFFER.FORM.TECHNICIAN_TYPE.LABEL' | translate }}
                            </label>
                            <select
                                id="technician-type"
                                [disabled]="createAgencyOfferFormValue.controls.ameType.isDisabled"
                                class="custom-select"
                                name="type"
                                [ngClass]="{
                                    'is-valid': createAgencyOfferFormValue.controls.ameType.isValid,
                                    'is-invalid':
                                        (highlightErrors || createAgencyOfferFormValue.controls.ameType.isDirty) &&
                                        createAgencyOfferFormValue.controls.ameType.isInvalid
                                }"
                                (change)="onTechnicianTypeChange()"
                                [(ngModel)]="manualUpdateControlValues.ameType"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option value="">
                                    {{ 'GENERAL.SELECT_AN_OPTION' | translate }}
                                </option>
                                <option *ngFor="let option of technicianTypeOptions" [value]="option.name">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col" *ngIf="!isSingletonNomenclature">
                            <label for="level" class="required">
                                {{ 'OFFER.FORM.TECHNICIAN_LEVEL.LABEL' | translate }}
                            </label>
                            <select
                                class="custom-select"
                                [disabled]="createAgencyOfferFormValue.controls.ameTitleId.isDisabled"
                                id="level"
                                [ngClass]="{
                                    'is-valid': createAgencyOfferFormValue.controls.ameTitleId.isValid,
                                    'is-invalid':
                                        highlightErrors && createAgencyOfferFormValue.controls.ameTitleId.isInvalid
                                }"
                                (change)="onTechnicianLevelChange($event)"
                                [(ngModel)]="manualUpdateControlValues.ameTitleId"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="null">
                                    {{ 'GENERAL.SELECT_AN_OPTION' | translate }}
                                </option>
                                <option *ngFor="let license of technicianLevelOptions" [value]="license.id">
                                    {{ license.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- AIRCRAFTS -->
                    <div class="form-group row">
                        <div class="form-group col aircrafts">
                            <label for="airplanes" class="required">
                                {{ 'OFFER.FORM.TYPE_OF_AIRCRAFT.LABEL' | translate }}
                            </label>
                            <multi-select-autocomplete
                                id="airplanes"
                                [placeholder]="'OFFER.FORM.TYPE_OF_AIRCRAFT.PLACEHOLDER' | translate"
                                [options]="aircraftList"
                                [selectedOptions]="selectedAircraftList"
                                (selectionChange)="handleAircraftSelectionChange($event)"
                                [valid]="createAgencyOfferFormValue.controls.airplanes.isValid"
                                [invalid]="
                                    createAgencyOfferFormValue.controls.airplanes.isInvalid &&
                                    (highlightErrors || createAgencyOfferFormValue.controls.airplanes.isDirty)
                                "
                                [disabled]="createAgencyOfferFormValue.controls.airplanes.isDisabled"
                            >
                            </multi-select-autocomplete>
                        </div>
                    </div>
                    <!-- EXPERIENCE AND OPEN POSITIONS -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="experience" class="required">
                                {{ 'OFFER.FORM.MIN_EXPERIENCE.LABEL' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    id="experience"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="60"
                                    autocomplete="off"
                                    [placeholder]="'OFFER.FORM.MIN_EXPERIENCE.PLACEHOLDER' | translate"
                                    [disabled]="createAgencyOfferFormValue.controls.minExperience.isDisabled"
                                    [ngrxFormControlState]="createAgencyOfferFormValue.controls.minExperience"
                                    [ngClass]="{
                                        'is-valid': createAgencyOfferFormValue.controls.minExperience.isValid,
                                        'is-invalid':
                                            createAgencyOfferFormValue.controls.minExperience.isInvalid &&
                                            (highlightErrors ||
                                                createAgencyOfferFormValue.controls.minExperience.isDirty)
                                    }"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="years">{{ 'years' | translate }}</span>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    createAgencyOfferFormValue.controls.minExperience.isInvalid &&
                                    createAgencyOfferFormValue.controls.minExperience.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_MINIMUM_EXPERIENCE_LENGTH'
                                            | translate : { maxLength: MAX_YEARS_OF_EXPERIENCE }
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col">
                            <div>
                                <label for="vacancies" class="required">
                                    {{ 'OFFER.FORM.NUMBER_OF_TECHNICIANS.LABEL' | translate }}
                                </label>
                                <input
                                    class="form-control"
                                    type="number"
                                    id="vacancies"
                                    min="0"
                                    max="1000"
                                    autocomplete="off"
                                    [placeholder]="'OFFER.FORM.NUMBER_OF_TECHNICIANS.PLACEHOLDER' | translate"
                                    [ngrxFormControlState]="createAgencyOfferFormValue.controls.vacancies"
                                    [ngClass]="{
                                        'is-valid': createAgencyOfferFormValue.controls.vacancies.isValid,
                                        'is-invalid':
                                            createAgencyOfferFormValue.controls.vacancies.isInvalid &&
                                            (highlightErrors || createAgencyOfferFormValue.controls.vacancies.isDirty)
                                    }"
                                />
                            </div>
                            <div
                                *ngIf="
                                    createAgencyOfferFormValue.controls.vacancies.isInvalid &&
                                    createAgencyOfferFormValue.controls.vacancies.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_VACANCIES_LENGTH'
                                            | translate : { maxLength: VACANCIES_MAX_VALUE }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- PAY RATE -->
                    <div class="form-group row col">
                        <!--Indicative rate option-->
                        <div class="form-group col custom-control custom-checkbox">
                            <input
                                id="hasIndicativeRate"
                                type="checkbox"
                                class="custom-control-input"
                                (click)="toggleIndicativeRateOption($event)"
                                (keydown)="toggleIndicativeRateOption($event)"
                                [ngrxFormControlState]="createAgencyOfferFormValue.controls.hasIndicativeRate"
                            />
                            <label for="hasIndicativeRate" class="custom-control-label">
                                {{ 'OFFER.FORM.PAY_RATE_PER_HOUR_RANGE_PUBLIC' | translate }}
                            </label>
                        </div>
                    </div>
                    <!--With indicative rate-->
                    <div class="form-group row" *ngIf="hasIndicativeRate; else noIndicativePayRate">
                        <div class="form-group col">
                            <label for="payRate-indicative-rate-lowest" class="required">
                                {{ 'AGENCY_OFFER.HOURLY_RATE_LOWEST' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    id="payRate-indicative-rate-lowest"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="payRateMaxValue"
                                    autocomplete="off"
                                    placeholder="{{ 'Lowest' | translate }}"
                                    [ngrxFormControlState]="createAgencyOfferFormValue.controls.payRate"
                                    [ngClass]="{
                                        'is-valid': createAgencyOfferFormValue.controls.payRate.isValid,
                                        'is-invalid':
                                            highlightErrors && createAgencyOfferFormValue.controls.payRate.isInvalid
                                    }"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currencySymbol }}
                                    </span>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    createAgencyOfferFormValue.controls.payRate.isInvalid &&
                                    createAgencyOfferFormValue.controls.payRate.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{ 'OFFER.FORM.INVALID_PAY_RATE' | translate : { maxPayRate: MAX_CHARGE_RATE } }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col">
                            <label for="payRate-indicative-rate-highest" class="required">
                                {{ 'AGENCY_OFFER.HOURLY_RATE_HIGHEST' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    id="payRate-indicative-rate-highest"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    max="payRateMaxValue"
                                    autocomplete="off"
                                    placeholder="{{ 'Highest' | translate }}"
                                    [ngrxFormControlState]="createAgencyOfferFormValue.controls.maxPayRate"
                                    [ngClass]="{
                                        'is-valid': createAgencyOfferFormValue.controls.maxPayRate.isValid
                                    }"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currencySymbol }}
                                    </span>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    createAgencyOfferFormValue.controls.maxPayRate.isInvalid &&
                                    createAgencyOfferFormValue.controls.maxPayRate.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_MAX_PAY_RATE_LENGTH'
                                            | translate : { maxPayRate: MAX_CHARGE_RATE }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!--Without indicative rate-->
                    <ng-template #noIndicativePayRate>
                        <div class="form-group row">
                            <div class="form-group col">
                                <label for="payRate" class="required">
                                    {{ 'OFFER.FORM.PAY_RATE_PER_HOUR.LABEL' | translate }}
                                </label>
                                <div class="input-group">
                                    <input
                                        id="payRate"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        max="payRateMaxValue"
                                        autocomplete="off"
                                        placeholder="{{ 'OFFER.FORM.PAY_RATE_PER_HOUR.PLACEHOLDER' | translate }}"
                                        [ngrxFormControlState]="createAgencyOfferFormValue.controls.payRate"
                                        [ngClass]="{
                                            'is-valid': createAgencyOfferFormValue.controls.payRate.isValid,
                                            'is-invalid':
                                                createAgencyOfferFormValue.controls.payRate.isInvalid &&
                                                (highlightErrors || createAgencyOfferFormValue.controls.payRate.isDirty)
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            {{ currencySymbol }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        createAgencyOfferFormValue.controls.payRate.isDirty &&
                                        createAgencyOfferFormValue.controls.payRate.isInvalid
                                    "
                                >
                                    <span class="validation-error">
                                        {{
                                            'OFFER.FORM.INVALID_PAY_RATE' | translate : { maxPayRate: MAX_CHARGE_RATE }
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group col">&nbsp;</div>
                        </div>
                    </ng-template>
                    <!-- WORKING PATTERN AND DESCRIPTION -->
                    <div class="form-group row">
                        <div class="form-group col-4 pattern-view">
                            <label for="work-pattern">
                                {{ 'OFFER.FORM.WORK_PATTERN.LABEL' | translate }}
                            </label>
                            <div class="custom-controls-stacked">
                                <div class="custom-control custom-radio">
                                    <input
                                        id="work-pattern"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{ fixedWorkingPatterns.first }}"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.first)"
                                        (change)="setWorkingPattern($event)"
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label for="work-pattern" class="custom-control-label">
                                        {{ '5 days on - 2 days off' | translate }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="second-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{ fixedWorkingPatterns.second }}"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.second)"
                                        (change)="setWorkingPattern($event)"
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label for="second-radio" class="custom-control-label">
                                        {{ '3 days on - 3 days off' | translate }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="third-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="{{ fixedWorkingPatterns.third }}"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.third)"
                                        (change)="setWorkingPattern($event)"
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label for="third-radio" class="custom-control-label">
                                        {{ '6 days on - 1 days off' | translate }}
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        id="fourth-radio"
                                        type="radio"
                                        name="pattern"
                                        class="custom-control-input"
                                        value="custom"
                                        (click)="customPatternSet()"
                                        (keydown)="customPatternSet()"
                                        [checked]="isCustomPatternSelected"
                                        [disabled]="isRestrictedMode()"
                                    />
                                    <label for="fourth-radio" class="custom-control-label">
                                        {{ 'Custom' | translate }}
                                    </label>
                                </div>
                                <div class="form-row mt-2">
                                    <div class="form-group col-6 padded" *ngIf="isCustomPatternSelected">
                                        <label for="work-days" class="required">
                                            {{ 'OFFER.FORM.WORK_DAYS.LABEL' | translate }}
                                        </label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="work-days"
                                            [tooltip]="'OFFER.FORM.WORK_DAYS.LABEL' | translate"
                                            name="ondays"
                                            min="1"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            (input)="setCustomWorkingPattern($event, true)"
                                            [ngClass]="{
                                                'is-invalid': createAgencyOfferFormValue.controls.workDaysOn.isInvalid
                                            }"
                                            [(ngModel)]="customWorkDaysPattern.on"
                                            [disabled]="isRestrictedMode()"
                                        />
                                    </div>
                                    <div class="form-group col-6" *ngIf="isCustomPatternSelected">
                                        <label for="off-days" class="required">
                                            {{ 'OFFER.FORM.OFF_DAYS.LABEL' | translate }}
                                        </label>
                                        <input
                                            class="form-control"
                                            type="number"
                                            id="off-days"
                                            [tooltip]="'OFFER.FORM.OFF_DAYS.LABEL' | translate"
                                            name="offdays"
                                            min="0"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            (input)="setCustomWorkingPattern($event, false)"
                                            [ngClass]="{
                                                'is-invalid': createAgencyOfferFormValue.controls.workDaysOff.isInvalid
                                            }"
                                            [(ngModel)]="customWorkDaysPattern.off"
                                            [disabled]="isRestrictedMode()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-8">
                            <label for="job-description" class="required">
                                {{ 'OFFER.FORM.JOB_DESCRIPTION.LABEL' | translate }}
                            </label>
                            <textarea
                                id="job-description"
                                class="form-control"
                                name="presentation"
                                cols="30"
                                rows="5"
                                [ngrxFormControlState]="createAgencyOfferFormValue.controls.shortPresentation"
                                [ngClass]="{
                                    'is-valid': createAgencyOfferFormValue.controls.shortPresentation.isValid,
                                    'is-invalid':
                                        createAgencyOfferFormValue.controls.shortPresentation.isInvalid &&
                                        (highlightErrors ||
                                            createAgencyOfferFormValue.controls.shortPresentation.isDirty)
                                }"
                            >
                            </textarea>
                        </div>
                    </div>
                    <!-- ADDITIONAL BENEFITS -->
                    <div class="form-group row mb-3" *ngIf="!hasIndicativeRate">
                        <collapsible heading="Benefits" style="width: 100%" highlight="true">
                            <div style="padding: 0 20px">
                                <staffnow-offer-benefit
                                    *ngFor="let benefit of benefits; let i = index"
                                    [(ngModel)]="benefits[i]"
                                    [ngModelOptions]="{ standalone: true }"
                                    [currencySymbol]="currencySymbol"
                                    [isValid]="isBenefitValid(benefits[i])"
                                    (changer)="markBenefitsAsDirty()"
                                    [isDisabled]="isRestrictedMode()"
                                ></staffnow-offer-benefit>
                            </div>
                        </collapsible>
                    </div>
                    <!-- FORM ACTIONS -->
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            class="mr-5"
                            backgroundStyle="outline"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            [text]="'CONFIRM' | translate"
                            [isLoading]="isSubmitting"
                            (onClick)="handleFormSubmit()"
                        ></general-button>
                    </div>
                    <div *ngIf="highlightErrors" class="invalid-form-error">
                        <span class="validation-error">
                            {{ 'OFFER.FORM.INVALID_SUBMITTED_FORM' | translate }}
                        </span>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>
