<span *ngIf="displayLabel">{{ 'Priority: ' | translate }}</span>
<strong
    [ngClass]="{
        'text-danger': isHighPriority(),
        'text-success': isMediumPriority(),
        'text-secondary': isLowPriority()
    }"
>
    {{ priority | translate }}
</strong>
