<nav
    closeOnOutside
    [triggerClass]="['hamburger']"
    (close)="closeMenuOnSelectItem()"
    class="navigation-mobile"
    [ngClass]="[isAsideMenuOpen ? 'visible' : '', brandName]"
>
    <ul>
        <li
            class="menu-item"
            *ngFor="let item of menuItems"
            [routerLinkActiveOptions]="{
                paths: 'subset',
                queryParams: 'ignored',
                matrixParams: 'ignored',
                fragment: 'ignored'
            }"
            [routerLinkActive]="['active']"
        >
            <a href="#" [routerLink]="['/' + item.route]" (click)="closeMenuOnSelectItem()">
                {{ item.label }}
            </a>
        </li>
    </ul>
    <div class="footer" *ngIf="!isPlatformWeb">
        <p>
            The {{ brandName }} app is a lean version of the {{ brandName }} platform. For the full experience, visit
            <a [href]="appUrl">{{ appUrl }}</a
            >.
        </p>
    </div>
</nav>
