import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MathService } from './services/math.service';
import { UsersService } from './services/users.service';
import { JobOpeningContractDeclinationService } from './services/job-opening-contract-declination.service';

import { RatePipe } from './pipes/rate.pipe';
import { ProfileStatusNotificationFormatterPipe } from './pipes/profile-status-notification-formater.pipe';
import { NameFormatterPipe } from './pipes/status-changed-by.pipe';
import { SelectedAircraftPipe } from './pipes/selected-aircrafts.pipe';
import { HumanReadableRolePipe } from './pipes/human-readable-role.pipe';
import { HumanReadableProfileStatusPipe } from './pipes/human-readable-profile-status.pipe';

import { HasAccessGuardService } from './services/has-access-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalDateWithTimeFormatPipe } from '@libs/shared/pipes/local-date-with-time-format/local-date-with-time-format.pipe';
import { AbsoluteDateFormatPipe } from '@libs/shared/pipes/absolute-date-format/absolute-date-format.pipe';
import { LooseChangesGuard } from './services/loose-changes-guard.service';
import { ResourceFactory } from './bms-common/rest/resource.factory';
import { ENVIRONMENT_CONFIG, getEnvironment } from './bms-common/environment/environment.loader';
import { IsAgencyGuardService } from './services/is-agency-guard.service';
import { IsTechnicianGuardService } from './services/is-technician-guard.service';
import { IsMroGuardService } from './services/is-mro-guard.service';
import { YesNoPipe } from '@libs/shared/pipes/yes-no.pipe';
import { HttpInterceptorService } from '@libs/auth/services/http-interceptor.service';
import { AmeTitleNamePipe } from './pipes/ame-title-name.pipe';
import { ResetPasswordService } from './services/reset-password.service';
import { RequestNewPasswordService } from './services/request-new-password.service';
import { DashboardsService } from '@libs/shared/services/dashboards.service';
import { ShortDateFormatPipe } from '@libs/shared/pipes/short-date-format/short-date-format.pipe';

@NgModule({
  declarations: [
    SelectedAircraftPipe,
    HumanReadableProfileStatusPipe,
    NameFormatterPipe,
    HumanReadableRolePipe,
    ProfileStatusNotificationFormatterPipe,
    LocalDateWithTimeFormatPipe,
    AbsoluteDateFormatPipe,
    ShortDateFormatPipe,
    RatePipe,
    YesNoPipe,
    AmeTitleNamePipe
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  providers: [
    HttpInterceptorService,
    MathService,
    UsersService,
    JobOpeningContractDeclinationService,
    HasAccessGuardService,
    IsAgencyGuardService,
    IsTechnicianGuardService,
    IsMroGuardService,
    RequestNewPasswordService,
    ResetPasswordService,
    LooseChangesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    ResourceFactory,
    { provide: ENVIRONMENT_CONFIG, useFactory: getEnvironment },
    DashboardsService
  ],
  exports: [
    SelectedAircraftPipe,
    HumanReadableProfileStatusPipe,
    NameFormatterPipe,
    HumanReadableRolePipe,
    ProfileStatusNotificationFormatterPipe,
    YesNoPipe,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    LocalDateWithTimeFormatPipe,
    AbsoluteDateFormatPipe,
    ShortDateFormatPipe,
    CommonModule,
    RatePipe,
    AmeTitleNamePipe
  ]
})
export class SharedModule {
  public static forRoot(apiConfig: any): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        HttpInterceptorService,
        LooseChangesGuard,
        {
          provide: 'apiConfig', // you can also use InjectionToken
          useValue: apiConfig
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        }
      ]
    };
  }
}
