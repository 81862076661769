import { Component, Input, OnInit } from '@angular/core';
import { Contract } from '@libs/shared/models/contract.model';
import {hasLink} from "@libs/shared/bms-common/rest/resource.utils";
import {RequestOverviewLinkRel} from "@libs/shared/linkrels/request-overview.linkrel";

@Component({
  selector: 'app-date-information-alert',
  templateUrl: './date-information-alert.component.html',
  styleUrls: ['./date-information-alert.component.scss']
})
export class DateInformationAlertComponent implements OnInit {
  @Input() contracts: Contract[];

  contractsWithoutStartDate: number;
  contractsWithoutEndDate: number;

  ngOnInit(): void {
    this.getNumberOfContractsAwaitingStartDate();
    this.getNumberOfContractsAwaitingEndDate();
  }

  getNumberOfContractsAwaitingStartDate(): void {
    this.contractsWithoutStartDate = this.contracts?.reduce(
      (acc: number, contract: Contract) => acc + (this.contractIsAwaitingStartDate(contract) ? Number(!contract.startDate) : 0),
      0
    );
  }

  getNumberOfContractsAwaitingEndDate(): void {
    this.contractsWithoutEndDate = this.contracts?.reduce(
      (acc: number, contract: Contract) => acc + (this.contractIsAwaitingEndDate(contract) ? Number(!contract.endDate) : 0),
      0
    );
  }

  contractIsAwaitingStartDate(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.ProposeContractStartDate) || hasLink(contract, RequestOverviewLinkRel.AcceptContractStartDate);
  }

  contractIsAwaitingEndDate(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.EndContract);
  }
}
