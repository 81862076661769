<div *ngIf="agencies$ | async as agencies">
    <ng-select
        bindLabel="name"
        bindValue="uuid"
        [(ngModel)]="selectedAgencyUuid"
        [items]="agencies"
        [loading]="loading"
        (close)="onSearch('')"
        (clear)="onSearch('')"
        (click)="findSelectedAgencyName()"
        (keydown.enter)="findSelectedAgencyName()"
        (scrollToEnd)="loadMore(agencies)"
        (search)="onSearch($event.term)"
        [searchable]="true"
        [placeholder]="'GENERAL.SEARCH_BY_NAME' | translate"
        [readonly]="readOnly"
    >
    </ng-select>
</div>
