import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModalService } from '../../../../common-ui/src/lib/services/modal.service';

@Injectable()
export class LooseChangesGuard  {
  constructor(private confirmService: ModalService) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    if (
      !component.editSection ||
      !component.editSection.accountForm ||
      !component.editSection.activeItem
    ) {
      return true;
    }

    if (
      component.editSection.accountForm.isDirty &&
      component.editSection.activeItem === 'Account'
    ) {
      return this.confirmChanges();
    }

    return true;
  }

  private confirmChanges(): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirmService.openConfirmModal(
        'SYSTEM.INFO.LEAVE_PAGE_CONFIRM',
        () => {
          resolve(true);
        }
      );
    });
  }
}
