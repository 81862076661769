import { ChangeDetectionStrategy, Component, forwardRef, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@libs/common-ui/buttons/buttons.module';
import { TechnicianSourceService } from '@libs/shared/services/technician-source.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MroSelectorComponent } from '@libs/common-ui/mro-selector/mro-selector.component';
import { TranslationModule } from '@libs/translation/translation.module';
import { Store } from '@ngrx/store';
import { getFilteredApiRoot, getOwnProfileUrl } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { map, switchMap, take } from 'rxjs/operators';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { MroFacilityLoaderService } from '../../../../offer-management/shared/services/mro-facility-loader.service';
import { Facility, MroFacility } from '@libs/shared/models/facility.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { AgencySelectorComponent } from '@libs/common-ui/agency-selector/agency-selector.component';
import { AgencyFacilityLoaderService } from '../../../../offer-management/shared/services/agency-facility-loader.service';
import { StaffLoaderComponent } from '@libs/common-ui/staff-loader/staff-loader.component';

export interface ITechnicianSource {
  id: number;
  name: string;
}
export interface KnowledgeSource {
  sourceId: string;
  notes?: string;
}

@Component({
  selector: 'staffnow-technician-source-selector',
  standalone: true,
  imports: [
    FormsModule,
    ButtonsModule,
    AsyncPipe,
    forwardRef(() => AgencySelectorComponent),
    MroSelectorComponent,
    TranslationModule,
    StaffLoaderComponent
  ],
  templateUrl: './technician-source-selector.component.html',
  styleUrl: './technician-source-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechnicianSourceSelectorComponent implements OnInit, OnDestroy {
  KNOWLEDGE_AVIATION_SOURCE_ID: number = 5;
  KNOWLEDGE_AGENGY_SOURCE_ID: number = 4;

  private subscription: Subscription = new Subscription();
  private readonly mroLoaderService: MroFacilityLoaderService = inject(MroFacilityLoaderService);
  private readonly agencyLoaderService: AgencyFacilityLoaderService = inject(AgencyFacilityLoaderService);

  userUuid: string;
  mro: KnowledgeSource = {
    sourceId: null,
    notes: null
  };
  agency: KnowledgeSource = {
    sourceId: null,
    notes: null
  };

  id: number = 0;
  sources$: Observable<ITechnicianSource[]>;

  constructor(
    private technicianSourceService: TechnicianSourceService,
    private bsModalRef: BsModalRef,
    private store: Store<any>
  ) {
    this.subscription.add(
      this.store.pipe(getOwnProfileUrl).subscribe(url => {
        if (url && typeof url == 'string') {
          const urlParts: string[] = url?.split('/');
          this.userUuid = urlParts[urlParts.length - 1];
        }
      })
    );
  }

  ngOnInit(): void {
    this.sources$ = this.technicianSourceService.getTechnicianSources();
    window.addEventListener('storage', this.handleStorageChange.bind(this));
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.subscription.unsubscribe();
  }

  handleStorageChange(event: StorageEvent): void {
    if (event.key === 'access_token' && !event.newValue) {
      this.closeModal();
    }
  }

  acceptHandler(): void {
    this.subscription.add(
      this.technicianSourceService
        .updateTechnicianSource(this.userUuid, this.getknowledgeSource())
        .subscribe(() => this.closeModal())
    );
  }

  getknowledgeSource(): KnowledgeSource {
    this.updateSourceId();
    return this.isAviationOrAgencySourceId(this.id)
      ? this.isAviationSelectorSelected()
        ? this.mro
        : this.agency
      : { sourceId: this.id.toString() };
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  loadMroList(term: string, pageNumber: number, pageSize: number): Observable<MroFacility[]> {
    return this.store.pipe(
      getFilteredApiRoot,
      take(1),
      switchMap((apiRoot: ApiRootResource) =>
        this.mroLoaderService.getMroFacilities(getUrl(apiRoot, ApiRootLinkRel.GetMroList), term, pageNumber, pageSize)
      ),
      map(facilityPage => facilityPage._embedded.facilities)
    );
  }

  loadAgencyList(term: string, pageNumber: number, pageSize: number): Observable<Facility[]> {
    return this.store.pipe(
      getFilteredApiRoot,
      take(1),
      switchMap((apiRoot: ApiRootResource) =>
        this.agencyLoaderService.getAgencyFacilities(
          getUrl(apiRoot, ApiRootLinkRel.GetAgencyFacilities),
          term,
          pageNumber,
          pageSize
        )
      ),
      map(facilityPage => facilityPage._embedded.facilities)
    );
  }

  isDisableAcceptHandler(id: number): boolean {
    return this.isAviationOrAgencySourceId(id) ? !(this.mro?.sourceId || this.agency?.sourceId) : !id;
  }

  updateNote(name: string): void {
    if (this.isAviationSelectorSelected()) {
      this.mro.notes = name;
    }
    if (this.isAgencySelectorSelected()) {
      this.agency.notes = name;
    }
  }

  updateSourceId(): void {
    if (this.isAviationSelectorSelected()) {
      this.mro.sourceId = this.KNOWLEDGE_AVIATION_SOURCE_ID.toString();
    }
    if (this.isAgencySelectorSelected()) {
      this.agency.sourceId = this.KNOWLEDGE_AGENGY_SOURCE_ID.toString();
    }
  }

  private isAviationSelectorSelected(): boolean {
    return this.id === this.KNOWLEDGE_AVIATION_SOURCE_ID;
  }

  private isAgencySelectorSelected(): boolean {
    return this.id === this.KNOWLEDGE_AGENGY_SOURCE_ID;
  }

  private isAviationOrAgencySourceId(id: number): boolean {
    return id == this.KNOWLEDGE_AGENGY_SOURCE_ID || id == this.KNOWLEDGE_AVIATION_SOURCE_ID;
  }
}
