import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UserProfileActions } from '../../../state/user-profile.actions';
import { ofType } from '@ngrx/effects';
import { selectUserProfile } from '@libs/user-profile/state/user-profile.selectors';
import { tap } from 'rxjs/operators';
import { TechnicianProfileModel } from '@libs/shared/models/technician-profile.model';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToggleWeeklyJoSummarySubscriptionParameters } from '@libs/common-ui/weekly-jo-summary-subscription/weekly-jo-summary-subscription.component';

@Component({
  selector: 'staffnow-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public blocklist: any = {};
  @Output() public loadBlocklist: EventEmitter<any> = new EventEmitter();
  @Output() public submitBlocklist: EventEmitter<any> = new EventEmitter();
  @Output()
  public loadNotificationSubscriptions: EventEmitter<any> = new EventEmitter();
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onToggleWeeklyJoSummarySubscription: EventEmitter<ToggleWeeklyJoSummarySubscriptionParameters> = new EventEmitter();

  public userProfile: TechnicianProfileModel;
  public blockedEntities: Array<any> = [];
  public valueChanged = false;
  public isLoading = false;
  private subs: Array<Subscription> = [];

  constructor(
    private actionsSubject: ActionsSubject,
    private store: Store<any>,
    private modalService: ModalService
  ) {
    this.subs.push(
      this.actionsSubject
        .pipe(ofType(UserProfileActions.SubmitBlocklist))
        .subscribe(() => {
          this.isLoading = true;
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(UserProfileActions.BlocklistLoaded))
        .subscribe(() => {
          this.valueChanged = false;
          this.isLoading = false;
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(UserProfileActions.FailedToGetBlocklist))
        .subscribe(() => {
          this.isLoading = false;
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(UserProfileActions.FailedToSubmitBlocklist))
        .subscribe(() => {
          this.isLoading = false;
        })
    );

    this.subs.push(
      this.store
        .select(selectUserProfile)
        .pipe(
          tap(
            userProfile =>
              (this.userProfile = userProfile as TechnicianProfileModel)
          )
        )
        .subscribe()
    );
  }

  public ngOnInit() {
    this.loadBlocklist.emit();
    this.loadNotificationSubscriptions.emit();
  }

  public ngOnChanges() {
    this.valueChanged = false;
    this.blockedEntities = this.blocklist.selectedFacilities;
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  public getBlocklist($event) {
    this.valueChanged = true;
    this.blockedEntities = $event;
  }

  public submitBlockedEntities() {
    this.modalService.openCustomizableConfirmModal({
      messages: [ 'Are you sure you want to hide from the selected aviation companies?' ],
      icon: 'visibility_off',
      canCancel: true,
      cancelText: 'No',
      acceptText: 'Yes',
      onCancelCallback: () => this.loadBlocklist.emit(),
      onConfirmedCallback: () => this.submitBlocklist.emit(this.blockedEntities.map(entity => entity.uuid))
    });
  }

  public toggleWeeklyJoSummarySubscription(event: ToggleWeeklyJoSummarySubscriptionParameters) {
    this.onToggleWeeklyJoSummarySubscription.emit(event);
  }
}
