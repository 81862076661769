<div class="overview-container">
    <div class="overview-filters align-items-center">
        <h4 class="h4">{{ 'TASKS.VIEW.APPLICATIONS_FILTERS.LABEL' | translate }}</h4>
        <div class="filters-buttons">
            @for (filterLabel of filterLabels; track filterLabel.filter; let i = $index;) {
            <general-button
                (onClick)="onToggleFiltersClick(filterLabel)"
                [highlight]="filterLabel.filter === currentFilter?.filter"
                color="primary-lowlight"
                size="small"
                [text]="filterLabel.label | translate : { isTechnician: false }"
            ></general-button>
            }
        </div>
    </div>
</div>
