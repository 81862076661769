import { Component, Input, OnInit } from '@angular/core';
import { PackageOfferDto } from '@libs/shared/models/offer.model';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { MroFacility } from '@libs/shared/models/facility.model';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PackageContract } from '@libs/shared/models/package-contract.model';
import { cloneDeep } from 'lodash-es';
import { getCurrentUTCDateString } from '@libs/shared/helpers/date-utils';
import { PackageContractPayload } from '@libs/common-ui/create-package-contract-form/package-contract-payload.model';

@Component({
  selector: 'staffnow-create-package-contract-form',
  templateUrl: './create-package-contract-form.component.html',
  styleUrls: ['./create-package-contract-form.component.scss']
})
export class CreatePackageContractFormComponent implements OnInit {
  @Input() public offer: PackageOfferDto = null;
  @Input() public contract?: PackageContract = null;
  @Input() public mode?: 'EDIT' | 'CREATE' = 'CREATE';
  @Input() public onClick: (payload: PackageContractPayload) => void;

  public readonly AGENCY_NOTES_MAX_LENGTH: number = 200;
  public selectedStartDate: string = getCurrentUTCDateString();
  public selectedEndDate: string = getCurrentUTCDateString();
  public offeredPrice: number = 0;
  public agencyNotes: string = null;
  public priceEstimate: boolean = false;
  public showDateError: boolean = false;

  constructor(public bsModalRef: BsModalRef) {}

  get currencySymbol(): string {
    const mroFacility = getEmbeddedResource<MroFacility>(this.offer, JobOfferLinkRel.Facility);
    return mroFacility.workingCurrency;
  }

  get isEditMode(): boolean {
    return this.mode === 'EDIT';
  }

  ngOnInit(): void {
    if (this.isEditMode) {
      const price = cloneDeep(this.contract.agencyOfferedPrices).pop();
      this.offeredPrice = price?.amount ?? 0;
      this.selectedStartDate = this.contract.startDate;
      this.selectedEndDate = this.contract.endDate;
      this.agencyNotes = this.contract.agencyNotes;
      this.priceEstimate = this.contract.priceEstimate;
    } else {
      this.selectedStartDate = this.offer.periodFrom;
      this.selectedEndDate = this.offer.periodTo;
    }
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public confirm(): void {
    this.onClick({
      offer: this.offer,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      agencyOfferedPrice: this.offeredPrice,
      agencyNotes: this.agencyNotes,
      priceEstimate: this.priceEstimate
    });
    this.close();
  }

  public handleStartDateUpdate(date: string): void {
    this.selectedStartDate = date;
    this.validateDates();
  }

  public handleEndDateUpdate(date: string): void {
    this.selectedEndDate = date;
    this.validateDates();
  }

  public validateDates(): void {
    if (!this.selectedStartDate || !this.selectedEndDate) return;
    this.showDateError = new Date(this.selectedStartDate) > new Date(this.selectedEndDate);
  }
}
