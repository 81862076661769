<div
    class="form-control date-picker"
    [ngClass]="{
        disabled: disabled,
        'is-valid': valid,
        'is-invalid': invalid
    }"
    (click)="openCalendar(datePicker)"
    (keydown)="openCalendar(datePicker)"
>
    <span>
        {{
            _range.length != 2
                ? placeholder
                : (_range[0] | absoluteDateFormat) + ' - ' + (_range[1] | absoluteDateFormat)
        }}
    </span>
    <span
        #datePicker="bsDaterangepicker"
        bsDaterangepicker
        [bsValue]="_range"
        (bsValueChange)="emitChange($event)"
        [bsConfig]="{
            showWeekNumbers: false,
            displayMonths: displayMonths
        }"
        [placement]="placement"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
    ></span>
</div>
