import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';
import { isEmpty, isNil } from 'lodash-es';
import { DocumentsLinkRel, StaffnowDocument } from '@libs/user-profile/model/documents.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { getCurrentUTCDateString, isDateInThePastOrToday, subtractDaysFromDate } from '@libs/shared/helpers/date-utils';
import { LabelSetterDialogComponent } from '@libs/common-ui/label-setter-dialog/label-setter-dialog.component';
import { FileItem } from 'ng2-file-upload';

@Component({
  selector: 'staffnow-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {
  @Input() public documents: StaffnowDocument[] = [];
  @Input() public uploader?: any = null;
  @Input() public canEdit?: boolean = false;
  @Input() public isAdmin?: boolean = false;
  @Input() public maximumFilesLimit?: number;
  @Input() public isReadOnly: boolean = false;
  @Input() public isFileOver: boolean = false;
  @Input() public showLabel: boolean = false;
  @Input() public noDocumentsUploadedDisclaimer: string;

  @Output() public singleDocumentStatus: EventEmitter<any> = new EventEmitter();
  @Output() public fileDeleted: EventEmitter<FileItem> = new EventEmitter();
  @Output() public downloaded: EventEmitter<any> = new EventEmitter();
  @Output() public expirationDateSet: EventEmitter<any> = new EventEmitter();
  @Output() public labelSet: EventEmitter<any> = new EventEmitter();

  public fileUploadConstants: any;

  constructor(private modalService: BsModalService) {}

  get documentsIsEmpty(): boolean {
    return isEmpty(this.documents);
  }

  get anyDocumentRequiresExpirationDate(): boolean {
    return !isEmpty(this.documents.filter(doc => doc.isExpirationDateRequired));
  }

  public ngOnInit() {
    this.fileUploadConstants = fileUploadConstants;
  }

  public handleDocumentStatusChange($event, item, url) {
    this.singleDocumentStatus.emit({
      uuid: item.uuid,
      status: $event,
      fileUrl: url
    });
  }

  public cancelFileUpload(item: FileItem): void {
    item.cancel();
  }

  public removeFile(item: any): void {
    this.fileDeleted.emit(item);
  }

  public downloadDocument(item) {
    this.downloaded.emit(item);
  }

  public canSetExpirationDate(item: StaffnowDocument): boolean {
    return hasLink(item, DocumentsLinkRel.SET_DOCUMENT_EXPIRATION_DATE);
  }

  public openExpirationDateDialog(document: StaffnowDocument): void {
    this.modalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (selectedDate: string) => this.expirationDateSet.emit({ document, selectedDate }),
        selectedDate: getCurrentUTCDateString(),
        warningMessage: isNil(document.expirationDate) ? null : 'SYSTEM.WARNING.DOCUMENT_EXPIRATION_DATE_EXISTS',
        title: 'SYSTEM.INFO.DOCUMENT_EXPIRATION_DATE_TILE',
        reminders: [{ text: 'SYSTEM.INFO.DOCUMENT_EXPIRATION_DATE_REMINDER' }],
        isBackoffice: true
      }
    });
  }

  public canSetLabel(item: StaffnowDocument): boolean {
    return hasLink(item, DocumentsLinkRel.LABEL_DOCUMENT);
  }

  public openSetLabelDialog(document: StaffnowDocument): void {
    this.modalService.show(LabelSetterDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (label: string) => this.labelSet.emit({ document, label }),
        label: document.label,
        title: 'SYSTEM.INFO.DOCUMENT_LABEL'
      }
    });
  }

  public shouldDisplayWarning(expiration: string): boolean {
    return !this.isExpired(expiration) && this.atLeastOneMonthLeftToDate(expiration);
  }

  public isExpired(expiration: string): boolean {
    return isDateInThePastOrToday(expiration);
  }

  private atLeastOneMonthLeftToDate(expiration: string): boolean {
    const oneMonthBeforeExpiration = subtractDaysFromDate(expiration, 31);
    return isDateInThePastOrToday(oneMonthBeforeExpiration);
  }
}
