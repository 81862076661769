import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {RoleWrapper} from '@libs/shared/models/roles.enum';
import {OfferOutDto, PermanentOfferDto} from '@libs/shared/models/offer.model';
import {Contract} from '@libs/shared/models/contract.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';

@Directive()
export abstract class AbstractExpandComponent {
  @Input() public effectiveRole: RoleWrapper = null;
  @Input() public offer: OfferOutDto | PermanentOfferDto = null;

  @Output() openPublicProfile: EventEmitter<{ contract: Contract; entityType: EntityTypes }> = new EventEmitter<{
    contract: Contract;
    entityType: EntityTypes;
  }>();
  @Output() acceptContract: EventEmitter<{ contract: Contract; offer: OfferOutDto }> = new EventEmitter<{
    contract: Contract;
    offer: OfferOutDto;
  }>();

  isMro(): boolean {
    return this.effectiveRole?.isMro();
  }

  /**
   *
   * @param contract
   * @param offer optional for when the method is called
   * from the offer info expand , because we need the offer
   * so we can get the agencylist url and open the agency popup
   */
  onAcceptContract(contract: Contract, offer?: OfferOutDto): void {
    this.acceptContract.emit({ contract, offer });
  }

  onOpenPublicProfile(entityType: EntityTypes, contract: Contract): void {
    this.openPublicProfile.emit({ entityType, contract: { ...contract } });
  }
}
