import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DocumentArea,
  DocumentType,
  StaffnowDocument
} from '../../../../model/documents.model';
import { Store } from '@ngrx/store';
import { DocumentsService } from '../../../../services/documents.service';
import {
  InitiateDocumentDownload,
  RemoveDocument,
  SetDocumentExpirationDate,
  SetDocumentPossession,
  UpdateUserProfile
} from '@libs/user-profile/state/user-profile.actions';
import { AbstractProfileComponent } from '@libs/user-profile/components/abstract-profile-component';
import { ActivatedRoute } from '@angular/router';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';

@Component({
  selector: 'staffnow-document-area',
  templateUrl: './document-area.component.html',
  styleUrls: ['./../collapsible-document.component.scss']
})
export class DocumentAreaComponent extends AbstractProfileComponent {
  private static readonly DOCUMENT_PENDING_CHANGE_CONTEXT = 'DOCUMENT';

  @Input() public technicianPendingChanges: Array<any> = [];
  @Input() documents: StaffnowDocument[];
  @Input() documentArea: DocumentArea;
  @Input() isLastArea = false;

  @Output() openArea = new EventEmitter<void>();
  @Output() openNextArea = new EventEmitter<void>();
  @Output() successfulFileUpload: EventEmitter<{ context: string; documentAreaId: number }> = new EventEmitter();

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute
  ) {
    super(store, documentsService, activatedRoute);
  }

  public onOpenArea(): void {
    this.openArea.emit();
  }

  public onOpenNextArea(): void {
    this.openNextArea.emit();
  }

  public hasMandatoryDocuments(): boolean {
    return this.documentArea?.documentEntities.some(document => document.isMandatory);
  }

  public getDocumentsByContext(context: string): StaffnowDocument[] {
    return this.documents?.filter(document => document.context === context);
  }

  public isDocumentRejected(documentType: DocumentType): boolean {
    return this.documentsService.isDocumentRejected(documentType?.context);
  }

  public isDocumentMissing(documentType: DocumentType): boolean {
    return this.documentsService.isDocumentMissing(documentType?.context);
  }

  public removeFile(file: any): void {
    const payload: any = { file };
    this.store.dispatch(RemoveDocument(
      {
        payload,
        documentAreaId: this.documentArea.id,
        documentContextsEmptyAfterRemoval:
          this.findDocumentContextsEmptyAfterRemoval(file)
      }
    ));
  }

  private findDocumentContextsEmptyAfterRemoval(file: any): string[] {
    const allContexts = this.documents.filter(it => it.name === file.name).map(it => it.context);
    const emptyContextsAfterRemoval = allContexts.filter(context =>
      this.documents.filter(it => it.context === context).length === 1
    );
    return emptyContextsAfterRemoval;
  }

  public downloadFile(url: string): void {
    this.store.dispatch(InitiateDocumentDownload({ documentDownloadUrl: url }));
  }

  public onSuccessfulFileUpload(event: any): void {
    this.store.dispatch(UpdateUserProfile());
    this.successfulFileUpload.emit({ ...event, documentAreaId: this.documentArea.id });
  }

  public handleDocumentStatusChange($event): void {
    this.documentsService.changeDocumentStatus($event);
  }

  public setExpirationDate({
    document,
    selectedDate
  }: {
    document: StaffnowDocument;
    selectedDate: string;
  }): void {
    this.store.dispatch(SetDocumentExpirationDate({ document, selectedDate }));
  }

  public setPossession(documentType: DocumentType): void {
    this.store.dispatch(SetDocumentPossession({ documentAreaId: this.documentArea.id, document: documentType } ));
  }

  public filesAdded(documentType: DocumentType): number {
    return this.technicianPendingChanges
      ? this.technicianPendingChanges.filter(
          it =>
            it['context'] ===
              DocumentAreaComponent.DOCUMENT_PENDING_CHANGE_CONTEXT &&
            it['subject'] === documentType.context &&
            !!it['subjectUuid']
        ).length
      : 0;
  }

  public filesRemoved(documentType: DocumentType): number {
    return this.technicianPendingChanges
      ? this.technicianPendingChanges.filter(
          it =>
            it['context'] ===
              DocumentAreaComponent.DOCUMENT_PENDING_CHANGE_CONTEXT &&
            it['subject'] === documentType.context &&
            !it['subjectUuid']
        ).length
      : 0;
  }

  public getNoDocumentsUploadedDisclaimer(): string {
    if(this.isAdmin || this.isModerator){
      return 'FILES.TECHNICIAN_NO_DOC_UPLOADED_DISCLAIMER';
    } else{
      return fileUploadConstants.noDocumentsFoundText;
    }
  }
}
