import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { EffectsModule } from '@ngrx/effects';

import { RegistrationComponent } from './components/registration.component';
import { getReducers as getRegistrationReducers, reducersInjectionToken } from './state/registration.reducers';
import {
  initialState as registrationInitialState,
  REGISTRATION_FEATURE_KEY,
  RegistrationState
} from './state/registration-state.model';
import { TechnicianRegistrationFormComponent } from './components/technician-registration-form/technician-registration-form.component';
import { SharedModule } from '../shared/shared.module';
import { RegistrationEffects } from './state/registration.effects';
import { RegistrationService } from './services/registration.service';
import { GoBackButtonComponent } from './components/go-back-button/go-back-button.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { WrongAppComponent } from './components/wrong-app/wrong-app.component';
import { UnauthorizedAppComponent } from './components/unauthorized-app/unauthorized-app.component';
import { RegistrationHeaderComponent } from './components/header/registration-header.component';

export function getInitialState(): Partial<RegistrationState> {
  return {
    ...registrationInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    NgrxFormsModule,
    SharedModule,
    HttpClientModule,
    EffectsModule.forFeature([RegistrationEffects]),
    StoreModule.forFeature(REGISTRATION_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    CommonUiModule,
    RegistrationHeaderComponent
  ],
  declarations: [
    RegistrationComponent,
    TechnicianRegistrationFormComponent,
    GoBackButtonComponent,
    WrongAppComponent,
    UnauthorizedAppComponent
  ],
  providers: [{ provide: reducersInjectionToken, useFactory: getRegistrationReducers }, RegistrationService],
  exports: [RegistrationComponent]
})
export class RegistrationModule {}
