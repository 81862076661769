import { Component, OnInit } from '@angular/core';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import {
  ApproveOffer,
  CloseOffer,
  DeleteOffer,
  LoadPermanentOfferWithContracts,
  ReopenOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Contract } from '@libs/shared/models/contract.model';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { isNil } from 'lodash-es';

@Component({
  selector: 'staffnow-mro-permanent-job-opening-details',
  templateUrl: './mro-permanent-job-opening-details.component.html',
  styleUrls: ['../facility-job-offer-details.component.scss', './mro-permanent-job-opening-details.component.scss']
})
export class MroPermanentJobOpeningDetailsComponent extends AbstractOfferDetailsComponent implements OnInit {
  contracts: Array<Contract> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private confirmService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadPermanentOfferWithContracts({
            refNumber: params['offerRefNumber']
          })
        );
      })
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contracts = state.contracts.map(contract => this.adjustContractEndDate(contract));
  }

  private adjustContractEndDate(contract): any {
    return {
      ...contract,
      endDate: contract.endDate ? contract.endDate : contract.provisionalEndDate
    };
  }

  get calculateRemainingPositions(): number {
    const remaining = this.jobOffer?.vacancies - this.jobOffer?.fullyMatchedSize;
    return remaining < 0 ? 0 : remaining;
  }

  get canDeleteOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.Delete]);
  }

  get canCloseOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.CloseOffer]);
  }

  get canReopenOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.ReopenOffer]);
  }

  get canEditOffer(): boolean {
    return !isNil(this.jobOffer?._links[RequestOverviewLinkRel.Edit]);
  }

  public approveOffer(): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.APPROVE_PUBLIC_JO', () => {
      this.store.dispatch(ApproveOffer({ offer: this.jobOffer }));
    });
  }

  handleDeleteOffer(): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.DELETE_JO_CONFIRMATION', () => {
      this.store.dispatch(DeleteOffer({ url: getUrl(this.jobOffer, 'delete'), refNumber: this.jobOffer.refNumber }));
    });
  }

  closeOffer(): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.CLOSE_JO', () =>
      this.store.dispatch(CloseOffer({ offer: this.permanentOffer }))
    );
  }

  reopenOffer(): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.REOPEN_JO', () =>
      this.store.dispatch(ReopenOffer({ offer: this.permanentOffer }))
    );
  }

  openEditOfferForm(): void {
    const offer = this.jobOffer;
    this.router.navigate(['permanent-offer'], {
      state: {
        preselectedFilters: offer,
        isPrivate: !offer.isPublic,
        selectedTechnicians: [],
        isEdit: true,
        breadcrumbs: [
          this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
          this.translateService.instant('MRO.PACKAGE_OFFER.PERMANENT_JO_DETAILS'),
          this.translateService.instant('AGENCY.DETAILS.EDIT_JOB_OPENING')
        ]
      }
    });
  }

  protected readonly RequestOverviewLinkRel = RequestOverviewLinkRel;
}
