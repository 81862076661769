@if(hasEndContractLink && contract.startDate) {
<general-button
    (onClick)="handleContractEnd()"
    [isDisabled]="!hasEndContractLink"
    backgroundStyle="outline"
    size="small"
    [title]="'SYSTEM.INFO.END_JO' | translate"
    text="{{ 'TASKS.VIEW.DESCRIPTION.FINISH_CONTRACT' | translate }}"
    color="primary"
></general-button>
} @else if(contract?.endDate && contract.startDate) {
<span>
    <strong>{{ contract.endDate || contract?.provisionalEndDate | absoluteDateFormat }}</strong>
</span>

} @else { - }
