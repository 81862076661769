import { Component, Input, OnInit, signal } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  AgencyOfferOutDto,
  ApplyOfferAsTechnicianPayload,
  OfferOutDto,
  OfferType,
  PackageOfferDto,
  PermanentOfferDto
} from '@libs/shared/models/offer.model';
import { getEmbeddedResource, getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { MroFacility } from '@libs/shared/models/facility.model';
import { isNil } from 'lodash-es';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';
import { Actions, ofType } from '@ngrx/effects';
import { SuccessfullyLoadedAgencyTechnicians } from '../../../../../apps/staffnow-platform/src/app/modules/search-overview/state/search-overview.actions';

type JoinedOfferTypes = OfferOutDto | PackageOfferDto | AgencyOfferOutDto | PermanentOfferDto;

@Component({
  selector: 'staffnow-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss']
})
export class OfferModalComponent implements OnInit {
  @Input() offer: JoinedOfferTypes = null;
  @Input() brandName: string = null;
  @Input() applyOfferAsTechnicianCallback?: (payload: ApplyOfferAsTechnicianPayload) => void = null;
  @Input() getTechnicians?: (offer: JoinedOfferTypes) => void = null;
  @Input() packageOfferApplicationForm?: (offer: JoinedOfferTypes) => void = null;

  readonly OFFER_TYPE = OfferType;
  facilityProfile: MroFacility = null;
  applyUrl: string = null;
  labourRegulationsAcknowledgmentClicked: boolean = false;
  displayAcknowledgeErrorMessage: boolean = false;
  isLoading = signal(false);

  constructor(public bsModalRef: BsModalRef, private actions: Actions) {}

  ngOnInit(): void {
    this.actions.pipe(ofType(SuccessfullyLoadedAgencyTechnicians)).subscribe(() => {
      this.isLoading.update(() => false);
      this.close();
    });

    try {
      this.facilityProfile = getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
    } catch (error) {
      console.log(error);
    }

    if (hasLink(this.offer, JobOfferLinkRel.ApplyOffer)) {
      this.applyUrl = getUrl(this.offer, JobOfferLinkRel.ApplyOffer);
    }
  }

  get applicationExistsMessage(): string {
    return `You already have an application for this job opening. Search the reference ${this.offer.refNumber} in 'My Job Openings' menu to see it.`;
  }

  public isStaffnowBrand(): boolean {
    return this.brandName === BrandName.StaffNow;
  }

  public isApplyAsTechnicianDisabled(): boolean {
    return this.isStaffnowBrand() && !this.labourRegulationsAcknowledgmentClicked;
  }

  public isUserTechnician(): boolean {
    return !isNil(this.applyOfferAsTechnicianCallback);
  }

  public isUserAgency(): boolean {
    return !isNil(this.getTechnicians) && hasLink(this.offer, JobOfferLinkRel.GetTechnicians);
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public applyToTemporaryOfferAsTechnician(): void {
    if (this.isStaffnowBrand()) {
      this.applyOfferAsTechnicianWithAcknowledge();
    } else {
      this.applyOfferAsTechnicianWithoutAcknowledge();
    }
  }

  private applyOfferAsTechnicianWithAcknowledge(): void {
    if (this.isApplyAsTechnicianDisabled()) {
      this.displayAcknowledgeErrorMessage = true;
    } else {
      this.displayAcknowledgeErrorMessage = false;
      this.applyOfferAsTechnicianInternal({
        offer: this.offer as any,
        labourRegulationsAcknowledgment: this.labourRegulationsAcknowledgmentClicked
      });
    }
  }

  public applyOfferAsTechnicianWithoutAcknowledge(): void {
    this.applyOfferAsTechnicianInternal({ offer: this.offer as any });
  }

  private applyOfferAsTechnicianInternal(payload: ApplyOfferAsTechnicianPayload): void {
    if (!isNil(this.applyOfferAsTechnicianCallback) && this.applyOfferAsTechnicianCallback instanceof Function) {
      this.applyOfferAsTechnicianCallback(payload);
    }
    this.close();
  }

  public applyToTemporaryOfferWithOwnTechnicians(): void {
    if (!isNil(this.getTechnicians) && this.getTechnicians instanceof Function) {
      this.isLoading.update(() => true);
      this.getTechnicians(this.offer);
    }
    this.close();
  }

  public applyToPackageOffer(): void {
    this.packageOfferApplicationForm(this.offer);
    this.close();
  }

  get offerIsClosed(): boolean {
    return this.offer.isClosed;
  }
}
