import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AccountFormModel, initialFormState } from '../components/views/account/account-form.model';
import { TechnicianUpdateRequestPayload } from '../model/technician-request-payload.model';
import { AdminRequestPayload, AgencyRequestPayload, MroRequestPayload } from '../model/request.payload';
import { box } from 'ngrx-forms';
import { selectUserProfile } from '../state/user-profile.selectors';
import { UserProfileType } from '@libs/shared/models/user-profile.type';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { UserRoles, UserRolesUtils } from '@libs/shared/models/roles.enum';
import { ContractEvent } from '@libs/shared/models/availability.model';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';
import { getCountries } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take, tap } from 'rxjs/operators';
import { LoadCountry } from '@libs/common-ui/country/state/country-selector.actions';
import { EventInput } from '@fullcalendar/core';

@Injectable()
export class UserProfileService {
  private userProfile: UserProfileType = null;
  private userRole: UserRoles = null;

  constructor(private store: Store<any>) {
    this.store.pipe(select(selectUserProfile)).subscribe(userProfile => {
      this.userProfile = userProfile;
      this.userRole = getEmbeddedResource(this.userProfile, UserProfileLinkRel.Profile)['role'];
    });
  }

  //TODO: we cant have this kind of types (userprofiletype) when they differ that much, it makes coding so hard for absolutely 0 reasons
  public parseUserProfileToFormModel(userData: UserProfileType, apiRegion?: string): AccountFormModel {
    const embeddedProfile: any = getEmbeddedResource({ ...userData }, UserProfileLinkRel.Profile);
    const formValue = { ...initialFormState.value };
    this.setOrDeletePropertyInForm('firstName', embeddedProfile, formValue);
    this.setOrDeletePropertyInForm('lastName', embeddedProfile, formValue);
    this.setOrDeletePropertyInForm('role', embeddedProfile, formValue);

    this.setOrDeletePropertyInForm('presentation', userData, formValue);
    this.setOrDeletePropertyInForm('experience', userData, formValue);
    this.setOrDeletePropertyInForm('street', userData, formValue);
    this.setOrDeletePropertyInForm('state', userData, formValue);
    this.setOrDeletePropertyInForm('city', userData, formValue);
    this.setOrDeletePropertyInForm('zipCode', userData, formValue);
    this.setOrDeletePropertyInForm('apartment', userData, formValue);
    this.setOrDeletePropertyInForm('otherAirplanes', userData, formValue);
    this.setOrDeletePropertyInForm('airplanes', userData, formValue);
    this.setOrDeletePropertyInForm('languages', userData, formValue);
    this.setOrDeletePropertyInForm('position', userData, formValue);
    this.setOrDeletePropertyInForm('defaultUser', userData, formValue);
    this.setOrDeletePropertyInForm('backofficeNotes', userData, formValue);
    this.setOrDeletePropertyInForm('isTcn', userData, formValue);
    this.setOrDeletePropertyInForm('preferredWorkShifts', userData, formValue);
    this.setOrDeletePropertyInForm('licenses', userData, formValue);
    this.setOrDeletePropertyInForm('licenseNumber', userData, formValue);
    this.setOrDeletePropertyInForm('preferredContractTypes', userData, formValue);
    this.setOrDeletePropertyInForm('preferredPayRangeMin', userData, formValue);
    this.setOrDeletePropertyInForm('preferredPayRangeMax', userData, formValue);
    this.setOrDeletePropertyInForm('geographicPreferences', userData, formValue);
    this.setOrDeletePropertyInForm('preferredAvailability', userData, formValue);
    this.setOrDeleteAmeTitlesInForm(userData, formValue);
    this.setOrDeleteUserPhoneInForm(userData, formValue);
    this.setOrDeleteApiRegionInForm(apiRegion, formValue);
    this.setOrDeleteUserNationalitiesInForm(userData, formValue);
    this.setOrDeleteUserCountryInForm(embeddedProfile, userData, formValue, apiRegion);
    return formValue;
  }

  public prepareBackendRequestPayload(formValue: AccountFormModel): any {
    if (this.userProfile) {
      if (UserRolesUtils.isMro(this.userRole)) {
        return new MroRequestPayload(formValue);
      }
      switch (this.userRole) {
        case UserRoles.ROLE_TECHNICIAN:
          return new TechnicianUpdateRequestPayload(formValue);
        case UserRoles.ROLE_MODERATOR:
        case UserRoles.ROLE_ADMIN:
          return new AdminRequestPayload(formValue);
        case UserRoles.ROLE_AGENCY:
          return new AgencyRequestPayload(formValue);
      }
    }
  }

  public computeEventMap(contractEvents: ContractEvent[]): EventInput[] {
    return contractEvents.map(period => {
      return {
        start: period.fromDate,
        end: period.toDate,
        backgroundColor: '#0e6360'
      };
    });
  }

  private setOrDeletePropertyInForm = (key, dataSource: any, formDestination: any) => {
    if (Object.prototype.hasOwnProperty.call(dataSource, key)) {
      formDestination[key] = dataSource[key];
    } else {
      delete formDestination[key];
    }
  };

  private setOrDeleteUserPhoneInForm = (userData: any, formDestination: any) => {
    if (Object.prototype.hasOwnProperty.call(userData, 'userPhone')) {
      if (userData.userPhone) {
        formDestination.userPhone = box(userData.userPhone);
      } else {
        formDestination.userPhone = box({ phoneNumber: null, countryId: null });
      }
    } else {
      delete formDestination.userPhone;
    }
  };

  private setOrDeleteAmeTitlesInForm = (userData: UserProfileType, formDestination: any) => {
    if (userData.ameTitles) {
      formDestination.ameTitles = userData.ameTitles.map(i => box(i));
    } else {
      delete formDestination.ameTitles;
    }
  };

  private setOrDeleteUserNationalitiesInForm = (userData: any, formDestination: AccountFormModel) => {
    if (userData.nationalities) {
      formDestination.nationalities = userData.nationalities.map(i => box(i));
    } else {
      delete formDestination.nationalities;
    }
  };

  private setOrDeleteUserCountryInForm = (
    embeddedProfile: any,
    userData: UserProfileType,
    formDestination: AccountFormModel,
    region: string
  ) => {
    if (embeddedProfile.role === UserRoles.ROLE_TECHNICIAN) {
      this.setUserCountry(userData, formDestination, region);
    } else {
      delete formDestination['country'];
    }
  };

  private setUserCountry = (userData: UserProfileType, formDestination: AccountFormModel, region: string) => {
    let userCountryName: string = '';
    if ('country' in userData) {
      if (!userData.country && region == AppRegionEnum.USA.toString()) {
        userCountryName = 'United States';
      } else {
        userCountryName = userData.country;
      }
      this.loadUserCountryAndSetFormValue(userCountryName, formDestination);
    }
  };

  private loadUserCountryAndSetFormValue(countryName: string, formDestination: AccountFormModel) {
    this.store
      .pipe(getCountries)
      .pipe(
        take(1),
        tap(countries => {
          const currentCountry = countries?.find(country => country.name === countryName);
          if (currentCountry) {
            formDestination.country = currentCountry.name;
            this.store.dispatch(LoadCountry({ country: currentCountry }));
          } else {
            formDestination.country = '';
          }
        })
      )
      .subscribe();
  }

  private setOrDeleteApiRegionInForm(userData: any, formDestination: any) {
    if (userData) {
      formDestination.apiRegion = userData;
    } else {
      delete formDestination.apiRegion;
    }
  }
}
