import { MENU_ITEMS_REVERSE_MAPPING_ENUM } from '@back-office/shared/config/menuItems.enum';
import { cloneDeep, orderBy } from 'lodash-es';

export interface ReportConfig {
  userType: string;
  key: string;
  type: string;
  description?: string;
  canSelectMroFacility?: boolean;
  hasFixedDate?: boolean;
  groupReportKey?: string;
  isGroupReport?: boolean;
  backofficeCanSelectMroGroup?: boolean;
  isMonthly?: boolean;
  maxDays?: number;
  includePlatformInUrl?: boolean;
}

export interface PlatformReport extends ReportConfig {
  url: string;
  groupUrl: string;
}

export interface BackendReportConfig {
  href: string;
  templated: boolean;
}

export interface BackofficeReportConfig extends ReportConfig, BackendReportConfig {
  uuid: string;
  loadGroup: boolean;
  range: Array<string> | string;
}

const reportsConfigurations: Array<ReportConfig> = [
  {
    userType: 'MROS',
    key: 'mroDetailed',
    type: 'REPORTS.TYPE.DETAILED_REPORT',
    description: 'REPORTS.DESCRIPTION.DETAILED_REPORT',
    canSelectMroFacility: true,
    groupReportKey: 'groupDetailed',
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'groupDetailed',
    type: 'REPORTS.TYPE.DETAILED_REPORT_GROUP',
    isGroupReport: true
  },
  {
    userType: 'MROS',
    key: 'mroConcludedMatches',
    type: 'REPORTS.TYPE.CONCLUDED_MATCHES',
    canSelectMroFacility: true,
    groupReportKey: 'groupConcludedMatches',
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'groupConcludedMatches',
    type: 'REPORTS.TYPE.CONCLUDED_MATCHES_GROUP',
    isGroupReport: true
  },
  {
    userType: 'MROS',
    key: 'techniciansForReview',
    type: 'REPORTS.TYPE.TECHNICIANS_REVIEW_REPORT',
    description: 'REPORTS.DESCRIPTION.TECHNICIANS_REVIEW_REPORT',
    hasFixedDate: true,
    canSelectMroFacility: false,
    backofficeCanSelectMroGroup: true,
    includePlatformInUrl: true
  },
  {
    userType: 'MROS',
    key: 'groupLongTermJobOpenings',
    type: 'REPORTS.TYPE.LONG_TERM_JO_REPORT',
    description: 'REPORTS.DESCRIPTION.LONG_TERM_JO_REPORT',
    hasFixedDate: false,
    canSelectMroFacility: false,
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'mroNewTechniciansStarting',
    type: 'REPORTS.TYPE.NEW_TECHNICIANS',
    description: 'REPORTS.DESCRIPTION.NEW_TECHNICIANS',
    hasFixedDate: true,
    canSelectMroFacility: true
  },
  {
    userType: 'MROS',
    key: 'groupRejectedTechnicians',
    type: 'REPORTS.TYPE.DECLINED_BY_QUALITY_GROUP',
    isGroupReport: true
  },
  {
    userType: 'MROS',
    key: 'mroRejectedTechnicians',
    type: 'REPORTS.TYPE.DECLINED_BY_QUALITY',
    canSelectMroFacility: true,
    groupReportKey: 'groupRejectedTechnicians',
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'groupActiveMroTechnicians',
    type: 'REPORTS.TYPE.ACTIVE_TECHNICIANS_GROUP',
    isGroupReport: true,
    hasFixedDate: true
  },
  {
    userType: 'MROS',
    key: 'mroActiveMroTechnicians',
    type: 'REPORTS.TYPE.ACTIVE_TECHNICIANS',
    description: 'REPORTS.DESCRIPTION.ACTIVE_TECHNICIANS',
    canSelectMroFacility: true,
    groupReportKey: 'groupActiveMroTechnicians',
    backofficeCanSelectMroGroup: true,
    hasFixedDate: true
  },
  {
    userType: 'MROS',
    key: 'groupReasonsForRejectionPerTechnician',
    type: 'REPORTS.TYPE.REASONS_DECLINE_GROUP',
    description: 'REPORTS.DESCRIPTION.REASONS_DECLINE_GROUP',
    isGroupReport: true,
    hasFixedDate: false
  },
  {
    userType: 'MROS',
    key: 'groupOffersAndStatuses',
    type: 'REPORTS.TYPE.JO_STATUS_GROUP',
    isGroupReport: true,
    hasFixedDate: false
  },
  {
    userType: 'MROS',
    key: 'mroReasonsForRejectionPerTechnician',
    type: 'REPORTS.TYPE.REASONS_DECLINE',
    description: 'REPORTS.DESCRIPTION.REASONS_DECLINE',
    canSelectMroFacility: true,
    groupReportKey: 'groupReasonsForRejectionPerTechnician',
    backofficeCanSelectMroGroup: true,
    hasFixedDate: false
  },
  {
    userType: 'MROS',
    key: 'mroPausedTechnicians',
    type: 'REPORTS.TYPE.PAUSED_TECHNICIANS',
    description: 'REPORTS.DESCRIPTION.PAUSED_TECHNICIANS',
    hasFixedDate: true,
    canSelectMroFacility: true
  },
  {
    userType: 'MROS',
    key: 'mroOffersAndStatuses',
    type: 'REPORTS.TYPE.JO_STATUSES',
    description: 'REPORTS.DESCRIPTION.JO_STATUSES',
    canSelectMroFacility: true,
    groupReportKey: 'groupOffersAndStatuses',
    backofficeCanSelectMroGroup: true,
    hasFixedDate: false
  },
  {
    userType: 'MROS',
    key: 'mroAssignments',
    type: 'REPORTS.TYPE.ASSIGNMENTS_MRO',
    description: 'REPORTS.DESCRIPTION.ASSIGNMENTS',
    hasFixedDate: false,
    maxDays: 30,
    includePlatformInUrl: true,
    canSelectMroFacility: true,
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'groupAssignments',
    type: 'REPORTS.TYPE.ASSIGNMENTS_GROUP',
    isGroupReport: true
  },
  {
    userType: 'MROS',
    key: 'mroApplicants',
    type: 'REPORTS.TYPE.APPLICANTS',
    description: 'REPORTS.DESCRIPTION.APPLICANTS',
    hasFixedDate: false,
    maxDays: 30,
    includePlatformInUrl: true,
    canSelectMroFacility: true,
    backofficeCanSelectMroGroup: true
  },
  {
    userType: 'MROS',
    key: 'groupApplicants',
    type: 'REPORTS.TYPE.APPLICANTS_GROUP',
    isGroupReport: true
  },
  {
    userType: 'AGENCIES',
    key: 'agency',
    type: 'REPORTS.TYPE.AGENCY_REPORT',
    description: 'REPORTS.DESCRIPTION.AGENCY_REPORT'
  },
  {
    userType: 'AGENCIES',
    key: 'agencyMonthly',
    type: 'REPORTS.TYPE.AGENCY_MONTHLY',
    isMonthly: true
  },
  {
    userType: 'AGENCIES',
    key: 'activeAgencyTechnicians',
    type: 'REPORTS.TYPE.ACTIVE_TECHNICIANS_REPORT',
    description: 'REPORTS.DESCRIPTION.ACTIVE_TECHNICIANS_REPORT',
    hasFixedDate: true
  },
  {
    userType: 'AGENCIES',
    key: 'agencyNewTechniciansStarting',
    type: 'REPORTS.TYPE.NEW_TECHNICIANS',
    description: 'REPORTS.DESCRIPTION.NEW_TECHNICIANS',
    hasFixedDate: true
  },
  {
    userType: 'AGENCIES',
    key: 'agencyReasonsForRejectionPerTechnician',
    type: 'REPORTS.TYPE.REASONS_DECLINE_REPORT',
    description: 'REPORTS.DESCRIPTION.REASONS_DECLINE_REPORT',
    hasFixedDate: false
  },
  {
    userType: 'AGENCIES',
    key: 'agencyOffersAndStatuses',
    type: 'REPORTS.TYPE.JO_STATUSES_REPORT',
    description: 'REPORTS.DESCRIPTION.JO_STATUSES_REPORT',
    hasFixedDate: false
  },
  {
    userType: 'AGENCIES',
    key: 'agencyPausedTechnicians',
    type: 'REPORTS.TYPE.PAUSED_TECHNICIANS',
    description: 'REPORTS.DESCRIPTION.PAUSED_TECHNICIANS',
    hasFixedDate: true
  },
  {
    userType: 'TECHNICIANS',
    key: 'assignmentsTechnicians',
    type: 'REPORTS.TYPE.TECHNICIAN_ASSIGNMENTS_REPORT',
    description: 'REPORTS.DESCRIPTION.ASSIGNMENTS_REPORT'
  },
  {
    userType: 'BACKOFFICE',
    key: 'boFinancial',
    type: 'REPORTS.TYPE.FINANCIAL_SUMMARIES'
  },
  {
    userType: 'BACKOFFICE',
    key: 'userList',
    type: 'REPORTS.TYPE.ALL_USERS',
    includePlatformInUrl: true,
    hasFixedDate: true
  },
  {
    userType: 'BACKOFFICE',
    key: 'boAverageResolutionTime',
    type: 'REPORTS.TYPE.AVERAGE_RESOLUTION_TIME'
  }
];

export function getReportConfig(key: string): ReportConfig {
  return cloneDeep(reportsConfigurations.find(r => r.key === key));
}

export function isGroupReport(key: string): boolean {
  return getReportConfig(key)?.isGroupReport;
}

export function isReportAvailable(reportKey: string, currentEntity: string): boolean {
  return getReportConfig(reportKey)?.userType === MENU_ITEMS_REVERSE_MAPPING_ENUM[currentEntity];
}

export function orderReportList(reportList: Array<any>) {
  return orderBy(reportList, report => report.type.toLowerCase());
}
