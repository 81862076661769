import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationsFiltersComponent } from './applications/applications-filters.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';

@NgModule({
  declarations: [ApplicationsFiltersComponent],
  exports: [ApplicationsFiltersComponent],
  imports: [CommonModule, TranslateModule, CommonUiModule]
})
export class FiltersModule {}
