import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, of, OperatorFunction, pipe, startWith, switchMap } from 'rxjs';
import {
  DisableAction,
  EnableAction,
  FormControlState,
  FormGroupState,
  MarkAsDirtyAction,
  MarkAsPristineAction,
  ResetAction,
  SetErrorsAction,
  SetValueAction
} from 'ngrx-forms';

import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  getAirplanesSortedByName,
  getEmploymentTypes,
  getFilteredApiRoot,
  getLicenses,
  getLoggedInUserRole,
  getWorkShifts
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { OfferEditMode, OfferOutDto, OfferType } from '@libs/shared/models/offer.model';

import { Actions, ofType } from '@ngrx/effects';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { getCurrentUTCDateString, isFirstDateBeforeTheSecond } from '@libs/shared/helpers/date-utils';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import {
  CreateOfferFormModel,
  temporaryJobOpeningInitialFormState,
  TechnicianSearchFilters
} from './create-offer.model';
import { AdditionalBenefitForm, AdditionalBenefitFormUtils } from './components/additional-benefits-form.model';
import { CREATE_EDIT_OFFER_FEATURE_KEY } from './create-offer.reducer';
import { FailedToSubmitOffer, OfferSubmitSuccess, ResetOfferForm, SubmitOffer } from './create-offer.actions';
import { TechnicianProfileModel, UserProfile } from '@libs/shared/models/technician-profile.model';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { AmeLicenseLevel } from '@libs/shared/models/ame-license-level.model';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { LocationPO } from '@libs/shared/models/location.model';
import { getLocations } from '@libs/shared/bms-common/environment/environment.selector';
import { LoadLocationList } from '@libs/shared/bms-common/api-root/api-root.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AviationCompany, MroFacilityLoaderService } from '../../../shared/services/mro-facility-loader.service';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import { Rate } from '@libs/shared/models/rate.model';
import { MroFacility } from '@libs/shared/models/facility.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import {
  SHORT_PRESENTATION_MAX_LENGTH,
  SHORT_PRESENTATION_MIN_LENGTH,
  JO_TITLE_MAX_LENGTH,
  JO_TITLE_MIN_LENGTH,
  MAX_CHARGE_RATE,
  MAX_YEARS_OF_EXPERIENCE,
  WORK_DAYS_MAX_VALUE,
  VACANCIES_MAX_VALUE,
  MAXIMUM_CLOSE_AFTER_DAYS,
  MINIMUM_CLOSE_AFTER_DAYS
} from '../../../shared/validators/offer-form.validator';
import { Option } from '@libs/common-ui/multi-select-autocomplete/multi-select-autocomplete.component';
import { ContractTypeDto } from '@libs/shared/models/contract-type-dto.model';
import { isEuropeDeployment, isUsaRegion } from '@libs/shared/bms-common/environment/environment.loader';
import { WorkShiftDto } from '@libs/shared/models/work-shift-dto.model';
import { License } from '@libs/shared/models/license.model';
import { AppState } from '@platform/state/app-state.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-create-offer-page',
  templateUrl: './create-offer-page.component.html',
  styleUrls: ['./create-offer-page.component.scss']
})
export class CreateOfferPageComponent implements OnInit, OnDestroy {
  aircraftList: Array<Aircraft> = [];
  locationList: LocationPO[] = [];
  licenses: License[] = [];
  employmentTypeList: ContractTypeDto[] = null;
  selectedLocation: LocationPO = null;
  isPrivate: boolean;
  preselectedFilters: OfferOutDto | TechnicianSearchFilters;
  selectedTechnicians: { [key: string]: TechnicianProfileModel } = null;
  isEditForm?: boolean = false;
  createOfferForm: Observable<FormGroupState<CreateOfferFormModel>>;
  createOfferFormValue: FormGroupState<CreateOfferFormModel> = null;
  isSubmitting: boolean = false;
  selectedAircraftList: Array<Aircraft> = [];
  selectedLicenses: License[] = null;
  rangePickerValue: Array<string> = [];
  rangePickerMinDate: string = getCurrentUTCDateString();
  technicianTypeOptions: AmeLicenseType[] = [];
  technicianLevelOptions: AmeLicenseLevel[] = [];
  isSingletonNomenclature: boolean = false;
  fixedWorkingPatterns = {
    first: '5/2',
    second: '3/3',
    third: '6/1'
  };
  public customWorkDaysPattern: { on: number; off: number } = {
    on: null,
    off: null
  };
  manualUpdateControlValues = {
    ameTitleId: null,
    ameType: null,
    aircrafts: [],
    employmentTypeId: null
  };
  getLabelFunction = (item: string) => item;
  benefits: Array<AdditionalBenefitForm> = AdditionalBenefitFormUtils.getDefault();
  isCloseAfterDaysEnabled: boolean = false;
  closeAfterDaysIsChecked: boolean = false;
  breadcrumbs: string[] = ['Technicians Search', 'Create Job Opening'];
  allowPrivateOffers: boolean = false;
  offersAreHandledCentrally = false;
  isSettingInitialValues: boolean = true;
  facilityProfile: AviationCompany;
  wrappedRole: RoleWrapper;
  mroSearchObservable = new BehaviorSubject({ term: '', pageSize: 20, page: 0, facilities: [] });
  mroList$: Observable<MroFacility[]>;
  loading: boolean = false;
  private readonly mroUuid: string = '';
  private readonly FILTERS_KEY: string = 'FILTERS';
  protected readonly MAX_CHARGE_RATE = MAX_CHARGE_RATE;

  protected readonly MAX_YEARS_OF_EXPERIENCE: number = MAX_YEARS_OF_EXPERIENCE;
  protected readonly JO_PRESENTATION_MIN_LENGTH = SHORT_PRESENTATION_MIN_LENGTH;
  protected readonly JO_PRESENTATION_MAX_LENGTH = SHORT_PRESENTATION_MAX_LENGTH;
  protected readonly JO_TITLE_MIN_LENGTH = JO_TITLE_MIN_LENGTH;
  protected readonly JO_TITLE_MAX_LENGTH = JO_TITLE_MAX_LENGTH;
  protected readonly VACANCIES_MAX_VALUE = VACANCIES_MAX_VALUE;
  protected readonly MAXIMUM_CLOSE_AFTER_DAYS = MAXIMUM_CLOSE_AFTER_DAYS;
  protected readonly MINIMUM_CLOSE_AFTER_DAYS = MINIMUM_CLOSE_AFTER_DAYS;

  protected readonly initialFormState = temporaryJobOpeningInitialFormState;

  public highlightErrors: boolean = false;
  protected readonly isStaffnow: boolean;
  protected isElaunchnowOrUSABackOffice: boolean;
  private workShiftList: WorkShiftDto[] = [];
  private selectedWorkShifts: WorkShiftDto[] = [];

  get isCustomPatternSelected(): boolean {
    return this.createOfferFormValue.value.workPattern === 'custom';
  }

  get currencySymbol(): string {
    return (this.facilityProfile as any)?.workingCurrency;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly toastMessageService: ToastMessageService,
    private readonly customNavigationService: CustomNavigationService,
    private readonly mroFacilityLoaderService: MroFacilityLoaderService
  ) {
    this.isStaffnow = isEuropeDeployment();
    this.isElaunchnowOrUSABackOffice = isUsaRegion();

    let navigationState: { [k: string]: any } = this.router.getCurrentNavigation()?.extras.state;
    if (navigationState) {
      sessionStorage.setItem(this.FILTERS_KEY, JSON.stringify(navigationState));
    } else if (sessionStorage.getItem(this.FILTERS_KEY)) {
      navigationState = JSON.parse(sessionStorage.getItem(this.FILTERS_KEY));
    }
    this.isPrivate = navigationState?.isPrivate;
    this.selectedTechnicians = navigationState?.selectedTechnicians;
    this.preselectedFilters = navigationState?.preselectedFilters;
    this.mroUuid = navigationState?.mroUuid;
    this.isEditForm = navigationState?.isEdit;
    if (navigationState?.breadcrumbs) {
      this.breadcrumbs = navigationState?.breadcrumbs;
    }
    this.createOfferForm = this.store.pipe(
      select(state => <FormGroupState<CreateOfferFormModel>>state[CREATE_EDIT_OFFER_FEATURE_KEY])
    );
    this.createOfferForm.subscribe(value => (this.createOfferFormValue = cloneDeep(value)));
    this.actions.pipe(ofType(OfferSubmitSuccess), untilDestroyed(this)).subscribe(action => {
      this.isSubmitting = false;
      sessionStorage.removeItem(this.FILTERS_KEY);
      if (action.refNumber && !this.wrappedRole.isAdminOrModerator()) {
        this.customNavigationService.goToOfferDetails(action.refNumber, OfferType.TEMPORARY, this.isEditForm);
      } else {
        this.customNavigationService.goBack();
      }
    });
    this.actions.pipe(ofType(FailedToSubmitOffer), untilDestroyed(this)).subscribe(() => (this.isSubmitting = false));
    this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
      this.wrappedRole = role(userRole);
    });
    this.store.dispatch(LoadLocationList());
  }

  ngOnInit() {
    this.initFormIndependentFields();
    this.storeSubscribe(getLocations, locationList => {
      this.locationList = locationList;
    });
    if (!this.isStaffnow) {
      this.storeSubscribe(getEmploymentTypes, employmentTypes => {
        this.employmentTypeList = employmentTypes;
      });
    }
    if (this.isElaunchnowOrUSABackOffice) {
      this.storeSubscribe(getLicenses, licenses => {
        this.licenses = licenses;
      });
    }

    this.storeSubscribe(getAirplanesSortedByName, aircraftList => {
      this.aircraftList = aircraftList;
    });
    this.storeSubscribe(getWorkShifts, workShifts => {
      this.workShiftList = workShifts;
    });
    if (this.isEditForm) {
      const facilityUrl: string = getUrl(
        getEmbeddedResource(this.preselectedFilters as any, JobOfferLinkRel.Facility),
        FacilityProfileLinkRel.Self
      );
      this.mroFacilityLoaderService
        .getFacility(facilityUrl)
        .pipe(take(1))
        .subscribe(facility => {
          this.mroList$ = of([facility]);
          this.initFormFacilityDependentFields(facility);
          this.markAsPristine();
          this.isSettingInitialValues = false;
        });
    } else {
      this.mroFacilityLoaderService
        .getFacilityProfile(this.mroUuid)
        .pipe(take(1))
        .subscribe(facility => {
          this.initFormFacilityDependentFields(facility.facilityProfile);
          this.isSettingInitialValues = false;
          this.mroList$ = this.mroSearchObservable.pipe(
            debounceTime(250),
            tap(() => (this.loading = true)),
            withLatestFrom(this.store.pipe(getFilteredApiRoot)),
            switchMap(([{ term, pageSize, page, facilities }, apiRoot]) =>
              this.mroFacilityLoaderService
                .getMroFacilities(
                  getUrl(apiRoot, ApiRootLinkRel.GetMROGroupFacilitiesPaged).replace(
                    '{mroGroupId}',
                    facility?.facilityProfile?.groupId
                  ),
                  term,
                  page,
                  pageSize
                )
                .pipe(
                  map(retrievedFacilities => [
                    this.facilityProfile,
                    ...facilities,
                    ...retrievedFacilities._embedded.facilities
                  ]),
                  tap(() => (this.loading = false))
                )
            ),
            startWith([])
          );
        });
    }
  }

  ngOnDestroy() {
    this.store.dispatch(ResetOfferForm());
    this.store.dispatch(new ResetAction(this.createOfferFormValue.id));
    sessionStorage.removeItem(this.FILTERS_KEY);
  }

  initFormFacilityDependentFields(facilityProfile: AviationCompany): void {
    this.facilityProfile = facilityProfile;
    const facilityUuidControlId = this.createOfferFormValue.controls.mroUuid.id;
    if (!facilityProfile) {
      this.store.dispatch(new SetValueAction(facilityUuidControlId, null));
      this.store.dispatch(new SetErrorsAction(facilityUuidControlId, { type: 'missing required field' }));
      return;
    }
    this.allowPrivateOffers = facilityProfile.allowPrivateOffers;
    this.offersAreHandledCentrally = facilityProfile.offersHandledCentrally;
    this.resetTitleAndChargeRateFields(facilityProfile);
    this.store.dispatch(new SetValueAction(facilityUuidControlId, facilityProfile.uuid));
    this.store.dispatch(new SetErrorsAction(facilityUuidControlId, {}));
    this.selectedLocation = this.locationList.find(location => location.name == this.facilityProfile.location);
    this.writeLocationIdToForm();
    if (!this.isEditForm && !this.allowPrivateOffers) {
      this.setPrivate(false);
    }
    if (this.isSettingInitialValues) {
      this.setPreselectedValuesAndDisableOnRestrictedMode();
    }
  }

  private initFormIndependentFields(): void {
    this.setMinimumDateIfPeriodFromIsFilled();
    if (!this.closeAfterDaysIsChecked) {
      this.disableCloseAfterDays();
    }
    if (this.isEditForm) {
      this.setWorkingPattern({
        target: { value: `${this.preselectedFilters['workDaysOn']}/${this.preselectedFilters['workDaysOff']}` }
      } as unknown as Event);
    } else {
      this.setWorkingPattern({ target: { value: this.fixedWorkingPatterns.first } } as unknown as Event);
    }
  }

  private resetTitleAndChargeRateFields(facilityProfile: AviationCompany) {
    this.technicianTypeOptions = getEmbeddedResource(facilityProfile, FacilityProfileLinkRel.AmeNomenclature);
    this.technicianLevelOptions = [];
    this.manualUpdateControlValues.ameType = null;
    this.manualUpdateControlValues.ameTitleId = null;
    const controls = this.createOfferFormValue.controls;
    this.store.dispatch(new SetValueAction(controls.ameType.id, null));
    this.store.dispatch(new SetValueAction(controls.ameTitleId.id, null));
    this.store.dispatch(new SetValueAction(controls.chargeRate.id, null));
    this.store.dispatch(new SetValueAction(controls.maxChargeRate.id, null));
  }

  private storeSubscribe<S>(pipedSelector: OperatorFunction<AppState, S>, subscribeFn: (a: S) => void) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  isPatternSelected(pattern: string): boolean {
    const { workDaysOn, workDaysOff } = this.createOfferFormValue.value;
    const [workDaysOnPatternValue, workDaysOffPatternValue] = pattern.split('/');

    return (
      !this.isCustomPatternSelected &&
      workDaysOn === +workDaysOnPatternValue &&
      workDaysOff === +workDaysOffPatternValue
    );
  }

  isRestrictedMode(): boolean {
    const editModeKey = 'editMode';
    if (!Object.hasOwn(this.preselectedFilters, editModeKey)) {
      return false;
    }
    const mode = this.preselectedFilters[editModeKey];
    return !isNil(mode) && mode === OfferEditMode.RESTRICTED;
  }

  isPatternFixed(pattern: string): boolean {
    let isFixed: boolean = false;
    Object.keys(this.fixedWorkingPatterns).forEach(key => {
      isFixed = isFixed || this.fixedWorkingPatterns[key] === pattern;
    });
    return isFixed;
  }

  // Work pattern

  setWorkingPattern($event: Event): void {
    const pattern: string = ($event.target as HTMLInputElement).value;
    const controls = this.createOfferFormValue.controls;
    const workDaysPattern = pattern.split('/');

    const workingPattern = this.isPatternFixed(pattern) ? 'fixed' : 'custom';

    if (!this.isPatternFixed(pattern)) {
      this.customWorkDaysPattern.on = Number(workDaysPattern[0]);
      this.customWorkDaysPattern.off = Number(workDaysPattern[1]);
    }

    this.store.dispatch(new SetValueAction(controls.workPattern.id, workingPattern));
    this.store.dispatch(new SetValueAction(controls.workDaysOn.id, Number(workDaysPattern[0])));
    this.store.dispatch(new SetValueAction(controls.workDaysOff.id, Number(workDaysPattern[1])));
    if (!this.isSettingInitialValues) {
      this.store.dispatch(new MarkAsDirtyAction(controls.workPattern.id));
    }
  }

  setCustomWorkingPattern(event: Event, isOnDays: boolean): void {
    const htmlInputElement = event.target as HTMLInputElement;
    if (Number(htmlInputElement.value) >= WORK_DAYS_MAX_VALUE) {
      if (this.customWorkDaysPattern.on >= WORK_DAYS_MAX_VALUE) {
        htmlInputElement.value = WORK_DAYS_MAX_VALUE.toString();
        this.customWorkDaysPattern.on = WORK_DAYS_MAX_VALUE;
      }

      if (this.customWorkDaysPattern.off >= WORK_DAYS_MAX_VALUE) {
        htmlInputElement.value = WORK_DAYS_MAX_VALUE.toString();
        this.customWorkDaysPattern.off = WORK_DAYS_MAX_VALUE;
      }
    }

    if (isOnDays) {
      this.store.dispatch(
        new SetValueAction(this.createOfferFormValue.controls.workDaysOn.id, this.customWorkDaysPattern.on)
      );
    } else {
      this.store.dispatch(
        new SetValueAction(this.createOfferFormValue.controls.workDaysOff.id, this.customWorkDaysPattern.off)
      );
    }
    if (!isNil(this.customWorkDaysPattern.on) && !isNil(this.customWorkDaysPattern.off)) {
      this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.workPattern.id));
    }
  }

  customPatternSet(): void {
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.workPattern.id, 'custom'));
    this.store.dispatch(
      new SetValueAction(this.createOfferFormValue.controls.workDaysOn.id, this.customWorkDaysPattern.on)
    );
    this.store.dispatch(
      new SetValueAction(this.createOfferFormValue.controls.workDaysOff.id, this.customWorkDaysPattern.off)
    );
  }

  handleAircraftSelectionChange(selectedAircrafts: Option[]): void {
    const airplanesIds: number[] = selectedAircrafts.filter(option => option.id != null).map(option => option.id);
    this.selectedAircraftList = selectedAircrafts;
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.airplanes.id, airplanesIds));
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.airplanes.id));
    const otherAirplanes: string = selectedAircrafts
      .filter(option => option.id == null)
      .map(option => option.name)
      .join(', ');

    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.otherAirplanes.id, otherAirplanes));
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.otherAirplanes.id));
  }

  // Indicative rate
  get hasIndicativeRate(): boolean {
    return this.createOfferFormValue.controls.hasIndicativeRate.value;
  }

  toggleIndicativeRateOption(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.benefits = AdditionalBenefitFormUtils.getDefault();
      this.updateChargeRateField(this.createOfferFormValue.controls.maxChargeRate.id);
    } else {
      this.resetChargeRateField(this.createOfferFormValue.controls.maxChargeRate.id);
    }
  }

  onEmploymentTypeChange($event: Event): void {
    const employmentTypeId: number = parseInt(($event.target as HTMLInputElement).value);
    const control = this.createOfferFormValue.controls.employmentTypeId;
    if (control.value !== employmentTypeId) {
      this.store.dispatch(new SetValueAction(control.id, employmentTypeId));
      this.store.dispatch(new MarkAsDirtyAction(control.id));
    }
  }

  // ameType and ameLicenses

  onTechnicianTypeChange(): void {
    const ameType: string = this.manualUpdateControlValues.ameType;
    const ameTypeControl = this.createOfferFormValue.controls.ameType;
    if (ameTypeControl.value !== ameType) {
      this.store.dispatch(new SetValueAction(ameTypeControl.id, ameType));
      this.store.dispatch(new MarkAsDirtyAction(ameTypeControl.id));
      this.getTechnicianLevelOptions(ameType);
      this.updateChargeRateField(this.createOfferFormValue.controls.chargeRate.id);
      if (this.hasIndicativeRate) {
        this.updateChargeRateField(this.createOfferFormValue.controls.maxChargeRate.id);
      }
    }
  }

  onTechnicianLevelChange($event: Event): void {
    const ameTitleIdControl: FormControlState<string> = this.createOfferFormValue.controls.ameTitleId;
    const ameTitleId: number = Number(($event.target as HTMLInputElement).value);
    this.store.dispatch(new MarkAsDirtyAction(ameTitleIdControl.id));
    if (isNaN(ameTitleId)) {
      this.store.dispatch(new SetValueAction(ameTitleIdControl.id, null));
      return;
    }
    this.manualUpdateControlValues.ameTitleId = ameTitleId;
    this.store.dispatch(new SetValueAction(ameTitleIdControl.id, ameTitleId));
    this.updateChargeRateField(this.createOfferFormValue.controls.chargeRate.id);
    if (this.hasIndicativeRate) {
      this.updateChargeRateField(this.createOfferFormValue.controls.maxChargeRate.id);
    }
  }

  getTechnicianLevelOptions(ameType: string): void {
    if (isEmpty(this.technicianTypeOptions)) {
      return;
    }
    this.manualUpdateControlValues.ameTitleId = null;
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.ameTitleId.id, null));
    const selectedAmeType = this.technicianTypeOptions.find(option => option.name == ameType);
    this.technicianLevelOptions = selectedAmeType ? selectedAmeType.licenses : [];
    this.isSingletonNomenclature = selectedAmeType?.isSingleton;
    if (this.isSingletonNomenclature) {
      this.onTechnicianLevelChange({ target: { value: this.technicianLevelOptions[0].id } } as unknown as Event);
    }
  }

  // Change range

  handleDateChange(period: string[]): void {
    if (period?.length === 2) {
      const oldPeriodFrom: string = this.preselectedFilters['periodFrom'];
      const oldPeriodTo: string = this.preselectedFilters['periodTo'];
      const newPeriodFrom: string = period[0];
      const newPeriodTo: string = period[1];
      if (!this.isSettingInitialValues && this.isEditForm) {
        if (oldPeriodFrom != newPeriodFrom || isFirstDateBeforeTheSecond(newPeriodTo, oldPeriodTo)) {
          this.resetOfferPeriod(oldPeriodFrom, oldPeriodTo);
          this.toastMessageService.fail('You can only increase the end date of the job opening.');
        } else if (newPeriodTo != oldPeriodTo) {
          this.modalService.openCustomizableConfirmModal({
            messages: [
              'You are about to extend the job opening. Be aware that there are technicians who already accepted the initial period of the job opening. Their contracts will not be automatically extended.',
              'To make sure they are available for the new period, please contact them.'
            ],
            acceptText: 'Ok',
            onConfirmedCallback: () => this.updateOfferPeriod(newPeriodFrom, newPeriodTo),
            canCancel: false
          });
        }
      } else {
        this.updateOfferPeriod(newPeriodFrom, newPeriodTo);
      }
    }
  }

  handleRemoveFromSelectedTechnicians(technicianToRemove: TechnicianProfileModel): void {
    const { userUuid } = getEmbeddedResource<UserProfile>(technicianToRemove, UserProfileLinkRel.Profile);
    delete this.selectedTechnicians[userUuid];
  }

  handleFormSubmit(): void {
    if (!this.canSubmitForm()) {
      this.highlightErrors = true;
      return;
    }
    this.highlightErrors = false;

    // The license value is changed from License type to Number type.
    this.modifyLicenseFormValue();

    this.markAsPristine();
    this.isSubmitting = true;

    if (this.isEditForm && this.preselectedFilters) {
      const offer = this.preselectedFilters as OfferOutDto;
      this.createOfferFormValue.value['uuid'] = offer.uuid;
      this.store.dispatch(
        SubmitOffer({
          payload: this.getPayload(),
          editUrl: getUrl(offer, 'edit')
        })
      );
    } else {
      this.store.dispatch(
        SubmitOffer({
          payload: {
            ...this.getPayload(),
            technicians: Object.keys(this.selectedTechnicians),
            isPublic: !this.isPrivate
          }
        })
      );
    }
  }

  canSubmitForm(): boolean {
    return this.createOfferFormValue.isValid && this.createOfferFormValue.isDirty && this.areBenefitsValid();
  }

  setPrivate(value: boolean): void {
    this.isPrivate = value;
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.isPublic.id, !value));
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.isPublic.id));
  }

  cancelCreation(): void {
    sessionStorage.removeItem(this.FILTERS_KEY);
    this.customNavigationService.goBack();
  }

  noSelectedTechnicians(): boolean {
    return isNil(this.selectedTechnicians) || isEmpty(this.selectedTechnicians);
  }

  areBenefitsValid(): boolean {
    return this.benefits
      .filter(benefit => benefit.isSelected)
      .every(benefit => AdditionalBenefitFormUtils.isValid(benefit));
  }

  isBenefitValid(benefit: AdditionalBenefitForm): boolean {
    return AdditionalBenefitFormUtils.isValid(benefit);
  }

  markBenefitsAsDirty(): void {
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.id));
  }

  // TODO(SN-1095): extract single searchable ng-select to component and unify single select components

  writeLocationIdToForm(): void {
    this.store.dispatch(
      new SetValueAction(this.createOfferFormValue.controls.locationId.id, this.selectedLocation?.id)
    );
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.locationId.id));
  }

  toggleAutoClose($event: Event): void {
    if (($event.target as HTMLInputElement).checked && !this.isRestrictedMode()) {
      this.enableCloseAfterDays();
    } else {
      this.disableCloseAfterDays();
    }
  }

  clipCloseAfterDays(): void {
    const closeAfterDays = this.createOfferFormValue.controls.closeAfterDays.value;
    if (isNil(closeAfterDays)) {
      return;
    }
    if (closeAfterDays > this.MAXIMUM_CLOSE_AFTER_DAYS) {
      this.store.dispatch(
        new SetValueAction(this.createOfferFormValue.controls.closeAfterDays.id, this.MAXIMUM_CLOSE_AFTER_DAYS)
      );
    } else if (closeAfterDays < this.MINIMUM_CLOSE_AFTER_DAYS) {
      this.store.dispatch(
        new SetValueAction(this.createOfferFormValue.controls.closeAfterDays.id, this.MINIMUM_CLOSE_AFTER_DAYS)
      );
    }
  }

  private setMinimumDateIfPeriodFromIsFilled(): void {
    if (!isNil(this.preselectedFilters) && !isNil(this.preselectedFilters['periodFrom'])) {
      this.rangePickerMinDate = this.preselectedFilters['periodFrom'];
    }
  }

  private setPreselectedValuesAndDisableOnRestrictedMode(): void {
    if (this.preselectedFilters && this.aircraftList) {
      const controls = this.createOfferFormValue.controls;
      const filterKeys: string[] = Object.keys(this.preselectedFilters);
      const ameTitle: AmeTitle = (this.preselectedFilters as any).ameTitle;

      if (ameTitle) {
        this.getTechnicianLevelOptions(ameTitle.type);
        this.manualUpdateControlValues.ameType = ameTitle.type;
        this.manualUpdateControlValues.ameTitleId = ameTitle.id;
        this.manualUpdateControlValues.employmentTypeId = (this.preselectedFilters as any).employmentType?.id;
        this.isSingletonNomenclature = isNil(ameTitle.license);
      }

      filterKeys.forEach(controlKey => {
        switch (controlKey) {
          case 'experience':
            this.store.dispatch(new SetValueAction(controls['minExperience'].id, this.preselectedFilters[controlKey]));
            break;
          case 'hasIndicativeRate': {
            const hasIndicativeRate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['hasIndicativeRate'].id, hasIndicativeRate));
            break;
          }
          case 'chargeRate': {
            const chargeRate: Rate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['chargeRate'].id, chargeRate.amount));
            break;
          }
          case 'maxChargeRate': {
            const maxChargeRate: Rate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['maxChargeRate'].id, maxChargeRate.amount));
            break;
          }
          case 'availability':
            this.rangePickerValue = this.preselectedFilters[controlKey].split(':');
            this.handleDateChange(this.rangePickerValue);
            break;
          case 'periodFrom':
            this.rangePickerValue = [this.preselectedFilters[controlKey], this.preselectedFilters['periodTo']];
            this.handleDateChange(this.rangePickerValue);
            break;
          case 'additionalBenefits':
            this.preselectedFilters[controlKey].forEach(benefit => {
              const index = this.benefits.findIndex(formBenefit => formBenefit.type === benefit.type);
              this.benefits[index].amount = benefit.amount?.amount;
              this.benefits[index].description = benefit.description;
              this.benefits[index].isSelected = true;
            });
            break;
          case 'minExperience':
          case 'hasHiddenRate':
          case 'workDaysOn':
          case 'workDaysOff':
          case 'vacancies':
          case 'shortPresentation':
          case 'priority':
          case 'title':
          case 'allowTcnApplications':
          case 'refNumber':
            this.store.dispatch(new SetValueAction(controls[controlKey].id, this.preselectedFilters[controlKey]));
            break;
          case 'closeAfterDays': {
            const closeAfterDays = this.preselectedFilters[controlKey];
            this.closeAfterDaysIsChecked = !isNil(closeAfterDays);
            this.store.dispatch(new SetValueAction(controls[controlKey].id, closeAfterDays));
            break;
          }
          case 'ameTitle':
            this.store.dispatch(new SetValueAction(controls['ameType'].id, this.manualUpdateControlValues.ameType));
            this.store.dispatch(
              new SetValueAction(controls['ameTitleId'].id, this.manualUpdateControlValues.ameTitleId)
            );
            break;
          case 'airplanes':
            this.preselectedFilters[controlKey].forEach(id => {
              this.selectedAircraftList.push(this.aircraftList.find(airplane => airplane.id === Number(id)));
            });
            this.store.dispatch(new SetValueAction(controls['airplanes'].id, this.preselectedFilters[controlKey]));
            break;
          case 'otherAirplanes':
            if (this.preselectedFilters[controlKey].length > 0) {
              this.preselectedFilters[controlKey]
                .split(', ')
                .forEach((otherAirplane: string) => this.selectedAircraftList.push({ id: null, name: otherAirplane }));
            }
            this.store.dispatch(new SetValueAction(controls['otherAirplanes'].id, this.preselectedFilters[controlKey]));
            break;
          case 'location': {
            const locationId = this.preselectedFilters[controlKey].id;
            this.selectedLocation = this.locationList.find(location => location.id == locationId);
            this.writeLocationIdToForm();
            break;
          }
          case 'aircraft': {
            const preselectedAirplaneIds = this.preselectedFilters[controlKey].split(',').map(id => Number(id));
            preselectedAirplaneIds.forEach(id => {
              this.selectedAircraftList.push(this.aircraftList.find(airplane => airplane.id === id));
            });
            this.store.dispatch(new SetValueAction(controls['airplanes'].id, preselectedAirplaneIds));
            break;
          }
          case 'workShifts': {
            this.selectedWorkShifts = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['workShifts'].id, this.selectedWorkShifts));
            break;
          }
          case 'licenses': {
            this.selectedLicenses = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['licenses'].id, this.selectedLicenses));
            break;
          }
          case 'employmentType': {
            this.store.dispatch(
              new SetValueAction(controls['employmentTypeId'].id, this.manualUpdateControlValues.employmentTypeId)
            );
            break;
          }
          default:
            break;
        }
      });
      if (this.isRestrictedMode()) {
        this.disableFieldsOnRestrictedMode();
      }
    }
  }

  private disableFieldsOnRestrictedMode(): void {
    const alwaysActiveControls = ['isPublic', 'priority', 'vacancies'];
    Object.keys(this.createOfferFormValue.controls).forEach(controlKey => {
      if (!alwaysActiveControls.includes(controlKey)) {
        this.store.dispatch(new DisableAction(this.createOfferFormValue.controls[controlKey].id));
      }
    });
  }

  private resetChargeRateField(controlId: string): void {
    this.store.dispatch(new SetValueAction(controlId, null));
  }

  private updateChargeRateField(controlId: string): void {
    const rateIndex = this.facilityProfile?.rates.findIndex(entry => {
      return Number(entry.ameTitle?.id) === Number(this.manualUpdateControlValues.ameTitleId);
    });
    if (rateIndex != -1)
      this.store.dispatch(new SetValueAction(controlId, this.facilityProfile?.rates[rateIndex].rate));
  }

  allowTcnApplicationsValueConverter = {
    convertViewToStateValue: value => {
      return !value;
    },
    convertStateToViewValue: state => {
      return !state;
    }
  };

  onSearch(term: string) {
    this.mroSearchObservable.next({
      page: 0,
      term,
      pageSize: 20,
      facilities: []
    });
  }

  onClose() {
    this.onSearch('');
  }

  loadMore(facilities: MroFacility[]) {
    const currentPageableValues = this.mroSearchObservable.getValue();
    this.mroSearchObservable.next({
      ...currentPageableValues,
      facilities,
      page: currentPageableValues.page + 1
    });
  }

  private resetOfferPeriod(oldPeriodFrom: string, oldPeriodTo: string) {
    this.rangePickerValue = [oldPeriodFrom, oldPeriodTo];
  }

  private updateOfferPeriod(periodFrom: string, periodTo: string): void {
    const periodFromControlId = this.createOfferFormValue.controls.periodFrom.id;
    this.store.dispatch(new SetValueAction(periodFromControlId, periodFrom));
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.periodTo.id, periodTo));
    this.store.dispatch(new MarkAsDirtyAction(periodFromControlId));
  }

  // Validations

  private getPayload(): CreateOfferFormModel {
    const payload = {
      ...this.createOfferFormValue.value
    };

    if (this.benefits.some(benefit => benefit.isSelected)) {
      payload.additionalBenefits = this.benefits
        .filter(benefit => benefit.isSelected)
        .map(benefit => ({
          type: benefit.type,
          amount: benefit.amount,
          description: benefit.description?.trim()
        }));
    }
    return payload;
  }

  private markAsPristine(): void {
    this.store.dispatch(new MarkAsPristineAction(this.createOfferFormValue.id));
  }

  private enableCloseAfterDays(): void {
    this.isCloseAfterDaysEnabled = true;
    this.store.dispatch(new EnableAction(this.createOfferFormValue.controls.closeAfterDays.id));
  }

  private disableCloseAfterDays(): void {
    this.isCloseAfterDaysEnabled = false;
    const closeAfterDaysId = this.createOfferFormValue.controls.closeAfterDays.id;
    this.store.dispatch(new DisableAction(closeAfterDaysId));
    this.store.dispatch(new MarkAsDirtyAction(closeAfterDaysId));
  }

  public canSelectLocation(): boolean {
    return !this.isRestrictedMode() && this.offersAreHandledCentrally;
  }

  getWorkShiftList(): WorkShiftDto[] {
    return this.workShiftList;
  }

  getSelectedWorkShifts(): WorkShiftDto[] {
    return this.selectedWorkShifts;
  }

  public onSelectWorkShift(selectedWorkShifts: Array<WorkShiftDto>): void {
    this.store.dispatch(new SetValueAction(this.createOfferFormValue.controls.workShifts.id, selectedWorkShifts));
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.workShifts.id));
  }

  onSelectLicenses(selectedLicenses: License[]): void {
    this.store.dispatch(
      new SetValueAction(
        this.createOfferFormValue.controls.licenses.id,
        selectedLicenses.map(license => license)
      )
    );
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.licenses.id));
  }

  modifyLicenseFormValue(): void {
    this.store.dispatch(
      new SetValueAction(
        this.createOfferFormValue.controls.licenses.id,
        this.createOfferFormValue.controls.licenses.value.map(license => license.id)
      )
    );
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.licenses.id));
  }
}
