import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AcceptAgencyOfferContract,
  DeclineAgencyOfferContract,
  LoadAgencyOfferWithContracts,
  LoadPublicProfile,
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ConversationService } from '../../../services/conversation.service';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { getLink, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { AgencyOfferOutDto } from '@libs/shared/models/offer.model';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';

@Component({
  selector: 'staffnow-technician-agency-offer-details',
  templateUrl: './technician-agency-offer-details.component.html',
  styleUrls: ['./technician-agency-offer-details.component.scss']
})
export class TechnicianAgencyOfferDetailsComponent extends AbstractOfferDetailsComponent implements OnInit {
  public offer: AgencyOfferOutDto = null;
  public contract: AgencyOfferContractOutDto = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadAgencyOfferWithContracts({
            refNumber: params['agencyOfferRefNumber']
          })
        );
      })
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contract = state.contracts[0] as AgencyOfferContractOutDto;
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(
      this.contract
    );
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(
      this.contract
    );
  }

  get canAccept(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptAgencyOfferContract);
  }

  public acceptContract() {
    this.store.dispatch(AcceptAgencyOfferContract({
      refNumber: this.contract.offerRefNumber,
      url: getLink(this.contract, RequestOverviewLinkRel.AcceptAgencyOfferContract).href,
      isBackoffice: false
    }));
  }

  get canReject(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineAgencyOfferContract);
  }
  
  public rejectContract() {
    this.store.dispatch(DeclineAgencyOfferContract({
      refNumber: this.contract.offerRefNumber,
      url: getLink(this.contract, RequestOverviewLinkRel.DeclineAgencyOfferContract).href,
      isBackoffice: false
    }));
  }

  public initiateMessageWithAgency(): void {
    this.conversationService
      .initiateMessageWithAgencyForAgencyOfferContract(this.contract);
  }

  get generalGuidelines(): string {
    if (this.contract.agencyStatus == ContractStatus.rejected) {
      return this.translateService.instant('AGENCY.DETAILS.GENERAL_GUIDELINES.DECLINED');
    }
    if (this.contract.agencyStatus == ContractStatus.pending) {
      return this.translateService.instant('AGENCY.DETAILS.GENERAL_GUIDELINES.PENDING');
    }
    if (this.contract.isFullyAccepted) {
      return this.translateService.instant('AGENCY.DETAILS.GENERAL_GUIDELINES.ACCEPTED');
    }
    return null;
  }

  get hasOfferExpired(): boolean {
    return this.contract.isAgencyOfferExpired
  }

  get areMoreDocumentsNeeded(): boolean {
    return this.contract.agencyStatus == ContractStatus.documentsNeeded;
  }

  get isRejected(): boolean {
    return this.contract.agencyStatus == ContractStatus.rejected;
  }

  public onOpenPublicProfile(entityType: EntityTypes): void {
    this.store.dispatch(
      LoadPublicProfile({ contract: this.contract, entityType })
    );
  }
}
