import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { addUtils, Contract } from '@libs/shared/models/contract.model';
import { Store } from '@ngrx/store';
import { AppState, FacilityProfileState } from '@platform/state/app-state.model';
import { selectDocumentsNeededTemplate } from '@platform/state/app.selectors';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { GetFacilityProfile } from '@libs/common-ui/facility-profile/facility-profile.actions';
import { Subscription } from 'rxjs';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';

@Component({
  selector: 'staffnow-documents-needed-modal',
  templateUrl: './documents-needed-modal.component.html',
  styleUrls: ['./documents-needed-modal.component.scss']
})
export class DocumentsNeededModalComponent implements OnInit, OnDestroy {
  @Input() onDocumentsNeededCallback: (pendingMoreDocumentDescription: string) => void;
  @Input() contract: Contract;

  form: UntypedFormGroup;
  pendingMoreDocumentDescription: string = '';
  documentsNeededTemplate: string = '';
  readonly textAreaMaxLength: number = 1000;
  private readonly subscriptions: Subscription = new Subscription();
  private apiRoot: ApiRootResource = null;
  facilityProfile: FacilityProfileState;

  constructor(
    private bsModalRef: BsModalRef,
    private store: Store<AppState>
  ) {
    this.subscriptions.add(
      this.store
        .pipe(getFilteredApiRoot)
        .pipe(take(1))
        .subscribe(apiRoot => {
          this.apiRoot = apiRoot;
        })
    );

    this.store.dispatch(GetFacilityProfile({ facilityUrl: this.getFacilityUrl() }));
  }

  ngOnInit(): void {
    this.pendingMoreDocumentDescription = this.getDocumentsNeededExplanation();

    this.subscriptions.add(
      this.store.pipe(selectDocumentsNeededTemplate).subscribe(documentsNeededTemplate => {
        this.documentsNeededTemplate = documentsNeededTemplate;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get canSubmit(): boolean {
    return this.pendingMoreDocumentDescription !== '';
  }

  getDocumentsNeededExplanation(): string {
    return addUtils(this.contract).getPendingReason()?.explanation ?? '';
  }

  submit(): void {
    this.onDocumentsNeededCallback(this.pendingMoreDocumentDescription);
    this.close();
  }

  close(): void {
    this.bsModalRef.hide();
  }

  setPendingMoreDocumentDescription(): void {
    this.pendingMoreDocumentDescription = this.documentsNeededTemplate;
  }

  getFacilityUrl(): string {
    return this.facilityProfile?._links
      ? getUrl(this.facilityProfile, ApiRootLinkRel.Self)
      : getUrl(this.apiRoot, ApiRootLinkRel.MyFacility);
  }
}
