import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  model,
  ModelSignal,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, debounceTime, EMPTY, Observable, startWith, switchMap } from 'rxjs';
import { AgencyFacility, SimpleFacilityWithUuid } from '@libs/shared/models/facility.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

const DEFAULT_PAGE_SIZE: number = 20;
@UntilDestroy()
@Component({
  selector: 'staffnow-agency-selector',
  standalone: true,
  imports: [AsyncPipe, NgIf, NgSelectModule, TranslateModule, FormsModule],
  templateUrl: './agency-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencySelectorComponent implements OnInit {
  @Input() loadAgencies: (searchTerm: string, page: number, pageSize: number) => Observable<AgencyFacility[]>;
  @Input() readOnly: boolean = false;
  @Output() selectedAgencyName: EventEmitter<string> = new EventEmitter();

  selectedAgencyUuid: ModelSignal<string> = model<string>(null);
  private readonly errorMessageService = inject(ErrorMessageService);
  agencies$: Observable<SimpleFacilityWithUuid[]>;
  search$ = new BehaviorSubject({
    term: '',
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    agencies: [] as SimpleFacilityWithUuid[]
  });
  loading: boolean = false;

  ngOnInit(): void {
    this.agencies$ = this.search$.pipe(
      untilDestroyed(this),
      tap(() => (this.loading = true)),
      debounceTime(250),
      switchMap(searchParams =>
        this.loadAgencies(searchParams.term, searchParams.page, searchParams.pageSize).pipe(
          map(
            agencies =>
              [
                ...searchParams.agencies,
                ...agencies.map(agency => ({ name: agency.name, uuid: agency.uuid }))
              ] as SimpleFacilityWithUuid[]
          )
        )
      ),
      catchError(err => {
        this.errorMessageService.handleErrorResponse(err);
        return EMPTY;
      }),
      tap(() => (this.loading = false)),
      startWith([])
    );
  }

  onSearch(term: string): void {
    this.search$.next({
      term: term,
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      agencies: [] as SimpleFacilityWithUuid[]
    });
  }

  loadMore(agenciesList: SimpleFacilityWithUuid[]): void {
    const previousPageableParams = this.search$.getValue();
    this.search$.next({
      ...previousPageableParams,
      page: previousPageableParams.page + 1,
      agencies: agenciesList
    });
  }

  findSelectedAgencyName(): void {
    this.agencies$.pipe().subscribe(agencies => {
      agencies.find(agency => {
        if (agency.uuid === this.selectedAgencyUuid()) {
          this.selectedAgencyName.emit(agency.name);
        }
      });
    });
  }
}
