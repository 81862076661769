import { Component, Input, OnChanges } from '@angular/core';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { PackageContractStatus } from '@libs/shared/models/package-contract.model';
import { CustomIconCode } from '@libs/shared/models/contract.model';
import { NgClass, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'status-circle',
  standalone: true,
  imports: [NgIf, NgClass, TooltipModule],
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss']
})
export class StatusCircleComponent implements OnChanges {
  readonly UNDER_REVIEW_ICON: CustomIconCode = CustomIconCode.ICON_000;
  @Input() status: ContractStatus | PackageContractStatus;
  @Input() shouldSoftenWords: boolean = false;
  @Input() customIcon: string = null;
  tooltipMessage: string = null;
  isAccepted: boolean = false;
  isPending: boolean = false;
  isRejected: boolean = false;
  isNegotiation: boolean = false;
  isDocumentsNeeded: boolean = false;

  ngOnChanges() {
    this.isAccepted = this.status == ContractStatus.accepted || this.status == PackageContractStatus.accepted;
    this.isPending = this.status == ContractStatus.pending || this.status == PackageContractStatus.pending;
    this.isRejected = this.status == ContractStatus.rejected || this.status == PackageContractStatus.rejected;
    this.isNegotiation = this.status == PackageContractStatus.negotiation;
    this.isDocumentsNeeded = this.status == ContractStatus.documentsNeeded;
    this.setTooltipMessage();
  }

  private setTooltipMessage() {
    if (this.isRejected && this.shouldSoftenWords) {
      this.tooltipMessage = 'REQUIRED';
    } else if (this.isRejected) {
      this.tooltipMessage = 'DECLINED';
    } else if (this.isPending && this.customIcon == this.UNDER_REVIEW_ICON) {
      this.tooltipMessage = 'UNDER REVIEW';
    } else {
      this.tooltipMessage = this.status.replace('_', ' ');
    }
  }
}
