<div class="buttons">
    <i class="material-icons close" (click)="close()">close</i>
</div>
<div class="quality-reject-container">
    <div class="header">
        <h1>{{ 'Reasons to Decline' | translate }}</h1>
    </div>
    <section class="reasons">
        <p class="sub-header text-muted">
            {{ 'SYSTEM.INFO.QUALITY_DECLINE' | translate }}
        </p>

        <form [formGroup]="form">
            <div
                class="custom-control custom-checkbox"
                *ngFor="let reason of reasonsGetter.controls; let i = index"
            >
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="reasonsArray{{ i }}"
                    [formControl]="reason"
                    (change)="getSelectedReasonsValue(); isFormValid()"
                />
                <label class="custom-control-label" for="reasonsArray{{ i }}">
                    {{ reasonsArray[i].name | translate }}
                </label>

                <div
                    class="reason-description"
                    [ngClass]="{ show: form.value.reasons[i] }"
                >
                    <textarea
                        class="form-control"
                        type="text"
                        placeholder="{{
                            'Enter Decline Reason explanation' | translate
                        }}"
                        (keydown)="validateDescriptionLength($event)"
                        (input)="
                            setSelectedReasonDescription(
                                $event,
                                reasonsArray[i].id
                            );
                            isFormValid()
                        "
                    ></textarea>
                </div>
            </div>
        </form>
    </section>

    <div class="actions">
        <general-button
            (onClick)="submit()"
            [isDisabled]="!canSubmit"
            text="Submit"
        ></general-button>
    </div>
</div>
