import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '@libs/shared/helpers/abstract-value-accessor';

@Component({
  selector: 'simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  providers: [MakeProvider(SimpleSelectComponent)]
})
export class SimpleSelectComponent extends AbstractValueAccessor {
  @Input() items: any[];
  @Input() placeholder: string = 'Please select an option';
  @Input() getLabel = item => item.name;
  @Input() getValue = item => item;
  @Input() valid: boolean = false;
  @Input() invalid: boolean = false;
  @Input() hasDefaultValue: boolean = false;
}
