import { Component, Input, OnInit } from '@angular/core';
import { OfferOutDto, SharedOfferOutDto } from '@libs/shared/models/offer.model';
import { SelectedAircraftPipe } from '@libs/shared/pipes/selected-aircrafts.pipe';

@Component({
  selector: 'staffnow-offer-aircrafts-field',
  templateUrl: './offer-aircraft-field.component.html'
})
export class OfferAircraftFieldComponent implements OnInit {
  @Input() offer: OfferOutDto | SharedOfferOutDto = null;
  @Input() isSharedOfferDto: boolean = false;
  @Input() displayInPairs: boolean = false;

  allAirplaneNames: string[] = [];
  constructor(private aircraftPipe: SelectedAircraftPipe) {}

  ngOnInit(): void {
    this.getAllAirplaneNames();
  }

  get offerAirplanes(): string {
    return this.isSharedOfferDto ? this.joinAirplanes() : this.aircraftPipe.transform(this.offer?.airplanes);
  }

  getAllAirplaneNames(): void {
    const airplanes: string = this.offerAirplanes;
    const otherAirplane: string = this.offer?.otherAirplanes ? this.offer?.otherAirplanes : '';

    const allAirplaneNames: string = otherAirplane !== '' ? airplanes.concat(',', otherAirplane) : airplanes;
    this.allAirplaneNames = allAirplaneNames.split(',');
  }

  private joinAirplanes(): string {
    return this.offer?.airplanes.join(', ');
  }
}
