<div class="message-input-container">
    <textarea class="message-input" [(ngModel)]="newMessageText"></textarea>
    <div style="display: flex; flex-direction: column">
        <input
            ng2FileSelect
            #fileInput
            hidden
            type="file"
            name="file"
            [accept]="inputAccepts"
            [multiple]="false"
            [uploader]="uploader"
        />
        <general-button
            icon="attachment"
            shape="round"
            [isLoading]="sendingMessageInProgress"
            (onClick)="fileInput.click()"
        ></general-button>
        <general-button
            icon="send"
            shape="round"
            [isDisabled]="!canSendMessage"
            [isLoading]="sendingMessageInProgress"
            (onClick)="sendMessage()"
        ></general-button>
    </div>
</div>
