<staffnow-go-back-button
    [currentPath]="['AGENCY.DETAILS.MY_JO' | translate, 'AGENCY.DETAILS.AGENCY_JO_DETAILS' | translate]"
></staffnow-go-back-button>
@if (isLoading) {
<staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
} @else {
<staffnow-panel
    [title]="'AGENCY.DETAILS.APPLICATION_STATUS.LABEL' | translate : { refNumber: contract?.offerRefNumber }"
>
    <ng-container data>
        <div class="technician-details">
            <div class="contract-status-and-date">
                <contract-status
                    [contract]="contract"
                    [isAgencyOfferContract]="true"
                    (openPublicProfile)="onOpenPublicProfile($event)"
                ></contract-status>
                <staffnow-contract-info [label]="'GENERAL.START_DATE' | translate">
                    <span>
                        {{ contract?.startDate | absoluteDateFormat }}
                    </span>
                </staffnow-contract-info>
                <staffnow-contract-info [label]="'GENERAL.FINISH_DATE' | translate">
                    <span>
                        {{ contract?.endDate | absoluteDateFormat }}
                    </span>
                </staffnow-contract-info>
            </div>
            <div>
                <!--Guidelines-->
                @if(!!generalGuidelines) {
                <staffnow-offer-info-section [title]="'GENERAL.INFORMATION' | translate" [icon]="'lightbulb'">
                    <staffnow-contract-info [displayBlock]="true">
                        <span [innerHtml]="generalGuidelines"></span>
                    </staffnow-contract-info>
                </staffnow-offer-info-section>
                }
                <!--More docs requested guidelines-->
                @if(areMoreDocumentsNeeded) {
                <staffnow-offer-info-section
                    [title]="'GENERAL.MORE_DOCS_NEEDED' | translate"
                    [icon]="'visibility'"
                    mode="warning"
                >
                    <staffnow-contract-info [displayBlock]="true">
                        {{ contract.documentsNeededExplanation }}
                    </staffnow-contract-info>
                </staffnow-offer-info-section>
                }
                <!--Agency decline guidelines-->
                @if(isRejected) {
                <staffnow-offer-info-section
                    [title]="'GENERAL.DECLINE_PRODUCTION_REASON' | translate"
                    [icon]="'report'"
                    mode="danger"
                >
                    <staffnow-contract-info [danger]="true" [displayBlock]="true">
                        {{ contract.rejectReason }}
                    </staffnow-contract-info>
                </staffnow-offer-info-section>
                }
            </div>
        </div>
    </ng-container>
    <ng-container actions>
        @if (canAccept) {
        <div class="contract-action">
            <general-button
                (onClick)="acceptContract()"
                [isDisabled]="hasOfferExpired"
                size="small"
                [text]="'GENERAL.ACCEPT_CONTRACT' | translate | uppercase"
            ></general-button>
            @if (hasOfferExpired) {
            <staffnow-info-icon-with-tooltip
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
            }
        </div>
        } @if(canReject) {
        <div class="contract-action">
            <general-button
                (onClick)="rejectContract()"
                [isDisabled]="hasOfferExpired"
                backgroundStyle="outline"
                size="small"
                [text]="'GENERAL.DECLINE_CONTRACT' | translate | uppercase"
                title="{{ 'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate }}"
                color="danger"
            ></general-button>
            @if (hasOfferExpired) {
            <staffnow-info-icon-with-tooltip
                [tooltipMessage]="'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate"
            ></staffnow-info-icon-with-tooltip>
            }
        </div>
        }
        <general-button
            (onClick)="initiateMessageWithAgency()"
            [isDisabled]="!canCreateConversationWithAgency && !canGetPreviousConversationWithAgency"
            backgroundStyle="outline"
            size="small"
            [text]="'GENERAL.CONTACT_AGENCY' | translate"
            title="{{ 'SYSTEM.INFO.MESSAGE_AGENCY_AFTER_ACCEPTING_JO' | translate }}"
            color="secondary"
        ></general-button>
    </ng-container>
</staffnow-panel>
<staffnow-offer-details
    [offer]="agencyOffer"
    [title]="'AGENCY.DETAILS.TECHNICIAN_OFFER_DETAILS' | translate : { offerDescription }"
>
    <ng-container data>
        @if(!jobOffer.hasHiddenRate) {
        <staffnow-contract-info [label]="'GENERAL.PAY_RATE' | translate">
            @if (hasIndicativeRateForOffer(agencyOffer)) {
            <div>
                {{ agencyOffer?.payRate | rate }}
                {{ ' - ' }}
                {{ agencyOffer?.maxPayRate | rate }}
                {{ ' per hour' | translate }}
            </div>
            } @else {
            {{ agencyOffer?.payRate | rate }}
            {{ ' per hour' | translate }}
            }
        </staffnow-contract-info>
        }
    </ng-container>
</staffnow-offer-details>
}
