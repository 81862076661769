@if(icon || text) {
<span (click)="handleClick($event)" (keydown.enter)="handleClick($event)" class="clickHandler">
    @if(icon){
    <i [ngClass]="getIconClasses()">
        <div [ngClass]="{ 'disable-action-button': isDisabled }">
            {{ icon }}
        </div>
    </i>
    } @if(text) {
    <span [ngClass]="getTextClasses()" [style]="{ textDecoration }">
        {{ text }}
    </span>
    }
</span>
}
