import { FormGroupState } from 'ngrx-forms';

import { initialFormState, RegistrationFormModel } from '../components/registration/registration-form.model';
import { MroRegistrationStateModel } from '../components/mro-facility-registration/mro-registration-state.model';
import { AgencyRegistrationStateModel } from '../components/agency-facility-registration/agency-registration-state.model';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import { MroFacility, MroGroup } from '@libs/shared/models/facility.model';
import { Skill } from '@libs/shared/models/skills.model';
import { WorkShiftDto } from '@libs/shared/models/work-shift-dto.model';
import { License } from '@libs/shared/models/license.model';

export const USERS_STATE_KEY = 'users';

export const initialState: UsersState = {
  registrationForm: initialFormState,
  usersList: {
    page: 0,
    pageSize: 0,
    totalElements: 0,
    _embedded: {
      admins: []
    },
    _links: {
      self: {
        href: ''
      }
    }
  },
  usersListingFilters: {
    term: '',
    regDate: null
  },
  listOfFacilities: null,
  mroGroupsList: []
};

/** ### MODELS ###  */
export interface UsersState {
  registrationForm?: FormGroupState<RegistrationFormModel>;
  usersList: UsersListingModel;
  usersListingFilters: UsersFilterValueModel;
  mroRegistrationForm?: FormGroupState<MroRegistrationStateModel>;
  agencyRegistrationForm?: FormGroupState<AgencyRegistrationStateModel>;
  listOfFacilities: UsersListingModel;
  mroGroupsList: MroGroup[];
}

export interface UsersListingModel extends Resource {
  term?: string;
  page: number;
  pageSize: number;
  totalElements: number;
  _embedded: {
    facilities?: MroFacility[];
    admins: Array<AdminUserProfile>;
  };
}

export interface AdminUserProfile extends Resource {
  userUuid: string;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  createdOn: string;
  lastLoginOn: string;
  status: string;
  completed: boolean;
  email: string;
  role: string;
  blocked?: boolean;
  statusChangedBy?: null;
}

export interface UsersFilterValueModel {
  term: string;
  regDate: string;
  role?: string;
  ameType?: number;
  ameLicense?: number;
  accountStatus?: string;
  profileStatus?: string;
  aircraft?: Array<Aircraft>;
  skills?: Array<Skill>;
  workShifts?: Array<WorkShiftDto>;
  licenses?: License[];
  name?: string;
  createdOn?: string;
  mroFacility?: string;
  agencyFacility?: string;
  position?: string;
  group?: string;
  ameTypeLicense?: {
    ameType?: number;
    ameLicense?: number;
  };
}
