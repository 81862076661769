import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {OperatorFunction, pipe} from 'rxjs';

import {
  FetchUserAvailability,
  GetBlocklist,
  InitiateChangeStaffieStatus,
  ResetUserProfileState,
  SubmitBlocklist,
  UpdateUserProfile
} from './state/user-profile.actions';
import {UserProfileService} from './services/user-profile.service';
import {CreateConversation, LoadConversation} from '@libs/mailbox/state/mailbox.actions';
import {selectAvailability, selectAvailabilityStart, selectBlacklist, selectUserProfileAirplanes} from './state/user-profile.selectors';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {Availability} from '@libs/shared/models/availability.model';
import {getAirplanes, getLoggedInUserRole} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {getLink, getUrl, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {accountStatusConstants} from '@libs/shared/constants/statuses.constants';
import {DocumentsService} from '@libs/user-profile/services/documents.service';
import {AbstractProfileComponent} from '@libs/user-profile/components/abstract-profile-component';
import {NavigationTabs} from '@libs/user-profile/model/account-form.config';
import {role, RoleWrapper} from '@libs/shared/models/roles.enum';
import {take} from 'rxjs/operators';
import {AppRegionEnum} from '@libs/shared/bms-common/environment/environment.model';
import {isPlatformWeb} from '@libs/shared/helpers/capacitor';

@Component({
  selector: 'staffnow-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends AbstractProfileComponent {
  @ViewChild('editSection') public editSection: ElementRef;

  public airplanesMap: Array<any>;
  public technicianAvailability: Availability;
  public technicianAvailabilityInitial: string = '';
  public selectedAircraftList: Array<any> = [];
  private fetchAvailabilityUrl: string;
  public profileNavItems: Array<string> = [];
  public canMarkInReview: boolean = false;
  public blocklistUrl: string = '';
  public blocklist: any = {};
  public isUserLinkedToMro: boolean = false;
  public technicianPendingChanges: Array<any> = [];
  private wrappedRole: RoleWrapper;

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    private profileService: UserProfileService,
    private confirmService: ModalService,
    private router: Router
  ) {
    super(store, documentsService, activatedRoute);
    this.setupStoreSubscriptions();
    this.setupRouteSubscription();
  }

  private setupStoreSubscriptions() {
    this.storeSubscribe(
      getAirplanes,
      airplanes => (this.airplanesMap = airplanes)
    );
    this.onUserProfileChange(userProfile => {
      this.canMarkInReview =
        hasLink(userProfile, UserProfileLinkRel.ProfileStatusChange) &&
        this.isTechnicianProfile();
      this.profileNavItems = this.prepareProfileNavigationItems();

      if (hasLink(userProfile, UserProfileLinkRel.Availability)) {
        this.fetchAvailabilityUrl = getUrl(
          userProfile,
          UserProfileLinkRel.Availability
        );
        this.fetchAvailability(this.technicianAvailabilityInitial);
      }

      if (hasLink(userProfile, UserProfileLinkRel.BlockList)) {
        this.blocklistUrl = getUrl(userProfile, UserProfileLinkRel.BlockList);
      }

      this.isUserLinkedToMro =
        this.isTechnicianProfile() && !!userProfile.isStaffie;

      this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
        this.wrappedRole = role(userRole);
        this.technicianPendingChanges =
          this.wrappedRole.isAdminOrModerator() && this.isTechnicianProfile()
            ? userProfile._embedded.pendingChanges
            : [];
      });
    });
    this.storeSubscribe(select(selectUserProfileAirplanes), airplanes => {
      if (airplanes && this.airplanesMap) {
        this.selectedAircraftList = this.getPreselectedAircrafts(
          this.airplanesMap,
          airplanes
        );
      }
    });
    this.storeSubscribe(
      select(selectBlacklist),
      blacklist => (this.blocklist = blacklist)
    );
    this.storeSubscribe(
      select(selectAvailability),
      availability => (this.technicianAvailability = availability)
    );
    this.storeSubscribe(
      select(selectAvailabilityStart),
      availabilityStart =>
        (this.technicianAvailabilityInitial = availabilityStart)
    );
  }

  private setupRouteSubscription() {
    this.addSub(
      this.activatedRoute.data.subscribe(() => {
        this.loadProfile();
      })
    );
  }

  private loadProfile() {
    this.store.dispatch(UpdateUserProfile());
  }

  public ngOnDestroy() {
    this.store.dispatch(ResetUserProfileState());
    super.ngOnDestroy();
  }

  public getPreselectedAircrafts(
    airplanesMap: Array<any>,
    technicianAirplanes: Array<number>
  ) {
    if (airplanesMap && technicianAirplanes) {
      return technicianAirplanes.map(id =>
        airplanesMap.find(item => item.id === id)
      );
    }
  }

  public handleLoadMonths($event) {
    this.fetchAvailability($event);
  }

  public handleFetchAvailability() {
    this.fetchAvailability(this.technicianAvailabilityInitial);
  }

  private fetchAvailability(params) {
    this.store.dispatch(
      FetchUserAvailability({
        availabilityUrl: this.fetchAvailabilityUrl + params
      })
    );
  }

  public handleLoadBlocklist() {
    this.store.dispatch(GetBlocklist({url: this.blocklistUrl}));
  }

  public handleSubmitBlocklist(blacklistedEntities) {
    this.store.dispatch(
      SubmitBlocklist({
        targetUrl: this.blocklistUrl,
        facilities: blacklistedEntities
      })
    );
  }

  public navigateToMailbox() {
    this.router.navigate(['mailbox']);
    if (hasLink(this.userProfile, UserProfileLinkRel.GetConversation)) {
      this.store.dispatch(
        LoadConversation({
          conversationUrl: getUrl(
            this.userProfile,
            UserProfileLinkRel.GetConversation
          )
        })
      );
    } else {
      this.store.dispatch(
        new CreateConversation(this.userProfile._embedded.profile.userUuid)
      );
    }
  }

  public editIsStaffie(value: { isStaffie: boolean; mroUuid: string }) {
    this.store.dispatch(
      InitiateChangeStaffieStatus({
        payload: this.profileService.prepareBackendRequestPayload({
          ...this.profileService.parseUserProfileToFormModel(this.userProfile),
          ...value
        }),
        changeStaffieStatusLink: getLink(
          this.userProfile,
          ApiRootLinkRel.ChangeStaffieStatus
        ),
        profileSelfLink: getLink(this.userProfile, ApiRootLinkRel.Self)
      })
    );
  }

  public isStaffieChangedHandler(value: {
    isStaffie: boolean;
    mroUuid: string;
    wasAlreadyStaffie: boolean;
  }) {
    this.confirmService.openConfirmModal(getConfirmMessage(), () => {
      this.editIsStaffie(value);
    });

    function getConfirmMessage() {
      if (value.isStaffie) {
        return value.wasAlreadyStaffie
          ? 'SYSTEM.INFO.CHANGE_STAFFIE_MRO'
          : 'SYSTEM.INFO.TURN_TECHNICIAN_STAFFIE';
      } else {
        return 'SYSTEM.INFO.TURN_STTAFFIE_TECHNICIAN';
      }
    }
  }

  private prepareProfileNavigationItems() {
    const navItems: string[] = [];
    this.addAccountNavItem(navItems);
    if (!this.isProfileDeleted()) {
      this.addDocumentsNavItem(navItems);
      this.addSkillsNavItem(navItems);
      this.addSelfIdentificationNavItem(navItems);
      this.addAvailabilityNavItem(navItems);
      this.addPerformanceFeedbacksNavItem(navItems);
      this.addSettingsNavItem(navItems);
      this.addBackofficeNavItem(navItems);
    }
    return navItems;
  }

  private isProfileDeleted() {
    return (
      this.userProfile?._embedded.profile.accountStatus ===
      accountStatusConstants.deleted
    );
  }

  private addAccountNavItem(navItems) {
    if (hasLink(this.userProfile, UserProfileLinkRel.Self)) {
      navItems.push(NavigationTabs.General);
    }
  }

  private addDocumentsNavItem(navItems: string[]) {
    if (hasLink(this.userProfile, UserProfileLinkRel.DownloadAllDocuments)) {
      navItems.push(NavigationTabs.Documents);
    }
  }

  private addSkillsNavItem(navItems: string[]) {
    if (this.isTechnicianProfile()) {
      navItems.push(NavigationTabs.Skills);
    }
  }

  private addSelfIdentificationNavItem(navItems: string[]) {
    if (!!this.userProfile.technicianSelfIdentificationOutDto && this.isTechnicianInUsa()) {
      navItems.push(NavigationTabs.SelfIdentification);
    }
  }

  private isTechnicianInUsa(): boolean {
    return this.apiRegion == AppRegionEnum.USA;
  }

  private addAvailabilityNavItem(navItems: string[]) {
    if (hasLink(this.userProfile, UserProfileLinkRel.Availability)) {
      navItems.push(NavigationTabs.Availability);
    }
  }

  private addSettingsNavItem(navItems: string[]) {
    if (
      hasLink(this.userProfile, UserProfileLinkRel.BlockList) &&
      this.canEdit
    ) {
      navItems.push(NavigationTabs.Settings);
    }
  }

  private addBackofficeNavItem(navItems: string[]) {
    if (
      hasLink(this.userProfile, UserProfileLinkRel.ChangeLoginEmail) &&
      this.canEdit
    ) {
      navItems.push(NavigationTabs.Backoffice);
    }
  }

  private addPerformanceFeedbacksNavItem(navItems: string[]) {
    if (
      hasLink(this.userProfile, UserProfileLinkRel.GetTechnicianPerformanceFeedbacks)
    ) {
      navItems.push(NavigationTabs.PerformanceFeedbacks);
    }
  }

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.addSub(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  shouldShowIncompleteWarning() {
    return this.profileIsIncomplete() && this.isOwnProfile();
  }

  private profileIsIncomplete() {
    return !this.userProfile._embedded.profile.completed;
  }

  private isOwnProfile() {
    return !this.activatedRoute.snapshot.params.userUuid;
  }
}
