import { Injectable } from '@angular/core';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isBlank } from '@libs/shared/helpers/help-functions';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCodeMessageExtractor, NonIgnorableErrors } from '@libs/common-ui/services/error-message/error-message.helper';
import { StaffnowErrorResponse } from '@libs/shared/exceptions/staffnow-error-response.model';

@Injectable()
export class ErrorMessageService {
  constructor(
    private modalService: ModalService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslateService
  ) {}

  public handleErrorResponseWithoutWarningTheUser(response: HttpErrorResponse): void {
    this.printTraceIntoTheConsole(response);
    if (this.isNonIgnorableError(response)) {
      this.displayToastFailureMessage(ErrorCodeMessageExtractor.getErrorMessageFromResponseStatus(response.status));
    }
  }

  public handleErrorResponseWithCustomMessage(response: HttpErrorResponse, message: string): void {
    this.printTraceIntoTheConsole(response);
    if (this.isNonIgnorableError(response)) {
      message = ErrorCodeMessageExtractor.getErrorMessageFromResponseStatus(response.status);
    }
    this.displayToastFailureMessage(message);
  }

  public handleErrorResponse(response: HttpErrorResponse, defaultMessage?: string): void {
    this.printTraceIntoTheConsole(response);
    this.displayToastFailureMessage(this.getErrorMessage(response, defaultMessage));
  }

  public handleErrorResponseWithCustomerSupportModal(response: HttpErrorResponse, modalTitle: string): void {
    this.printTraceIntoTheConsole(response);
    this.modalService.openCustomerSupportErrorModal({
      title: modalTitle,
      message: this.getErrorMessage(response)
    });
  }

  private printTraceIntoTheConsole(response: HttpErrorResponse): void {
    console.trace('Received this error response from server: ', response);
  }

  private getErrorMessage(response: HttpErrorResponse, defaultMessage: string = 'ERROR_MESSAGES.UNKNOWN_ERROR'): string {
    const errorCode: string = this.responseErrorCode(response);
    if (isBlank(errorCode)) {
      return this.translateService.instant(
        this.isNonIgnorableError(response)
          ? ErrorCodeMessageExtractor.getErrorMessageFromResponseStatus(response.status)
          : defaultMessage
      );
    } else {
      return this.translateErrorCode(response, errorCode, defaultMessage);
    }
  }

  private translateErrorCode(
    response: HttpErrorResponse | StaffnowErrorResponse,
    errorCode: string,
    defaultMessage: string
  ) {
    const errorMessageKey = ErrorCodeMessageExtractor.getErrorMessageKeyFromErrorCode(errorCode);
    const extraData = this.responseExtraData(response);

    const translatedMessage = extraData
      ? this.translateService.instant(errorMessageKey, this.extractTranslationParams(extraData))
      : this.translateService.instant(errorMessageKey);

    return errorMessageKey === translatedMessage ? this.translateService.instant(defaultMessage) : translatedMessage;
  }

  private responseErrorCode(response: any): string | undefined {
    return response.error?.errorCode ?? response.errorCode;
  }

  private responseExtraData(response: any): object | undefined {
    return response.error?.extraData ?? response.extraData;
  }

  private extractTranslationParams(extraData: any): object {
    return typeof extraData !== 'object' ? { errorData: extraData } : extraData;
  }

  private isNonIgnorableError(response: HttpErrorResponse): boolean {
    return response.status == NonIgnorableErrors.DELETED_FEATURE.httpStatus;
  }

  private displayToastFailureMessage(message: string): void {
    this.toastMessageService.fail(message);
  }
}
