import { Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';
import {
  AdditionalBenefit,
  benefitTypeToReadable
} from '@libs/shared/models/additional-benefit.model';
import { RatePipe } from '@libs/shared/pipes/rate.pipe';
import { Rate } from '@libs/shared/models/rate.model';

@Component({
  selector: 'staffnow-offer-benefit-details',
  templateUrl: './offer-benefit-details.component.html',
  styleUrls: ['./offer-benefit-details.component.scss']
})
export class OfferBenefitDetailsComponent {
  @Input() public benefits: Array<AdditionalBenefit> = [];

  constructor(private ratePipe: RatePipe) {}

  public hasDescription(benefit: AdditionalBenefit): boolean {
    return !isNil(benefit?.description);
  }

  public getLabel(benefit: AdditionalBenefit): string {
    return benefitTypeToReadable(benefit?.type);
  }

  public getPayment(benefit: AdditionalBenefit): string {
    const amount: Rate = benefit?.amount as Rate;
    if (amount?.amount) {
      return ` (${this.ratePipe.transform(amount)})`;
    }
    return '';
  }
}
