<i (click)="close()" class="material-icons close-icon"> clear </i>
<div class="header">
    <h1 class="heading">{{ 'PACKAGE_CONTRACT.DECLINE' | translate }}</h1>
    <p class="text-muted sub-heading">
        {{ 'PACKAGE_CONTRACT.DECLINE.REASON' | translate }}
    </p>
</div>
<div class="content">
    <div class="notes-container">
        <label class="notes-label" for="notes">{{ 'GENERAL.DECLINE_REASON' | translate }}</label>
        <textarea
            (input)="charactersCounter($event)"
            [(ngModel)]="explanation"
            [maxlength]="textAreaMaxLength"
            autofocus
            class="form-control notes"
            id="notes"
            name="notes"
            rows="8"
        >
        </textarea>
        <small class="counter">
            <span>{{ charactersCount }}</span> / {{ textAreaMaxLength }}
        </small>
    </div>
    <general-button
        (onClick)="submit()"
        [isDisabled]="isDisabled"
        text="Submit"
    ></general-button>
</div>
