import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, of, OperatorFunction, pipe, startWith, switchMap } from 'rxjs';
import {
  DisableAction,
  FormGroupState,
  MarkAsDirtyAction,
  MarkAsPristineAction,
  ResetAction,
  SetErrorsAction,
  SetValueAction
} from 'ngrx-forms';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { cloneDeep, isNil } from 'lodash-es';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { getCurrentUTCDateString } from '@libs/shared/helpers/date-utils';
import { CreatePackageOfferFormModel, packageOfferInitialFormState } from '../../state/create-package-offer.model';
import { CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY } from '../../state/create-package-offer.reducer';
import {
  FailedToSubmitPackageOffer,
  ResetForm,
  SubmitPackageOffer,
  SucceededToSubmitPackageOffer
} from '../../state/package-offer.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OfferEditMode, OfferType, PackageOfferDto } from '@libs/shared/models/offer.model';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { LocationPO } from '@libs/shared/models/location.model';
import { getLocations } from '@libs/shared/bms-common/environment/environment.selector';
import { LoadLocationList } from '@libs/shared/bms-common/api-root/api-root.actions';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { AviationCompany, MroFacilityLoaderService } from '../../../../../shared/services/mro-facility-loader.service';
import { MroFacility } from '@libs/shared/models/facility.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import {
  JO_TITLE_MAX_LENGTH,
  JO_TITLE_MIN_LENGTH,
  SHORT_PRESENTATION_MAX_LENGTH,
  SHORT_PRESENTATION_MIN_LENGTH
} from '../../../../../shared/validators/offer-form.validator';
import { AppState } from '@platform/state/app-state.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-create-package-offer-page',
  templateUrl: './create-package-offer.component.html',
  styleUrls: ['./create-package-offer.component.scss']
})
export class CreatePackageOfferComponent implements OnInit, OnDestroy {
  public isEditForm?: boolean = false;
  public createOfferForm: Observable<FormGroupState<CreatePackageOfferFormModel>>;
  public createOfferFormValue: FormGroupState<CreatePackageOfferFormModel> = null;
  public isSubmitting: boolean = false;
  public rangePickerValue: Array<string> = [];
  public rangePickerMinDate: string = getCurrentUTCDateString();
  public getLabelFunction: any = item => item;
  public breadcrumbs: string[] = ['Job Openings and Applications', 'Create Fixed-Price Job Opening'];
  public isSettingInitialValues: boolean = true;
  public facilityProfile: AviationCompany;
  public locationList: LocationPO[] = [];
  public selectedLocation: LocationPO = null;
  public offersAreHandledCentrally = false;
  public facilityList: any[] = [];
  public wrappedRole: RoleWrapper;
  mroSearchObservable = new BehaviorSubject({ term: '', pageSize: 20, page: 0, facilities: [] });
  mroList$: Observable<MroFacility[]>;
  loading: boolean = false;

  private readonly existingOffer: PackageOfferDto = null;
  private readonly mroUuid: string = '';
  private readonly SESSION_STATE_KEY: string = 'STATE';

  public highlightErrors: boolean = false;

  protected readonly JO_TITLE_MIN_LENGTH = JO_TITLE_MIN_LENGTH;
  protected readonly JO_TITLE_MAX_LENGTH = JO_TITLE_MAX_LENGTH;
  protected readonly JO_PRESENTATION_MAX_LENGTH = SHORT_PRESENTATION_MAX_LENGTH;
  protected readonly JO_PRESENTATION_MIN_LENGTH = SHORT_PRESENTATION_MIN_LENGTH;
  protected readonly packageOfferInitialFormState = packageOfferInitialFormState;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly customNavigationService: CustomNavigationService,
    private readonly mroFacilityLoaderService: MroFacilityLoaderService
  ) {
    this.store.dispatch(LoadLocationList());
    let state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      sessionStorage.setItem(this.SESSION_STATE_KEY, JSON.stringify(state));
    } else if (sessionStorage.getItem(this.SESSION_STATE_KEY)) {
      state = JSON.parse(sessionStorage.getItem(this.SESSION_STATE_KEY));
    }
    this.mroUuid = state.mroUuid;
    this.isEditForm = state.isEdit;
    this.existingOffer = state.offer;
    this.breadcrumbs = state.breadcrumbs ?? this.breadcrumbs;
    this.createOfferForm = this.store.pipe(
      select(state => <FormGroupState<CreatePackageOfferFormModel>>state[CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY])
    );
    this.storeSubscribe(getLocations, locationList => {
      this.locationList = locationList;
    });
    this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
      this.wrappedRole = role(userRole);
    });
    this.createOfferForm.subscribe(value => (this.createOfferFormValue = cloneDeep(value)));
    this.actions.pipe(ofType(SucceededToSubmitPackageOffer), untilDestroyed(this)).subscribe(action => {
      this.isSubmitting = false;
      if (action.refNumber && !this.wrappedRole.isAdminOrModerator()) {
        this.customNavigationService.goToOfferDetails(action.refNumber, OfferType.FIXED_PRICE, this.isEditForm);
      } else {
        this.customNavigationService.goBack();
      }
    });
    this.actions
      .pipe(ofType(FailedToSubmitPackageOffer), untilDestroyed(this))
      .subscribe(() => (this.isSubmitting = false));
  }

  ngOnInit() {
    if (this.isEditForm) {
      const facilityUrl: string = getUrl(
        getEmbeddedResource(this.existingOffer as any, JobOfferLinkRel.Facility),
        FacilityProfileLinkRel.Self
      );
      this.mroFacilityLoaderService
        .getFacility(facilityUrl)
        .pipe(take(1))
        .subscribe(facility => {
          this.mroList$ = of([facility]);
          this.initForm(facility);
          this.setFormValuesAndDisableOnRestrictedMode();
          this.markAsPristine();
          this.isSettingInitialValues = false;
        });
    } else {
      this.mroFacilityLoaderService
        .getFacilityProfile(this.mroUuid)
        .pipe(take(1))
        .subscribe(facility => {
          this.initForm(facility.facilityProfile);
          this.isSettingInitialValues = false;
          this.mroList$ = this.mroSearchObservable.pipe(
            debounceTime(250),
            tap(() => (this.loading = true)),
            withLatestFrom(this.store.pipe(getFilteredApiRoot)),
            switchMap(([{ term, pageSize, page, facilities }, apiRoot]) =>
              this.mroFacilityLoaderService
                .getMroFacilities(
                  getUrl(apiRoot, ApiRootLinkRel.GetMROGroupFacilitiesPaged).replace(
                    '{mroGroupId}',
                    facility?.facilityProfile?.groupId
                  ),
                  term,
                  page,
                  pageSize
                )
                .pipe(
                  map(retrievedFacilities => [
                    this.facilityProfile,
                    ...facilities,
                    ...retrievedFacilities._embedded.facilities
                  ]),
                  tap(() => (this.loading = false))
                )
            ),
            startWith([])
          );
        });
    }
  }

  public initForm(facilityProfile: AviationCompany): void {
    this.facilityProfile = facilityProfile;
    const facilityUuidControlId = this.createOfferFormValue.controls.mroUuid.id;
    if (!facilityProfile) {
      this.store.dispatch(new SetValueAction(facilityUuidControlId, null));
      this.store.dispatch(new SetErrorsAction(facilityUuidControlId, { type: 'missing required field' }));
      return;
    }
    this.offersAreHandledCentrally = facilityProfile.offersHandledCentrally;
    this.store.dispatch(new SetValueAction(facilityUuidControlId, facilityProfile.uuid));
    this.store.dispatch(new SetErrorsAction(facilityUuidControlId, {}));
    this.selectedLocation = this.locationList.find(location => location.name == this.facilityProfile.location);
    this.writeLocationIdToForm();
  }

  private setFormValuesAndDisableOnRestrictedMode(): void {
    const controls = this.createOfferFormValue.controls;

    Object.keys(this.existingOffer).forEach(controlKey => {
      switch (controlKey) {
        case 'periodFrom':
          this.rangePickerValue = [this.existingOffer[controlKey], this.existingOffer['periodTo']];
          this.rangePickerMinDate = this.rangePickerValue[0];
          this.handleDateChange(this.rangePickerValue);
          break;
        case 'location': {
          const locationId = this.existingOffer[controlKey].id;
          this.selectedLocation = this.locationList.find(location => location.id == locationId);
          this.writeLocationIdToForm();
          break;
        }
        case 'title':
        case 'priority':
        case 'refNumber':
        case 'specification':
          this.store.dispatch(new SetValueAction(controls[controlKey].id, this.existingOffer[controlKey]));
          break;
        default:
          break;
      }
    });

    if (this.isRestrictedMode()) {
      this.disableFieldsOnRestrictedMode();
    }
  }

  private disableFieldsOnRestrictedMode(): void {
    const alwaysActiveControls = ['priority', 'contactUserUuid'];
    Object.keys(this.createOfferFormValue.controls).forEach(controlKey => {
      if (!alwaysActiveControls.includes(controlKey)) {
        this.store.dispatch(new DisableAction(this.createOfferFormValue.controls[controlKey].id));
      }
    });
  }

  private storeSubscribe<S>(pipedSelector: OperatorFunction<AppState, S>, subscribeFn: (a: S) => void) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  ngOnDestroy() {
    this.store.dispatch(ResetForm());
    this.store.dispatch(new ResetAction(this.createOfferFormValue.id));
    sessionStorage.removeItem(this.SESSION_STATE_KEY);
  }

  public isRestrictedMode(): boolean {
    return this.existingOffer?.editMode === OfferEditMode.RESTRICTED;
  }

  public handleDateChange(period: string[]): void {
    if (period.length === 2 && this.haveDatesChanged(period)) {
      const controls = this.createOfferFormValue.controls;
      this.store.dispatch(new SetValueAction(controls.preferredStartDate.id, period[0]));
      this.store.dispatch(new SetValueAction(controls.preferredEndDate.id, period[1]));
      this.store.dispatch(new MarkAsDirtyAction(controls.preferredStartDate.id));
    }
  }

  private haveDatesChanged(period: string[]): boolean {
    const startDate = this.createOfferFormValue.controls.preferredStartDate.value;
    const endDate = this.createOfferFormValue.controls.preferredEndDate.value;
    return startDate != period[0] || endDate != period[1];
  }

  public handleFormSubmit() {
    if (!this.canSubmitForm()) {
      this.highlightErrors = true;
      return;
    }
    this.highlightErrors = false;
    this.markAsPristine();
    this.isSubmitting = true;
    this.store.dispatch(
      SubmitPackageOffer({
        payload: {
          ...this.createOfferFormValue.value,
          uuid: this.existingOffer?.uuid
        },
        editUrl: isNil(this.existingOffer) ? null : getUrl(this.existingOffer, RequestOverviewLinkRel.Edit)
      })
    );
  }

  public canSubmitForm(): boolean {
    return this.createOfferFormValue.isValid && this.createOfferFormValue.isDirty;
  }

  public cancelCreation() {
    this.customNavigationService.goBack();
  }

  private markAsPristine(): void {
    this.store.dispatch(new MarkAsPristineAction(this.createOfferFormValue.id));
  }

  public writeLocationIdToForm() {
    this.store.dispatch(
      new SetValueAction(this.createOfferFormValue.controls.locationId.id, this.selectedLocation?.id)
    );
    this.store.dispatch(new MarkAsDirtyAction(this.createOfferFormValue.controls.locationId.id));
  }

  onSearch(term: string) {
    this.mroSearchObservable.next({
      page: 0,
      term,
      pageSize: 20,
      facilities: []
    });
  }

  onClose() {
    this.onSearch('');
  }

  loadMore(facilities: MroFacility[]) {
    const currentPageableValues = this.mroSearchObservable.getValue();
    this.mroSearchObservable.next({
      ...currentPageableValues,
      facilities,
      page: currentPageableValues.page + 1
    });
  }

  public canSelectLocation(): boolean {
    return !this.isRestrictedMode() && this.offersAreHandledCentrally;
  }
}
