<div class="contract-card-actions">
    @if(canAcceptContract){
    <general-button
        (onClick)="acceptContractAviationCompany()"
        [isDisabled]="hasAlreadyAviationCompanyAccepted || isOfferEnded"
        size="small"
        [text]="'GENERAL.ACCEPT' | translate"
        backgroundStyle="outline"
    ></general-button>

    } @if(canDeclineContract){
    <general-button
        (onClick)="declineContractAviationCompany()"
        [isDisabled]="hasAlreadyAviationCompanyRejected || isOfferEnded"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.DECLINE' | translate"
        title="{{
            !isOfferEnded
                ? ('SYSTEM.INFO.DECLINE_JOB_OPENING' | translate)
                : ('SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate)
        }}"
        color="danger"
    ></general-button>

    } @if(canMarkDocumentsNeeded) {
    <general-button
        (onClick)="markContractAsDocumentsNeeded()"
        [isDisabled]="hasAlreadyMroRejected || isOfferEnded"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.MORE_DOCS' | translate"
        title="{{
            !isOfferEnded
                ? ('SYSTEM.INFO.DOCUMENTS_NEEDED' | translate)
                : ('SYSTEM.INFO.DISABLED_ACTION_ENDED_JO' | translate)
        }}"
        color="dark"
    ></general-button>
    } @if(canPauseContract) {
    <general-button
        (onClick)="handlePauseContract()"
        backgroundStyle="outline"
        size="small"
        [text]="('GENERAL.PAUSE' | translate) + ' \u00A0'"
        color="primary"
    ></general-button>
    } @if(canResumeContract) {
    <general-button
        (onClick)="handleResumeTechnician()"
        backgroundStyle="outline"
        size="small"
        [text]="'GENERAL.RESUME' | translate"
        color="primary"
    ></general-button>
    }

    <div class="btn-group" dropdown container="body" placement="bottom right" [isDisabled]="isContactActionDisable()">
        <button
            dropdownToggle
            type="button"
            class="btn btn-outline-secondary btn-sm dropdown-toggle"
            aria-controls="dropdown-custom-html"
            title="{{ 'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate }}"
        >
            {{ 'TABLES.LABEL.GENERAL_CONTACT' | translate }}<span class="caret"></span>
        </button>
        <ul id="dropdown-container" *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <li>
                <general-button
                    (onClick)="initiateMessageWithTechnician()"
                    [isDisabled]="!canCreateConversationWithTechnician && !canGetPreviousConversationWithTechnician"
                    backgroundStyle="outline"
                    class="dropdown-item"
                    size="small"
                    [text]="'GENERAL.CONTACT_TECHNICIAN' | translate"
                    title="{{ 'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate }}"
                    color="secondary"
                ></general-button>
            </li>

            <li>
                @if(contract.requiresAgencyAcceptance){
                <general-button
                    (onClick)="initiateMessageWithAgency()"
                    [isDisabled]="!canCreateConversationWithAgency && !canGetPreviousConversationWithAgency"
                    backgroundStyle="outline"
                    class="dropdown-item"
                    size="small"
                    [text]="'GENERAL.CONTACT_AGENCY' | translate"
                    title="{{ 'SYSTEM.INFO.MESSAGE_ON_MATCH' | translate }}"
                    color="secondary"
                ></general-button>
                }
            </li>
        </ul>
    </div>
</div>
