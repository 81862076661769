<div class="overview-container">
    <div class="overview-table-container">
        <table class="table table-sm table-striped" aria-label="List of jobs">
            <thead>
                <tr>
                    <th></th>
                    @for (col of columns; track col.key) {
                    <th
                        class="{{ col.key }}"
                        [ngClass]="{
                            clickable: hasProp(col.key) || col.key === 'period'
                        }"
                        (click)="handleSortingChange(col.key)"
                        (keydown.enter)="handleSortingChange(col.key)"
                    >
                        <span>{{ col.name | translate }}</span>

                        @if(col.key === 'period') {
                        <i
                            class="material-icons sorting-arrow"
                            [ngClass]="{
                                asc: sortState['periodFrom'] === sortingStagesEnum.asc,
                                desc: sortState['periodFrom'] === sortingStagesEnum.desc
                            }"
                        >
                            arrow_drop_down
                        </i>
                        } @if(hasProp(col.key)) {
                        <i
                            class="material-icons sorting-arrow"
                            [ngClass]="{
                                asc: sortState[col.key] === sortingStagesEnum.asc,
                                desc: sortState[col.key] === sortingStagesEnum.desc
                            }"
                        >
                            arrow_drop_down
                        </i>
                        }
                    </th>
                    }
                </tr>
            </thead>
            <tbody>
                @for (offer of offerList; track offer.id ; let index = $index) {
                <tr
                    class="clickable"
                    (click)="handleRowExpand(offer, index)"
                    (keydown.enter)="handleRowExpand(offer, index)"
                >
                    <td>
                        <i
                            [ngClass]="{
                                expanded: expandedRowIndex === index,
                                collapsed: expandedRowIndex !== index
                            }"
                            class="material-icons expand-arrow"
                        >
                            arrow_right
                        </i>
                    </td>
                    @for (col of columns; track col.key) {
                    <td class="{{ col.key }}">
                        @switch (col.key) { @case ('status') {
                        <status-offer-icon [type]="getCornerCardType(offer)"> </status-offer-icon>
                        } @case ('priority') {
                        <staffnow-offer-priority-info
                            [priority]="offer?.priority"
                            [displayLabel]="false"
                        ></staffnow-offer-priority-info>
                        } @case ('ameType') {
                        {{ offer?.ameTitle ? (offer.ameTitle | ameTitleName) : '-' }}
                        } @case ('aircraft') {
                        <staffnow-offer-aircrafts-field
                            [offer]="offer"
                            [displayInPairs]="true"
                        ></staffnow-offer-aircrafts-field>
                        } @case ('period') { @if(offer?.periodFrom && offer?.periodTo) {
                        <span
                            >From <strong>{{ offer?.periodFrom | shortDateFormat }}</strong></span
                        >
                        <br />
                        <span
                            >to <strong>{{ offer?.periodTo | shortDateFormat }}</strong></span
                        >
                        } @else {
                        <strong>{{ offer?.startDate | shortDateFormat }}</strong>
                        } } @case ('actions') {
                        <app-offer-actions [offer]="offer"></app-offer-actions>
                        } @default { @if(col.key === 'fullyMatchedSize' && !offer[col.key] || col.key ===
                        'rejectedContracts' && !offer[col.key] || col.key === 'finishedContracts' && !offer[col.key]) {
                        - } @else {
                        <div [class]="col.key">
                            {{ offer[col.key] ? offer[col.key] : '-' }}
                        </div>
                        } } }
                    </td>
                    }
                </tr>
                @if (expandedRowIndex === index) {
                <tr class="append">
                    <td class="append-td" [colSpan]="columns.length + 1">
                        <div
                            class="content-wrapper"
                            [ngClass]="{
                                expandedRow: expandedRowIndex === index,
                                collapsedRow: expandedRowIndex !== index
                            }"
                        >
                            <ng-container>
                                <app-contracts-list-jo-expand
                                    [contractsList]="offerContractsList"
                                    [offerList]="offerList"
                                    [isLoading]="expandLoading"
                                    [expandedRowIndex]="expandedRowIndex"
                                ></app-contracts-list-jo-expand>
                            </ng-container>
                        </div>
                    </td>
                </tr>
                } } @if (offerList.length === 0) {
                <tr>
                    <td class="text-center" [colSpan]="columns.length">
                        <span>{{ 'SYSTEM.INFO.NO_RESULTS_SHOWN' | translate }}</span>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    <div class="overview-pagination">
        <pagination
            class="pagination"
            previousText="Prev"
            firstText="&laquo;"
            lastText="&raquo;"
            [maxSize]="5"
            [totalItems]="totalElements"
            [ngModel]="page"
            [itemsPerPage]="pageSize"
            [boundaryLinks]="true"
            (pageChanged)="pageChanged($event)"
        >
        </pagination>
    </div>
</div>
