import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import {
  AVIATION_COMPANY_PERMANENT_JOB_OPENING_CONFIG,
  AVIATION_COMPANY_TEMPORARY_JOB_OPENING_CONFIG
} from '../../../../../../../back-office/src/app/modules/admin-request-overview/components/request-listing/table.config';
import { GenericOfferType } from '@libs/shared/models/genericOffer.type';
import { OfferOutDto } from '@libs/shared/models/offer.model';
import { OFFER_STATUS_ENUM } from '@libs/shared/constants/offer-type.enum';
import { RoleWrapper } from '@libs/shared/models/roles.enum';
import { select, Store } from '@ngrx/store';
import {
  ContractLoaded,
  FailedToLoadOfferContracts,
  LoadContract,
  LoadList,
  LoadOfferContracts,
  LoadPermanentOfferWithContracts,
  SelectedOfferContractsLoaded,
  SetSort
} from '@libs/request-overview-common/state/requests-overview.actions';
import {
  selectPage,
  selectPageSize,
  selectSelectedEntityUuid,
  selectSelectedOfferContracts,
  selectTotalElements
} from '@libs/request-overview-common/state/requests-overview.selectors';
import { cloneDeep } from 'lodash-es';
import { Contract } from '@libs/shared/models/contract.model';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { SortingStages } from '@libs/shared/models/sorting-stages.enum';
import { AbstractOfferDetailsComponent } from '../offer-details/abstract-offer-details.component';
import { RequestOverviewState } from '../../state/request-overview-state.model';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';

interface TableColumn {
  name: string;
  key: string;
}

@Component({
  selector: 'app-offer-listing',
  templateUrl: './offer-listing.component.html',
  styleUrls: ['./offer-listing.component.scss']
})
export class OfferListingComponent extends AbstractOfferDetailsComponent implements OnInit, OnChanges {
  @Input() offerList: Array<any> = [];
  @Input() userRole: RoleWrapper = null;
  @Input() isTemporaryJobOpening: boolean = false;
  @Input() isListFilterChanged: boolean = false;
  @Input() isListApplicationsFilterChanged: boolean = false;

  columns: TableColumn[] = [];

  expandedRowIndex: number = null;
  expandLoading: boolean = false;

  page: number = 0;
  pageSize: number = 0;
  totalElements: number = 0;

  offerContractsList: Array<Contract> = [];
  selectedEntityUuid: string = '';

  sortState = {
    periodFrom: '',
    periodTo: '',
    ameType: '',
    vacancies: ''
  };
  sortingQuery: string = '';
  sortingStagesEnum = SortingStages;
  readonly sortingStages: Array<string> = [SortingStages.empty, SortingStages.asc, SortingStages.desc];

  constructor(public store: Store<any>, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.initializeColumns();
    this.setupStoreSubscriptions();
    this.setupActionsSubscriptions();

    this.actions.pipe(ofType(ContractLoaded)).subscribe(() => {
      this.triggerListLoad();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isListApplicationsFilterChanged: SimpleChange = changes['isListApplicationsFilterChanged'];

    if (changes['isTemporaryJobOpening']) {
      this.closeRowExpand();
      const change: SimpleChange = changes['isTemporaryJobOpening'];
      if (change.previousValue !== change.currentValue) this.initializeColumns();
    }
    if (changes['isListFilterChanged']) {
      this.closeRowExpand();
    }
    if (isListApplicationsFilterChanged?.previousValue !== isListApplicationsFilterChanged?.currentValue) {
      this.closeRowExpand();
    }
  }

  hasProp(columnName: string): boolean {
    return Object.hasOwn(this.sortState, columnName);
  }

  getCornerCardType(offer: GenericOfferType): OFFER_STATUS_ENUM {
    if (this.isUnSeen(offer)) {
      return OFFER_STATUS_ENUM.UNSEEN;
    } else if (this.isExpired(offer)) {
      return OFFER_STATUS_ENUM.EXPIRED;
    } else if (this.isClosed(offer)) {
      return OFFER_STATUS_ENUM.CLOSED;
    } else if (!this.isPublic(offer)) {
      return OFFER_STATUS_ENUM.PRIVATE;
    } else {
      return null;
    }
  }

  initializeColumns(): void {
    this.columns = this.isTemporaryJobOpening
      ? AVIATION_COMPANY_TEMPORARY_JOB_OPENING_CONFIG
      : AVIATION_COMPANY_PERMANENT_JOB_OPENING_CONFIG;
  }

  closeRowExpand(): void {
    this.expandedRowIndex = null;
  }

  handleRowExpand(offer: OfferOutDto, index: number): void {
    if (this.expandedRowIndex !== index) {
      this.expandedRowIndex = index;
      this.expandLoading = true;
      if (this.isTemporaryJobOpening) {
        this.store.dispatch(
          LoadOfferContracts({
            getContractsUrl: getUrl(offer, ApiRootLinkRel.GetContracts),
            userUuid: this.selectedEntityUuid
          })
        );
      } else {
        this.store.dispatch(
          LoadPermanentOfferWithContracts({
            refNumber: offer.refNumber
          })
        );
      }
    } else {
      this.closeRowExpand();
    }
  }

  handleSortingChange(sortKey: string): void {
    if (!Object.hasOwn(this.sortState, sortKey) && sortKey !== 'period') {
      return;
    }
    this.expandedRowIndex = null;

    if (sortKey !== 'period') {
      this.handleSortingForNonPeriod(sortKey);
    } else {
      this.handleSortingForPeriod();
    }

    for (const key in this.sortState) {
      if (
        (key !== sortKey && sortKey !== 'period') ||
        (sortKey === 'period' && key !== 'periodFrom' && key !== 'periodTo')
      ) {
        this.sortState[key] = '';
      }
    }

    const REQ_SORTING_CONFIG = { ...this.sortState };

    for (const key in REQ_SORTING_CONFIG) {
      if (!REQ_SORTING_CONFIG[key]) {
        delete REQ_SORTING_CONFIG[key];
      }
    }

    this.sortingQuery = this.generateSortingQuery(REQ_SORTING_CONFIG);
    this.store.dispatch(SetSort({ sort: this.sortingQuery }));
    this.triggerListLoad();
  }

  pageChanged($event: { page: number; itemsPerPage: number }) {
    this.expandedRowIndex = null;
    this.search($event.page);
  }

  search(page: number = 1): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        page: page
      }
    });
  }

  private isUnSeen(offer: GenericOfferType): boolean {
    return !offer?.isSeen && !(offer as OfferOutDto)?.isExpired;
  }

  private isExpired(offer: GenericOfferType): boolean {
    return (offer as OfferOutDto)?.isExpired;
  }

  private isClosed(offer: GenericOfferType): boolean {
    return offer?.isClosed;
  }

  private isPublic(offer: GenericOfferType): boolean {
    return (offer as OfferOutDto)?.isPublic;
  }

  private triggerListLoad(): void {
    this.store.dispatch(LoadList({ role: this.userRole?.getRole() as any }));
  }

  private generateSortingQuery(sortConfig: any): string {
    const sortQuery = [];

    for (const key in sortConfig) {
      if (Object.hasOwn(sortConfig, key)) {
        sortQuery.push(`${sortConfig[key]}${key}`);
      }
    }

    return sortQuery.join(',');
  }

  private handleSortingForNonPeriod(sortKey: string): void {
    const initialSortIndex = 0;
    switch (this.sortState[sortKey]) {
      case this.sortingStages[0]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex + 1];
        break;
      case this.sortingStages[1]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex + 2];
        break;
      case this.sortingStages[2]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex];
        break;
      default:
        break;
    }
  }

  private handleSortingForPeriod(): void {
    const initialSortIndex = 0;
    switch (this.sortState['periodFrom']) {
      case this.sortingStages[0]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex + 1];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex + 1];
        break;
      case this.sortingStages[1]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex + 2];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex + 2];
        break;
      case this.sortingStages[2]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex];
        break;
      default:
        break;
    }
  }

  private setupActionsSubscriptions(): void {
    this.onAction(LoadContract, () => (this.expandLoading = true));
    this.onAction(
      ContractLoaded,
      SelectedOfferContractsLoaded,
      FailedToLoadOfferContracts,
      () => (this.expandLoading = false)
    );
  }

  private setupStoreSubscriptions(): void {
    this.storeSubscribe(select(selectSelectedOfferContracts), selectedOfferContracts => {
      if (selectedOfferContracts) {
        this.offerContractsList = cloneDeep(selectedOfferContracts?.contracts) as Array<Contract>;
        this.storeSubscribe(select(selectSelectedEntityUuid), uuid => (this.selectedEntityUuid = uuid));
      }
    });
    this.storeSubscribe(select(selectPage), page => (this.page = page + 1));
    this.storeSubscribe(select(selectPageSize), pageSize => (this.pageSize = pageSize));
    this.storeSubscribe(select(selectTotalElements), totalElements => (this.totalElements = totalElements));
  }

  protected updateFromState(state: RequestOverviewState): void {
    this.offerContractsList = state?.contracts as Array<Contract>;
    this.expandLoading = false;
  }
}
