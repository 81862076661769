import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { select, Store } from '@ngrx/store';
import { selectUserProfileAvatarData } from '../../state/user-profile.selectors';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UpdateAuthorizedUserPicture, UpdateMroFavoriteTechnicians, UpdateUserProfile } from '../../state/user-profile.actions';
import { FileUploadService } from '@libs/common-ui/services/file-upload.service';
import { PictureTypes } from '@libs/common-ui/constants/picture-types.enum';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';
import { AbstractProfileComponent } from '@libs/user-profile/components/abstract-profile-component';
import { DocumentsService } from '@libs/user-profile/services/documents.service';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@Component({
  selector: 'staffnow-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss']
})
export class AvatarUploadComponent extends AbstractProfileComponent {
  public fileSizeLimit = 3;
  public apiUrl: string;
  public imageUrl: string;
  public inputAccepts: string;
  public uploader: any;
  public loading: boolean = false;
  public fileRestrictionsTooltip: string;

  private allowedFileTypes = ['jpg', 'jpeg', 'png'];
  private userUuid: string = '';

  @ViewChild('fileInput') public fileInput: any;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    public uploadService: FileUploadService,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService,
    private cdRef: ChangeDetectorRef
  ) {
    super(store, documentsService, activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToProfileData();
    this.inputAccepts = this.uploadService.fileListToExtensionCSV(
      this.allowedFileTypes
    );
    this.fileRestrictionsTooltip = `Click to upload new avatar. File must be ${this.inputAccepts} and maxsize of ${this.fileSizeLimit}MB.`;
  }

  private subscribeToProfileData() {
    this.addSub(
      this.store
        .pipe(
          select(selectUserProfileAvatarData),
          tap(avatarData => {
            this.imageUrl = avatarData.pictureUrl;
            this.userUuid = avatarData.userUuid;
            this.apiUrl = avatarData.updatePictureUrl;
            this.configUploader();
          })
        )
        .subscribe()
    );
  }

  private configUploader() {
    this.uploadService.configUploader(
      this.apiUrl,
      this.fileSizeLimit,
      this.allowedFileTypes
    ).then(uploader => {
      this.uploader = uploader;
      this.uploader.onBeforeUploadItem = (fileItem: any) => {
        fileItem.alias = 'picture';
        this.loading = true;
      };

      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        const picInfo = {
          type: PictureTypes.ProfilePicture,
          uuid: this.userUuid
        };
        form.append(
          'data',
          new Blob([JSON.stringify(picInfo)], {type: 'application/json'})
        );
      };

      this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
        switch (filter.name) {
          case 'fileSize':
            const fileSizeLimitBytes = this.fileSizeLimit * 1000000;
            const mime: string = fileItem.rawFile.type;
            if (mime.includes("image")) {
              const downsizeRate = fileSizeLimitBytes / fileItem.rawFile.size;
              this.uploadService.downsizeImage(
                fileItem,
                this.apiUrl,
                downsizeRate,
                {
                  type: PictureTypes.ProfilePicture,
                  uuid: this.userUuid
                },
                (response) => this.handleResizeResponse(response));
            } else {
              this.toastMessageService.fail(fileUploadConstants.fileExceededLimit);
            }
            break;
          case 'mimeType':
            this.toastMessageService.fail(fileUploadConstants.fileHasUnsupportedFormat);
            break;
          default:
            break;
        }

        this.fileInput.nativeElement.value = '';
      };

      this.uploader.onSuccessItem = (_, response: string) => {
        try {
          this.updatePicture(JSON.parse(response)._links.self.href);
          this.cdRef.detectChanges();
        } catch (error) {
          console.error('Could not parse response from upload pic request');
        }
      };

      this.uploader.onErrorItem = (fileItem: any, response: string) => {
        if (!!response && response.length > 0) {
          this.errorMessageService.handleErrorResponse(JSON.parse(response));
        }
        this.loading = false;
        this.uploader.clearQueue();
      };
    });
  }

  private updatePicture(newImageUrl: string) {
    this.toastMessageService.success('SYSTEM.INFO.UPDATED_USER_PROFILE');
    this.loading = false;
    this.imageUrl = newImageUrl;
    this.store.dispatch(UpdateUserProfile());
    // That means the loaded profile is own profile, so we have to update
    // the authorized user profile state
    if (!this.activatedRoute.snapshot.params.userUuid) {
      this.store.dispatch(
        UpdateAuthorizedUserPicture({updatedPicUrl: newImageUrl})
      );
    }
  }

  public handleUpdateMroFavorites(isFavoriteTechnician: boolean): void {
    this.store.dispatch(
      UpdateMroFavoriteTechnicians({
        url: this.getFavoriteTechnicianUrl(isFavoriteTechnician),
        technicianUuid: this.userProfile?._embedded.profile.userUuid
      })
    );
  }

  get canEditFavoriteTechniciansList(): boolean {
    return (
      hasLink(this.userProfile, UserProfileLinkRel.MroAddToFavorites) ||
      hasLink(this.userProfile, UserProfileLinkRel.MroRemoveFromFavorites)
    );
  }

  private getFavoriteTechnicianUrl(isFavoriteTechnician: boolean): string {
    return isFavoriteTechnician
      ? getUrl(this.userProfile, UserProfileLinkRel.MroRemoveFromFavorites)
      : getUrl(this.userProfile, UserProfileLinkRel.MroAddToFavorites);
  }

  private handleResizeResponse(response) {
    response.subscribe(
      () => {
        try {
          this.updatePicture(JSON.parse(response)._links.self.href);
          this.cdRef.detectChanges();
          this.toastMessageService.success(fileUploadConstants.successfullFileUpload);
        } catch (error) {
          console.error('Could not parse response from upload pic request');
        }
      },
      response => {
        this.errorMessageService.handleErrorResponse(response, "UPLOAD.IMAGE_FAILED");
      });
  }
}
