<i class="material-icons close-icon" (click)="bsModalRef.hide()"> clear </i>
<div class="content">
    <div class="wrapper">
        <strong>{{ 'BREAK_DATES.STARTING_ON' | translate }}</strong>
        <date-picker-single
                [initialValue]="startDate"
                [minDate]="minimumDate"
                (dateUpdated)="handleStartDateUpdate($event)"
                [placeholder]="'GENERAL.PICK_A_DATE_FROM_CALENDAR' | translate"
        ></date-picker-single>
    </div>
    <div class="wrapper">
        <strong>Returning to work on: </strong>
        <date-picker-single
                [initialValue]="endDate"
                [minDate]="minimumDate"
                (dateUpdated)="handleEndDateUpdate($event)"
                [placeholder]="'GENERAL.PICK_A_DATE_FROM_CALENDAR' | translate"
        ></date-picker-single>
    </div>
    <div class="wrapper" *ngIf="areDatesValid">
        <p class="text-danger">
            {{ 'GENERAL.RETURNING_DATE_AFTER_START' | translate}}
        </p>
    </div>
    <general-button
        (onClick)="submit()"
        [isDisabled]="areDatesValid"
        class="button-custom"
        [text]="'GENERAL.SUBMIT' | translate"
    ></general-button>
</div>
