import { Component, Input } from '@angular/core';
import { StartDatePhase, StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { Contract } from '@libs/shared/models/contract.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { ProposeStartDate } from '@libs/request-overview-common/state/requests-overview.actions';
import { getCurrentUTCDateString, isFirstDateBeforeTheSecond } from '@libs/shared/helpers/date-utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OfferOutDto } from '@libs/shared/models/offer.model';

@Component({
  selector: 'app-offer-start-date',
  templateUrl: './offer-start-date.component.html'
})
export class OfferStartDateComponent {
  @Input() contract: Contract = null;
  @Input() offer: OfferOutDto = null;

  constructor(
    private bsModalService: BsModalService,
    private store: Store<any>,
    private translateService: TranslateService
  ) {}

  isPendingOrUnset(phase: StartDatePhase): boolean {
    return StartDatePhaseUtils.isPendingOrUnset(phase);
  }

  hasProposeStartDateLink(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.ProposeContractStartDate);
  }

  handleStartDateProposal(contract: Contract): void {
    this.bsModalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (startDate: string) => this.proposeStartDate(contract, startDate),
        selectedDate: this.getPreselectedDate(),
        warningMessage: this.warningMessage(contract),
        title: this.translateService.instant('TASKS.VIEW.DESCRIPTION.PROPOSE_START_DATE'),
        reminders: [
          {
            label: this.translateService.instant('SYSTEM.INFO.START_DATE_AGENCY_REMINDER.LABEL'),
            text: this.translateService.instant('SYSTEM.INFO.START_DATE_AGENCY_REMINDER.TEXT')
          },
          {
            label: this.translateService.instant('SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.LABEL'),
            text: this.translateService.instant('SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.TEXT')
          }
        ]
      }
    });
  }

  datePhaseIsAccepted(contract: Contract): boolean {
    return StartDatePhaseUtils.isAccepted(contract.startDatePhase);
  }

  private proposeStartDate(contract: Contract, startDate: string): void {
    this.store.dispatch(ProposeStartDate({ startDate, contract }));
  }

  private getPreselectedDate(): string {
    const today: string = getCurrentUTCDateString();
    const offerStartDate: string = this.offer.periodFrom;
    return isFirstDateBeforeTheSecond(offerStartDate, today) ? today : offerStartDate;
  }

  private warningMessage(contract: Contract): string {
    if (this.datePhaseIsAccepted(contract)) {
      return 'TECHNICIAN.PERMANENT_OFFER_DETAILS.ALREADY_START_DATE';
    }
    return null;
  }
}
