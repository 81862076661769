import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { JobOpeningFiltersConstants } from '@platform/shared/constants/job-opening-filters.constants';
import { OfferType } from '@libs/shared/models/offer.model';
import { selectOfferType } from '@libs/request-overview-common/state/requests-overview.selectors';
import { Store } from '@ngrx/store';

interface Filter {
  filter: string;
  label: string;
}

@Component({
  selector: 'app-applications-filters',
  templateUrl: './applications-filters.component.html',
  styleUrls: ['./applications-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationsFiltersComponent implements OnInit, OnChanges {
  @Output() toggleFiltersEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() currentFilter: Filter = null;
  filterLabels: Filter[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(selectOfferType).subscribe(offerType => this.initializeFilterLabels(offerType));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentFilter']) {
      this.currentFilter = {
        filter: changes['currentFilter'].currentValue?.filter,
        label: this.getLabel(this.currentFilter?.filter)
      };
    }
  }

  initializeFilterLabels(offerType: string): void {
    this.filterLabels = [
      { filter: JobOpeningFiltersConstants.applicationFilter.allApplications, label: 'GENERAL.ALL' },
      { filter: JobOpeningFiltersConstants.applicationFilter.pendingApplicationsFilter, label: 'OFFER.STATUS.PENDING' },
      {
        filter: JobOpeningFiltersConstants.applicationFilter.rejectedApplicationsFilter,
        label: 'REQUEST_LISTING.DECLINED_FILTER_LABEL'
      }
    ];
    if (offerType != OfferType.FIXED_PRICE.toString()) {
      this.filterLabels.splice(1, 0, {
        filter: JobOpeningFiltersConstants.applicationFilter.activeTechnicianApplicationsFilter,
        label: 'GENERAL.ACTIVE_TECHNICIANS'
      },
      {
        filter: JobOpeningFiltersConstants.applicationFilter.documentsNeededApplicationsFilter,
        label: 'REQUEST_LISTING.DOCUMENT_NEEDED_FILTER_LABEL'
      });
    }
  }

  getLabel(filter: string): string {
    switch (filter) {
      case JobOpeningFiltersConstants.applicationFilter.allApplications:
        return 'GENERAL.ALL';
      case JobOpeningFiltersConstants.applicationFilter.activeTechnicianApplicationsFilter:
        return 'GENERAL.ACTIVE_TECHNICIANS';
      case JobOpeningFiltersConstants.applicationFilter.pendingApplicationsFilter:
        return 'OFFER.STATUS.PENDING';
      case JobOpeningFiltersConstants.applicationFilter.documentsNeededApplicationsFilter:
        return 'REQUEST_LISTING.DOCUMENT_NEEDED_FILTER_LABEL';
      case JobOpeningFiltersConstants.applicationFilter.rejectedApplicationsFilter:
        return 'REQUEST_LISTING.DECLINED_FILTER_LABEL';
    }
  }

  onToggleFiltersClick(filter: Filter): void {
    this.toggleFiltersEvent.emit(filter?.filter);
  }
}
