<div class="list-wrapper">
    <ng-container *ngIf="contracts?.length > 0; else noApplicants">
        <staffnow-collapsible-agency-offer-contracts-list
            [heading]="'OFFER.STATUS.PENDING' | translate"
            [contracts]="pendingContracts"
        ></staffnow-collapsible-agency-offer-contracts-list>

        <staffnow-collapsible-agency-offer-contracts-list
            [heading]="'OFFER.STATUS.ACCEPTED' | translate"
            [contracts]="fullyAcceptedContracts"
        ></staffnow-collapsible-agency-offer-contracts-list>

        <staffnow-collapsible-agency-offer-contracts-list
            [heading]="'OFFER.STATUS.FINISHED' | translate"
            [contracts]="finishedContracts"
        ></staffnow-collapsible-agency-offer-contracts-list>
        <staffnow-collapsible-agency-offer-contracts-list
            [heading]="'OFFER.STATUS.DECLINED' | translate"
            [contracts]="rejectedContracts"
        ></staffnow-collapsible-agency-offer-contracts-list>
    </ng-container>
    <ng-template #noApplicants>
        <div class="no-applicants">
            <ng-content select="[no-data]"></ng-content>
        </div>
    </ng-template>
</div>
