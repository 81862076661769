import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, OperatorFunction, pipe, Subscription } from 'rxjs';
import {
  DisableAction,
  FormControlState,
  FormGroupState,
  MarkAsDirtyAction,
  MarkAsPristineAction,
  ResetAction,
  SetValueAction
} from 'ngrx-forms';

import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  getAirplanesSortedByName,
  getFilteredApiRoot,
  getLoggedInUserRole
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { AgencyOfferInDto, OfferEditMode, OfferType } from '@libs/shared/models/offer.model';

import { Actions, ofType } from '@ngrx/effects';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { agencyOfferInitialFormState, CreateAgencyOfferFormModel } from './create-agency-offer.model';
import { AdditionalBenefitForm, AdditionalBenefitFormUtils } from './components/additional-benefits-form.model';
import { CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY } from './create-agency-offer.reducer';
import {
  AgencyOfferSubmitSuccess,
  FailedToSubmitAgencyOffer,
  ResetAgencyOfferForm,
  SubmitAgencyOffer
} from './create-agency-offer.actions';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { selectFacilityProfileFiltered } from '@platform/state/app.selectors';
import { GetFacilityProfile, ResetFacilityProfile } from '@libs/common-ui/facility-profile/facility-profile.actions';
import { FacilityProfileState, AppState } from '@platform/state/app-state.model';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { AmeLicenseLevel } from '@libs/shared/models/ame-license-level.model';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { take } from 'rxjs/operators';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { Rate } from '@libs/shared/models/rate.model';
import { LocationPO } from '@libs/shared/models/location.model';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import {
  JO_TITLE_MAX_LENGTH,
  JO_TITLE_MIN_LENGTH,
  MAX_CHARGE_RATE,
  MAX_YEARS_OF_EXPERIENCE,
  VACANCIES_MAX_VALUE,
  WORK_DAYS_MAX_VALUE
} from '../../../shared/validators/offer-form.validator';
import { Option } from '@libs/common-ui/multi-select-autocomplete/multi-select-autocomplete.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'staffnow-create-agency-offer-page',
  templateUrl: './create-agency-offer-page.component.html',
  styleUrls: ['./create-agency-offer-page.component.scss']
})
export class CreateAgencyOfferPageComponent implements OnInit, OnDestroy {
  public aircraftList: Aircraft[] = [];
  public preselectedFilters: AgencyOfferInDto;
  public isEditForm?: boolean = false;
  public createAgencyOfferForm: Observable<FormGroupState<CreateAgencyOfferFormModel>>;
  public createAgencyOfferFormValue: FormGroupState<CreateAgencyOfferFormModel> = null;
  public isSubmitting: boolean = false;
  public selectedAircraftList: Aircraft[] = [];
  public technicianTypeOptions: AmeLicenseType[] = [];
  public technicianLevelOptions: AmeLicenseLevel[] = [];
  public isSingletonNomenclature: boolean = false;
  public fixedWorkingPatterns: { first: string; second: string; third: string } = {
    first: '5/2',
    second: '3/3',
    third: '6/1'
  };
  public customWorkDaysPattern = {
    on: null,
    off: null
  };
  public manualUpdateControlValues = {
    ameTitleId: null,
    ameType: '',
    aircrafts: []
  };
  public getLabelFunction = (item: string) => item;
  private readonly subscriptions: Subscription = new Subscription();
  public benefits: AdditionalBenefitForm[] = AdditionalBenefitFormUtils.getDefault();
  public breadcrumbs: string[] = [
    'My Job Openings',
    'My Agency Fixed-Price Job Openings',
    'Job Opening Details',
    'Create Agency Job Opening'
  ];
  public isSettingInitialValues: boolean = true;
  public facilityProfile: FacilityProfileState;
  public location: LocationPO = null;
  private wrappedRole: RoleWrapper;
  private readonly mroUuid: string = '';
  private readonly packageContractId: number;
  private readonly createUrl: Link = null;
  private readonly editUrl: Link = null;
  private readonly getMroFacilityUrl: Link = null;
  public agencyOfferPeriod: string[] = null;

  protected readonly JO_TITLE_MIN_LENGTH = JO_TITLE_MIN_LENGTH;
  protected readonly JO_TITLE_MAX_LENGTH = JO_TITLE_MAX_LENGTH;
  protected readonly MAX_YEARS_OF_EXPERIENCE = MAX_YEARS_OF_EXPERIENCE;
  protected readonly VACANCIES_MAX_VALUE = VACANCIES_MAX_VALUE;
  protected readonly MAX_CHARGE_RATE = MAX_CHARGE_RATE;

  public highlightErrors: boolean = false;

  get isCustomPatternSelected(): boolean {
    return this.createAgencyOfferFormValue.value.workPattern === 'custom';
  }

  get currencySymbol(): string {
    return this.facilityProfile?.workingCurrency;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly customNavigationService: CustomNavigationService
  ) {
    let navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState) {
      sessionStorage.setItem('FILTERS', JSON.stringify(navigationState));
    } else if (sessionStorage.getItem('FILTERS')) {
      navigationState = JSON.parse(sessionStorage.getItem('FILTERS'));
    }
    this.preselectedFilters = navigationState?.preselectedFilters;
    this.agencyOfferPeriod = [navigationState?.startDate, navigationState?.endDate];
    this.mroUuid = navigationState?.mroUuid;
    this.packageContractId = navigationState?.packageContractId;
    this.createUrl = navigationState?.createUrl;
    this.editUrl = navigationState?.editUrl;
    this.getMroFacilityUrl = navigationState?.getMroFacilityUrl;
    this.isEditForm = navigationState?.isEdit;
    this.breadcrumbs = navigationState?.breadcrumbs;
    this.location = navigationState?.location;
    this.createAgencyOfferForm = this.store.pipe(
      select(state => <FormGroupState<CreateAgencyOfferFormModel>>state[CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY])
    );
    this.createAgencyOfferForm.subscribe(value => (this.createAgencyOfferFormValue = cloneDeep(value)));
    this.subscriptions.add(
      this.actions.pipe(ofType(AgencyOfferSubmitSuccess)).subscribe(action => {
        this.isSubmitting = false;
        sessionStorage.removeItem('FILTERS');
        if (action.refNumber && !this.wrappedRole.isAdminOrModerator()) {
          this.customNavigationService.goToOfferDetails(action.refNumber, OfferType.AGENCY, this.isEditForm);
        } else {
          this.customNavigationService.goBack();
        }
      })
    );

    this.subscriptions.add(
      this.actions.pipe(ofType(FailedToSubmitAgencyOffer)).subscribe(() => (this.isSubmitting = false))
    );

    this.storeSubscribe(pipe(getLoggedInUserRole, take(1)), userRole => {
      this.wrappedRole = role(userRole);
    });
  }

  ngOnInit() {
    this.storeSubscribe(pipe(getFilteredApiRoot, take(1)), apiRoot => {
      const facilityUrl = this.wrappedRole.isAgency()
        ? this.getMroFacilityUrl.href
        : getUrl(apiRoot, ApiRootLinkRel.GetMroFacilities) + '/' + this.mroUuid;
      this.store.dispatch(GetFacilityProfile({ facilityUrl }));
    });
    this.storeSubscribe(pipe(selectFacilityProfileFiltered, take(1)), facilityProfile =>
      this.initForm(facilityProfile)
    );
  }

  private initForm(facilityProfile: FacilityProfileState): void {
    this.facilityProfile = facilityProfile;
    this.technicianTypeOptions = getEmbeddedResource(facilityProfile, FacilityProfileLinkRel.AmeNomenclature);
    this.technicianLevelOptions = [];
    this.store.dispatch(
      new SetValueAction(this.createAgencyOfferFormValue.controls.packageContractId.id, this.packageContractId)
    );

    if (!this.isEditForm) {
      this.setWorkingPattern({ target: { value: this.fixedWorkingPatterns.first } } as unknown as Event);
    } else {
      this.setWorkingPattern({
        target: { value: `${this.preselectedFilters['workDaysOn']}/${this.preselectedFilters['workDaysOff']}` }
      } as unknown as Event);
    }

    this.storeSubscribe(getAirplanesSortedByName, aircraftList => {
      this.aircraftList = aircraftList;
    });

    this.setPreselectedValuesAndDisableOnRestrictedMode();

    if (this.isEditForm) {
      this.markAsPristine();
    }

    this.isSettingInitialValues = false;
  }

  private storeSubscribe<S>(pipedSelector: OperatorFunction<AppState, S>, subscribeFn: (a: S) => void) {
    this.store.pipe(pipedSelector, untilDestroyed(this)).subscribe(subscribeFn);
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAgencyOfferForm());
    this.store.dispatch(ResetFacilityProfile());
    this.store.dispatch(new ResetAction(this.createAgencyOfferFormValue.id));
    this.subscriptions.unsubscribe();
    sessionStorage.removeItem('FILTERS');
  }

  public isPatternSelected(pattern: string): boolean {
    const { workDaysOn, workDaysOff } = this.createAgencyOfferFormValue.value;
    const [workDaysOnPatternValue, workDaysOffPatternValue] = pattern.split('/');

    return (
      !this.isCustomPatternSelected &&
      workDaysOn === +workDaysOnPatternValue &&
      workDaysOff === +workDaysOffPatternValue
    );
  }

  private setPreselectedValuesAndDisableOnRestrictedMode(): void {
    if (this.preselectedFilters && this.aircraftList) {
      const controls = this.createAgencyOfferFormValue.controls;
      const ameTitle: AmeTitle = (this.preselectedFilters as any).ameTitle;

      if (ameTitle) {
        this.getTechnicianLevelOptions(ameTitle.type);
        this.manualUpdateControlValues.ameType = ameTitle.type;
        this.manualUpdateControlValues.ameTitleId = ameTitle.id;
        this.isSingletonNomenclature = isNil(ameTitle.license);
      }

      Object.keys(this.preselectedFilters).forEach(controlKey => {
        switch (controlKey) {
          case 'experience': {
            this.store.dispatch(new SetValueAction(controls['minExperience'].id, this.preselectedFilters[controlKey]));
            break;
          }
          case 'hasIndicativeRate': {
            const hasIndicativeRate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls['hasIndicativeRate'].id, hasIndicativeRate));
            break;
          }
          case 'payRate':
          case 'maxPayRate': {
            const rate = this.preselectedFilters[controlKey];
            this.store.dispatch(new SetValueAction(controls[controlKey].id, rate.amount));
            break;
          }
          case 'additionalBenefits': {
            this.preselectedFilters[controlKey].forEach(benefit => {
              const index = this.benefits.findIndex(formBenefit => formBenefit.type === benefit.type);
              this.benefits[index].amount = (benefit.amount as Rate).amount;
              this.benefits[index].description = benefit.description;
              this.benefits[index].isSelected = true;
            });
            break;
          }
          case 'minExperience':
          case 'workDaysOn':
          case 'workDaysOff':
          case 'vacancies':
          case 'shortPresentation':
          case 'priority':
          case 'title':
          case 'refNumber': {
            this.store.dispatch(new SetValueAction(controls[controlKey].id, this.preselectedFilters[controlKey]));
            break;
          }
          case 'ameTitle': {
            this.store.dispatch(new SetValueAction(controls['ameType'].id, this.manualUpdateControlValues.ameType));
            this.store.dispatch(
              new SetValueAction(controls['ameTitleId'].id, this.manualUpdateControlValues.ameTitleId)
            );
            break;
          }
          case 'airplanes': {
            this.preselectedFilters[controlKey].forEach(it => {
              this.selectedAircraftList.push(this.aircraftList.find(airplane => airplane.id === Number(it.id)));
            });
            this.store.dispatch(
              new SetValueAction(
                controls['airplanes'].id,
                this.preselectedFilters[controlKey].map(it => it.id)
              )
            );
            break;
          }
          case 'otherAirplanes':
            if (this.preselectedFilters[controlKey].length > 0) {
              this.preselectedFilters[controlKey]
                .split(', ')
                .forEach((otherAirplane: string) => this.selectedAircraftList.push({ id: null, name: otherAirplane }));
            }
            this.store.dispatch(new SetValueAction(controls['otherAirplanes'].id, this.preselectedFilters[controlKey]));
            break;
          default: {
            break;
          }
        }
      });
      if (this.isRestrictedMode()) {
        this.disableFieldsOnRestrictedMode();
      }
    }
  }

  public isRestrictedMode(): boolean {
    const editModeKey = 'editMode';
    if (!Object.hasOwn(this.preselectedFilters, editModeKey)) {
      return false;
    }
    const mode = this.preselectedFilters[editModeKey];
    return !isNil(mode) && mode === OfferEditMode.RESTRICTED;
  }

  private disableFieldsOnRestrictedMode(): void {
    const alwaysActiveControls = ['priority', 'vacancies'];
    Object.keys(this.createAgencyOfferFormValue.controls).forEach(controlKey => {
      if (!alwaysActiveControls.includes(controlKey)) {
        this.store.dispatch(new DisableAction(this.createAgencyOfferFormValue.controls[controlKey].id));
      }
    });
  }

  public isPatternFixed(pattern: string): boolean {
    let isFixed = false;
    Object.keys(this.fixedWorkingPatterns).forEach(key => {
      isFixed = isFixed || this.fixedWorkingPatterns[key] === pattern;
    });
    return isFixed;
  }

  // Work pattern

  public setWorkingPattern($event: Event): void {
    const pattern: string = ($event.target as HTMLInputElement).value;
    const controls = this.createAgencyOfferFormValue.controls;
    const workDaysPattern = pattern.split('/');

    const workingPattern = this.isPatternFixed(pattern) ? 'fixed' : 'custom';

    if (!this.isPatternFixed(pattern)) {
      this.customWorkDaysPattern.on = workDaysPattern[0];
      this.customWorkDaysPattern.off = workDaysPattern[1];
    }

    this.store.dispatch(new SetValueAction(controls.workPattern.id, workingPattern));
    this.store.dispatch(new SetValueAction(controls.workDaysOn.id, Number(workDaysPattern[0])));
    this.store.dispatch(new SetValueAction(controls.workDaysOff.id, Number(workDaysPattern[1])));
    if (!this.isSettingInitialValues) {
      this.store.dispatch(new MarkAsDirtyAction(controls.workPattern.id));
    }
  }

  public setCustomWorkingPattern(e, isOnDays: boolean): void {
    if (e.target.value >= WORK_DAYS_MAX_VALUE) {
      if (this.customWorkDaysPattern.on >= WORK_DAYS_MAX_VALUE) {
        e.target.value = WORK_DAYS_MAX_VALUE;
        this.customWorkDaysPattern.on = WORK_DAYS_MAX_VALUE;
      }

      if (this.customWorkDaysPattern.off >= WORK_DAYS_MAX_VALUE) {
        e.target.value = WORK_DAYS_MAX_VALUE;
        this.customWorkDaysPattern.off = WORK_DAYS_MAX_VALUE;
      }
    }

    if (isOnDays) {
      this.store.dispatch(
        new SetValueAction(this.createAgencyOfferFormValue.controls.workDaysOn.id, this.customWorkDaysPattern.on)
      );
    } else {
      this.store.dispatch(
        new SetValueAction(this.createAgencyOfferFormValue.controls.workDaysOff.id, this.customWorkDaysPattern.off)
      );
    }
    if (!isNil(this.customWorkDaysPattern.on) && !isNil(this.customWorkDaysPattern.off)) {
      this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.controls.workPattern.id));
    }
  }

  public customPatternSet(): void {
    this.store.dispatch(new SetValueAction(this.createAgencyOfferFormValue.controls.workPattern.id, 'custom'));
    this.store.dispatch(
      new SetValueAction(this.createAgencyOfferFormValue.controls.workDaysOn.id, this.customWorkDaysPattern.on)
    );
    this.store.dispatch(
      new SetValueAction(this.createAgencyOfferFormValue.controls.workDaysOff.id, this.customWorkDaysPattern.off)
    );
  }

  // Indicative rate

  get hasIndicativeRate(): boolean {
    return this.createAgencyOfferFormValue.controls.hasIndicativeRate.value;
  }

  public toggleIndicativeRateOption(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.benefits = AdditionalBenefitFormUtils.getDefault();
    } else {
      this.resetMaxPayRateField();
    }
  }

  private resetMaxPayRateField(): void {
    const controlId = this.createAgencyOfferFormValue.controls.maxPayRate.id;
    this.store.dispatch(new SetValueAction(controlId, null));
  }

  // ameType and ameLicenses

  public onTechnicianTypeChange(): void {
    const ameType: string = this.manualUpdateControlValues.ameType;
    const oldAmeType = this.createAgencyOfferFormValue.controls.ameType.value;
    if (oldAmeType !== ameType) {
      this.store.dispatch(new SetValueAction(this.createAgencyOfferFormValue.controls.ameType.id, ameType));
      this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.controls.ameType.id));
      this.getTechnicianLevelOptions(ameType);
    }
  }

  public onTechnicianLevelChange($event: Event): void {
    const ameTitleIdControl: FormControlState<string> = this.createAgencyOfferFormValue.controls.ameTitleId;
    const ameTitleId: number = Number(($event.target as HTMLInputElement).value);
    if (isNaN(ameTitleId)) {
      this.store.dispatch(new SetValueAction(ameTitleIdControl.id, null));
      return;
    }
    this.manualUpdateControlValues.ameTitleId = ameTitleId;
    this.store.dispatch(new SetValueAction(ameTitleIdControl.id, ameTitleId));
    this.store.dispatch(new MarkAsDirtyAction(ameTitleIdControl.id));
  }

  public getTechnicianLevelOptions(ameType: string): void {
    if (isEmpty(this.technicianTypeOptions)) {
      return;
    }
    this.manualUpdateControlValues.ameTitleId = null;
    this.store.dispatch(new SetValueAction(this.createAgencyOfferFormValue.controls.ameTitleId.id, null));
    const selectedAmeType = this.technicianTypeOptions.find(option => option.name == ameType);
    this.technicianLevelOptions = selectedAmeType ? selectedAmeType.licenses : [];
    this.isSingletonNomenclature = selectedAmeType?.isSingleton;
    if (this.isSingletonNomenclature) {
      this.onTechnicianLevelChange({ target: { value: this.technicianLevelOptions[0].id } } as unknown as Event);
    }
  }

  public handleFormSubmit(): void {
    if (!this.canSubmitForm()) {
      this.highlightErrors = true;
      return;
    }
    this.highlightErrors = false;
    this.markAsPristine();
    this.isSubmitting = true;

    this.handleAgencyOfferFormSubmit();
  }

  public canSubmitForm(): boolean {
    return (
      this.createAgencyOfferFormValue.isValid && this.createAgencyOfferFormValue.isDirty && this.areBenefitsValid()
    );
  }

  private handleAgencyOfferFormSubmit(): void {
    if (this.isEditForm && this.preselectedFilters) {
      const agencyOffer = this.preselectedFilters;
      this.createAgencyOfferFormValue.value['uuid'] = agencyOffer.uuid;
      this.store.dispatch(SubmitAgencyOffer({ payload: this.getPayload(), editUrl: this.editUrl.href }));
    } else {
      this.store.dispatch(SubmitAgencyOffer({ payload: this.getPayload(), createUrl: this.createUrl.href }));
    }
  }

  private getPayload(): CreateAgencyOfferFormModel {
    const payload = { ...this.createAgencyOfferFormValue.value };

    if (this.benefits.some(benefit => benefit.isSelected)) {
      payload.additionalBenefits = this.benefits
        .filter(benefit => benefit.isSelected)
        .map(benefit => ({
          type: benefit.type,
          amount: benefit.amount,
          description: benefit.description?.trim()
        }));
    }
    return payload;
  }

  public cancelCreation(): void {
    sessionStorage.removeItem('FILTERS');
    this.customNavigationService.goBack();
  }

  public areBenefitsValid(): boolean {
    return this.benefits
      .filter(benefit => benefit.isSelected)
      .every(benefit => AdditionalBenefitFormUtils.isValid(benefit));
  }

  public isBenefitValid(benefit: AdditionalBenefitForm): boolean {
    return AdditionalBenefitFormUtils.isValid(benefit);
  }

  public markBenefitsAsDirty(): void {
    this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.id));
  }

  private markAsPristine(): void {
    this.store.dispatch(new MarkAsPristineAction(this.createAgencyOfferFormValue.id));
  }

  protected readonly agencyOfferInitialFormState = agencyOfferInitialFormState;

  handleAircraftSelectionChange(selectedAircrafts: Option[]) {
    const airplanesIds: number[] = selectedAircrafts.filter(option => option.id != null).map(option => option.id);
    this.selectedAircraftList = selectedAircrafts;
    this.store.dispatch(new SetValueAction(this.createAgencyOfferFormValue.controls.airplanes.id, airplanesIds));
    this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.controls.airplanes.id));
    const otherAirplanes: string = selectedAircrafts
      .filter(option => option.id == null)
      .map(option => option.name)
      .join(', ');
    this.store.dispatch(new SetValueAction(this.createAgencyOfferFormValue.controls.otherAirplanes.id, otherAirplanes));
    this.store.dispatch(new MarkAsDirtyAction(this.createAgencyOfferFormValue.controls.otherAirplanes.id));
  }
}
