import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dateTimeFromString, dateToUTCString } from '@libs/shared/helpers/date-utils';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'date-picker-range',
  templateUrl: './date-picker-range.component.html',
  styleUrls: ['./date-picker-range.component.scss']
})
export class DatePickerRangeComponent {
  @Input() set range(dates: string[]) {
    if (!isEmpty(dates) && !isEmpty(dates[0]) && !isEmpty(dates[1])) {
      this._range = [this.dateStringToJSDate(dates[0]), this.dateStringToJSDate(dates[1])];
    } else {
      this._range = [];
    }
  }

  @Input() set minDate(date: string) {
    this._minDate = this.dateStringToJSDate(date);
  }

  @Input() set maxDate(date: string) {
    this._maxDate = this.dateStringToJSDate(date);
  }

  @Input() public displayMonths: number = 2;
  @Input() public placeholder: string = '';
  @Input() public placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  @Input() public disabled: boolean = false;
  @Input() public valid: boolean = false;
  @Input() public invalid: boolean = false;
  @Output() public dateUpdated = new EventEmitter<string[]>();
  public _minDate: Date = null;
  public _maxDate: Date = null;
  public _range: Date[] = [];

  private dateStringToJSDate(date: string): Date {
    return dateTimeFromString(date).toJSDate();
  }

  private areEqual(date1: Date, date2: Date): boolean {
    return date1?.getTime() === date2?.getTime();
  }

  public emitChange(dates: Date[]): void {
    if (!this.areEqual(this._range[0], dates[0]) || !this.areEqual(this._range[1], dates[1])) {
      this._range = dates;
      this.dateUpdated.emit([dateToUTCString(dates[0]), dateToUTCString(dates[1])]);
    }
  }

  public openCalendar(datePicker: BsDaterangepickerDirective): void {
    if (!this.disabled) {
      datePicker.show();
    }
  }
}
