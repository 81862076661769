import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';

import { FailedToSubmitOffer, OfferSubmitSuccess, SubmitOffer } from './create-offer.actions';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@Injectable()
export class CreateOfferEffects {
  public submitOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitOffer),
      throttleTime(DURATION_1000_MILLISECONDS),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        let url: string;
        let request: Observable<any>;
        if (action.editUrl) {
          url = action.editUrl;
          request = this.httpService.put(url, action.payload);
        } else {
          url = getUrl(apiRoot, ApiRootLinkRel.Offers);
          request = this.httpService.post(url, action.payload);
        }
        return request.pipe(
          map((response: any) => {
            this.toastMessageService.success(this.offerSubmitSuccessMessage(action.payload, !!action.editUrl));
            return OfferSubmitSuccess({
              refNumber: response.textPayload
            });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToSubmitOffer());
          })
        );
      })
    )
  );

  private offerSubmitSuccessMessage(offer: any, isEdit: boolean): string {
    if (isEdit) return 'OFFER.UPDATED';
    if (offer.isPublic) return 'OFFER.PUBLIC_CREATED';
    if (offer.technicians?.length > 0) return 'OFFER.PERSONAL_CREATED';
    return 'OFFER.PRIVATE_CREATED';
  }

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private toastMessageService: ToastMessageService,
    private httpService: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {
  }
}
