@if (!isPermanent) {
<borderless-button
    [isDisabled]="isInviteTechniciansDisabled"
    (onClick)="handleInviteTechniciansToOffer()"
    icon="person_add"
    title="{{
        (isOfferEnded
            ? 'SYSTEM.INFO.DISABLED_ACTION_ENDED_JO'
            : closed
            ? 'SYSTEM.INFO.DISABLED_ACTION_CLOSED_JO'
            : 'SYSTEM.INFO.ADD_TECHNICIANS_TOOLTIP'
        ) | translate
    }}"
></borderless-button>
}

<borderless-button
    (onClick)="openOfferDescription()"
    icon="description"
    title="{{ 'SYSTEM.INFO.VIEW_FULL_JO_PROFILE' | translate }}"
></borderless-button>

<borderless-button
    (onClick)="openEditOfferForm()"
    icon="edit"
    title="{{ 'SYSTEM.INFO.EDIT_JO' | translate }}"
></borderless-button>

<borderless-button
    [isDisabled]="canNotCloseOffer"
    (onClick)="closeOffer()"
    icon="lock"
    title="{{ 'SYSTEM.INFO.CLOSE_JO_TOOLTIP' | translate }}"
></borderless-button>
