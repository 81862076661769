<div style="text-align: right">
    <i (click)="close()" (keydown)="close()" class="material-icons close-icon">close</i>
</div>
<div class="modal-title">
    <ng-container *ngIf="isEditMode; else applyTitle">
        {{ 'CREATE_PACKAGE_CONTRACT.FORM.TITLE' | translate }}
    </ng-container>
    <ng-template #applyTitle>
        {{ 'CREATE_PACKAGE_CONTRACT.FORM.APPLY_TITLE' | translate }}
    </ng-template>
</div>
<div class="text-muted">
    <ng-container *ngIf="isEditMode; else applyReminders">
        <p class="reminder">
            {{ 'CREATE_PACKAGE_CONTRACT.FORM.REMINDER' | translate }}
        </p>
    </ng-container>
    <ng-template #applyReminders>
        <p class="reminder" [innerHTML]="'CREATE_PACKAGE_CONTRACT.FORM.APPLY_REMINDER' | translate"></p>
        <p class="reminder">
            {{
                'CREATE_PACKAGE_CONTRACT.FORM.PREFERRED_PERIOD'
                    | translate
                        : {
                              fromDate: offer?.periodFrom | absoluteDateFormat,
                              toDate: offer?.periodTo | absoluteDateFormat
                          }
            }}
        </p>
        <p class="reminder">
            {{ 'CREATE_PACKAGE_CONTRACT.FORM.PROPOSE_DATE' | translate }}
        </p>
    </ng-template>
</div>
<div class="apply-to-package-offer-form">
    <div class="form-field">
        <label for="start-date">{{ 'GENERAL.START_DATE' | translate }}</label>
        <date-picker-single
            id="start-date"
            [initialValue]="selectedStartDate"
            (dateUpdated)="handleStartDateUpdate($event)"
        ></date-picker-single>
    </div>
    <div class="form-field">
        <label for="end-date">{{ 'GENERAL.END_DATE' | translate }}</label>
        <date-picker-single
            id="end-date"
            [initialValue]="selectedEndDate"
            (dateUpdated)="handleEndDateUpdate($event)"
        ></date-picker-single>
    </div>
    <div class="form-field">
        <label for="offered-price"> Offered Price: </label>
        <div class="input-group">
            <input
                id="offered-price"
                class="form-control"
                type="number"
                min="0"
                max="chargeRateMaxValue"
                autocomplete="off"
                [placeholder]="'GENERAL.ENTER_PRICE' | translate"
                [(ngModel)]="offeredPrice"
            />
            <div class="input-group-append">
                <span class="input-group-text">
                    {{ currencySymbol }}
                </span>
            </div>
        </div>
        <div class="custom-control custom-checkbox">
            <input
                id="priceEstimate"
                type="checkbox"
                class="custom-control-input"
                [disabled]="this.isEditMode"
                [(ngModel)]="priceEstimate"
            />
            <label for="priceEstimate" class="custom-control-label">
                {{ 'CREATE_PACKAGE_CONTRACT.FORM.OFFERED_PRICE' | translate }}
            </label>
        </div>
    </div>
    <div class="form-field">
        <label for="agency_notes">{{ 'GENERAL.NOTES' | translate }}:</label>
        <textarea
            id="agency_notes"
            type="text"
            class="form-control"
            [placeholder]="'GENERAL.ENTER_NOTE' | translate"
            [disabled]="this.isEditMode"
            [(ngModel)]="agencyNotes"
            [maxlength]="AGENCY_NOTES_MAX_LENGTH"
        ></textarea>
    </div>
</div>
<div *ngIf="showDateError" class="error-message">
    {{ 'CREATE_PACKAGE_CONTRACT.FORM.INVALID_DATE_RANGE' | translate }}
</div>
<general-button
    style="width: 120px"
    (onClick)="confirm()"
    [isDisabled]="offeredPrice <= 0 || showDateError"
    class="confirm-btn"
    [text]="'GENERAL.CONFIRM' | translate"
></general-button>
