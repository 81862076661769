<mat-form-field
    class="material-form-control"
    appearance="outline"
    subscriptSizing="dynamic"
    [ngClass]="{
        'is-valid': valid,
        'is-invalid': invalid,
        disabled: disabled
    }"
>
    <mat-chip-grid #chipGrid>
        @for (option of selectedOptions; track $index) {
        <mat-chip-row [removable]="!disabled" (removed)="removeSelection(option)">
            {{ option.name }}
            <button matChipRemove *ngIf="!disabled">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        }
    </mat-chip-grid>
    <input
        [placeholder]="placeholder"
        #typeOptionInput
        [matAutocomplete]="auto"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addNewOption($event); typeOptionInput.value = ''"
        (input)="onInputChange($event)"
        (focus)="onFocus()"
        [disabled]="disabled"
    />

    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addSelection($event.option.value); typeOptionInput.value = ''"
    >
        @for (option of filteredOptions; track option) {
        <mat-option [value]="option">
            {{ option.name }}
        </mat-option>
        }
    </mat-autocomplete>
</mat-form-field>
