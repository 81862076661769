import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { isEmpty } from 'lodash-es';
import {
  ConversationsUpdated,
  FailedToSendMessage,
  LoadMessages,
  MessageSent,
  SendMessage
} from '@libs/mailbox/state/mailbox.actions';
import { select, Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';
import { FileUploadService } from '@libs/common-ui/services/file-upload.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { Conversation, FEATURE_STATE_KEY } from '@libs/mailbox/state/mailbox-state.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'staffnow-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss']
})
export class MessageInputComponent implements OnInit {
  private static readonly DEFAULT_FILE_SIZE_LIMIT_FOR_CHAT_UPLOAD: number = 3;
  private static readonly DEFAULT_ALLOWED_FILE_TYPES_LIST_FOR_CHAT_UPLOAD: string[] = ['png', 'jpg', 'jpeg', 'pdf'];
  @Input() public conversation: Conversation = null;
  @ViewChild('fileInput') private readonly fileInput: ElementRef;
  public inputAccepts: string;
  public newMessageText: string = '';
  public uploader: FileUploader;
  public sendingMessageInProgress: boolean = false;
  private lastMessageTimestamp: number = 0;
  private postConversationMessageTemporaryFileUrl: string;

  constructor(
    private readonly store: Store<any>,
    private readonly uploadService: FileUploadService,
    private readonly toastMessageService: ToastMessageService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translateService: TranslateService
  ) {
    this.store
      .pipe(
        select(state => state[FEATURE_STATE_KEY].messageList),
        untilDestroyed(this)
      )
      .subscribe(messageList => {
        this.lastMessageTimestamp = messageList.lastMessageTimestamp;
        this.postConversationMessageTemporaryFileUrl = messageList.postConversationMessageTemporaryFileUrl;
        this.prepareUploader();
      });
    this.store
      .pipe(
        select(state => state[FEATURE_STATE_KEY].sendingMessageInProgress),
        untilDestroyed(this)
      )
      .subscribe(value => (this.sendingMessageInProgress = value));
  }

  ngOnInit(): void {
    this.inputAccepts = this.uploadService.fileListToExtensionCSV(
      MessageInputComponent.DEFAULT_ALLOWED_FILE_TYPES_LIST_FOR_CHAT_UPLOAD
    );
  }

  get canSendMessage(): boolean {
    return !isEmpty(this.newMessageTextTrimmed);
  }

  get newMessageTextTrimmed(): string {
    return this.newMessageText?.trim();
  }

  public sendMessage(): void {
    if (this.canSendMessage) {
      this.store.dispatch(new SendMessage(this.newMessageTextTrimmed, this.lastMessageTimestamp));
      this.newMessageText = '';
    }
  }

  private prepareUploader() {
    this.uploadService
      .configUploader(
        this.postConversationMessageTemporaryFileUrl,
        MessageInputComponent.DEFAULT_FILE_SIZE_LIMIT_FOR_CHAT_UPLOAD,
        MessageInputComponent.DEFAULT_ALLOWED_FILE_TYPES_LIST_FOR_CHAT_UPLOAD
      )
      .then(uploader => {
        this.uploader = uploader;
        this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
          fileItem.url = this.postConversationMessageTemporaryFileUrl;
          fileItem.alias = 'file';
        };

        this.uploader.onSuccessItem = () => {
          this.store.dispatch(new MessageSent());
          this.store.dispatch(new LoadMessages(this.conversation, this.lastMessageTimestamp));
          this.store.dispatch(
            ConversationsUpdated({
              updatedConversations: [this.conversation]
            })
          );
          this.fileInput.nativeElement.value = '';
        };

        this.uploader.onErrorItem = () => {
          this.store.dispatch(new FailedToSendMessage());
          this.uploader.clearQueue();
        };

        this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
          this.uploader.queue.find((file: FileItem, index: number) => {
            if (file === fileItem) {
              this.uploader.queue.splice(index, 1);
            }
          });

          switch (filter.name) {
            case 'fileSize':
              if (fileItem.rawFile.type.includes('image')) {
                const downsizeRate =
                  (MessageInputComponent.DEFAULT_FILE_SIZE_LIMIT_FOR_CHAT_UPLOAD * 1000000) / fileItem.rawFile.size;
                this.uploadService.downsizeImage(
                  fileItem,
                  this.postConversationMessageTemporaryFileUrl,
                  downsizeRate,
                  {},
                  response => this.handleResizeResponse(response)
                );
              } else {
                this.toastMessageService.fail(this.translateService.instant(fileUploadConstants.fileExceededLimit));
              }
              break;
            case 'mimeType':
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.fileHasUnsupportedFormat)
              );
              break;
            case 'queueLimit':
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.uploaderQueueLimitExceeded, {
                  limit: this.uploader.options.queueLimit
                })
              );
              break;
            default:
              break;
          }

          if (this.uploader.queue.length === 0) {
            this.fileInput.nativeElement.value = '';
          }
        };
      });
  }

  private handleResizeResponse(response) {
    response.subscribe(
      () => {
        this.store.dispatch(new MessageSent());
        this.store.dispatch(new LoadMessages(this.conversation, this.lastMessageTimestamp));
        this.store.dispatch(
          ConversationsUpdated({
            updatedConversations: [this.conversation]
          })
        );
        this.fileInput.nativeElement.value = '';
        this.toastMessageService.success(this.translateService.instant(fileUploadConstants.successfullFileUpload));
      },
      response => {
        this.errorMessageService.handleErrorResponse(response, 'UPLOAD.IMAGE_FAILED');
      }
    );
  }

  @HostListener('keyup', ['$event'])
  public handleEnterKey(event) {
    // 13 is the keyCode for the Enter
    if (event.keyCode === 13 && event.shiftKey === false && !this.sendingMessageInProgress) {
      this.sendMessage();
    }
  }
}
