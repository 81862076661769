import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  ConversationListLoaded,
  ConversationLoaded,
  ConversationsUpdated,
  CreateConversation,
  DeleteConversationMessage,
  DownloadConversationFile,
  FailedToCreateConversation,
  FailedToLoadConversation,
  FailedToLoadConversations,
  FailedToLoadMessages,
  FailedToLoadNextPage,
  FailedToSendMessage,
  LoadConversation,
  LoadConversations,
  LoadMessages,
  LoadNextPage,
  MailboxActions,
  MessageSent,
  MessagesLoaded,
  NextPageLoaded,
  SendMessage,
  SetCurrentPageNumber,
  SuccessfulConversationFileDownload,
  SuccessfulConversationMessageDelete,
  UpdateConversations
} from './mailbox.actions';
import { Conversation, FEATURE_STATE_KEY, Message } from './mailbox-state.model';
import { MailboxService } from '../mailbox.service';
import { cloneDeep, isNil } from 'lodash-es';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ResourceFactory } from '@libs/shared/bms-common/rest/resource.factory';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { downloadRawBlob } from '@libs/shared/helpers/download-blob-file';
import { DownloadService } from '@libs/shared/services/download.service';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';

@Injectable()
export class MailboxEffects {
  public loadConversations$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadConversations),
      switchMap(() => {
        return this.resourceFactory
          .fromId(this.conversationsUrl)
          .get()
          .pipe(
            switchMap((response: any) => {
              return [
                ConversationListLoaded({
                  conversationList: response._embedded.conversations
                }),
                SetCurrentPageNumber({ payload: response.page })
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToLoadConversations());
            })
          );
      })
    )
  );

  public updateConversations$ = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateConversations),
      switchMap(action => {
        return this.resourceFactory
          .fromId(this.conversationsUrl)
          .get({
            lastUpdatedSince: this.parseNumberOrZero(action.lastUpdatedSince),
            getUpdatedOnly: true
          })
          .pipe(
            switchMap((response: any) => {
              return [
                ConversationsUpdated({
                  updatedConversations: response._embedded.updatedConversations
                })
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(FailedToLoadConversations());
            })
          );
      })
    )
  );

  public loadNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.LoadNextPage),
      switchMap((action: LoadNextPage) => {
        return this.resourceFactory
          .fromId(this.conversationsUrl)
          .get({
            lastUpdatedSince: this.parseNumberOrZero(action.lastUpdatedSince),
            page: !isNil(this.currentPage) ? this.currentPage + 1 : 0
          })
          .pipe(
            switchMap((response: any) => {
              if (response._embedded.conversations < response.pageSize) {
                response.page = this.currentPage;
              }

              return [
                NextPageLoaded({
                  conversations: response._embedded.conversations,
                  updatedConversations: response._embedded.updatedConversations
                }),
                SetCurrentPageNumber({ payload: response.page })
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(new FailedToLoadNextPage());
            })
          );
      })
    )
  );

  public loadConversation$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadConversation),
      switchMap((action: { conversationId?: number; conversationUrl?: string }) => {
        const conversationUrl = !isNil(action.conversationUrl)
          ? action.conversationUrl
          : `${this.conversationsUrl as string}/${action.conversationId}`;
        return this.resourceFactory
          .fromId(conversationUrl)
          .get()
          .pipe(
            switchMap((conversation: Conversation) => [ConversationLoaded({ conversation })]),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(new FailedToLoadConversation());
            })
          );
      })
    )
  );

  public loadMessages$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.LoadMessages),
      withLatestFrom(this.store.pipe(select(state => state[FEATURE_STATE_KEY].messageList))),
      switchMap(([action, { messages }]: [LoadMessages, { messages: Message[] }]) => {
        const stateListIsEmpty = isNil(messages) || messages.length === 0;
        const conversation = !isNil(action.conversation) ? action.conversation : this.selectedConversation;
        if (isNil(conversation) || isNil(conversation._links)) {
          return of(new FailedToLoadMessages());
        }
        const url = action.loadUpdatedMessages
          ? conversation._links.updatedMessages.href.split('?')[0]
          : conversation._links.messages.href.split('?')[0];
        const since: number = this.parseNumberOrZero(action.since);
        const before: number = this.parseNumberOrZero(action.before);
        const params: { since?: number; before?: number } = {};
        if (since != 0) {
          params.since = since;
        }
        if (before != 0) {
          params.before = before;
        }
        return this.httpService.get(url, { params: new HttpParams({ fromObject: params }) }).pipe(
          switchMap((response: any) => {
            if (
              stateListIsEmpty ||
              messages[0]?.conversationId !== conversation?.conversationId ||
              messages.length !== response._embedded.messages.length
            ) {
              this.mailboxService.scrollMessageListToBottom();
            }
            return [
              new MessagesLoaded(
                getUrl(response, 'postConversationMessageTemporaryFile'),
                response,
                action.loadUpdatedMessages,
                action.since,
                action.before
              )
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToLoadMessages());
          })
        );
      })
    )
  );

  private parseNumberOrZero(value: number): number {
    return !isNil(value) && !Number.isNaN(value) ? value : 0;
  }

  public sendMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.SendMessage),
      switchMap((action: SendMessage) => {
        return this.httpService
          .post(this.selectedConversation._links.messages.href, {
            text: action.text
          })
          .pipe(
            switchMap(() => {
              const conversation = { ...this.selectedConversation };
              return [
                new MessageSent(),
                new LoadMessages(conversation),
                ConversationsUpdated({ updatedConversations: [conversation] })
              ];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(new FailedToSendMessage());
            })
          );
      })
    )
  );

  public createConversation$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.CreateConversation),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: CreateConversation) => {
        const requestBody: any = { secondUserUuid: action.otherUserUuid };
        if (!isNil(action.contractId)) {
          requestBody.contractId = action.contractId;
        } else if (!isNil(action.packageContractId)) {
          requestBody.packageContractId = action.packageContractId;
        } else if (!isNil(action.agencyOfferContractId)) {
          requestBody.agencyOfferContractId = action.agencyOfferContractId;
        }

        return this.httpService.post(this.conversationsUrl, requestBody).pipe(
          switchMap((response: any) => {
            return [LoadConversation({ conversationId: response.conversationId }), LoadConversations()];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedToCreateConversation());
          })
        );
      })
    )
  );

  public downloadConversationFile$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.DownloadConversationFile),
      switchMap((action: DownloadConversationFile) => {
        return this.downloadService.doGetRequest(action.getConversationMessageTemporaryFileUrl).pipe(
          map((response: any) => {
            downloadRawBlob(response.body, action.filename);
            return new SuccessfulConversationFileDownload();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Failed to download conversation file'
            );
            return EMPTY;
          })
        );
      })
    )
  );

  public deleteConversationMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(MailboxActions.DeleteConversationMessage),
      switchMap((action: DeleteConversationMessage) => {
        return this.httpService.delete(action.deleteConversationMessageUrl).pipe(
          switchMap(() => {
            const conversation = { ...this.selectedConversation };
            conversation.hasUnseenMessages = false;
            return [
              new SuccessfulConversationMessageDelete(),
              ConversationsUpdated({ updatedConversations: [conversation] })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              'Failed to delete conversation message'
            );
            return EMPTY;
          })
        );
      })
    )
  );

  private conversationsUrl: any;
  private selectedConversation: any = null;
  public currentPage: number = null;

  constructor(
    private readonly actions: Actions,
    private readonly resourceFactory: ResourceFactory,
    private readonly store: Store<any>,
    private readonly httpService: HttpClient,
    private readonly mailboxService: MailboxService,
    private readonly downloadService: DownloadService,
    private readonly errorMessageService: ErrorMessageService
  ) {
    this.store
      .pipe(getFilteredApiRoot)
      .subscribe(apiRoot => (this.conversationsUrl = apiRoot._links.conversations.href.split('?')[0]));

    this.store.pipe(select(state => state[FEATURE_STATE_KEY].currentPage)).subscribe(currentPage => {
      this.currentPage = currentPage;
    });

    this.store.pipe(select(state => state[FEATURE_STATE_KEY].selectedConversation)).subscribe(selectedConv => {
      this.selectedConversation = cloneDeep(selectedConv);
    });
  }
}
