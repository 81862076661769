import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'match-score',
  templateUrl: './match-score.component.html',
  styleUrls: ['./match-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true
})
export class MatchScoreComponent implements OnInit, OnChanges {
  @Input() percentage: number = 0;
  @Input() color: string = '#4db8ff';
  circumference: number = 2 * Math.PI * 22; // circle of 25px - 3px of stroke-width of radius
  unfilledCircle: number = 0;

  ngOnInit() {
    this.calculateColor();
    this.calculateUnfilledCircle();
  }

  ngOnChanges() {
    this.calculateColor();
    this.calculateUnfilledCircle();
  }

  calculateColor() {
    if (this.percentage >= 75) {
      this.color = 'green';
    } else if (this.percentage >= 50) {
      this.color = 'orange';
    } else {
      this.color = 'grey';
    }
  }

  calculateUnfilledCircle() {
    const filledCircle = (this.percentage / 100) * this.circumference;
    this.unfilledCircle = this.circumference - filledCircle;
  }

  protected readonly Math = Math;
}
