<h1>
    {{ report.type | translate }}
</h1>
<p>
    {{ report.description | translate }}
</p>
<div *ngIf="isMroFacilitySelectAvailable()">
    <label for="mro-facility-select">{{ 'GENERAL.AVIATION_COMPANY' | translate }}</label>
    <simple-select
        id="mro-facility-select"
        [placeholder]="'GENERAL.SELECT_FACILITY' | translate"
        [(ngModel)]="uuid"
        [items]="mroFacilities"
        [getValue]="getFacilityUuid"
    >
    </simple-select>
</div>
@if (isRangeSelectAvailable()) {
<date-picker-range
    [range]="range"
    (dateUpdated)="handleRangeChange($event)"
    [placeholder]="'GENERAL.RANGE' | translate"
    [displayMonths]="1"
></date-picker-range>
<small *ngIf="isSelectedRangeGreaterThanAllowed()" class="validation-error">
    {{ 'GENERAL.RANGE_VALIDATION_ERROR' | translate : { days: report.maxDays } }}
</small>
}
<div class="generate-buttons">
    <general-button
        [text]="'GENERAL.GENERATE_SELECTION' | translate"
        [isLoading]="isLoading"
        [isDisabled]="isGenerateDisabled()"
        (onClick)="generateReport()"
    ></general-button>
    <general-button
        [text]="'GENERAL.GENERATE_GROUP' | translate"
        *ngIf="report.groupUrl"
        [isLoading]="isLoading"
        [isDisabled]="isGenerateGroupDisabled()"
        (onClick)="generateReport(report.groupUrl)"
    ></general-button>
</div>
