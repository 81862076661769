import { Pipe, PipeTransform } from '@angular/core';
import { profileStatusConstants } from '../constants/statuses.constants';
import { isUsaDeployment } from '@libs/shared/bms-common/environment/environment.loader';

@Pipe({
  name: 'profileStatusNotificationFormatter'
})
export class ProfileStatusNotificationFormatterPipe implements PipeTransform {
  private statusKeys = Object.keys(profileStatusConstants.serverLangToHumanLang);

  private readonly COMPLETE_STATUSES = [profileStatusConstants.pending, profileStatusConstants.inReview];
  private statusRegex: RegExp = new RegExp(this.statusKeys.join('|'), 'g');

  transform(value: string): string {
    const matches = Array.from(new Set(value.match(this.statusRegex)));
    if (matches && matches.length > 0) {
      matches.forEach(match => {
        const shouldShowCompleteStatus = isUsaDeployment() && this.COMPLETE_STATUSES.includes(match);
        const replacedValue: string = shouldShowCompleteStatus ? profileStatusConstants.complete : match;
        value = value.replace(
          `'${match}'`,
          `<br><span class="${replacedValue}">${profileStatusConstants.serverLangToHumanLang[replacedValue]}</span>`
        );
      });
    }
    return value;
  }
}
