import { createFormStateReducerWithUpdate, FormGroupState, setErrors, updateGroup, validate } from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';

import { CreateOfferFormModel, createOfferInitialFormState, FormState } from './create-offer.model';
import { ResetOfferForm } from './create-offer.actions';
import { Action } from '@ngrx/store';
import {
  getChargeRateValidator,
  getShortPresentationValidator,
  getMinExperienceValidator,
  getTitleValidator,
  getVacanciesValidator,
  getWorkDaysOffValidator,
  getWorkDaysOnValidator,
  MAX_CHARGE_RATE,
  getLicensesValidator,
  getCustomReferenceValidator
} from '../../../shared/validators/offer-form.validator';
export const CREATE_EDIT_OFFER_FEATURE_KEY = 'createEditOffer';

const validateAircraft = (state: FormState): FormGroupState<CreateOfferFormModel> => {
  if (state.controls.otherAirplanes.value) {
    return updateGroup<CreateOfferFormModel>({
      airplanes: setErrors(null)
    })(state);
  }
  return state;
};

const validateMaxChargeRate = (
  state: FormState = createOfferInitialFormState
): FormGroupState<CreateOfferFormModel> => {
  if (state.controls.hasIndicativeRate.value) {
    return updateGroup<CreateOfferFormModel>({
      maxChargeRate: validate(
        required,
        greaterThan(-1),
        lessThanOrEqualTo(MAX_CHARGE_RATE),
        greaterThan(state.controls.chargeRate.value ?? 0)
      )
    })(state);
  } else {
    return updateGroup<CreateOfferFormModel>({
      maxChargeRate: setErrors(null)
    })(state);
  }
};

const updateMyFormGroup = updateGroup<CreateOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  shortPresentation: getShortPresentationValidator(),
  periodFrom: validate(required),
  periodTo: validate(required),
  vacancies: getVacanciesValidator(),
  ameType: validate(required),
  ameTitleId: validate(required),
  airplanes: validate(required),
  minExperience: getMinExperienceValidator(),
  chargeRate: getChargeRateValidator(),
  workDaysOn: getWorkDaysOnValidator(),
  workDaysOff: getWorkDaysOffValidator(),
  locationId: validate(required),
  priority: validate(required),
  title: getTitleValidator(),
  licenses: state => getLicensesValidator(state)
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createOfferFormReducer(
  state: FormState = createOfferInitialFormState,
  action: Action
): FormGroupState<CreateOfferFormModel> {
  if (action.type === ResetOfferForm.type) {
    return {
      ...createOfferInitialFormState
    };
  }
  return {
    ...validateMaxChargeRate(validateAircraft(formReducer(state, action)))
  };
}
