import { Component, Input, OnDestroy } from '@angular/core';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { role, RoleWrapper } from '@libs/shared/models/roles.enum';
import { Contract } from '@libs/shared/models/contract.model';
import { QualityAcceptComponent } from '@libs/common-ui/quality-accept/quality-accept.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  AcceptContract,
  PauseContract,
  ResumeContract
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { Subscription } from 'rxjs';
import { JobOpeningContractDeclinationService } from '@libs/shared/services/job-opening-contract-declination.service';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { AbstractContractCardComponent } from '../contract-card/abstract-contract-card.component';
import { ConversationService } from '../../services/conversation.service';
import { BreakDatesPickerDialogComponent } from '@libs/common-ui/break-dates-picker-dialog/break-dates-picker-dialog.component';

interface ContractWithNotes extends Contract {
  notes?: string;
}

@Component({
  selector: 'app-contract-action-table',
  templateUrl: './contract-action-table.component.html',
  styleUrls: ['./contract-action-table.component.scss']
})
export class ContractActionTableComponent extends AbstractContractCardComponent implements OnDestroy {
  @Input() offerStartDate: string = null;
  @Input() offerEndDate: string = null;
  @Input() reasonsDeclineOffer: [] = [];
  @Input() contract: Contract = null;

  private userRole: RoleWrapper = null;
  private subs: Array<Subscription> = [];

  constructor(
    store: Store,
    conversationService: ConversationService,
    jobOpeningContractDeclinationService: JobOpeningContractDeclinationService,
    private modalService: BsModalService
  ) {
    super(store, conversationService, jobOpeningContractDeclinationService);
    this.subs.push(
      this.store.pipe(getLoggedInUserRole).subscribe(r => {
        this.userRole = role(r);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  get canAcceptContract(): boolean {
    return this.contract?._links[RequestOverviewLinkRel.AcceptContract] && this.userRole.isMro();
  }

  get canDeclineContract(): boolean {
    return this.contract?._links[RequestOverviewLinkRel.DeclineContract] && this.userRole.isMro();
  }

  get canPauseContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.PauseContract);
  }

  get hasAlreadyAviationCompanyAccepted(): boolean {
    return this.getMroStatus() === ContractStatus.accepted;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offerEndDate);
  }

  get hasAlreadyAviationCompanyRejected(): boolean {
    return this.getMroStatus() === ContractStatus.rejected;
  }

  get canMarkDocumentsNeeded(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.MarkAsDocumentsNeeded);
  }

  get hasAlreadyMroRejected(): boolean {
    return this.getMroStatus() === ContractStatus.rejected;
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(this.contract);
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(this.contract);
  }

  get canResumeContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ResumeContract);
  }

  markContractAsDocumentsNeeded(): void {
    this.jobOpeningContractDeclinationService.onDocumentsNeeded(this.contract);
  }

  acceptContractAviationCompany(): void {
    if (this.userRole.isMroAnyQuality()) {
      this.onAcceptMroQuality(this.contract);
    } else {
      this.onAcceptContract(this.contract);
    }
  }

  declineContractAviationCompany(): void {
    if (this.userRole.isMroAnyQuality()) {
      this.jobOpeningContractDeclinationService.onDeclineMroQuality(this.contract, this.reasonsDeclineOffer);
    } else {
      this.jobOpeningContractDeclinationService.onDeclineMroProduction(this.contract, this.reasonsDeclineOffer);
    }
  }

  handlePauseContract(): void {
    this.modalService.show(BreakDatesPickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        minimumDate: this.contract.startDate,
        onAcceptCallback: (fromDate: string, toDate: string) => {
          this.store.dispatch(PauseContract({ contract: this.contract, fromDate, toDate }));
        }
      }
    });
  }

  handleResumeTechnician(): void {
    this.store.dispatch(ResumeContract({ contract: this.contract }));
  }

  initiateMessageWithAgency(): void {
    this.conversationService.initiateMessageWithAgency(this.contract);
  }

  onAcceptMroQuality(contract: Contract): void {
    this.modalService.show(QualityAcceptComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (notes: string) =>
          this.onAcceptContract({
            ...contract,
            notes: notes || null
          })
      }
    });
  }

  isContactActionDisable(): boolean {
    return (
      (!this.canCreateConversationWithTechnician && !this.canGetPreviousConversationWithTechnician) ||
      (!this.canCreateConversationWithAgency && !this.canGetPreviousConversationWithAgency)
    );
  }

  private onAcceptContract(contract: ContractWithNotes): void {
    this.store.dispatch(AcceptContract({ contract, agencyUuid: null }));
  }

  private getMroStatus(): ContractStatus {
    if (this.userRole.isMroAnyQuality()) {
      return this.contract?.mroQualityStatus;
    } else if (this.userRole.isMroProduction()) {
      return this.contract?.mroStatus;
    } else {
      return this.contract?.mroHumanResourcesStatus;
    }
  }
}
