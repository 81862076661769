<staffnow-create-offer-banner
    [facilityProfile]="facilityProfile"
    invitationTarget="agencies"
></staffnow-create-offer-banner>
<div class="package-offer-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    <div *ngIf="isSettingInitialValues" class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    <div *ngIf="!isSettingInitialValues" class="create-offer-container form-container">
        <!-- CREATE OFFER FORM -->
        <section class="create-offer-main">
            <div>
                <form *ngIf="createOfferForm" [ngrxFormState]="createOfferForm | async" class="form-body" novalidate>
                    <div class="form-group highlight row create-offer-header">
                        <!-- PRIORITY -->
                        <div class="form-group header-selector">
                            <label
                                for="priority-level"
                                [class.required]="packageOfferInitialFormState.priority == null"
                            >
                                {{ 'OFFER.FORM.PRIORITY_LEVEL.LABEL' | translate }}
                            </label>
                            <simple-select
                                id="priority-level"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="createOfferFormValue.controls.priority"
                                [placeholder]="'OFFER.FORM.PRIORITY.PLACEHOLDER' | translate"
                                [valid]="
                                    createOfferFormValue.controls.priority.isValid &&
                                    createOfferFormValue.controls.priority.isDirty
                                "
                                [invalid]="createOfferFormValue.controls.priority.isInvalid"
                                [hasDefaultValue]="packageOfferInitialFormState.priority != null"
                            >
                            </simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    {{ 'OFFER.FORM.VISIBILITY' | translate }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <!-- SELECT COMPANY -->
                    <div *ngIf="wrappedRole.isHumanResourcesOrCentralQuality()" class="form-group row">
                        <div class="form-group col">
                            @if (mroList$ | async; as entities) {
                            <ng-select
                                bindLabel="name"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="facilityProfile"
                                [items]="entities"
                                [multiple]="false"
                                [loading]="loading"
                                [closeOnSelect]="true"
                                [searchable]="true"
                                [placeholder]="'REQUEST_LISTING.SELECT_AVIATION_COMPANY' | translate"
                                (close)="onClose()"
                                (clear)="onSearch('')"
                                (scrollToEnd)="loadMore(entities)"
                                (search)="onSearch($event.term)"
                                (change)="initForm($event)"
                                [disabled]="isEditForm"
                            >
                            </ng-select>
                            }
                        </div>
                    </div>
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            <custom-reference-input
                                *ngIf="!isSettingInitialValues"
                                [formState]="createOfferFormValue"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                            ></custom-reference-input>
                        </div>
                        <div class="form-group col">
                            <label for="preferred-period" class="required">
                                {{ 'OFFER.FORM.PERIOD.LABEL' | translate }}
                            </label>
                            <date-picker-range
                                id="preferred-period"
                                (dateUpdated)="handleDateChange($event)"
                                [disabled]="isRestrictedMode()"
                                [minDate]="rangePickerMinDate"
                                [range]="rangePickerValue"
                                [placeholder]="'OFFER.FORM.PERIOD.PLACEHOLDER' | translate"
                                [valid]="
                                    createOfferFormValue.controls.preferredStartDate.isValid &&
                                    createOfferFormValue.controls.preferredEndDate.isValid
                                "
                                [invalid]="
                                    highlightErrors &&
                                    createOfferFormValue.controls.preferredStartDate.isInvalid &&
                                    createOfferFormValue.controls.preferredEndDate.isInvalid
                                "
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="title">
                                {{ 'OFFER.FORM.TITLE.LABEL' | translate }}
                            </label>
                            <input
                                id="title"
                                [ngrxFormControlState]="createOfferFormValue.controls.title"
                                autocomplete="off"
                                class="form-control"
                                [placeholder]="
                                    'OFFER.FORM.TITLE.PLACEHOLDER'
                                        | translate : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                "
                                type="text"
                                [ngClass]="{
                                    'is-valid':
                                        createOfferFormValue.controls.title.isDirty &&
                                        createOfferFormValue.controls.title.isValid,
                                    'is-invalid':
                                        createOfferFormValue.controls.title.isDirty &&
                                        createOfferFormValue.controls.title.isInvalid
                                }"
                            />
                            <div *ngIf="createOfferFormValue.controls.title.isInvalid">
                                <span
                                    class="validation-error"
                                    *ngIf="createOfferFormValue.controls.title.errors?.invalidTitleLength"
                                >
                                    {{
                                        'OFFER.FORM.INVALID_TITLE_LENGTH'
                                            | translate
                                                : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">
                                {{ 'OFFER.FORM.LOCATION.LABEL' | translate }}
                            </label>
                            <ng-select
                                (change)="writeLocationIdToForm()"
                                [(ngModel)]="selectedLocation"
                                [clearSearchOnAdd]="true"
                                [closeOnSelect]="true"
                                [disabled]="!canSelectLocation()"
                                [items]="locationList"
                                [ngModelOptions]="{ standalone: true }"
                                [searchable]="true"
                                bindLabel="name"
                                id="location"
                                [placeholder]="'OFFER.FORM.LOCATION.PLACEHOLDER' | translate"
                                [clearable]="false"
                            ></ng-select>
                        </div>
                    </div>
                    <!-- SPECIFICATION -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="technical-specifications" class="required">Technical Specifications</label>
                            <textarea
                                id="technical-specifications"
                                [ngrxFormControlState]="createOfferFormValue.controls.specification"
                                class="form-control"
                                cols="30"
                                name="presentation"
                                placeholder="Enter the job opening specifications"
                                rows="5"
                                [ngClass]="{
                                    'is-valid': createOfferFormValue.controls.specification.isValid,
                                    'is-invalid':
                                        createOfferFormValue.controls.specification.isInvalid &&
                                        (highlightErrors || createOfferFormValue.controls.specification.isDirty)
                                }"
                            >
                            </textarea>
                            <div
                                *ngIf="
                                    createOfferFormValue.controls.specification.isInvalid &&
                                    createOfferFormValue.controls.specification.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_JOB_DESCRIPTION_LENGTH'
                                            | translate
                                                : {
                                                      minLength: JO_PRESENTATION_MIN_LENGTH,
                                                      maxLength: JO_PRESENTATION_MAX_LENGTH
                                                  }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- FORM ACTIONS -->
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            backgroundStyle="outline"
                            class="mr-5"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            (onClick)="handleFormSubmit()"
                            [isLoading]="isSubmitting"
                            [text]="'CONFIRM' | translate"
                        ></general-button>
                    </div>
                    <div *ngIf="highlightErrors" class="invalid-form-error">
                        <span class="validation-error">
                            {{ 'OFFER.FORM.INVALID_SUBMITTED_FORM' | translate }}
                        </span>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>
