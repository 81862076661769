import { NgModule } from '@angular/core';
import { CustomReferenceInputComponent } from './components/custom-reference-input/custom-reference-input.component';
import { NgIf } from '@angular/common';
import { NgrxFormsModule } from 'ngrx-forms';
import { TranslationModule } from '@libs/translation/translation.module';
import { MroFacilityLoaderService } from './services/mro-facility-loader.service';
import { AviationCompanySelectorComponent } from './components/aviation-company-selector/aviation-company-selector.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgencyFacilityLoaderService } from './services/agency-facility-loader.service';

@NgModule({
  declarations: [CustomReferenceInputComponent, AviationCompanySelectorComponent],
  imports: [NgIf, NgrxFormsModule, TranslationModule, CommonUiModule, FormsModule, NgSelectModule],
  exports: [CustomReferenceInputComponent, AviationCompanySelectorComponent],
  providers: [MroFacilityLoaderService, AgencyFacilityLoaderService]
})
export class OfferManagementSharedModule {}
