import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { GetOwnTechniciansListing } from '../../state/agency-request-overview-wrapper.actions';
import {
  AgenciesMainState,
  STATE_FEATURE_KEY,
  TechnicianListingState
} from '../../state/agency-request-overview-wrapper.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { identity, isEmpty, isNil, omitBy, pickBy } from 'lodash-es';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { FilterTypes } from '@libs/staff-filters/constants/FilterTypes';
import { UsersService } from '@libs/shared/services/users.service';
import {
  getAirplanesSortedByName,
  getLicenses,
  getSkillsSortedByName,
  getWorkShifts
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { TechnicianProfileModel, UserProfile } from '@libs/shared/models/technician-profile.model';
import { Skill } from '@libs/shared/models/skills.model';
import { Aircraft } from '@libs/shared/models/aircraft.model';
import { UsersFilterValueModel } from '../../../../../../../back-office/src/app/modules/users/state/users-state.model';
import { WorkShiftDto } from '@libs/shared/models/work-shift-dto.model';
import { License } from '@libs/shared/models/license.model';
import { isUsaDeployment } from '@libs/shared/bms-common/environment/environment.loader';

interface SearchFilters {
  term: string;
  ameType: string;
  ameLicense: string;
  aircraft: Aircraft[];
  skills: Skill[];
  workShifts: WorkShiftDto[];
  licenses: License[];
}

interface SearchValues {
  term: string;
  ameTypeLicense: {
    ameType: string;
    ameLicense: string;
  };
  aircraft: Aircraft[];
  skills: Skill[];
  workShifts: WorkShiftDto[];
  licenses: License[];
}

const SKILLS: string = 'skills';
const SHIFTS: string = 'workShifts';
const AIRCRAFT: string = 'aircraft';
const LICENSES: string = 'licenses';
const AME_TYPE_LICENSE: string = 'ameTypeLicense';

@Component({
  selector: 'staffnow-own-technicians',
  templateUrl: './own-technicians.component.html',
  styleUrls: ['./own-technicians.component.scss']
})
export class OwnTechniciansComponent implements OnInit, OnDestroy {
  readonly isElaunchNowInstance: boolean = isUsaDeployment();

  public usersList: TechnicianProfileModel[];
  public currentPage: number = 0;
  public pageSize: number;
  public isLoading: boolean = false;
  private readonly subs: Subscription[] = [];
  public totalElements: number;
  public filtersConfig: FilterConfigModel[] = [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.NAME_EMAIL.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.NAME_EMAIL.PLACEHOLDER'
    },
    {
      type: FilterTypes.AmeTypeLicense,
      key: AME_TYPE_LICENSE,
      initialValue: {}
    },
    {
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.AIRCRAFT.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.AIRCRAFT.PLACEHOLDER',
      key: AIRCRAFT,
      className: 'aircrafts-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name'
    },
    {
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.SKILLS.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.SKILLS.PLACEHOLDER',
      key: SKILLS,
      className: 'skills-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name'
    },
    {
      label: 'OWN_TECHNICIANS.FILTERS.CONFIG.SHIFTS.LABEL',
      placeholder: 'OWN_TECHNICIANS.FILTERS.CONFIG.SHIFTS.PLACEHOLDER',
      key: SHIFTS,
      className: 'shifts-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name'
    }
  ];

  private readonly licenseFilter: FilterConfigModel = {
    label: 'SYSTEM.INFO.LICENSE',
    placeholder: 'GENERAL.USER_ACCOUNT.SELECT_LICENSES',
    key: LICENSES,
    className: 'licenses-filter',
    type: FilterTypes.Enum,
    multiple: true,
    labelForId: 'id',
    bindLabel: 'name'
  };

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly usersService: UsersService
  ) {
    this.subs.push(
      this.store.pipe(getAirplanesSortedByName, take(1)).subscribe(aircraftList => {
        this.filtersConfig.find(obj => obj.key === AIRCRAFT).items = aircraftList;
      })
    );
    this.subs.push(
      this.store.pipe(getSkillsSortedByName, take(1)).subscribe((skillsList: Skill[]) => {
        this.filtersConfig.find(obj => obj.key === SKILLS).items = skillsList;
      })
    );
    this.subs.push(
      this.store.pipe(getWorkShifts, take(1)).subscribe((workShiftList: WorkShiftDto[]) => {
        this.filtersConfig.find(filterConfigModel => filterConfigModel.key === SHIFTS).items = workShiftList;
      })
    );

    if (this.isElaunchNowInstance) {
      this.filtersConfig.push(this.licenseFilter);
      this.subs.push(
        this.store.pipe(getLicenses, take(1)).subscribe((licenses: License[]): void => {
          this.filtersConfig.find(filterConfigModel => filterConfigModel.key === LICENSES).items = licenses;
        })
      );
    }

    this.subs.push(
      this.store
        .pipe(select(state => (state[STATE_FEATURE_KEY] as AgenciesMainState).ownTechniciansListing))
        .subscribe((state: TechnicianListingState) => {
          if (state !== null) {
            const { page, pageSize, totalElements } = state;
            this.usersList = getEmbeddedResource(state, 'results');
            this.currentPage = page + 1;
            this.pageSize = pageSize;
            this.totalElements = totalElements;
          }
        })
    );
  }

  public ngOnInit() {
    this.watchQueryParams();
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private watchQueryParams(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        const filters: SearchFilters = omitBy(this.parseQueryParamsToFiltersObject(params), isNil) as SearchFilters;
        this.setFiltersValues(filters);
        this.currentPage = Number(params.page) + 1 || 1;
        this.store.dispatch(new GetOwnTechniciansListing(filters, this.currentPage - 1));
      })
    );
  }

  private parseQueryParamsToFiltersObject(params: Params): SearchFilters {
    return {
      term: params.term as string,
      ameType: params.ameType as string,
      ameLicense: params.ameLicense as string,
      aircraft: this.usersService.convertAircraftStringToArray(params.aircraft as string),
      skills: this.convertSkillStringToArray(params.skills as string),
      workShifts: this.convertWorkShiftsStringToArray(params.workShifts as string),
      licenses: params.licenses ? this.convertLicensesStringToArray(params.licenses) : null
    };
  }

  private setFiltersValues(filters: SearchFilters): void {
    this.filtersConfig.forEach(config => {
      config.initialValue = filters[config.key] || null;
    });
    if (!isEmpty(filters.aircraft)) {
      const ids = filters.aircraft.map(aircraft => aircraft.id);
      const aircraftConfig = this.filtersConfig.find(config => config.key === AIRCRAFT);
      aircraftConfig.initialValue = aircraftConfig.items.filter((aircraft: Aircraft) => ids.includes(aircraft.id));
    }
    const ameTitleConfig = this.filtersConfig.find(config => config.key === AME_TYPE_LICENSE);
    if (ameTitleConfig) {
      ameTitleConfig.initialValue = filters.ameType
        ? { ameType: filters.ameType, ameLicense: filters.ameLicense || null }
        : {};
    }
  }

  searchUsers(newValues: SearchValues): void {
    this.router.navigate([], {
      queryParams: {
        ...this.usersService.createFilterValueObject(this.prepareFilter(newValues), null, this.currentPage - 1)
      }
    });
  }

  clearFilters(): void {
    this.router.navigate([], { queryParams: {} });
  }

  private prepareFilter(values: SearchValues): UsersFilterValueModel {
    const result = {
      ...values,
      ameType: values.ameTypeLicense?.ameType,
      ameLicense: values.ameTypeLicense?.ameLicense,
      aircraft: values.aircraft,
      skills: values.skills,
      workShifts: values.workShifts,
      licenses: values.licenses
    };
    delete result.ameTypeLicense;
    return pickBy(result, identity) as unknown as UsersFilterValueModel;
  }

  pageChanged($event: { page: number; itemsPerPage: number }): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { page: $event.page - 1 }
    });
  }

  openProfile(technician: TechnicianProfileModel): void {
    const profile: UserProfile = getEmbeddedResource(technician, 'profile');
    this.router.navigate([`../profile/${profile.userUuid}`], {
      relativeTo: this.activatedRoute
    });
  }

  private convertSkillStringToArray(skillsString: string): Skill[] {
    const ids = this.getIdsFromCommaSeparatedString(skillsString);
    const skills: Skill[] = this.filtersConfig.find(config => config.key === SKILLS).items as Skill[];
    const filteredSkills = skills?.filter(skill => ids?.includes(skill.id));
    return filteredSkills || null;
  }

  private getIdsFromCommaSeparatedString(skillsString: string): number[] {
    return skillsString?.split(',').map(id => Number(id));
  }

  private convertWorkShiftsStringToArray(shiftsString: string): WorkShiftDto[] {
    return this.getWorkShiftDtos()?.filter(workShiftDto =>
      this.getIdsFromCommaSeparatedString(shiftsString)?.includes(workShiftDto.id)
    );
  }

  private convertLicensesStringToArray(licenses: string): License[] {
    const currentLicenseNames = licenses.split(',');
    return this.getLicenses().filter(license => currentLicenseNames.includes(license.name));
  }

  private getWorkShiftDtos(): WorkShiftDto[] {
    return this.filtersConfig.find(filterConfigModel => filterConfigModel.key === SHIFTS).items as WorkShiftDto[];
  }

  private getLicenses(): License[] {
    return this.filtersConfig.find(filterConfigModel => filterConfigModel.key === LICENSES).items as License[];
  }

  private getCommaSeparatedEntityNames(entityList: Array<{ id: number; name: string }>): string {
    if (!entityList || entityList.length === 0) {
      return;
    }
    return entityList.map(entity => entity?.name).join(',');
  }
}
