interface NonIgnorableErrorData {
  httpStatus: number;
  apiCode: string;
}

export class NonIgnorableErrors {
  public static readonly DELETED_FEATURE: NonIgnorableErrorData = { httpStatus: 410, apiCode: 'DELETED_FEATURE' };
  private static readonly ALL_ERRORS: NonIgnorableErrorData[] = [NonIgnorableErrors.DELETED_FEATURE];

  static getApiCode(httpStatus: number): string {
    return NonIgnorableErrors.ALL_ERRORS.find(error => error.httpStatus == httpStatus).apiCode;
  }
}

export class ErrorCodeMessageExtractor {
  public static getErrorMessageFromResponseStatus(responseStatus: number): string {
    return errorMessages.get(NonIgnorableErrors.getApiCode(responseStatus));
  }
  public static getErrorMessageKeyFromErrorCode(errorCode: string): string {
    return errorMessages.get(errorCode) ?? `ERROR_MESSAGES.${errorCode}`;
  }
}

const errorMessages: Map<string, string> = new Map();
errorMessages.set(NonIgnorableErrors.DELETED_FEATURE.apiCode, 'ERROR_MESSAGES.DELETED_FEATURE');
errorMessages.set(
  'AGENCY_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS_ON_BEHALF_OF_TECHNICIANS',
  'ERROR_MESSAGES.TECHNICIAN_DID_NOT_ACKNOWLEDGE_LABOUR_REGULATIONS'
);
errorMessages.set('JOB_OPENING_CALENDAR_COLLISION', 'ERROR_MESSAGES.CONTRACT_CONFLICT');
