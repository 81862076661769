import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { dateToUTCString } from '@libs/shared/helpers/date-utils';

@Pipe({ name: 'shortDateFormat' })
export class ShortDateFormatPipe implements PipeTransform {
  /*
   * The aim of this pipe is to display a UTC date as it is, without time.
   * */
  public transform(value: string | Date): string {
    if (!value) {
      return null;
    }
    let result: DateTime;
    if (typeof value === 'string') {
      result = this.getAbsoluteDateTimeFromString(value);
    } else if (value instanceof Date) {
      result = this.getAbsoluteDateTimeFromDate(value);
    }
    return result.toFormat('dd/MMM/yyyy');
  }

  private getAbsoluteDateTimeFromString(text: string): DateTime {
    // This is done to ignore time before converting the string to an object, otherwise there will be precision errors since days can flip.
    const dateString = text.split('T')[0];
    return DateTime.fromISO(dateString, { zone: 'utc' });
  }

  private getAbsoluteDateTimeFromDate(date: Date): DateTime {
    return this.getAbsoluteDateTimeFromString(dateToUTCString(date));
  }
}
