import { Component, HostBinding, Input } from '@angular/core';
import { OFFER_STATUS_ENUM } from '@libs/shared/constants/offer-type.enum';

@Component({
  selector: 'status-offer-icon',
  templateUrl: './status-offer-icon.component.html',
  styleUrls: ['./status-offer-icon.component.scss']
})
export class StatusOfferIconComponent {
  @Input()
  type: OFFER_STATUS_ENUM = null;

  @HostBinding('class.unseen')
  get unseen(): boolean {
    return this.type === OFFER_STATUS_ENUM.UNSEEN;
  }

  @HostBinding('class.expired')
  get expired(): boolean {
    return this.type === OFFER_STATUS_ENUM.EXPIRED;
  }

  @HostBinding('class.closed')
  get closed(): boolean {
    return this.type === OFFER_STATUS_ENUM.CLOSED;
  }

  @HostBinding('class.private')
  get private(): boolean {
    return this.type === OFFER_STATUS_ENUM.PRIVATE;
  }

  get iconType(): string {
    return this.unseen ? 'visibility' : this.expired ? 'warning' : this.closed ? 'lock' : this.private ? 'key' : null;
  }
}
