import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, of } from 'rxjs';
import {
  AgencyOfferWithContractsLoaded,
  DisableExperienceLetter,
  EnableExperienceLetter,
  FailedToLoadAgencyOfferWithContracts,
  FailedToLoadOfferWithContracts,
  FailedToLoadPermanentOfferWithContracts,
  FailedToNotifyContractEnd,
  LoadOwnTechnicianOfferWithContracts,
  NotifyContractEnd,
  OfferWithContractsLoaded,
  PermanentOfferWithContractsLoaded
} from './request-overview.actions';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import {
  AddTechniciansToPackageContract,
  LoadAgencyOfferWithContracts,
  LoadContract,
  LoadOffer,
  LoadOfferWithContracts,
  LoadPackageOfferAndContractForTechnician,
  LoadPackageOfferWithContracts,
  LoadPermanentOfferWithContracts,
  LoadTechniciansForPackageContract,
  RemoveTechnicianFromPackageContract,
  TechniciansForPackageContractLoaded
} from '@libs/request-overview-common/state/requests-overview.actions';
import { isFixedPrice, isPermanent } from '@libs/shared/models/offer.model';
import { ContractActions } from '@libs/request-overview-common/constants/contract-actions';
import { Contract } from '@libs/shared/models/contract.model';
import { selectActingAs } from '@libs/request-overview-common/state/requests-overview.selectors';
import { isEmpty } from 'lodash-es';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RequestOverviewEffects {
  public loadOfferWithContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadOfferWithContracts),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.loadOfferWithContractsInternal(
          apiRoot,
          new HttpParams({
            fromObject: {
              offerRefNumber: action.refNumber
            }
          })
        );
      })
    )
  );

  public loadPermanentOfferWithContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadPermanentOfferWithContracts),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.loadPermanentOfferWithContractsInternal(
          apiRoot,
          new HttpParams({
            fromObject: {
              offerRefNumber: action.refNumber
            }
          })
        );
      })
    )
  );

  public loadOwnTechnicianOfferWithContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadOwnTechnicianOfferWithContracts),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.loadOfferWithContractsInternal(
          apiRoot,
          new HttpParams({
            fromObject: {
              offerRefNumber: action.refNumber,
              technicianUuid: action.technicianUuid
            }
          })
        );
      })
    )
  );

  public notifyContractEnd$ = createEffect(() =>
    this.actions.pipe(
      ofType(NotifyContractEnd),
      switchMap(action => {
        const url = getUrl(
          action.contract,
          RequestOverviewLinkRel.NotifyContractEnd
        );
        return this.httpClient.get(url).pipe(
          map(() => {
            this.toastService.success('GENERAL.CONTRACT_END');
            return LoadOfferWithContracts({ refNumber: action.offerRefNumber });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToNotifyContractEnd());
          })
        );
      })
    )
  );

  public disableExperienceLetter$ = createEffect(() =>
    this.actions.pipe(
      ofType(DisableExperienceLetter),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) =>
        this.changeExperienceLetter(
          action.contract,
          ContractActions.DisableExperienceLetter,
          actingAs
        )
      )
    )
  );

  public enableExperienceLetter$ = createEffect(() =>
    this.actions.pipe(
      ofType(EnableExperienceLetter),
      withLatestFrom(this.store.select(selectActingAs)),
      switchMap(([action, actingAs]) =>
        this.changeExperienceLetter(
          action.contract,
          ContractActions.EnableExperienceLetter,
          actingAs
        )
      )
    )
  );

  private changeExperienceLetter(
    contract: Contract,
    action:
      | ContractActions.EnableExperienceLetter
      | ContractActions.DisableExperienceLetter,
    actingAs: string
  ) {
    const url = getUrl(contract, RequestOverviewLinkRel.ChangeExperienceLetter);
    return this.httpClient.patch(url, { action, actingAs }).pipe(
      map(() => {
        this.toastService.success('CONTRACTS.SUCCESSFULLY_MODIFIED_CONTRACT');
        return LoadContract({ contract: contract });
      }),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return EMPTY;
      })
    );
  }

  private loadOfferWithContractsInternal(
    apiRoot: ApiRootResource,
    params: HttpParams
  ) {
    const reqUrl = getUrl(apiRoot, ApiRootLinkRel.GetOfferWithContracts)?.split(
      '?'
    )[0];
    return this.httpClient.get(reqUrl, { params }).pipe(
      map(response => OfferWithContractsLoaded({ response })),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return of(FailedToLoadOfferWithContracts());
      })
    );
  }

  private loadPermanentOfferWithContractsInternal(
    apiRoot: ApiRootResource,
    params: HttpParams
  ) {
    const reqUrl = getUrl(
      apiRoot,
      ApiRootLinkRel.GetPermanentOfferWithContracts
    )?.split('?')[0];
    return this.httpClient.get(reqUrl, { params }).pipe(
      map(response => PermanentOfferWithContractsLoaded({ response })),
      catchError(err => {
        this.errorMessageService.handleErrorResponse(err);
        return of(FailedToLoadPermanentOfferWithContracts());
      })
    );
  }

  public loadOffer$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadOffer),
      map(action => {
        const { refNumber } = action.offer;
        if (isFixedPrice(action.offer)) {
          return LoadPackageOfferWithContracts({ refNumber });
        }
        return isPermanent(action.offer) ? LoadPermanentOfferWithContracts({ refNumber }) : LoadOfferWithContracts({ refNumber });
      })
    )
  );

  public loadPackageOfferWithContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadPackageOfferWithContracts),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        const params = new HttpParams({
          fromObject: {
            offerRefNumber: action.refNumber
          }
        });
        const reqUrl = getUrl(
          apiRoot,
          ApiRootLinkRel.GetPackageOfferWithContracts
        )?.split('?')[0];
        return this.httpClient.get(reqUrl, { params }).pipe(
          map(response => {
            return OfferWithContractsLoaded({ response });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToLoadOfferWithContracts());
          })
        );
      })
    )
  );

  public loadPackageOfferAndContractForTechnician = createEffect(() =>
    this.actions.pipe(
      ofType(LoadPackageOfferAndContractForTechnician),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        const params = new HttpParams({
          fromObject: {
            offerRefNumber: action.refNumber
          }
        });
        const reqUrl = getUrl(
          apiRoot,
          ApiRootLinkRel.GetPackageOfferDetailsForTechnician
        )?.split('?')[0];
        return this.httpClient.get(reqUrl, { params }).pipe(
          map(response => {
            return OfferWithContractsLoaded({ response });
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(FailedToLoadOfferWithContracts());
          })
        );
      })
    )
  );

  public loadTechniciansForPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadTechniciansForPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.GetOwnTechnicians
        );
        return this.httpClient.get(reqUrl).pipe(
          switchMap((response: any) => {
            const technicians: any = isEmpty(response)
              ? []
              : getEmbeddedResource(response, 'technicianOutDtoList');
            return [
              TechniciansForPackageContractLoaded({ technicians: technicians })
            ];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  public addTechniciansToPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(AddTechniciansToPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.AddTechnicians
        );
        return this.httpClient
          .patch(reqUrl, { techniciansAccounts: action.technicians })
          .pipe(
            switchMap(() => {
              this.toastService.success('GENERAL.TECHNICIAN_ADDED');
              return [LoadContract({ contract: action.contract })];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public removeTechnicianFromPackageContract$ = createEffect(() =>
    this.actions.pipe(
      ofType(RemoveTechnicianFromPackageContract),
      switchMap(action => {
        const reqUrl = getUrl(
          action.contract,
          RequestOverviewLinkRel.RemoveTechnician
        ).split('?')[0];
        return this.httpClient
          .patch(reqUrl, {
            technicianAccount: action.technician
          })
          .pipe(
            switchMap(() => {
              this.toastService.success('GENERAL.TECHNICIAN_REMOVED');
              return [LoadContract({ contract: action.contract })];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return EMPTY;
            })
          );
      })
    )
  );

  public loadAgencyOfferWithContracts$ = createEffect(() =>
    this.actions.pipe(
      ofType(LoadAgencyOfferWithContracts),
      withLatestFrom(this.store.pipe(getFilteredApiRoot)),
      switchMap(([action, apiRoot]) => {
        return this.loadAgencyOfferWithContractsInternal(
          apiRoot,
          action.refNumber
        );
      })
    )
  );

  private loadAgencyOfferWithContractsInternal(
    apiRoot: ApiRootResource,
    agencyOfferRefNumber: string
  ) {
    const reqUrl = getUrl(apiRoot, ApiRootLinkRel.GetAgencyOfferWithContracts)
      ?.split('{?')[0]
      .replace('{agencyOfferRefNumber}', agencyOfferRefNumber);
    return this.httpClient.get(reqUrl).pipe(
      map(response => AgencyOfferWithContractsLoaded({ response })),
      catchError(response => {
        this.errorMessageService.handleErrorResponse(response);
        return of(FailedToLoadAgencyOfferWithContracts());
      })
    );
  }

  constructor(
    private actions: Actions,
    private store: Store,
    private httpClient: HttpClient,
    private toastService: ToastMessageService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService
  ) {}
}
