import { Component, Input } from '@angular/core';

@Component({
  selector: 'offer-countdown-timer',
  templateUrl: './offer-countdown-timer.html',
  styleUrls: ['./offer-countdown-timer.scss']
})
export class OfferCountdownTimerComponent {
  @Input() public timeLeftString: string;
}
