import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { OfferPriority } from '@libs/shared/models/offer.model';
import { BaseOfferFormModel } from '../../../shared/components/base-offer-form.model';

export interface TechnicianSearchFilters {
  ameType: string;
  aircraft: string;
  ameLicense: string;
  experience: string;
  availability: string;
}

export interface CreateOfferFormModel extends BaseOfferFormModel {
  periodTo: string;
  periodFrom: string;
  hasHiddenRate: boolean;
  allowTcnApplications: boolean;
  locationId: number;
  closeAfterDays: number;
  isPublic: boolean;
  mroUuid?: string;
  chargeRate: number;
  maxChargeRate: number;
  employmentTypeId: number;
}

export interface FormState extends FormGroupState<CreateOfferFormModel> {}

export const DEFAULT_CLOSE_AFTER_DAYS = 28;

export const temporaryJobOpeningInitialFormState = {
  refNumber: null,
  useCustomReference: false,
  title: '',
  shortPresentation: '',
  periodFrom: '',
  periodTo: '',
  vacancies: null,
  ameType: null,
  ameTitleId: null,
  allowTcnApplications: true,
  airplanes: [],
  otherAirplanes: '',
  minExperience: null,
  closeAfterDays: DEFAULT_CLOSE_AFTER_DAYS,
  hasIndicativeRate: false,
  hasHiddenRate: false,
  chargeRate: null,
  maxChargeRate: null,
  locationId: null,
  workPattern: '',
  workDaysOn: null,
  workDaysOff: null,
  isPublic: true,
  additionalBenefits: [],
  priority: OfferPriority.MEDIUM,
  mroUuid: '',
  workShifts: [],
  licenses: [],
  employmentTypeId: null
};
export const createOfferInitialFormState: FormState = createFormGroupState<CreateOfferFormModel>(
  'createOffer',
  temporaryJobOpeningInitialFormState
);
