<div
    *ngIf="contractWithUtils.hasAcceptanceNotes()"
    class="reasons-group acceptance"
>
    <collapsible [heading]="'REASONS_LIST.HEADER' | translate">
        <span>{{ contractWithUtils.getAcceptanceNotes() }}</span>
    </collapsible>
</div>
<div
    *ngIf="contractWithUtils.hasPendingReasons()"
    class="reasons-group warning"
>
    <collapsible [heading]="'REASONS_LIST.REASONS_FOR_QUALITY_PENDING.HEADER' | translate">
        <staffnow-reasons-list-item
            [reason]="pendingReason"
        ></staffnow-reasons-list-item>
    </collapsible>
</div>
<div *ngIf="contractWithUtils.hasProductionReasons()" class="reasons-group">
    <collapsible [heading]="'REASONS_LIST.REASONS_FOR_PRODUCTION_DECLINE.HEADER' | translate">
        <staffnow-reasons-list-item
            *ngFor="let reason of contractWithUtils.getProductionReasons()"
            [reason]="reason"
        ></staffnow-reasons-list-item>
    </collapsible>
</div>
<div *ngIf="contractWithUtils.hasQualityRejectedReasons()" class="reasons-group">
    <collapsible [heading]="'REASONS_LIST.REASONS_FOR_QUALITY_DECLINE.HEADER' | translate">
        <staffnow-reasons-list-item
            *ngFor="let reason of contractWithUtils.getQualityRejectedReasons()"
            [reason]="reason"
        ></staffnow-reasons-list-item>
    </collapsible>
</div>
<div *ngIf="isAgency && contractWithUtils.hasAgencyRejectedReasons()" class="reasons-group">
    <collapsible [heading]="'REASONS_LIST.REASONS_FOR_AGENCY_DECLINE.HEADER' | translate">
        <staffnow-reasons-list-item
            *ngFor="let reason of contractWithUtils.getAgencyRejectedReasons()"
            [reason]="reason"
        ></staffnow-reasons-list-item>
    </collapsible>
</div>
