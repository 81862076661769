<select
    class="custom-select"
    [(ngModel)]="value"
    [ngClass]="{
        'is-valid': valid,
        'is-invalid': invalid
    }"
>
    <option *ngIf="!hasDefaultValue" [ngValue]="null">
        {{ placeholder | translate }}
    </option>
    <option *ngFor="let item of items" [ngValue]="getValue(item)">
        {{ getLabel(item) }}
    </option>
</select>
