@if (!isAgencyOffer && isPrivateOffer) {
    <card-tab-text [text]="'OFFER_DETAILS.PRIVATE_JOB_OPENING' | translate" position="right"></card-tab-text>
}
@if (offer?.isClosed) {
    <card-tab-text
        [text]="'OFFER_DETAILS.CLOSED_FOR_APPLICATIONS' | translate"
        position="right"
        type="danger"
    ></card-tab-text>
}
<staffnow-panel [title]="title">
    <ng-container data>
        <div class="offer-data">
            <div class="offer-data-group">
                <div class="basic-offer-data">
                    <staffnow-contract-info [icon]="'location_on'">
                        {{ offer?.location.name }}
                    </staffnow-contract-info>
                    <staffnow-contract-info *ngIf="isPermanentOffer; else range" [icon]="'date_range'">
                        {{ offer?.startDate | absoluteDateFormat }}
                    </staffnow-contract-info>
                    <ng-template #range>
                        <staffnow-contract-info [icon]="'date_range'">
                            {{ offer?.periodFrom | absoluteDateFormat }} -
                            {{ offer?.periodTo | absoluteDateFormat }}
                        </staffnow-contract-info>
                    </ng-template>

                    <ng-container *ngIf="!isPackageOffer">
                        <staffnow-contract-info [icon]="'av_timer'">
                            {{ (offer.minExperience || 0) + ' year/s of experience' | translate }}
                        </staffnow-contract-info>
                        <staffnow-contract-info [icon]="'build'">
                            {{ offer.ameTitle | ameTitleName }}
                        </staffnow-contract-info>
                        <staffnow-contract-info [icon]="'airplanemode_active'">
                            <staffnow-offer-aircrafts-field [offer]="offer"></staffnow-offer-aircrafts-field>
                        </staffnow-contract-info>
                        @if (isStaffnow) {
                            <staffnow-contract-info [icon]="'work'">
                                <work-pattern [daysOn]="offer.workDaysOn" [daysOff]="offer.workDaysOff"></work-pattern>
                            </staffnow-contract-info>
                        }
                        @if (!isStaffnow && licenseNames) {
                            <staffnow-contract-info [icon]="'library_books'">
                                {{ licenseNames }}
                            </staffnow-contract-info>
                        }
                        @if (workShiftNames) {
                            <staffnow-contract-info [icon]="'access_time'">
                                {{ workShiftNames }}
                            </staffnow-contract-info>
                        }
                        <ng-content select="[data]"></ng-content>
                    </ng-container>
                </div>
            </div>
            <div class="offer-data-group">
                <ng-container *ngIf="!isPackageOffer">
                    <staffnow-offer-info-section [title]="'GENERAL.DESCRIPTION' | translate" [icon]="'description'">
                        <read-more [content]="offer?.shortPresentation"></read-more>
                    </staffnow-offer-info-section>
                </ng-container>
                <offer-countdown-timer [timeLeftString]="offer?.timeLeftString"> </offer-countdown-timer>
            </div>
            <staffnow-offer-info-section
                *ngIf="isPackageOffer"
                [title]="'GENERAL.TECHNICAL_SPECIFICATION' | translate"
                [icon]="'description'"
            >
                <read-more [content]="offer?.specification"></read-more>
            </staffnow-offer-info-section>
        </div>
        @if (certifications?.length > 0) {
            <staffnow-offer-info-section
                [title]="'GENERAL.CERTIFICATION_REQUIREMENTS' | translate"
                [icon]="'workspace_premium'"
            >
                @for (certification of certifications; track certification) {
                    {{ certification }} <br />
                }
            </staffnow-offer-info-section>
        }

        <ng-container *ngIf="!isPackageOffer && !hasIndicativeRateForOffer(offer)">
            <staffnow-offer-info-section
                [title]="'GENERAL.ADDITIONAL_BENEFITS' | translate"
                [icon]="'add_circle_outline'"
            >
                <staffnow-offer-benefit-details [benefits]="offer?.additionalBenefits"></staffnow-offer-benefit-details>
            </staffnow-offer-info-section>
        </ng-container>
    </ng-container>

    <ng-container actions>
        <ng-content select="[actions]"></ng-content>
        @if (!isPermanentOffer) {
            <staffnow-share-offer [offer]="offer"></staffnow-share-offer>
        }
    </ng-container>

    <ng-container footer>
        <div class="footer-wrapper" [ngClass]="{ 'package-offer': isPackageOffer }">
            @if (!facilityProfile.hideMroInformationInJobOffers) {
                <collapsible [heading]="'GENERAL.COMPANY_INFORMATION' | translate">
                    <div class="mro-company">
                        <facility-logo [resource]="facilityProfile" size="large"></facility-logo>
                        <h1 class="name h1">{{ facilityName }}</h1>
                        <staffnow-offer-info-section
                            [title]="facilityLocation"
                            [icon]="'location_on'"
                        ></staffnow-offer-info-section>
                        <staffnow-offer-info-section
                            [title]="'GENERAL.PRESENTATION' | translate"
                            [icon]="'description'"
                        >
                            {{ facilityPresentation }}
                        </staffnow-offer-info-section>
                        @if (facilityChecklist) {
                            <staffnow-offer-info-section
                                [title]="'GENERAL.REQUIREMENTS_LIST' | translate"
                                [icon]="'notification_important'"
                            >
                                {{ facilityChecklist }}
                            </staffnow-offer-info-section>
                        }
                    </div>
                </collapsible>
            }
        </div>
    </ng-container>
</staffnow-panel>
