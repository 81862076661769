import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { ModalWindowComponent } from '@libs/common-ui/modal-window/modal-window.component';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { FileUploadService } from '@libs/common-ui/services/file-upload.service';
import { fileUploadConstants } from '@libs/shared/constants/fileupload.constants';
import { isNil } from 'lodash-es';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-file-upload-2',
  templateUrl: './file-upload-2.component.html',
  styleUrls: ['./file-upload-2.component.scss']
})
export class FileUpload2Component implements OnChanges {
  @Input() noDocumentsUploadedDisclaimer: string;
  @Input() canEdit = true;
  @Input() isAdmin = false;
  @Input() multiple = false;
  @Input() allowedFileTypes: string[] = ['pdf', 'png', 'jpg', 'jpeg'];
  @Input() fileSizeLimit: number = 25;
  @Input() maximumFilesLimit: number = 1;
  @Input() documents: any[] = [];
  @Input() isMandatory: boolean = false;
  @Input() documentName: string = 'New Document';
  @Input() tooltip: string = 'FILES.DEFAULT_CONTRACT_TOOLTIP';

  @Input() uploadUrl: string = '';
  @Input() isRequired: boolean = false;
  @Input() fileListHeight: string = null;
  @Input() showLabel: boolean = false;

  @Output() public uploadSucceeded = new EventEmitter<void>();
  @Output() public downloaded = new EventEmitter<string>();
  @Output() public fileDeleted = new EventEmitter<any>();
  @Output() public fileUploadCanceled: EventEmitter<FileItem> = new EventEmitter();
  @Output() public labelSet = new EventEmitter<string>();

  @ViewChild('modalWindow') private readonly modalWindow: ModalWindowComponent;
  @ViewChild('fileInput') private readonly fileInput: ElementRef;

  hasBaseDropZoneOver = false;
  uploader: FileUploader | undefined;
  isLoading: boolean = false;

  constructor(
    private readonly confirmService: ModalService,
    private readonly toastMessageService: ToastMessageService,
    private readonly uploadService: FileUploadService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translateService: TranslateService
  ) {}

  ngOnChanges(): void {
    if (!isNil(this.uploadUrl)) {
      this.uploadService.configUploader(this.uploadUrl, this.fileSizeLimit, this.allowedFileTypes).then(
        uploader => {
          this.uploader = uploader;
          this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
            if (this.maximumFilesLimit === this.documents.length) {
              this.uploader.cancelItem(fileItem);
              this.uploader.clearQueue();
              this.toastMessageService.fail('FILES.LIMIT_OF_FILES_REACHED');
            }
          };

          this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            const documentInfo = {
              mime: fileItem.file.type
            };

            form.append('document', new Blob([JSON.stringify(documentInfo)], { type: 'application/json' }));
          };

          this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
            this.uploader.queue.forEach((file: FileItem, index: number) => {
              if (file === fileItem) {
                this.uploader.queue.splice(index, 1);
              }
            });

            switch (filter.name) {
              case 'fileSize': {
                const fileSizeLimitBytes = this.fileSizeLimit * 1000000;
                const mime: string = fileItem.rawFile.type;
                if (mime.includes('image')) {
                  const downsizeRate = fileSizeLimitBytes / fileItem.rawFile.size;
                  this.uploadService.downsizeImage(fileItem, this.uploadUrl, downsizeRate, {}, response => {
                    this.handleResizeResponse(response);
                  });
                } else {
                  this.toastMessageService.fail(fileUploadConstants.fileExceededLimit);
                }
                break;
              }
              case 'mimeType':
                this.toastMessageService.fail(fileUploadConstants.fileHasUnsupportedFormat);
                break;
              case 'queueLimit':
                this.toastMessageService.fail(
                  this.translateService.instant(fileUploadConstants.uploaderQueueLimitExceeded, {
                    limit: this.uploader.options.queueLimit
                  })
                );
                break;
              default:
                break;
            }

            if (this.uploader.queue.length === 0) {
              this.fileInput.nativeElement.value = '';
            }
          };

          this.uploader.onSuccessItem = (fileItem: any) => {
            this.isLoading = false;
            this.uploader.queue.forEach((file, i) => {
              if (file === fileItem) {
                this.uploader.queue.splice(i, 1);
              }
            });

            if (this.uploader.queue.length === 0) {
              this.uploadSucceeded.emit();
              this.toastMessageService.success(fileUploadConstants.successfullFileUpload);
              this.fileInput.nativeElement.value = '';
            }
          };

          this.uploader.onBeforeUploadItem = () => {
            this.isLoading = true;
          };

          this.uploader.onCancelItem = () => {
            this.isLoading = false;
          };

          this.uploader.onErrorItem = (fileItem: any, response: string) => {
            this.isLoading = false;
            this.uploader.queue.forEach((file, i) => {
              if (file === fileItem) {
                this.uploader.queue.splice(i, 1);
              }
            });

            const parsedResponse = JSON.parse(response);
            this.errorMessageService.handleErrorResponse(parsedResponse, 'FILES.ERROR_OCCURRED');

            if (this.uploader.queue.length === 0) {
              this.fileInput.nativeElement.value = '';
            }
          };
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  deleteFile(item: any): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.DELETE_DOC', () => {
      this.fileDeleted.emit(item);
    });
  }

  penModalWithAllFiles(): void {
    this.modalWindow.open();
  }

  downloadDocument($event): void {
    this.downloaded.emit($event);
  }

  fileOverBase(event: any): void {
    this.hasBaseDropZoneOver = event;
  }

  private handleResizeResponse(response): void {
    response.subscribe(
      () => {
        this.uploadSucceeded.emit();
        this.toastMessageService.success(fileUploadConstants.successfullFileUpload);
      },
      response => {
        this.errorMessageService.handleErrorResponse(response, 'UPLOAD.IMAGE_FAILED');
      }
    );
  }

  public handleSetLabel($event): void {
    this.labelSet.emit($event);
  }

  get isTooltipEnabled(): boolean {
    return !isNil(this.tooltip) && this.tooltip.length > 0;
  }
}
