import { Component, Input } from '@angular/core';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import { EndContract } from '@libs/request-overview-common/state/requests-overview.actions';
import { getCurrentUTCDateString } from '@libs/shared/helpers/date-utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { Contract } from '@libs/shared/models/contract.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';

@Component({
  selector: 'app-offer-end-date',
  templateUrl: './offer-end-date.component.html'
})
export class OfferEndDateComponent {
  @Input() contract: Contract = null;
  @Input() offerStartDate: string = null;

  constructor(private store: Store, private modalService: BsModalService) {}

  public handleContractEnd(): void {
    this.modalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (endDate: string) =>
          this.store.dispatch(
            EndContract({
              contract: this.contract,
              endDate
            })
          ),
        title: 'GENERAL.END_CONTRACT',
        minDate: this.offerStartDate,
        maxDate: getCurrentUTCDateString(),
        reminders: [
          { label: 'SYSTEM.INFO.END_CONTRACT_REMINDER.LABEL', text: 'SYSTEM.INFO.END_CONTRACT_REMINDER.TEXT' }
        ]
      }
    });
  }

  get hasEndContractLink(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.EndContract);
  }
}
