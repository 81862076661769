import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { BrandName, EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { Observable } from 'rxjs';
import { ITechnicianSource } from '@libs/common-ui/technician-source-selector/technician-source-selector.component';

export interface KnowledgeSource {
  sourceId: string;
  notes?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TechnicianSourceService {
  private backendUrl: string;

  constructor(private httpClient: HttpClient, private store: Store<never>) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.setBackendUrl(env);
    });
  }

  public getTechnicianSources(): Observable<ITechnicianSource[]> {
    return this.httpClient.get<ITechnicianSource[]>(this.backendUrl + '/api/platform/technician-sources');
  }

  updateTechnicianSource(userUuid: string, knowledgeSource: KnowledgeSource): Observable<object> {
    return this.httpClient.post(`${this.backendUrl}/api/platform/technicians/${userUuid}/knowledge-source`, {
      sourceId: knowledgeSource.sourceId,
      notes: knowledgeSource?.notes
    });
  }

  private setBackendUrl(env: EnvironmentState): void {
    this.backendUrl = env.brandConfig?.brandName === BrandName.eLAUNCHNow ? env.usaApiUrl : env.europeApiUrl;
  }
}
