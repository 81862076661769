@if(enabled){
<span
    [tooltip]="isPlatformWeb ? USER_PROFILE_TOOLTIPS.missingData : ''"
    class="alert-icon"
    [ngStyle]="customStyles"
    (click)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
    (keydown.enter)="onTooltipClick(USER_PROFILE_TOOLTIPS.missingData)"
>
    warning
</span>
}
