import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MenuItem } from '@libs/shared/models/menu-item.model';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { AppState } from '../../../../../apps/back-office/src/app/state/app-state.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent {
  @Input() public menuItems: MenuItem[] = [];
  @Input() public isAsideMenuOpen: boolean;
  @Output() public clickOutside = new EventEmitter();

  public brandName: string = null;
  appUrl: string = '';

  constructor(private readonly store: Store<AppState>) {
    this.store.pipe(getStaticEnvironment, untilDestroyed(this)).subscribe(envData => {
      this.brandName = envData.brandConfig.brandName.toLowerCase();
      this.appUrl = envData.brandConfig.appUrl;
    });
  }

  public closeMenuOnSelectItem(): void {
    if (this.isAsideMenuOpen) {
      this.clickOutside.emit();
    }
  }

  protected readonly isPlatformWeb: boolean = isPlatformWeb();
}
