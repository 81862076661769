<multiple-options-select
    *ngIf="filterConfig.multiple; else singleSelect"
    class="enum-filter"
    [placeholder]="filterConfig.placeholder | translate"
    [elements]="filterConfig.items"
    [selectedElements]="value"
    [searchable]="true"
    [disabled]="filterConfig.disabled"
    [bindLabel]="filterConfig.bindLabel"
    [labelForId]="filterConfig.labelForId"
    (valueChanged)="handleChange($event)"
></multiple-options-select>
<ng-template #singleSelect>
    <select
        [disabled]="filterConfig.disabled"
        class="form-control custom-select enum-filter"
        (change)="handleChange($event.target.value)"
    >
        <option *ngIf="filterConfig.placeholder" value="null" [selected]="!value">
            {{ filterConfig.placeholder | translate }}
        </option>
        <ng-container *ngFor="let item of filterConfig.items">
            <option *ngIf="filterConfig.isIdText" [value]="item?.value || item.name" [selected]="item.name == value">
                {{ item.name | translate }}
            </option>
            <option *ngIf="!filterConfig.isIdText" [value]="item.id" [selected]="item.id == value">
                {{ item.name | translate }}
            </option>
        </ng-container>
    </select>
</ng-template>
