import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AddArrayControlAction,
  box,
  disable,
  DisableAction,
  enable,
  EnableAction,
  FormGroupState,
  MarkAsDirtyAction,
  MarkAsPristineAction,
  RemoveArrayControlAction,
  SetValueAction,
  unbox
} from 'ngrx-forms';

import { ALL_ADMIN_ROLES, MRO_ROLES } from '@back-office/shared/config/roles';
import { AccountFormConfig } from '../../../model/account-form.config';
import { ACCOUNT_FORM_ID, AccountFormModel } from './account-form.model';
import { SubmitProfileChanges, UpdateBackofficeNotes } from '../../../state/user-profile.actions';
import { selectAccountForm, selectProfileLoading } from '../../../state/user-profile.selectors';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { DocumentsService } from '../../../services/documents.service';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { UserProfileTooltips } from '@libs/shared/models/user-profile.tooltips';
import {
  getAirplanesSortedByName,
  getCountries,
  getLanguagesSortedByName,
  getLicenses,
  getLoggedInUserRole,
  getNationalities
} from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource, getUrl, hasEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { accountStatusConstants } from '@libs/shared/constants/statuses.constants';
import { UserRolesUtils } from '@libs/shared/models/roles.enum';
import { AbstractProfileComponent } from '@libs/user-profile/components/abstract-profile-component';
import { UserProfileService } from '@libs/user-profile/services/user-profile.service';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { Language } from '@libs/shared/models/language.model';
import { AmeLicenseType } from '@libs/shared/models/ame-license-type.model';
import { Country } from '@libs/shared/models/country.model';
import { WorkShiftDto } from '@libs/shared/models/work-shift-dto.model';
import { UserPhone } from '@libs/shared/models/user-phone.model';
import { DateTime } from 'luxon';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { dateTimeFromString, dateToUTCString } from '@libs/shared/helpers/date-utils';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';
import { License } from '@libs/shared/models/license.model';
import { ContractTypeDto } from '@libs/shared/models/contract-type-dto.model';
import { Nationality } from '@libs/shared/models/Nationality';
import { LocationPO } from '@libs/shared/models/location.model';
import { getLocations } from '@libs/shared/bms-common/environment/environment.selector';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'staffnow-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends AbstractProfileComponent {
  private static readonly TITLES_REQUIREMENTS_TAB = 'titles-requirements';
  private static readonly TITLES_REQUIREMENTS_ITEM = 0;
  private static readonly SHORT_PRESENTATION_PENDING_CHANGE_CONTEXT = 'TECHNICIAN';
  private static readonly SHORT_PRESENTATION_PENDING_CHANGE_SUBJECT = 'presentation';

  public USER_PROFILE_TOOLTIPS = UserProfileTooltips;

  @Input() technicianPendingChanges: Array<any> = [];
  @Input() selectedAircraftList: Array<any> = [];
  selectedLanguages: Array<Language> = [];
  selectedLicenses: Array<License> = [];
  selectedNationalities: Array<Nationality> = [];
  accountForm: Observable<FormGroupState<AccountFormModel>>;
  accountFormValue: FormGroupState<AccountFormModel>;

  maxDate: string;
  aircraftList: Array<any>;
  languageList: Array<Language>;
  licenses: Array<License>;
  countries: Array<Country>;
  counter = 0;
  textAreaMaxLength = 250;
  profileIsLoading: boolean = false;
  workShiftList: WorkShiftDto[] = [];
  preferredWorkShifts: WorkShiftDto[] = [];
  contractTypeList: ContractTypeDto[] = [];
  preferredContractTypes: ContractTypeDto[] = [];
  preferredPayRangeMin: number = 0;
  preferredPayRangeMax: number = 0;
  nationalities: Nationality[];
  locations: LocationPO[];
  geographicPreferences: LocationPO[];
  availabilityOptions: any[] = [
    { value: 'IMMEDIATE', name: 'Immediately' },
    { value: 'IN_30_DAYS', name: 'In 30 days or later' },
    { value: 'IN_60_DAYS', name: 'In 60 days or later' }
  ];

  localDateNgrxValueConverter = {
    convertViewToStateValue: (value: Date): string => {
      return dateToUTCString(value);
    },
    convertStateToViewValue: (state: string): Date => {
      return state ? dateTimeFromString(state).toJSDate() : null;
    }
  };

  private application: string = null;
  private loggedInUserRole: string = null;
  public backofficeNotes: string = null;

  @ViewChild('firstNameInput') public firstNameInputEl: ElementRef;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  public readonly FIRST_NAME = 'firstName';
  public readonly LAST_NAME = 'lastName';
  public readonly COUNTRY = 'country';
  public readonly ZIP_CODE = 'zipCode';
  public readonly STATE = 'state';
  public readonly CITY = 'city';
  public readonly NATIONALITY = 'nationality';
  public readonly USER_PHONE = 'userPhone';
  public readonly STREET = 'street';
  public readonly AME_TITLE = 'ameTitle';
  public readonly AIRPLANES = 'airplanes';
  public readonly OTHER_AIRPLANES = 'otherAirplanes';
  public readonly EXPERIENCE = 'experience';
  public readonly SHORT_PRESENTATION = 'presentation';
  public readonly WORK_SHIFTS = 'workShifts';
  public readonly PAY_RANGE = 'payRange';
  public readonly PREFERRED_CONTRACT_TYPES = 'contractTypes';
  public readonly GEOGRAPHIC_PREFERENCES = 'geographicPreferences';
  public readonly AVAILABILITY_PREFERENCES = 'preferredAvailability';

  constructor(
    store: Store<any>,
    documentsService: DocumentsService,
    activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmationService: ModalService,
    private readonly userService: UserProfileService,
    private readonly toastMessageService: ToastMessageService
  ) {
    super(store, documentsService, activatedRoute);
    this.maxDate = DateTime.now().minus({ year: 18 }).toSQLDate();
    this.store.pipe(getLoggedInUserRole, take(1)).subscribe(userRole => (this.loggedInUserRole = userRole));
    this.accountForm = store.pipe(select(selectAccountForm));
    this.accountForm.subscribe(value => {
      this.accountFormValue = value;
    });
    this.store.pipe(getNationalities, take(1)).subscribe(nationalities => (this.nationalities = nationalities));
    this.store.pipe(getAirplanesSortedByName, take(1)).subscribe(aircraftList => (this.aircraftList = aircraftList));
    this.store.pipe(getLanguagesSortedByName, take(1)).subscribe(languages => (this.languageList = languages));
    this.store.pipe(getLicenses, take(1)).subscribe(licenses => (this.licenses = licenses));
    this.store.pipe(getCountries, take(1)).subscribe(countries => (this.countries = countries));
    this.store.pipe(getLocations, untilDestroyed(this)).subscribe(locations => (this.locations = locations));
    this.subscriptions.add(
      this.store
        .pipe(select(selectProfileLoading))
        .subscribe(profileLoading => (this.profileIsLoading = profileLoading))
    );

    this.onCanEditChange(() => this.disableReadonlyFields());
    this.onUserProfileChange(() => {
      this.updatePresentationCharactersCount();
      if (this.userProfile?.languages) {
        this.selectedLanguages = this.languageList.filter(
          language => this.userProfile.languages.indexOf(language.id) >= 0
        );
      }
      if (this.userProfile?.licenses) {
        this.selectedLicenses = this.licenses.filter(license => this.userProfile.licenses.indexOf(license.id) >= 0);
      }
      if (this.userProfile?.nationalities?.length) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        this.selectedNationalities = [...this.userProfile?.nationalities];
      }
      if (hasEmbeddedResource(this.userProfile, UserProfileLinkRel.WorkShiftOptions)) {
        this.workShiftList = getEmbeddedResource(this.userProfile, UserProfileLinkRel.WorkShiftOptions);
        this.preferredWorkShifts = this.workShiftList.filter(
          shift => this.userProfile.preferredWorkShifts.indexOf(shift.id) >= 0
        );
      }
      if (hasEmbeddedResource(this.userProfile, UserProfileLinkRel.ContractTypeOptions)) {
        this.contractTypeList = getEmbeddedResource(this.userProfile, UserProfileLinkRel.ContractTypeOptions);
        this.preferredContractTypes = this.contractTypeList.filter(
          contractType => this.userProfile.preferredContractTypes.indexOf(contractType.id) >= 0
        );
      }
      this.preferredPayRangeMax = this.userProfile?.preferredPayRangeMax;
      this.preferredPayRangeMin = this.userProfile?.preferredPayRangeMin;
      if (hasEmbeddedResource(this.userProfile, UserProfileLinkRel.GeographicPreferences)) {
        this.geographicPreferences = getEmbeddedResource(this.userProfile, UserProfileLinkRel.GeographicPreferences);
        this.geographicPreferences = this.locations.filter(
          location => this.userProfile.geographicPreferences.indexOf(location.id) >= 0
        );
      }
      this.fillInForm();
    });
  }

  ngOnInit() {
    // This ngOnInit call needs to be after onUserProfileChange and onCanEditChange functions init
    super.ngOnInit();
    if (this.activatedRoute.snapshot.data.application) {
      this.application = this.activatedRoute.snapshot.data.application;
    }
  }

  get canSubmitBackofficeNotes(): boolean {
    return !isNil(this.backofficeNotes) && !isEqual(this.backofficeNotes, this.userProfile.backofficeNotes);
  }

  get ameNomenclature(): AmeLicenseType[] {
    if (hasEmbeddedResource(this.userProfile, UserProfileLinkRel.AmeNomenclature)) {
      return getEmbeddedResource(this.userProfile, UserProfileLinkRel.AmeNomenclature);
    }
    return [];
  }

  get isUserDeleted() {
    return getEmbeddedResource(this.userProfile, 'profile')['accountStatus'] === accountStatusConstants.deleted;
  }

  get hasShortPresentationChanged(): boolean {
    return (
      !!this.technicianPendingChanges &&
      this.technicianPendingChanges.filter(
        it =>
          it['context'] === AccountComponent.SHORT_PRESENTATION_PENDING_CHANGE_CONTEXT &&
          it['subject'] === AccountComponent.SHORT_PRESENTATION_PENDING_CHANGE_SUBJECT
      ).length > 0
    );
  }

  get isTechnician(): boolean {
    return UserRolesUtils.isTechnician(this.loggedInUserRole);
  }

  get isEurope(): boolean {
    return this.apiRegion == AppRegionEnum.EU;
  }

  get contactNumberReadOnly(): string {
    if (this.userProfile.userPhone) {
      const phone: UserPhone = this.userProfile.userPhone;
      const country = this.countries.find(country => country.id === phone.countryId);
      return `+${country.phoneCode} ${phone.phoneNumber}`;
    }
    return '';
  }

  openTitlesRequirementsSectionTitlesRequirements() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/help`], {
        queryParams: {
          tab: AccountComponent.TITLES_REQUIREMENTS_TAB,
          item: AccountComponent.TITLES_REQUIREMENTS_ITEM
        }
      })
    );
    window.open(url, '_blank');
  }

  userProfileIsLoaded(): boolean {
    return this.userProfile._embedded.profile.userUuid;
  }

  updateBackofficeNotes(): void {
    this.store.dispatch(
      UpdateBackofficeNotes({
        notes: this.backofficeNotes,
        url: getUrl(this.userProfile, UserProfileLinkRel.UpdateBackofficeNotes)
      })
    );
  }

  trackById = (index, control) => (control.value ? control.value.type + '-' + control.value.license : index);

  updatePresentationCharactersCount() {
    const inputTextLength = this.accountFormValue.controls.presentation?.value?.length ?? 0;
    if (inputTextLength <= this.textAreaMaxLength) {
      this.counter = inputTextLength;
    }
  }

  setDefaultUser($event): any {
    $event.preventDefault();

    this.confirmationService.openConfirmModal('SYSTEM.INFO.DEFAULT_USER', () => {
      this.store.dispatch(new SetValueAction(this.accountFormValue.controls.onlyDefaultSet.id, true));
      this.store.dispatch(new SetValueAction(this.accountFormValue.controls.defaultUser.id, true));
      this.store.dispatch(
        SubmitProfileChanges({
          formValue: this.accountFormValue.value,
          profileSelfLink: this.userProfile._links.self
        })
      );
    });
  }

  setListOfNationalities(selectedItemsList: Array<Nationality>): void {
    const control = this.accountFormValue.controls['nationalities'];
    this.store.dispatch(
      new SetValueAction(
        control.id,
        selectedItemsList.map(i => box(i))
      )
    );
    this.store.dispatch(new MarkAsDirtyAction(control.id));
  }

  setListOfItems(selectedItemsList: Array<any>, controlName: string): void {
    const control = this.accountFormValue.controls[controlName];
    this.store.dispatch(
      new SetValueAction(
        control.id,
        selectedItemsList.map(item => item.id)
      )
    );
    this.store.dispatch(new MarkAsDirtyAction(control.id));
  }

  addNewAmeTitle(): void {
    this.store.dispatch(new AddArrayControlAction(this.accountFormValue.controls.ameTitles.id, null));
  }

  removeAmeTitle(index: number): void {
    this.store.dispatch(new RemoveArrayControlAction(this.accountFormValue.controls.ameTitles.id, index));
  }

  updateUserProfile(): void {
    this.store.dispatch(
      SubmitProfileChanges({
        formValue: unbox(this.accountFormValue.value),
        profileSelfLink: this.userProfile._links.self //TODO(SN-975): THIS PARAMETER IS IGNORED!!
      })
    );
  }

  getUserRoleList(): Array<{ name; serverName }> {
    let result = null;
    if (UserRolesUtils.isMro(this.userProfileRole)) {
      result = MRO_ROLES;
    } else if (UserRolesUtils.isAdminOrModerator(this.userProfileRole)) {
      result = ALL_ADMIN_ROLES;
    }
    return result;
  }

  canEditRoles(): boolean {
    if (UserRolesUtils.isModerator(this.loggedInUserRole) && UserRolesUtils.isMro(this.userProfileRole)) {
      return true;
    }
    return UserRolesUtils.isAdmin(this.loggedInUserRole) && this.isMroOrAdminProfile(this.userProfileRole);
  }

  isAttributeMissing(context: string): boolean {
    return this.documentsService.isDocumentMissing(context);
  }

  isPhoneNumberRequired(): boolean {
    return !this.isEurope;
  }

  onTooltipClick(tooltipMessage: string) {
    if (!isPlatformWeb()) {
      this.toastMessageService.info(tooltipMessage);
    }
  }

  canEditIsTcn(): boolean {
    return this.isEurope && this.isAdminOrModeratorAccessingTechinicanProfile();
  }

  canEditLicenses(): boolean {
    return !this.isEurope && UserRolesUtils.isTechnician(this.userProfileRole);
  }

  onSelectedCountryChange() {
    this.store.dispatch(new SetValueAction(this.accountFormValue.controls.state.id, ''));
  }

  isUserProfileTechnicianRole(): boolean {
    return UserRolesUtils.isTechnician(this.userProfileRole);
  }

  canAccessAdditionalProfileSections(): boolean {
    return (
      this.isTechnician ||
      this.isAdminOrModeratorAccessingTechinicanProfile() ||
      this.isAgencyManagingOwnTechnicianProfile()
    );
  }

  isAdminOrModeratorAccessingTechinicanProfile(): boolean {
    return UserRolesUtils.isAdminOrModerator(this.loggedInUserRole) && this.isUserProfileTechnicianRole();
  }

  private isAgencyManagingOwnTechnicianProfile(): boolean {
    return this.loggedInRoleIsAgency && this.isOwnTechnician;
  }

  private fillInForm(): void {
    //TODO(SN-989): find a better way to fix this
    if (this.userProfileIsLoaded()) {
      this.store.dispatch(
        new SetValueAction(
          ACCOUNT_FORM_ID,
          this.userService.parseUserProfileToFormModel(this.userProfile, this.apiRegion)
        )
      );
      this.backofficeNotes = cloneDeep(this.userProfile.backofficeNotes);
      this.store.dispatch(new MarkAsPristineAction(ACCOUNT_FORM_ID));
      //TODO(SN-989): find a better way to fix this (the delay is necessary because "accountFormValue" takes some time to be updated
      setTimeout(() => this.disableReadonlyFields(), 10);
    }
  }

  private disableReadonlyFields(): void {
    if (this.canEdit) {
      this.enableAllFields();
      this.getReadOnlyFields().forEach(key => this.disableFormControl(key));
    } else {
      this.disableAllFields();
    }
  }

  private disableAllFields() {
    Object.keys(this.accountFormValue.value).forEach(key => (key !== 'state' ? this.disableFormControl(key) : noop()));
  }

  private disableFormControl(formControlKey) {
    const formControl = this.accountFormValue.controls[formControlKey];
    this.store.dispatch(new DisableAction(formControl.id));
    disable(formControl);
  }

  private getReadOnlyFields() {
    return AccountFormConfig[this.application]?.[this.userProfileRole]?.readonlyFields ?? [];
  }

  private enableAllFields(): void {
    Object.keys(this.accountFormValue.value).forEach(key => this.enableFormControl(key));
  }

  private enableFormControl(formControlKey) {
    //TODO: Form control handles its own state if country has no states, we should look into this
    if (formControlKey != 'state') {
      const formControl = this.accountFormValue.controls[formControlKey];
      this.store.dispatch(new EnableAction(formControl.id));
      enable(formControl);
    }
  }

  private isMroOrAdminProfile(profileRole): boolean {
    return UserRolesUtils.isMro(profileRole) || UserRolesUtils.isAdminOrModerator(profileRole);
  }

  getStylesForFirstNameWarning(): { [key: string]: string } {
    return {
      transform: 'translateY(-66%)',
      left: '17px'
    };
  }

  getStylesForLastNameWarning(): { [key: string]: string } {
    return {
      left: '17px',
      top: '44%'
    };
  }

  getStylesForShortPresentationWarning(): { [key: string]: string } {
    return {
      transform: 'translateY(-221%)'
    };
  }

  getStylesForUserPhoneWarning(): { [key: string]: string } {
    return {
      transform: 'translateY(-47%)'
    };
  }

  addMissingDataStyleIfAttributeIsMissing(attribute: string): { 'missing-data': boolean } {
    return {
      'missing-data': this.isAttributeMissing(attribute)
    };
  }
}
