import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FacilityProfileModalComponent } from '@libs/common-ui/facility-profile-modal/facility-profile-modal.component';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { TechnicianProfileModalComponent } from '@libs/common-ui/technician-profile-modal/technician-profile-modal.component';
import { Contract } from '@libs/shared/models/contract.model';
import { TranslateService } from '@ngx-translate/core';
import {getLoggedInUserRole} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {take} from "rxjs/operators";
import {UserRolesUtils} from "@libs/shared/models/roles.enum";
import {Store} from "@ngrx/store";

interface LoadProfile {
  profile: any;
  contract: Contract;
  entityType: EntityTypes;
}

@Injectable({
  providedIn: 'root'
})
export class RequestOverviewModalService {

  private userRole: string = null;

  constructor(
    private modalService: BsModalService,
    private customNavigationService: CustomNavigationService,
    private translateService: TranslateService,
    private store: Store
  ) {
    this.store
      .pipe(getLoggedInUserRole, take(1))
      .subscribe(userRole => (this.userRole = userRole));
  }

  public openProfile(action: LoadProfile): void {
    switch (action.entityType) {
      case EntityTypes.TECHNICIAN:
        if (action.contract.doesUserHaveAccessToTechnician) {
          this.navigateToTechnicianProfile(action);
        } else {
          this.openTechnicianModal(action);
        }
        break;
      case EntityTypes.MRO:
      case EntityTypes.AGENCY:
        this.modalService.show(FacilityProfileModalComponent, {
          ignoreBackdropClick: false,
          class: 'facility-profile-modal',
          initialState: {
            facilityProfile: action.profile
          }
        });
        break;
    }
  }

  private navigateToTechnicianProfile(action: LoadProfile) {
    const path: string[] = [
      'profile',
      getEmbeddedResource(action.profile, UserProfileLinkRel.Profile)[
        'userUuid'
      ]
    ];
    this.customNavigationService.openNewTab(path);
  }

  private openTechnicianModal(action: LoadProfile) {
    const dataProtectionBanner = UserRolesUtils.isAgency(this.userRole) ?
      this.translateService.instant('SYSTEM.INFO.TECHNICIAN_DATE_PROTECTION_BANNER_FOR_AGENCY') :
      this.translateService.instant('SYSTEM.INFO.TECHNICIAN_DATE_PROTECTION_BANNER');
    this.modalService.show(TechnicianProfileModalComponent, {
      ignoreBackdropClick: true,
      class: 'technician-profile-modal modal-center',
      initialState: {
        technician: action.profile,
        isBackoffice: false,
        dataProtectionBanner: dataProtectionBanner
      }
    });
  }
}
