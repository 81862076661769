<table class="table" *ngIf="!documentsIsEmpty">
    <thead>
        <tr>
            <th class="small-column">#</th>
            <th>{{ 'FILES.FILENAME' | translate }}</th>
            <th *ngIf="showLabel">{{ 'FILES.LABEL' | translate }}</th>
            <th>{{ 'FILES.UPLOAD_DATE' | translate }}</th>
            <th *ngIf="anyDocumentRequiresExpirationDate">
                {{ 'FILES.EXPIRY' | translate }}
            </th>
            <th *ngIf="isAdmin" class="action small-column">{{ 'GENERAL.VALIDITY' | translate }}</th>
            <th *ngIf="!isReadOnly" class="action small-column">
                {{ 'GENERAL.ACTION' | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <!-- Already uploaded files template -->
        <tr
            *ngFor="let item of documents; let i = index"
            [tooltip]="item.rejected ? ('FILES.DECLINED_DATA_TOOLTIP' | translate) : ''"
            class="uploadedItem"
            [ngClass]="{ rejected: item.rejected }"
        >
            <td>{{ i + 1 }}</td>
            <td class="item-filename">
                <a (click)="downloadDocument(item)" [title]="item?.name">
                    {{ item?.name }}
                </a>
            </td>
            <td class="item-filename" *ngIf="showLabel">
                <a (click)="downloadDocument(item)" [title]="item?.label">
                    {{ item?.label }}
                </a>
            </td>
            <td class="item-filename" [title]="item?.createdOn | absoluteDateFormat">
                {{ item?.createdOn | absoluteDateFormat }}
            </td>
            <td
                *ngIf="item?.expirationDate"
                [title]="item?.expirationDate | absoluteDateFormat"
                class="item-filename"
                [ngClass]="{
                    'expiry-danger': isExpired(item.expirationDate),
                    'expiry-warning': shouldDisplayWarning(item.expirationDate)
                }"
            >
                {{ item?.expirationDate | absoluteDateFormat }}
            </td>
            <td *ngIf="!item?.expirationDate && item.isExpirationDateRequired" class="item-filename">
                {{ 'FILES.UNSET' | translate }}
            </td>
            <ng-container *ngIf="!isReadOnly">
                <td *ngIf="isAdmin" class="review-action">
                    <staffnow-tri-state-switch
                        *ngIf="canEdit"
                        [rejected]="item.rejected"
                        [pendingChange]="item.pendingChange"
                        (selectedValue)="handleDocumentStatusChange($event, item, item.fileUrl)"
                    >
                    </staffnow-tri-state-switch>
                </td>
                <td class="action-container">
                    <borderless-button
                        (onClick)="removeFile(item)"
                        *ngIf="canEdit"
                        tooltip="{{ 'FILES.REMOVE_FILE' | translate }}"
                        triggers="mouseenter:mouseout"
                        placement="top"
                        icon="delete_forever"
                        actionType="danger"
                        size="small"
                    ></borderless-button>
                    <borderless-button
                        *ngIf="canSetExpirationDate(item)"
                        (onClick)="openExpirationDateDialog(item)"
                        tooltip="{{ 'FILES.SET_EXP_DATE' | translate }}"
                        triggers="mouseenter:mouseout"
                        placement="top"
                        icon="event"
                        size="small"
                    ></borderless-button>
                    <borderless-button
                        class="edit"
                        *ngIf="canSetLabel(item)"
                        (onClick)="openSetLabelDialog(item)"
                        tooltip="{{ 'FILES.SET_LABEL' | translate }}"
                        triggers="mouseenter:mouseout"
                        placement="top"
                        icon="edit"
                        size="small"
                    ></borderless-button>
                </td>
            </ng-container>
        </tr>

        <!-- Currently uploading files template  -->
        <ng-container *ngFor="let item of uploader?.queue">
            <tr [ngClass]="{ uploading: item.progress !== 100 }">
                <td></td>
                <td class="item-filename">
                    {{ item?.file?.name }}
                </td>
                <td class="action">
                    @if (item.progress !== 100 && !item.isCancel) {
                    <borderless-button
                        (onClick)="cancelFileUpload(item)"
                        tooltip="{{ 'FILES.CANCEL_FILE_UPLOAD' | translate }}"
                        triggers="mouseenter:mouseout"
                        placement="top"
                        icon="clear"
                    ></borderless-button>
                    }
                </td>
            </tr>
            <tr>
                @if (!item.isCancel) {
                <td class="progress-row" colspan="3">
                    <div class="progress">
                        <progressbar
                            class="progress-bar"
                            [ngStyle]="{
                                width: item.progress + '%'
                            }"
                        ></progressbar>
                    </div>
                </td>
                }
            </tr>
        </ng-container>
    </tbody>
</table>
<div *ngIf="documentsIsEmpty" class="empty-documents-section" [ngClass]="{ 'file-over': isFileOver }">
    <div>
        <p class="text-muted">
            {{ noDocumentsUploadedDisclaimer | translate }}
            <br />
            {{ fileUploadConstants.limit + maximumFilesLimit }}
        </p>
    </div>
</div>
