<div class="circular-progress-container">
    <svg class="circular-progress" width="50" height="50" viewBox="0 0 50 50">
        <circle class="background-circle" cx="25" cy="25" r="22" stroke-width="6" />
        <circle
            class="progress-circle"
            cx="25"
            cy="25"
            r="22"
            stroke-width="6"
            [attr.stroke-dasharray]="circumference"
            [attr.stroke-dashoffset]="unfilledCircle"
            [ngStyle]="{ stroke: color }"
            transform="rotate(-90 25 25)"
        />
        <text class="percentage-text" x="50%" y="50%" text-anchor="middle" alignment-baseline="middle">
            {{ Math.round(percentage) }}%
        </text>
    </svg>
</div>
