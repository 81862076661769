@if (sources$ | async; as sources) {
<div class="modal-header form">
    <h3 class="modal-title pull-left">{{ 'GENERAL.QUESTION.HOW_DID_YOU_FIRST_HEAR' | translate }}</h3>
</div>
<div class="modal-body">
    @for (source of sources; track source.id) {
    <div class="form-check">
        <input class="form-check-input" type="radio" [value]="source.id" [id]="source.name" [(ngModel)]="id" />
        <label class="form-check-label" [for]="source.name"> {{ source.name }} </label>
    </div>
    <div class="selector">
        @if (source.name === 'Aviation company' && id == KNOWLEDGE_AVIATION_SOURCE_ID) {
        <mro-selector
            [(selectedMroUuid)]="mro.sourceId"
            [loadMros]="loadMroList.bind(this)"
            (selectedMroName)="updateNote($event)"
        ></mro-selector>
        } @if (source.name === 'Agency' && id == KNOWLEDGE_AGENGY_SOURCE_ID) {
        <staffnow-agency-selector
            [(selectedAgencyUuid)]="agency.sourceId"
            [loadAgencies]="loadAgencyList.bind(this)"
            (selectedAgencyName)="updateNote($event)"
        ></staffnow-agency-selector>
        }
    </div>
    }
</div>
<div class="d-flex justify-content-end">
    <general-button
        text="{{ 'GENERAL.CONFIRM' | translate }}"
        [isDisabled]="isDisableAcceptHandler(id)"
        (onClick)="acceptHandler()"
        [size]="'small'"
    ></general-button>
    <general-button
        class="ml-2"
        text="{{ 'GENERAL.ASK_ME_LATER' | translate }}"
        color="secondary"
        (onClick)="closeModal()"
        [size]="'small'"
    ></general-button>
</div>
} @else{
<div class="splash-screen-container">
    Loading
    <staff-loader customClass="splash-screen-loader"></staff-loader>
</div>
}
