<ng-select
    (change)="emitChange()"
    [(ngModel)]="selectedElements"
    [bindLabel]="bindLabel"
    [clearSearchOnAdd]="true"
    [closeOnSelect]="closeOnSelect"
    [disabled]="disabled"
    [items]="elements"
    [labelForId]="labelForId"
    [multiple]="true"
    [ngModelOptions]="{ standalone: true }"
    [placeholder]="placeholder | translate"
    [searchable]="searchable"
    [ngClass]="{
        'is-valid': valid,
        'is-invalid': invalid,
        'missing-data-warning': missingDataWarning
    }"
>
    <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
        <div class="custom-control custom-checkbox">
            <input class="custom-control-input" [checked]="item$.selected" id="item-{{ index }}" type="checkbox" />
            <label
                class="ng-option-label custom-control-label"
                for="item-{{ index }}"
                (click)="toggleItem(item$)"
                (keydown)="toggleItem(item$)"
            >
                {{ item[bindLabel] }}
            </label>
        </div>
    </ng-template>
</ng-select>
