import { Component, Input } from '@angular/core';
import {
  PackageContract,
  PackageContractStatusUtils
} from '@libs/shared/models/package-contract.model';
import { PackageOfferDto } from '@libs/shared/models/offer.model';
import {
  getEmbeddedResource,
  hasEmbeddedResource,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import { Store } from '@ngrx/store';
import { ConversationService } from '../../services/conversation.service';
import {
  AcceptContract,
  AcceptNewPriceForPackageContract,
  DeclineContract,
  ProposeNewPriceForPackageContract
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { PackageContractRejectionComponent } from '@libs/common-ui/package-contract-rejection/package-contract-rejection.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash-es';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { FileListDialogComponent } from '@libs/common-ui/file-upload/file-list-dialog/file-list-dialog.component';
import { DownloadAgencyApprovals } from '@libs/common-ui/facility-profile/facility-profile.actions';
import { StaffnowDocument } from '@libs/user-profile/model/documents.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-package-contract-card',
  templateUrl: './package-contract-card.component.html',
  styleUrls: ['./package-contract-card.component.scss']
})
export class PackageContractCardComponent {
  @Input() public offer: PackageOfferDto = null;
  @Input() public contract: PackageContract = null;

  public agencyApprovals: StaffnowDocument[] = [];

  constructor(
    private store: Store,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private toastService: ToastMessageService,
    private conversationService: ConversationService,
    private customNavigationService: CustomNavigationService,
    private translateService: TranslateService
  ) {}

  get canAcceptContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContract);
  }

  get canDeclineContract(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineContract);
  }

  get canProposePrice(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.ProposePrice);
  }

  get canAcceptPrice(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptPrice);
  }

  get canCreateConversationWithAgency(): boolean {
    return this.conversationService.canCreateConversationWithAgency(
      this.contract
    );
  }

  get canGetPreviousConversationWithAgency(): boolean {
    return this.conversationService.canGetPreviousConversationWithAgency(
      this.contract
    );
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offer.periodTo);
  }

  public initiateMessageWithAgency(): void {
    this.conversationService.initiateMessageWithAgencyForPackageContract(
      this.contract
    );
  }

  public acceptContractMro(): void {
    this.store.dispatch(AcceptContract({ contract: this.contract }));
  }

  public declineContractMro(): void {
    if (this.contractHasWorkers()) {
      this.bsModalService.show(PackageContractRejectionComponent, {
        ignoreBackdropClick: true,
        initialState: {
          onRejectCallback: reason => this.declineContractInternal(reason)
        }
      });
    } else {
      this.declineContractInternal();
    }
  }

  private declineContractInternal(reason?: string): void {
    this.store.dispatch(
      DeclineContract({
        contract: this.contract,
        reasons: [reason]
      })
    );
  }

  public contractHasWorkers(): boolean {
    return this.workers.length > 0;
  }

  get workers(): Array<Resource> {
    return getEmbeddedResource(this.contract, RequestOverviewLinkRel.Workers);
  }

  public openTechnicianProfile(uuid: string): void {
    if (!PackageContractStatusUtils.isRejected(this.contract)) {
      this.customNavigationService.openNewTab(['profile', uuid]);
    } else {
      this.toastService.fail(
        this.translateService.instant('PACKAGE_CONTRACT_CARD.CANNOT_OPEN_TECHNICIAN_PROFILE')
      );
    }
  }

  public proposePrice(): void {
    this.modalService.openNumberPickerDialog(
      this.translateService.instant('GENERAL.COUNTEROFFER'),
      this.translateService.instant('GENERAL.INPUT_NEW_PRICE'),
      number =>
        this.store.dispatch(
          ProposeNewPriceForPackageContract({
            newPrice: number,
            contract: this.contract
          })
        )
    );
  }

  public acceptPrice(): void {
    const price = cloneDeep(this.contract.agencyOfferedPrices).pop();
    this.modalService.openConfirmModal(
      this.translateService.instant('PACKAGE_CONTRACT_CARD.ACCEPT_AGENCY_PRICE', {
        currency: price.currencySymbol,
        amount: price.amount,
      }),
      () =>
        this.store.dispatch(
          AcceptNewPriceForPackageContract({
            contract: this.contract
          })
        )
    );
  }

  public openSeeAgencyApprovalsModal() {
    if (
      hasEmbeddedResource(
        this.contract,
        FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals
      )
    ) {
      this.agencyApprovals = getEmbeddedResource(
        this.contract,
        FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals
      );
    } else {
      this.agencyApprovals = [];
    }
    this.bsModalService.show(FileListDialogComponent, {
      class: 'modal-center modal-big',
      initialState: {
        onDownloadCallback: ($event: never) => this.downloadAgencyApprovals($event),
        documents: this.agencyApprovals,
        showLabel: true,
        title: this.translateService.instant('PACKAGE_CONTRACT_CARD.AGENCY_APPROVALS')
      }
    });
  }

  downloadAgencyApprovals($event: never) {
    this.store.dispatch(DownloadAgencyApprovals({ document: $event }));
  }
}
