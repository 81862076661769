<div class="status-wrapper flex-grow-1" (click)="viewProfile(ENTITY_TYPES.TECHNICIAN)" (keydown.enter)="viewProfile(ENTITY_TYPES.TECHNICIAN)">
    <status-circle [status]="contract?.technicianStatus"></status-circle>
    <span class="view-contr-profile" [ngClass]="{ disabled: !hasTechnicianProfileLink }">
        {{ 'GENERAL.TECHNICIAN' | translate }}
    </span>
</div>
@if(!isAgencyOfferContract){
    <div class="status-wrapper flex-grow-1" (click)="viewProfile(ENTITY_TYPES.MRO)">
        <div class="mro-statuses">
            @if(temporaryJobOfferContract?.requiresQualityAcceptance) {
                <div class="status-with-label">
                    <span class="label"
                        ><strong>{{ 'GENERAL.QUALITY' | translate }}</strong></span
                    >
                    <status-circle
                        [status]="temporaryJobOfferContract?.mroQualityStatus"
                        [customIcon]="temporaryJobOfferContract?.customMroQualityStatusIcon"
                        [shouldSoftenWords]="isUserTechnician"
                    ></status-circle>
                </div>
            }
            @if(temporaryJobOfferContract?.requiresHumanResourcesAcceptance) {
                <div class="status-with-label">
                    <span class="label"
                        ><strong>{{ 'GENERAL.HR' | translate }}</strong></span
                    >
                    <status-circle
                        [status]="temporaryJobOfferContract?.mroHumanResourcesStatus"
                        [customIcon]="temporaryJobOfferContract?.customMroHumanResourcesStatusIcon"
                    ></status-circle>
                </div>
            }
            @if(temporaryJobOfferContract?.requiresProductionAcceptance) {
                <div class="status-with-label">
                    <span class="label"
                    ><strong>{{ 'GENERAL.PRODUCTION' | translate }}</strong></span
                    >
                    <status-circle
                        [status]="temporaryJobOfferContract?.mroStatus"
                        [customIcon]="temporaryJobOfferContract?.customMroStatusIcon"
                    ></status-circle>
                </div>
            }
        </div>
        <span class="view-mro-profile">
            {{ 'GENERAL.AVIATION_COMPANY' | translate }}
        </span>
    </div>
}
@if(isAgencyOfferContract || temporaryJobOfferContract?.requiresAgencyAcceptance) {
    <div
        class="status-wrapper flex-grow-1"
        (click)="(isAgencyOfferContract || isAgencySelected) && viewProfile(ENTITY_TYPES.AGENCY)"
        (keydown.enter)="(isAgencyOfferContract || isAgencySelected) && viewProfile(ENTITY_TYPES.AGENCY)"
    >
        <status-circle [status]="contract?.agencyStatus"></status-circle>
        <span class="view-agency-profile" [ngClass]="{ disabled: !isAgencyOfferContract && !isAgencySelected }">
            {{ 'GENERAL.AGENCY' | translate }}
        </span>
    </div>
}
