import { Component, Input } from '@angular/core';
import { UserProfileTooltips } from '@libs/shared/models/user-profile.tooltips';
import { isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';

@Component({
  selector: 'staffnow-missing-data-warning',
  templateUrl: './missing-data-warning.component.html',
  styleUrl: './missing-data-warning.component.scss'
})
export class MissingDataWarningComponent {
  @Input() enabled: boolean = false;
  @Input() customStyles: { [key: string]: string };
  protected readonly USER_PROFILE_TOOLTIPS = UserProfileTooltips;
  protected readonly isPlatformWeb = isPlatformWeb;
  private readonly toastMessageService: ToastMessageService;

  constructor(toastMessageService: ToastMessageService) {
    this.toastMessageService = toastMessageService;
  }

  onTooltipClick(tooltipMessage: string) {
    if (!isPlatformWeb()) {
      this.toastMessageService.info(tooltipMessage);
    }
  }
}
