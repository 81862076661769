import { Component, inject, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OperatorFunction, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  LoadPackageOfferWithContracts,
  PublicProfileLoaded
} from '@libs/request-overview-common/state/requests-overview.actions';
import { RequestOverviewModalService } from '../../services/request-overview-modal.service';
import {
  AgencyOfferOutDto,
  OfferOutDto,
  PackageOfferDto,
  hasIndicativeRate,
  PermanentOfferDto,
  isFixedPrice
} from '@libs/shared/models/offer.model';
import { selectFeature } from '../../state/request-overview.selectors';
import { RequestOverviewState } from '../../state/request-overview-state.model';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import { ResetOfferDetails } from '../../state/request-overview.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({ template: '' })
export abstract class AbstractOfferDetailsComponent implements OnDestroy {
  public isLoading: boolean = true;
  public offer: OfferOutDto | PackageOfferDto | AgencyOfferOutDto | PermanentOfferDto = null;
  protected userRole: RoleWrapper = null;
  protected subs: Array<Subscription> = [];

  protected store: Store<any>;
  public actions: Actions;
  protected customNavigationService: CustomNavigationService;
  protected translateService: TranslateService;
  protected requestOverviewModalService: RequestOverviewModalService;

  protected constructor() {
    this.store = inject(Store);
    this.actions = inject(Actions);
    this.translateService = inject(TranslateService);
    this.customNavigationService = inject(CustomNavigationService);
    this.requestOverviewModalService = inject(RequestOverviewModalService);

    this.onAction(PublicProfileLoaded, action => this.requestOverviewModalService.openProfile(action));
    this.storeSubscribe(getLoggedInUserRole, (r: UserRoles) => {
      this.userRole = role(r);
    });
    this.storeSubscribe(select(selectFeature), state => this.updateFromState(state));
  }

  get offerDescription(): string {
    return this.offer?.refNumber + (this.offer?.title ? ` ― ${this.offer?.title}` : '');
  }

  get jobOffer(): OfferOutDto {
    return this.offer as OfferOutDto;
  }

  get permanentOffer(): PermanentOfferDto {
    return this.offer as PermanentOfferDto;
  }

  get agencyOffer(): AgencyOfferOutDto {
    return this.offer as AgencyOfferOutDto;
  }

  get agencyOfferHasPayRate(): boolean {
    return !!this.agencyOffer?.payRate;
  }

  get packageOffer(): PackageOfferDto {
    return this.offer as PackageOfferDto;
  }

  protected updateFromState(state: RequestOverviewState) {
    if (state.offerNotFound) {
      this.store.dispatch(ResetOfferDetails());
      this.customNavigationService.goToRouteAfterError(CustomNavigationService.getOffersRoute);
    }
    this.isLoading = state.loading;
    this.offer = state.offer;
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  protected storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void) {
    this.subs.push(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  protected onAction(...args: any[]) {
    const subscribeFn = args.pop();
    this.subs.push(this.actions.pipe(ofType(...args)).subscribe(subscribeFn));
  }

  public hasIndicativeRateForOffer(offer): boolean {
    return hasIndicativeRate(offer);
  }

  public reloadOffer() {
    if (isFixedPrice(this.offer)) {
      this.store.dispatch(
        LoadPackageOfferWithContracts({
          refNumber: this.packageOffer.refNumber
        })
      );
    }
  }
}
