import { EmbeddedResources, Resource } from '@libs/shared/bms-common/rest/resource.model';
import { Rate } from '@libs/shared/models/rate.model';
import { AdditionalBenefit } from '@libs/shared/models/additional-benefit.model';
import { Facility } from '@libs/shared/models/facility.model';
import { AmeTitle } from '@libs/shared/models/ame-title.model';
import { Aircraft } from './aircraft.model';
import { LocationPO } from '@libs/shared/models/location.model';
import { ContractTypeDto } from '@libs/shared/models/contract-type-dto.model';
import { WorkShiftDto } from '@libs/shared/models/work-shift-dto.model';
import { License } from '@libs/shared/models/license.model';

export const CUSTOM_REFERENCE_NUMBER_MAX_LENGTH: number = 11;
export const CUSTOM_REFERENCE_NUMBER_MIN_LENGTH: number = 4;

export enum OfferPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum OfferEditMode {
  FULL = 'FULL',
  RESTRICTED = 'RESTRICTED',
  NOT_ALLOWED = 'NOT_ALLOWED'
}

export enum OfferType {
  AGENCY = 'AGENCY',
  FIXED_PRICE = 'FIXED_PRICE',
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT'
}

export interface JobOpeningFilterType {
  name: string;
  value: OfferType;
}

export const AGENCY_JOB_OPENING: JobOpeningFilterType = {
  name: 'OFFER.AGENCY',
  value: OfferType.AGENCY
};

export const FIXED_PRICE_JOB_OPENING: JobOpeningFilterType = {
  name: 'OFFER.FIXED_PRICE',
  value: OfferType.FIXED_PRICE
};

export const PERMANENT_JOB_OPENING: JobOpeningFilterType = {
  name: 'OFFER.PERMANENT',
  value: OfferType.PERMANENT
};

export const temporaryJobOpeningFilterGetter = () => {
  return {
    name: `OFFER.TEMPORARY_JO_MANPOWER`,
    value: OfferType.TEMPORARY
  };
};

export const isTemporary = (offer: { offerType: OfferType }): boolean => {
  return offer?.offerType === OfferType.TEMPORARY;
};

export const isPermanent = (offer: { offerType: OfferType }): boolean => {
  return offer?.offerType === OfferType.PERMANENT;
};

export const isFixedPrice = (offer: { offerType: OfferType }): boolean => {
  return offer?.offerType === OfferType.FIXED_PRICE;
};

export const isAgencyOffer = (offer: { offerType: OfferType }): boolean => {
  return offer?.offerType === OfferType.AGENCY;
};

export const hasIndicativeRate = (offer: { hasIndicativeRate }): boolean => {
  return !!offer?.hasIndicativeRate;
};

export interface BaseOfferDto extends Omit<Resource, '_embedded'> {
  id: number;
  vacancies?: number;
  workDaysOn: number;
  workDaysOff: number;
  minExperience: number;
  fullyMatchedSize?: number;
  payRate?: Rate;
  hasIndicativeRate: boolean;
  hasHiddenRate: boolean;
  chargeRate: Rate;
  maxChargeRate?: Rate;
  maxPayRate?: Rate;
  minPayRate?: Rate;
  isSeen?: boolean;
  isExpired?: boolean;
  isPublic?: boolean;
  isClosed?: boolean;
  isApproved: boolean;
  allowTcnApplications?: boolean;
  isExactMatch: boolean;
  location: LocationPO;
  uuid: string;
  ameTitle: AmeTitle;
  refNumber: string;
  title?: number;
  shareLink: string;
  mroUserUuid: string;
  otherAirplanes: string;
  shortPresentation: string;
  periodTo: string;
  createdOn: string;
  periodFrom: string;
  additionalBenefits: Array<AdditionalBenefit>;
  certifications: Array<string>;
  offerType: OfferType;
  employmentType: ContractTypeDto;
  matchingScore?: number;
  timeLeftString: string;
}

export interface SharedOfferOutDto extends BaseOfferDto {
  airplanes: Array<string>;
  _embedded:
    | EmbeddedResources
    | {
        facility: {
          name: string;
          location: string;
        };
      };
}

export interface OfferOutDto extends BaseOfferDto {
  editMode?: OfferEditMode;
  airplanes: Array<number>;
  priority: OfferPriority;
  pendingContracts: number;
  allowExperienceLetterRequest: boolean;
  allowTcnApplications: boolean;
  canLoggedInTechnicianApply?: boolean;
  requiresAgencyAcceptance: boolean;
  requiresAgencySelectionForOfferApplication: boolean;
  workShifts: WorkShiftDto[];
  licenses: number[] | License[];
  license?: License;
  _embedded: EmbeddedResources | { facility: Facility };
}

export interface PermanentOfferDto extends BaseOfferDto {
  startDate: Date;
  airplanes: Array<number>;
  allowTcnApplications: boolean;
  monthlySalary: Rate;
  priority: OfferPriority;
  workShifts: WorkShiftDto[];
  closed: boolean;
  _embedded: EmbeddedResources | { facility: Facility };
}

export interface PackageOfferDto extends Omit<Resource, '_embedded'> {
  id: number;
  isSeen?: boolean;
  isClosed?: boolean;
  isApproved?: boolean;
  editMode?: OfferEditMode;
  uuid: string;
  title: string;
  location: LocationPO;
  refNumber: string;
  specification: string;
  priority: OfferPriority;
  periodTo: string;
  periodFrom: string;
  offerType: OfferType;
  shareLink: string;
  fullyMatchedSize: number;
  pendingContracts: number;
  loggedInAgencyCanApply: boolean;
  matchingScore?: number;
  timeLeftString: string;
  _embedded: EmbeddedResources | { facility: Facility };
}

export interface PackageOfferFileDto extends Omit<Resource, '_embedded'> {
  id: number;
  name: string;
  createdOn: string;
  _embedded: EmbeddedResources;
}

export interface AgencyOfferInDto {
  id: number;
  uuid: string;
  packageContractId: number;
  shortPresentation: string;
  ameTitleId: number;
  minExperience: number;
  payRate: Rate;
  maxPayRate: Rate;
  workDaysOn: number;
  workDaysOff: number;
  vacancies: number;
  priority: OfferPriority;
  useCustomReference: boolean;
  additionalBenefits: Array<AdditionalBenefit>;
  airplanes: Array<Aircraft>;
  certifications: Array<string>;
  otherAirplanes: string;
  refNumber: string;
  title: string;
  location: string;
}

export interface AgencyOfferOutDto extends Omit<Resource, '_embedded'> {
  id: number;
  uuid: string;
  packageContractId: number;
  shortPresentation: string;
  minExperience: number;
  hasIndicativeRate: boolean;
  hasHiddenRate: boolean;
  payRate: Rate;
  maxPayRate: Rate;
  workDaysOn: number;
  workDaysOff: number;
  vacancies: number;
  priority: OfferPriority;
  useCustomReference: boolean;
  additionalBenefits: Array<AdditionalBenefit>;
  airplanes: Array<Aircraft>;
  certifications: Array<string>;
  otherAirplanes: string;
  refNumber: string;
  agencyOfferAgencyName: string;
  title: string;
  location: LocationPO;
  isSeen?: boolean;
  isClosed?: boolean;
  offerType: OfferType;
  periodTo: string;
  periodFrom: string;
  fullyMatchedSize: number;
  pendingContracts: number;
  ameTitle: AmeTitle;
  mroUuid: string;
  shareLink: string;
  canLoggedInTechnicianApply?: boolean;
  editMode?: OfferEditMode;
  matchingScore?: number;
  timeLeftString: string;
  _embedded: EmbeddedResources;
}

export interface SharedAgencyOfferOutDto {
  id: number;
  uuid: string;
  shortPresentation: string;
  periodFrom: string;
  periodTo: string;
  vacancies: number;
  minExperience: number;
  workDaysOn: number;
  workDaysOff: number;
  otherAirplanes: string;
  fullyMatchedSize: number;
  createdOn: string;
  refNumber: string;
  title: string;
  isExpired: boolean;
  hasIndicativeRate: boolean;
  hasHiddenRate: boolean;
  additionalBenefits: Array<AdditionalBenefit>;
  certifications: Array<string>;
  ameTitle: AmeTitle;
  isClosed: boolean;
  location: LocationPO;
  airplanes: Array<string>;
  offerType: OfferType;
  _embedded: EmbeddedResources;
}

export interface SharedFixedPriceOfferOutDto {
  title: string;
  refNumber: string;
  specification: string;
  periodTo: string;
  periodFrom: string;
  location: LocationPO;
  offerType: OfferType;
  _embedded: EmbeddedResources;
}

export interface ApplyOfferAsTechnicianPayload {
  offer: OfferOutDto | AgencyOfferOutDto;
  labourRegulationsAcknowledgment?: boolean;
}
