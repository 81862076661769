<div
    class="form-control date-picker"
    [ngClass]="{
        disabled: disabled,
        'is-valid': valid,
        'is-invalid': invalid
    }"
    (click)="openCalendar(datePicker)"
    (keydown.enter)="openCalendar(datePicker)"
>
    @if(value){
    {{ value | absoluteDateFormat }}
    } @else {
    <span>{{ placeholder }}</span>
    }
    <span
        #datePicker="bsDatepicker"
        bsDatepicker
        [bsConfig]="{
            showWeekNumbers: false,
            minMode: mode
        }"
        (bsValueChange)="emitChange($event)"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
    ></span>
</div>
