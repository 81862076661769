<div class="contract-details">
    <staffnow-offer-info-section
        *ngIf="contractWithUtils.hasPendingReasons()"
        [icon]="'visibility'"
        [title]="'GENERAL.MORE_DOCS_NEEDED' | translate"
        mode="warning"
    >
        <staffnow-contract-info [displayBlock]="true">
            {{ contractWithUtils.getPendingReason().explanation }}
        </staffnow-contract-info>
    </staffnow-offer-info-section>
    <staffnow-offer-info-section
        *ngIf="contractWithUtils.hasProductionReasons()"
        [icon]="'report'"
        [title]="'GENERAL.DECLINE_PRODUCTION_REASON' | translate"
        mode="danger"
    >
        <staffnow-contract-info
            *ngFor="let reason of contractWithUtils.getProductionReasons()"
            [danger]="true"
            [displayBlock]="true"
            [label]="reason.name + ': '"
        >
            {{ reason.explanation }}
        </staffnow-contract-info>
    </staffnow-offer-info-section>
    <staffnow-offer-info-section
        *ngIf="contractWithUtils.hasQualityRejectedReasons()"
        [icon]="'report'"
        [title]="'REASONS_LIST.REASONS_QUALITY_REQUESTING_INFORMATION' | translate"
        mode="danger"
    >
        <staffnow-contract-info
            *ngFor="let reason of contractWithUtils.getQualityRejectedReasons()"
            [danger]="true"
            [displayBlock]="true"
            [label]="reason.name + ': '"
        >
            {{ reason.explanation }}
        </staffnow-contract-info>
    </staffnow-offer-info-section>
    <staffnow-offer-info-section
        *ngIf="contractWithUtils.hasAgencyRejectedReasons()"
        [icon]="'report'"
        [title]="'REASONS_LIST.REASONS_FOR_AGENCY_DECLINE.HEADER' | translate"
        mode="danger"
    >
        <staffnow-contract-info
            *ngFor="let reason of contractWithUtils.getAgencyRejectedReasons()"
            [danger]="true"
            [displayBlock]="true"
            [label]="reason.name + ': '"
        >
            {{ reason.explanation }}
        </staffnow-contract-info>
    </staffnow-offer-info-section>
</div>
