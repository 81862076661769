import { createFormStateReducerWithUpdate, FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { CreatePackageOfferFormModel, FormState, initialPackageOfferFormState } from './create-package-offer.model';
import { ResetForm } from './package-offer.actions';
import { Action } from '@ngrx/store';
import {
  getCustomReferenceValidator,
  getShortPresentationValidator,
  getTitleValidator
} from '../../../../shared/validators/offer-form.validator';

export const CREATE_EDIT_PACKAGE_OFFER_FEATURE_KEY = 'createEditPackageOffer';

const updateMyFormGroup = updateGroup<CreatePackageOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  specification: getShortPresentationValidator(),
  preferredStartDate: validate(required),
  preferredEndDate: validate(required),
  locationId: validate(required),
  priority: validate(required),
  title: getTitleValidator()
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createPackageOfferFormReducer(
  state: FormState = initialPackageOfferFormState,
  action: Action
): FormGroupState<CreatePackageOfferFormModel> {
  const form = formReducer(state, action);

  if (action.type === ResetForm.type) {
    return {
      ...initialPackageOfferFormState
    };
  }
  return {
    ...form
  };
}
