<span class="start-date">
    @if(contract?.startDatePhase && !isPendingOrUnset(contract.startDatePhase)) {
    <span
        ><strong>{{ contract.startDate | absoluteDateFormat }}</strong></span
    >
    } @else if(hasProposeStartDateLink(contract)) {
    <general-button
        (onClick)="handleStartDateProposal(contract)"
        size="small"
        backgroundStyle="outline"
        color="{{ datePhaseIsAccepted(contract) ? 'dark' : 'primary' }}"
        text="{{ 'GENERAL.PROPOSE' | translate }} {{ 'TABLES.LABEL.GENERAL_START_DATE' | translate }}"
    ></general-button>
    } @else { - }
</span>
