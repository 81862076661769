import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface Option {
  id: number;
  name: string;
}

@Component({
  selector: 'multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrl: './multi-select-autocomplete.component.scss'
})
export class MultiSelectAutocompleteComponent {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() placeholder: string = 'Introduce an option';
  @Input() options: Option[] = [];
  @Input() selectedOptions: Option[] = [];
  @Input() disabled: boolean = false;
  @Input() valid: boolean = true;
  @Input() invalid: boolean = false;

  @Output() selectionChange = new EventEmitter<Option[]>();

  filteredOptions: Option[] = [];
  searchValue: string = '';

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  get availableOptions(): Option[] {
    return this.options.filter((opt: Option) => !this.selectedOptions.includes(opt));
  }

  onInputChange($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value.toLowerCase();
    this.filteredOptions = this.availableOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    if (this.filteredOptions.length > 0) {
      this.autocomplete.openPanel();
    }
  }

  onFocus() {
    this.filteredOptions = this.availableOptions;
    this.autocomplete.openPanel();
  }

  addSelection(option: Option): void {
    this.selectedOptions = [...this.selectedOptions, option];
    this.selectionChange.emit(this.selectedOptions);
    this.searchValue = '';
    this.filteredOptions = [];
  }

  removeSelection(option: Option): void {
    this.selectedOptions = this.selectedOptions.filter(v => v !== option);
    this.selectionChange.emit(this.selectedOptions);
  }

  addNewOption(event: MatChipInputEvent) {
    const optionName: string = event.value.trim();
    if (!optionName || this.selectedOptions.some(option => option.name === optionName)) return;

    this.selectedOptions = [...this.selectedOptions, { id: null, name: optionName }];
    this.selectionChange.emit(this.selectedOptions);
    this.searchValue = '';
  }
}
