<staffnow-create-offer-banner [facilityProfile]="facilityProfile"></staffnow-create-offer-banner>
<div class="contract-details-container">
    <staffnow-go-back-button [currentPath]="breadcrumbs"></staffnow-go-back-button>
    @if(isSettingInitialValues) {
    <div class="loader-wrapper">
        <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
    </div>
    } @else {
    <div class="create-offer-container form-container">
        <!-- CREATE JOB OPENING FORM -->
        <section class="create-offer-main">
            <div class="profile-container">
                @if(createOfferForm) {
                <form [ngrxFormState]="createOfferForm | async" class="form-body" novalidate>
                    <!-- TYPE AND PRIORITY -->
                    <div class="form-group highlight row create-offer-header">
                        @if(allowPrivateOffers) {
                        <div class="form-group offer-type-selector">
                            <label for="type">
                                {{ 'OFFER.FORM.TYPE.LABEL' | translate }}
                            </label>
                            <br />
                            <button
                                id="type"
                                (click)="setPrivate(false)"
                                [ngClass]="{ 'btn-highlighted': !isPrivate }"
                                class="btn btn-outline-primary mr-4"
                            >
                                @if(!isPrivate) {
                                <span class="material-icons-outlined btn-icon">check_circle</span>
                                } PUBLIC
                            </button>
                            <button
                                (click)="setPrivate(true)"
                                [ngClass]="{ 'btn-highlighted': isPrivate }"
                                class="btn btn-outline-primary"
                            >
                                @if(isPrivate) {
                                <span *ngIf="isPrivate" class="material-icons-outlined btn-icon">check_circle</span>
                                } PRIVATE
                            </button>
                        </div>
                        }
                        <div class="form-group offer-priority-selector">
                            <label for="priority-level" [class.required]="initialFormState.priority == null">
                                {{ 'OFFER.FORM.PRIORITY_LEVEL.LABEL' | translate }}
                            </label>
                            <simple-select
                                id="priority-level"
                                [getLabel]="getLabelFunction"
                                [items]="['HIGH', 'MEDIUM', 'LOW']"
                                [ngrxFormControlState]="createOfferFormValue.controls.priority"
                                [placeholder]="'OFFER.FORM.PRIORITY.PLACEHOLDER' | translate"
                                [valid]="
                                    createOfferFormValue.controls.priority.isValid &&
                                    !createOfferFormValue.controls.priority.isPristine
                                "
                                [invalid]="createOfferFormValue.controls.priority.isInvalid"
                                [hasDefaultValue]="initialFormState.priority != null"
                            ></simple-select>
                            <span class="user-guide">
                                <small class="text-muted">
                                    {{ 'OFFER.FORM.VISIBILITY' | translate }}
                                </small>
                            </span>
                        </div>
                        <!-- AUTOMATIC CLOSE -->
                        <div class="form-group automatic-close-input">
                            <div class="form-group col">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        (change)="toggleAutoClose($event)"
                                        [(ngModel)]="closeAfterDaysIsChecked"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="close-after-days-check"
                                        name="close-after-days-check"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="close-after-days-check">
                                        Close automatically after
                                    </label>
                                </div>
                                <div class="input-group">
                                    <input
                                        (keyup)="clipCloseAfterDays()"
                                        [disabled]="!isCloseAfterDaysEnabled"
                                        [max]="MAXIMUM_CLOSE_AFTER_DAYS"
                                        [min]="MINIMUM_CLOSE_AFTER_DAYS"
                                        [ngrxFormControlState]="createOfferFormValue.controls.closeAfterDays"
                                        autocomplete="off"
                                        class="form-control"
                                        id="close-after-days-input"
                                        type="number"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">days</span>
                                    </div>
                                </div>
                                <span class="user-guide">
                                    <small class="text-muted optional">
                                        Up to {{ MAXIMUM_CLOSE_AFTER_DAYS }} days.
                                    </small>
                                </span>
                            </div>
                        </div>
                        @if (facilityProfile.allowTcnApplicationsToggle) {
                        <div class="form-group highlight row w-100 mb-0">
                            <div class="custom-control custom-checkbox">
                                <input
                                    [ngrxFormControlState]="createOfferFormValue.controls.allowTcnApplications"
                                    [ngrxValueConverter]="allowTcnApplicationsValueConverter"
                                    class="custom-control-input"
                                    id="allowTcnApplications"
                                    name="allowTcnApplications"
                                    type="checkbox"
                                />
                                <label class="custom-control-label" for="allowTcnApplications">
                                    {{ 'CREATE_OFFER.TCN_LABEL' | translate }}
                                </label>
                            </div>
                        </div>
                        }
                    </div>
                    <!-- SELECT COMPANY -->
                    @if(wrappedRole.isHumanResourcesOrCentralQuality()) {
                    <div class="form-group row">
                        <div class="form-group col">
                            @if (mroList$ | async; as entities) {
                            <ng-select
                                bindLabel="name"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="facilityProfile"
                                [items]="entities"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [loading]="loading"
                                [searchable]="true"
                                [placeholder]="'REQUEST_LISTING.SELECT_AVIATION_COMPANY' | translate"
                                (close)="onClose()"
                                (clear)="onSearch('')"
                                (scrollToEnd)="loadMore(entities)"
                                (search)="onSearch($event.term)"
                                (change)="initFormFacilityDependentFields($event)"
                                [disabled]="isEditForm"
                            >
                            </ng-select>
                            }
                        </div>
                    </div>
                    }
                    <!-- REFERENCE NUMBER AND DATE -->
                    <div class="form-group row">
                        <div class="form-group col" style="padding-right: 26px">
                            @if (!isSettingInitialValues) {
                            <custom-reference-input
                                [formState]="createOfferFormValue"
                                [isEditMode]="isEditForm"
                                [isRestrictedMode]="isRestrictedMode()"
                            ></custom-reference-input>
                            }
                        </div>
                        <div class="form-group col">
                            <label for="pick-period" class="required">
                                {{ 'OFFER.FORM.PERIOD.LABEL' | translate }}
                            </label>
                            <date-picker-range
                                id="pick-period"
                                (dateUpdated)="handleDateChange($event)"
                                [minDate]="rangePickerMinDate"
                                [range]="rangePickerValue"
                                [placeholder]="'OFFER.FORM.PERIOD.PLACEHOLDER' | translate"
                                [valid]="
                                    createOfferFormValue.controls.periodFrom.isValid &&
                                    createOfferFormValue.controls.periodTo.isValid
                                "
                                [invalid]="
                                    highlightErrors &&
                                    createOfferFormValue.controls.periodFrom.isInvalid &&
                                    createOfferFormValue.controls.periodTo.isInvalid
                                "
                            ></date-picker-range>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="title">
                                {{ 'OFFER.FORM.TITLE.LABEL' | translate }}
                            </label>
                            <input
                                id="title"
                                [ngrxFormControlState]="createOfferFormValue.controls.title"
                                autocomplete="off"
                                class="form-control"
                                [placeholder]="
                                    'OFFER.FORM.TITLE.PLACEHOLDER'
                                        | translate : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                "
                                type="text"
                                [ngClass]="{
                                    'is-valid':
                                        !createOfferFormValue.controls.title.isPristine &&
                                        createOfferFormValue.controls.title.isValid,
                                    'is-invalid':
                                        !createOfferFormValue.controls.title.isPristine &&
                                        createOfferFormValue.controls.title.isInvalid
                                }"
                            />
                            <div *ngIf="createOfferFormValue.controls.title.isInvalid">
                                <span
                                    class="validation-error"
                                    *ngIf="createOfferFormValue.controls.title.errors?.invalidTitleLength"
                                >
                                    {{
                                        'OFFER.FORM.INVALID_TITLE_LENGTH'
                                            | translate
                                                : { minLength: JO_TITLE_MIN_LENGTH, maxLength: JO_TITLE_MAX_LENGTH }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="location">
                                {{ 'OFFER.FORM.LOCATION.LABEL' | translate }}
                            </label>
                            <ng-select
                                (change)="writeLocationIdToForm()"
                                [(ngModel)]="selectedLocation"
                                [clearSearchOnAdd]="true"
                                [closeOnSelect]="true"
                                [disabled]="!canSelectLocation()"
                                [items]="locationList"
                                [ngModelOptions]="{ standalone: true }"
                                [searchable]="true"
                                bindLabel="name"
                                id="location"
                                [placeholder]="'OFFER.FORM.LOCATION.PLACEHOLDER' | translate"
                                [clearable]="false"
                            ></ng-select>
                        </div>
                    </div>
                    <!-- EMPLOYMENT TYPE, TECHNICIAN TYPE AND LICENSE -->
                    <div class="form-group row">
                        @if(employmentTypeList) {
                        <div class="form-group col">
                            <label for="employment-type">
                                {{ 'OFFER.FORM.EMPLOYMENT_TYPE.LABEL' | translate }}
                            </label>
                            <select
                                id="employment-type"
                                (change)="onEmploymentTypeChange($event)"
                                [(ngModel)]="manualUpdateControlValues.employmentTypeId"
                                [disabled]="createOfferFormValue.controls.employmentTypeId.isDisabled"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select"
                                name="employment-type"
                            >
                                <option [value]="null">{{ 'GENERAL.SELECT_AN_OPTION' | translate }}</option>
                                @for (type of employmentTypeList; track type.id) {
                                <option [value]="type.id">
                                    {{ type.name }}
                                </option>
                                }
                            </select>
                        </div>
                        }
                        <div class="form-group col">
                            <label for="technician-type" class="required">
                                {{ 'OFFER.FORM.TECHNICIAN_TYPE.LABEL' | translate }}
                            </label>
                            <select
                                id="technician-type"
                                (change)="onTechnicianTypeChange()"
                                [(ngModel)]="manualUpdateControlValues.ameType"
                                [disabled]="createOfferFormValue.controls.ameType.isDisabled"
                                [ngClass]="{
                                    'is-valid': createOfferFormValue.controls.ameType.isValid,
                                    'is-invalid':
                                        (highlightErrors || createOfferFormValue.controls.ameType.isDirty) &&
                                        createOfferFormValue.controls.ameType.isInvalid
                                }"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select"
                                name="type"
                            >
                                <option [ngValue]="null">{{ 'GENERAL.SELECT_AN_OPTION' | translate }}</option>
                                @for (option of technicianTypeOptions; track option.name) {
                                <option [value]="option.name">
                                    {{ option.name }}
                                </option>
                                }
                            </select>
                        </div>
                        @if(!isSingletonNomenclature) {
                        <div class="form-group col">
                            <label for="level" class="required">
                                {{ 'OFFER.FORM.TECHNICIAN_LEVEL.LABEL' | translate }}
                            </label>
                            <select
                                (change)="onTechnicianLevelChange($event)"
                                [(ngModel)]="manualUpdateControlValues.ameTitleId"
                                [disabled]="createOfferFormValue.controls.ameTitleId.isDisabled"
                                [ngClass]="{
                                    'is-valid': createOfferFormValue.controls.ameTitleId.isValid,
                                    'is-invalid':
                                        (highlightErrors || createOfferFormValue.controls.ameTitleId.isDirty) &&
                                        createOfferFormValue.controls.ameTitleId.isInvalid
                                }"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select"
                                id="level"
                            >
                                <option [ngValue]="null">{{ 'GENERAL.SELECT_AN_OPTION' | translate }}</option>
                                @for (license of technicianLevelOptions; track license.id) {
                                <option [value]="license.id">
                                    {{ license.name }}
                                </option>
                                }
                            </select>
                        </div>
                        }
                    </div>
                    <!-- AIRCRAFTS -->
                    <div class="form-group row">
                        <div class="form-group col aircrafts">
                            <label for="airplanes" class="required">
                                {{ 'OFFER.FORM.TYPE_OF_AIRCRAFT.LABEL' | translate }}
                            </label>
                            <multi-select-autocomplete
                                id="airplanes"
                                [placeholder]="'OFFER.FORM.TYPE_OF_AIRCRAFT.PLACEHOLDER' | translate"
                                [options]="aircraftList"
                                [selectedOptions]="selectedAircraftList"
                                (selectionChange)="handleAircraftSelectionChange($event)"
                                [valid]="createOfferFormValue.controls.airplanes.isValid"
                                [invalid]="
                                    createOfferFormValue.controls.airplanes.isInvalid &&
                                    (highlightErrors || createOfferFormValue.controls.airplanes.isDirty)
                                "
                                [disabled]="createOfferFormValue.controls.airplanes.isDisabled"
                            >
                            </multi-select-autocomplete>
                        </div>
                    </div>
                    <!-- EXPERIENCE AND OPEN POSITIONS -->
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="experience" class="required">
                                {{ 'OFFER.FORM.MIN_EXPERIENCE.LABEL' | translate }}
                            </label>
                            <div class="input-group">
                                <input
                                    [disabled]="createOfferFormValue.controls.minExperience.isDisabled"
                                    [ngrxFormControlState]="createOfferFormValue.controls.minExperience"
                                    autocomplete="off"
                                    class="form-control"
                                    id="experience"
                                    max="60"
                                    min="0"
                                    [placeholder]="'OFFER.FORM.MIN_EXPERIENCE.PLACEHOLDER' | translate"
                                    type="number"
                                    [ngClass]="{
                                        'is-valid': createOfferFormValue.controls.minExperience.isValid,
                                        'is-invalid':
                                            createOfferFormValue.controls.minExperience.isInvalid &&
                                            (highlightErrors || createOfferFormValue.controls.minExperience.isDirty)
                                    }"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="years">years</span>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    createOfferFormValue.controls.minExperience.isInvalid &&
                                    createOfferFormValue.controls.minExperience.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_MINIMUM_EXPERIENCE_LENGTH'
                                            | translate : { maxLength: MAX_YEARS_OF_EXPERIENCE }
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col">
                            <div>
                                <label for="vacancies" class="required">
                                    {{ 'OFFER.FORM.NUMBER_OF_TECHNICIANS.LABEL' | translate }}
                                </label>
                                <input
                                    [ngrxFormControlState]="createOfferFormValue.controls.vacancies"
                                    autocomplete="off"
                                    class="form-control"
                                    id="vacancies"
                                    max="1000"
                                    min="0"
                                    [placeholder]="'OFFER.FORM.NUMBER_OF_TECHNICIANS.PLACEHOLDER' | translate"
                                    type="number"
                                    [ngClass]="{
                                        'is-valid': createOfferFormValue.controls.vacancies.isValid,
                                        'is-invalid':
                                            createOfferFormValue.controls.vacancies.isInvalid &&
                                            (highlightErrors || createOfferFormValue.controls.vacancies.isDirty)
                                    }"
                                />
                            </div>
                            <div
                                *ngIf="
                                    createOfferFormValue.controls.vacancies.isInvalid &&
                                    createOfferFormValue.controls.vacancies.isDirty
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_VACANCIES_LENGTH'
                                            | translate : { maxLength: VACANCIES_MAX_VALUE }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- CHARGE RATE -->
                    <div class="form-group">
                        <div class="row">
                            <!--Indicative rate option-->
                            <div class="form-group col">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        (click)="toggleIndicativeRateOption($event)"
                                        (keydown.enter)="toggleIndicativeRateOption($event)"
                                        [disabled]="isEditForm"
                                        [ngrxFormControlState]="createOfferFormValue.controls.hasIndicativeRate"
                                        class="custom-control-input"
                                        id="hasIndicativeRate"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="hasIndicativeRate">
                                        {{ 'OFFER.FORM.PAY_RATE_PER_HOUR_RANGE_PUBLIC' | translate }}
                                    </label>
                                </div>
                                @if (facilityProfile.allowsHideRateInJobOffers) {
                                <div class="custom-control custom-checkbox">
                                    <input
                                        [disabled]="isEditForm"
                                        [ngrxFormControlState]="createOfferFormValue.controls.hasHiddenRate"
                                        class="custom-control-input"
                                        id="hasHiddenRate"
                                        type="checkbox"
                                    />
                                    <label class="custom-control-label" for="hasHiddenRate">
                                        {{ 'CREATE_OFFER.HIDDING_SALARY_LABEL' | translate }}
                                    </label>
                                </div>
                                }
                            </div>
                        </div>
                        <!--With indicative rate-->
                        @if(hasIndicativeRate) {
                        <ng-container>
                            <div class="row">
                                <div class="col">
                                    <label for="chargeRate-indicative-rate" class="required">
                                        {{ 'OFFER.FORM.HOURLY_RATE.LABEL' | translate }} (lowest)
                                    </label>
                                    <div class="input-group">
                                        <input
                                            [ngrxFormControlState]="createOfferFormValue.controls.chargeRate"
                                            autocomplete="off"
                                            class="form-control"
                                            id="chargeRate-indicative-rate"
                                            max="chargeRateMaxValue"
                                            min="0"
                                            placeholder="Lowest"
                                            type="number"
                                            [ngClass]="{
                                                'is-valid': createOfferFormValue.controls.chargeRate.isValid,
                                                'is-invalid':
                                                    highlightErrors &&
                                                    createOfferFormValue.controls.chargeRate.isInvalid
                                            }"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"> {{ currencySymbol }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <label for="chargeRate" class="required">
                                        {{ 'OFFER.FORM.HOURLY_RATE.LABEL' | translate }} (highest)
                                    </label>
                                    <div class="input-group">
                                        <input
                                            [ngrxFormControlState]="createOfferFormValue.controls.maxChargeRate"
                                            autocomplete="off"
                                            class="form-control"
                                            id="maxChargeRate"
                                            placeholder="Highest"
                                            type="number"
                                            [ngClass]="{
                                                'is-valid': createOfferFormValue.controls.maxChargeRate.isValid,
                                                'is-invalid':
                                                    highlightErrors &&
                                                    createOfferFormValue.controls.maxChargeRate.isInvalid
                                            }"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"> {{ currencySymbol }} </span>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="
                                            createOfferFormValue.controls.maxChargeRate.isInvalid &&
                                            createOfferFormValue.controls.maxChargeRate.isDirty
                                        "
                                    >
                                        <span class="validation-error">
                                            {{
                                                'OFFER.FORM.INVALID_MAX_CHARGE_RATE_LENGTH'
                                                    | translate : { maxPayRate: MAX_CHARGE_RATE }
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        } @else {
                        <!--Without indicative rate-->
                        <div class="row">
                            <div class="col">
                                <label for="chargeRate" class="required">
                                    {{ 'OFFER.FORM.HOURLY_RATE.LABEL' | translate }}
                                </label>
                                <div class="input-group">
                                    <input
                                        [ngrxFormControlState]="createOfferFormValue.controls.chargeRate"
                                        autocomplete="off"
                                        class="form-control"
                                        id="chargeRate"
                                        max="chargeRateMaxValue"
                                        min="0"
                                        placeholder="{{ 'OFFER.FORM.HOURLY_RATE.PLACEHOLDER' | translate }}"
                                        type="number"
                                        [ngClass]="{
                                            'is-valid': createOfferFormValue.controls.chargeRate.isValid,
                                            'is-invalid':
                                                createOfferFormValue.controls.chargeRate.isInvalid &&
                                                (highlightErrors || createOfferFormValue.controls.chargeRate.isDirty)
                                        }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text"> {{ currencySymbol }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col"><!--dummy div for alignment--></div>
                        </div>
                        }
                        <div
                            *ngIf="
                                createOfferFormValue.controls.chargeRate.isInvalid &&
                                createOfferFormValue.controls.chargeRate.isDirty
                            "
                        >
                            <span class="validation-error">
                                Charge rate must be a number less than {{ MAX_CHARGE_RATE }}
                            </span>
                        </div>
                    </div>

                    <!-- LICENSE (Airframe/Powerplant/A&P) -->
                    @if (isElaunchnowOrUSABackOffice) {
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="licenses" class="required">
                                {{ 'SYSTEM.INFO.LICENSE' | translate }}
                            </label>
                            <multiple-options-select
                                id="licenses"
                                placeholder="{{ 'GENERAL.SELECT_AN_OPTION' | translate }}"
                                [elements]="licenses"
                                [searchable]="true"
                                [selectedElements]="selectedLicenses"
                                (valueChanged)="onSelectLicenses($event)"
                                [disabled]="isRestrictedMode()"
                                [valid]="createOfferFormValue.controls.licenses.isValid"
                                [invalid]="
                                    (highlightErrors || createOfferFormValue.controls.licenses.isDirty) &&
                                    createOfferFormValue.controls.licenses.isInvalid
                                "
                            ></multiple-options-select>
                            @if( createOfferFormValue.controls.licenses.isInvalid &&
                            createOfferFormValue.controls.licenses.isDirty ) {
                            <div>
                                <span class="validation-error">
                                    {{ 'OFFER.FORM.INVALID_LICENSE' | translate }}
                                </span>
                            </div>
                            }
                        </div>
                    </div>
                    }
                    <!-- WORKING PATTERN AND DESCRIPTION -->
                    <div class="form-group row">
                        @if(isStaffnow){
                        <div class="form-group col-4 pattern-view">
                            <label for="work-pattern">
                                {{ 'OFFER.FORM.WORK_PATTERN.LABEL' | translate }}
                            </label>
                            <div class="custom-controls-stacked" id="work-pattern">
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.first)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="first-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.first }}"
                                    />
                                    <label class="custom-control-label" for="first-radio">
                                        5 days on - 2 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.second)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="second-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.second }}"
                                    />
                                    <label class="custom-control-label" for="second-radio">
                                        3 days on - 3 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (change)="setWorkingPattern($event)"
                                        [checked]="isPatternSelected(fixedWorkingPatterns.third)"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="third-radio"
                                        name="pattern"
                                        type="radio"
                                        value="{{ fixedWorkingPatterns.third }}"
                                    />
                                    <label class="custom-control-label" for="third-radio">
                                        6 days on - 1 days off
                                    </label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input
                                        (click)="customPatternSet()"
                                        (keydown.enter)="customPatternSet()"
                                        [checked]="isCustomPatternSelected"
                                        [disabled]="isRestrictedMode()"
                                        class="custom-control-input"
                                        id="fourth-radio"
                                        name="pattern"
                                        type="radio"
                                        value="custom"
                                    />
                                    <label class="custom-control-label" for="fourth-radio"> Custom </label>
                                </div>
                                <div class="form-row mt-2">
                                    @if(isCustomPatternSelected) {
                                    <div class="form-group col-6 padded">
                                        <label for="work-days" class="required">
                                            {{ 'OFFER.FORM.WORK_DAYS.LABEL' | translate }}
                                        </label>
                                        <input
                                            (input)="setCustomWorkingPattern($event, true)"
                                            [(ngModel)]="customWorkDaysPattern.on"
                                            [disabled]="isRestrictedMode()"
                                            [ngClass]="{
                                                'is-valid': createOfferFormValue.controls.workDaysOn.isValid,
                                                'is-invalid': createOfferFormValue.controls.workDaysOn.isInvalid
                                            }"
                                            class="form-control"
                                            id="work-days"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            min="1"
                                            name="ondays"
                                            tooltip="Work days"
                                            type="number"
                                        />
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="off-days" class="required">
                                            {{ 'OFFER.FORM.OFF_DAYS.LABEL' | translate }}
                                        </label>
                                        <input
                                            (input)="setCustomWorkingPattern($event, false)"
                                            [(ngModel)]="customWorkDaysPattern.off"
                                            [disabled]="isRestrictedMode()"
                                            [ngClass]="{
                                                'is-valid': createOfferFormValue.controls.workDaysOff.isValid,
                                                'is-invalid': createOfferFormValue.controls.workDaysOff.isInvalid
                                            }"
                                            class="form-control"
                                            id="off-days"
                                            max="workDaysMaxValue"
                                            maxlength="2"
                                            min="0"
                                            name="offdays"
                                            tooltip="Off days"
                                            type="number"
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                        <div class="form-group col-8">
                            <label for="job-description" class="required">
                                {{ 'OFFER.FORM.JOB_DESCRIPTION.LABEL' | translate }}
                            </label>
                            <textarea
                                id="job-description"
                                [ngrxFormControlState]="createOfferFormValue.controls.shortPresentation"
                                class="form-control"
                                cols="30"
                                [attr.maxlength]="JO_PRESENTATION_MAX_LENGTH"
                                name="presentation"
                                rows="5"
                                [ngClass]="{
                                    'is-valid': createOfferFormValue.controls.shortPresentation.isValid,
                                    'is-invalid':
                                        createOfferFormValue.controls.shortPresentation.isInvalid &&
                                        (highlightErrors || createOfferFormValue.controls.shortPresentation.isDirty)
                                }"
                            ></textarea>
                            <div
                                *ngIf="
                                    createOfferFormValue.controls.shortPresentation.isInvalid &&
                                    !createOfferFormValue.controls.shortPresentation.isPristine
                                "
                            >
                                <span class="validation-error">
                                    {{
                                        'OFFER.FORM.INVALID_JOB_DESCRIPTION_LENGTH'
                                            | translate
                                                : {
                                                      minLength: JO_PRESENTATION_MIN_LENGTH,
                                                      maxLength: JO_PRESENTATION_MAX_LENGTH
                                                  }
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-group col">
                            <label for="workShiftPreference">
                                {{ 'SYSTEM.INFO.SHIFT' | translate }}
                            </label>
                            <multiple-options-select
                                id="workShiftPreference"
                                placeholder="{{ 'OFFER.FORM.SHIFT.PLACEHOLDER' | translate }}"
                                [searchable]="true"
                                [elements]="getWorkShiftList()"
                                [selectedElements]="getSelectedWorkShifts()"
                                (valueChanged)="onSelectWorkShift($event)"
                                [disabled]="createOfferFormValue.controls.workShifts.isDisabled"
                            ></multiple-options-select>
                        </div>
                    </div>
                    <!-- ADDITIONAL BENEFITS -->
                    @if(!hasIndicativeRate) {
                    <div class="form-group row mb-3">
                        <collapsible heading="Benefits" highlight="true" style="width: 100%">
                            <div style="padding: 0 20px">
                                @for (benefit of benefits;track benefit.description ;let i = $index) {
                                <staffnow-offer-benefit
                                    (changer)="markBenefitsAsDirty()"
                                    [(ngModel)]="benefits[i]"
                                    [currencySymbol]="currencySymbol"
                                    [isDisabled]="isRestrictedMode()"
                                    [isValid]="isBenefitValid(benefits[i])"
                                    [ngModelOptions]="{ standalone: true }"
                                ></staffnow-offer-benefit>
                                }
                            </div>
                        </collapsible>
                    </div>
                    }
                    <!-- FORM ACTIONS -->
                    <div class="form-row justify-content-center create-offer-form-actions">
                        <general-button
                            (onClick)="cancelCreation()"
                            backgroundStyle="outline"
                            class="mr-5"
                            text="CANCEL"
                        ></general-button>
                        <general-button
                            (onClick)="handleFormSubmit()"
                            [isLoading]="isSubmitting"
                            text="CONFIRM"
                        ></general-button>
                    </div>
                    <div *ngIf="highlightErrors" class="invalid-form-error">
                        <span class="validation-error">
                            {{ 'OFFER.FORM.INVALID_SUBMITTED_FORM' | translate }}
                        </span>
                    </div>
                </form>
                }
            </div>
        </section>

        <!-- TECHNICIANS -->
        <section class="create-offer-user">
            <div class="user-container">
                <div class="form-head" style="text-align: center">
                    <h1 class="h1">
                        <strong>Selected Technicians</strong>
                    </h1>
                    <span *ngIf="noSelectedTechnicians()"> No technicians selected </span>
                    @for (technician of selectedTechnicians | keyvalue; track technician.key) {
                    <div class="technician-item">
                        @if(technician.value._links?.getProfilePicture) {
                        <div
                            [ngStyle]="{
                                'background-image': 'url(\'' + technician.value._links.getProfilePicture.href + '\')'
                            }"
                            class="profile-pic"
                        ></div>
                        } @else {
                        <div class="profile-pic">
                            <i class="material-icons"> person </i>
                        </div>
                        }
                        <div class="name">
                            <h4 class="h4">
                                {{ technician.value._embedded.profile.firstName }}
                                {{ technician.value._embedded.profile.lastName }}
                            </h4>
                        </div>
                        <div
                            (click)="handleRemoveFromSelectedTechnicians(technician.value)"
                            (keydown.enter)="handleRemoveFromSelectedTechnicians(technician.value)"
                            class="clear"
                        >
                            <i class="material-icons"> clear </i>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
    </div>
    }
</div>
