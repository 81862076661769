export class JobOpeningFiltersConstants {
  public static readonly quickFilter = {
    all: 'all',
    active: 'active',
    closedForApplications: 'closed',
    expired: 'past'
  };
  public static readonly applicationFilter = {
    allApplications: 'allApplications',
    activeTechnicianApplicationsFilter: 'activeTechnicians',
    pendingApplicationsFilter: 'pending',
    documentsNeededApplicationsFilter: 'documentsNeeded',
    rejectedApplicationsFilter: 'rejectedTechnicians'
  };
}
