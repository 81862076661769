import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedModule } from '@platform/shared/shared.module';
import { TechnicianPaginatedSelectorComponent } from '@libs/common-ui/technician-paginated-selector/technician-paginated-selector.component';


@Component({
  selector: 'staffnow-select-technician-from-list-modal',
  templateUrl: './select-technician-from-list-modal.component.html',
  standalone: true,
  imports: [
    SharedModule,
    TechnicianPaginatedSelectorComponent
  ],
  styleUrls: ['./select-technician-from-list-modal.component.scss']
})
export class SelectTechnicianFromListModalComponent {
  @Input() retrieverUrl: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() onConfirmCallback: Function = null;
  @Input() placeholder: string = null;
  @Input() bindLabel: string = null;
  @Input() multiple = false;
  selectedTechnicians: string[];

  constructor(public bsModalRef: BsModalRef) {
  }

  close(): void {
    this.bsModalRef.hide();
  }

  onConfirm() {
    this.onConfirmCallback(this.selectedTechnicians);
    this.close();
  }

  isFormValid() {
    return this.selectedTechnicians?.length;
  }

  onSelectedTechnicianSelect($event: string[]): void {
    this.selectedTechnicians = $event;
  }
}
