import { createFormStateReducerWithUpdate, FormGroupState, setErrors, updateGroup, validate } from 'ngrx-forms';
import { greaterThan, lessThanOrEqualTo, required } from 'ngrx-forms/validation';
import { CreateAgencyOfferFormModel, createAgencyOfferInitialFormState, FormState } from './create-agency-offer.model';

import { ResetAgencyOfferForm } from './create-agency-offer.actions';
import { Action } from '@ngrx/store';
import {
  getChargeRateValidator,
  getShortPresentationValidator,
  getMinExperienceValidator,
  getTitleValidator,
  getVacanciesValidator,
  getWorkDaysOffValidator,
  getWorkDaysOnValidator,
  MAX_CHARGE_RATE,
  getCustomReferenceValidator
} from '../../../shared/validators/offer-form.validator';

export const CREATE_EDIT_AGENCY_OFFER_FEATURE_KEY = 'createEditAgencyOffer';

const validateAircraft = (state: FormState): FormGroupState<CreateAgencyOfferFormModel> => {
  if (state.controls.otherAirplanes.value) {
    return updateGroup<CreateAgencyOfferFormModel>({
      airplanes: setErrors(null)
    })(state);
  }
  return state;
};

const validateMaxPayRate = (
  state: FormState = createAgencyOfferInitialFormState
): FormGroupState<CreateAgencyOfferFormModel> => {
  if (state.controls.hasIndicativeRate.value) {
    return updateGroup<CreateAgencyOfferFormModel>({
      maxPayRate: validate(
        required,
        greaterThan(-1),
        lessThanOrEqualTo(MAX_CHARGE_RATE),
        greaterThan(state.controls.payRate.value ?? 0)
      )
    })(state);
  } else {
    return updateGroup<CreateAgencyOfferFormModel>({
      maxPayRate: setErrors(null)
    })(state);
  }
};

const updateMyFormGroup = updateGroup<CreateAgencyOfferFormModel>({
  refNumber: getCustomReferenceValidator(),
  shortPresentation: getShortPresentationValidator(),
  vacancies: getVacanciesValidator(),
  ameType: validate(required),
  ameTitleId: validate(required),
  airplanes: validate(required),
  minExperience: getMinExperienceValidator(),
  payRate: getChargeRateValidator(),
  workDaysOn: getWorkDaysOnValidator(),
  workDaysOff: getWorkDaysOffValidator(),
  priority: validate(required),
  title: getTitleValidator()
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function createAgencyOfferFormReducer(
  state: FormState = createAgencyOfferInitialFormState,
  action: Action
): FormGroupState<CreateAgencyOfferFormModel> {
  if (action.type === ResetAgencyOfferForm.type) {
    return {
      ...createAgencyOfferInitialFormState
    };
  }
  return {
    ...validateMaxPayRate(validateAircraft(formReducer(state, action)))
  };
}
