import { createSelector, select } from '@ngrx/store';
import { AppState } from './app-state.model';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { isNil } from 'lodash-es';

export const selectFeature = (state: AppState): AppState => state;

export const selectMroFacilities = select(createSelector(selectFeature, state => state.mroFacilities));

export const selectFacilityProfile = select(createSelector(selectFeature, state => state.facilityProfile));

export const selectApprovedAgenciesStatus = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.approvedAgenciesStatus)
);

export const selectMroRatesStatus = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.mroRatesStatus)
);

export const selectApprovedAgencies = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.approvedAgencies)
);

export const selectMroRates = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.rates)
);

export const selectDocumentsNeededTemplate = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.documentsNeededTemplate)
);

export const selectMroApprovals = pipe(
  selectFacilityProfile,
  select(facilityProfile => facilityProfile.mroApprovals)
);

export const selectFacilityProfileFiltered = pipe(
  selectFacilityProfile,
  filter(facilityProfile => !isNil(facilityProfile._links))
);

export const selectApprovedAgenciesUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile => hasLink(facilityProfile, FacilityProfileLinkRel.ApprovedAgencies)),
  select(facilityProfile => getUrl(facilityProfile, FacilityProfileLinkRel.ApprovedAgencies))
);

export const selectMroApprovalsUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile => hasLink(facilityProfile, FacilityProfileLinkRel.MroApprovals)),
  select(facilityProfile => getUrl(facilityProfile, FacilityProfileLinkRel.MroApprovals))
);

export const selectMroRatesUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile => hasLink(facilityProfile, FacilityProfileLinkRel.MroRates)),
  select(facilityProfile => getUrl(facilityProfile, FacilityProfileLinkRel.MroRates))
);

export const selectCustomerSupportTopics = createSelector(
  selectFeature,
  appState => appState.customerSupportTopicTypes
);
