import { createFormGroupState, FormGroupState } from 'ngrx-forms';
import { OfferPriority } from '@libs/shared/models/offer.model';
import { BaseOfferFormModel } from '../../../shared/components/base-offer-form.model';

export interface CreateAgencyOfferFormModel extends BaseOfferFormModel {
  packageContractId: number;
  payRate: number;
  maxPayRate: number;
}

export interface FormState extends FormGroupState<CreateAgencyOfferFormModel> {}

export const agencyOfferInitialFormState = {
  refNumber: null,
  useCustomReference: false,
  title: '',
  shortPresentation: '',
  vacancies: null,
  ameType: null,
  ameTitleId: null,
  airplanes: [],
  otherAirplanes: '',
  minExperience: null,
  hasIndicativeRate: false,
  payRate: null,
  maxPayRate: null,
  workPattern: '',
  workDaysOn: null,
  workDaysOff: null,
  additionalBenefits: [],
  priority: OfferPriority.MEDIUM,
  packageContractId: null,
  workShifts: []
};

export const createAgencyOfferInitialFormState: FormState = createFormGroupState<CreateAgencyOfferFormModel>(
  'createAgencyOffer',
  agencyOfferInitialFormState
);
