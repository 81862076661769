import { Rate } from './rate.model';

export interface AdditionalBenefit {
  type: BenefitType;
  amount?: Rate | number;
  description?: string;
}

export enum BenefitType {
  DAILY_COMMUTING = 'DAILY_COMMUTING',
  TRAVELING_COST = 'TRAVELING_COST',
  ACCOMMODATION = 'ACCOMMODATION',
  OVERTIME_RATE = 'OVERTIME_RATE',
  PER_DIEMS = 'PER_DIEMS',
  OTHER = 'OTHER'
}

export function benefitTypeToReadable(type: BenefitType): string {
  return 'BENEFIT_TYPE.' + type.toString();
}
