import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FacilityPage } from '@libs/shared/models/facility.model';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class AgencyFacilityLoaderService {
  constructor(private httpService: HttpClient) {}

  getAgencyFacilities(
    url: string,
    term: string,
    pageNumber: number,
    pageSize: number,
    additionalParams?: { [key: string]: string }
  ): Observable<FacilityPage> {
    const params = {
      searchTerm: term,
      page: pageNumber,
      size: pageSize,
      sort: 'name,asc',
      ...additionalParams
    };
    const httpParams = new HttpParams({ fromObject: params });
    return this.httpService.get<FacilityPage>(`${url.split('?')[0]}`, { params: httpParams });
  }
}
