import { Component, OnInit } from '@angular/core';
import { isPermanent, OfferOutDto, OfferType } from '@libs/shared/models/offer.model';
import { isDateInThePastOrToday } from '@libs/shared/helpers/date-utils';
import { FacilityStatus, MroFacility } from '@libs/shared/models/facility.model';
import { getEmbeddedResource, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { CloseOffer } from '@libs/request-overview-common/state/requests-overview.actions';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { Store } from '@ngrx/store';
import { AbstractRequestCardComponent } from '../request-card/abstract-request-card.component';
import { License } from '@libs/shared/models/license.model';

@Component({
  selector: 'app-offer-actions',
  templateUrl: './offer-actions.component.html'
})
export class OfferActionsComponent extends AbstractRequestCardComponent implements OnInit {
  isPermanent: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly confirmService: ModalService,
    private readonly translateService: TranslateService,
    store: Store
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.isPermanent = isPermanent(this.offer);
  }

  handleInviteTechniciansToOffer(): void {
    if (!this.jobOffer.isApproved) {
      return;
    }
    const offer: OfferOutDto = this.jobOffer;
    const queryParams: any = {
      aircraft: offer.airplanes.join(','),
      offer: offer.refNumber,
      availability: `${offer.periodFrom}:${offer.periodTo}`,
      ameType: offer.ameTitle.type,
      ameLicense: offer.ameTitle.license,
      experience: offer.minExperience,
      location: offer.location?.id,
      contractTypes: offer?.employmentType?.id
    };

    if (offer.licenses && offer.licenses.length > 0) {
      queryParams.licenses = (offer.licenses as License[]).map(license => license.name).join(',');
    }

    if (offer.workShifts && offer.workShifts.length > 0) {
      queryParams.workShifts = offer.workShifts.map(value => value.id).join(',');
    }

    this.router.navigate(['technician-overview'], { queryParams });
  }

  get isInviteTechniciansDisabled(): boolean {
    return !this.jobOffer?.isApproved || this.isOfferClosedOrEnded || this.isMroPending;
  }

  get jobOffer(): OfferOutDto {
    return this.offer as OfferOutDto;
  }

  get isOfferClosedOrEnded(): boolean {
    return this.jobOffer?.isClosed || this.isOfferEnded;
  }

  get isOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offer?.periodTo);
  }

  get isMroPending(): boolean {
    const mroFacility: MroFacility = this.facilityProfile();
    return mroFacility?.status == FacilityStatus.PENDING_REVIEW;
  }

  get canNotCloseOffer(): boolean {
    return !hasLink(this.jobOffer, RequestOverviewLinkRel.CloseOffer);
  }

  closeOffer(): void {
    this.confirmService.openConfirmModal(this.translateService.instant('SYSTEM.INFO.CLOSE_JO'), () =>
      this.store.dispatch(CloseOffer({ offer: this.jobOffer }))
    );
  }

  openEditOfferForm(): void {
    const offer: OfferOutDto = this.jobOffer;
    const urlRoute: string = offer.offerType === OfferType.PERMANENT ? 'permanent-offer' : 'offer';
    const jobOpeningTitle: string =
      offer.offerType === OfferType.PERMANENT
        ? 'MRO.PACKAGE_OFFER.PERMANENT_JO_DETAILS'
        : 'AGENCY.PACKAGE_OFFER.DETAILS.JO_DETAILS';
    this.router
      .navigate([urlRoute], {
        state: {
          preselectedFilters: offer,
          isPrivate: !offer.isPublic,
          selectedTechnicians: [],
          isEdit: true,
          breadcrumbs: [
            this.translateService.instant('AGENCY.DETAILS.TITLE_JO_APPLICATIONS'),
            this.translateService.instant(jobOpeningTitle),
            this.translateService.instant('AGENCY.DETAILS.EDIT_JOB_OPENING')
          ]
        }
      })
      .then();
  }

  openOfferDescription(): void {
    this.router.navigate([this.getRoute(), this.offer.refNumber]);
  }

  private facilityProfile(): MroFacility {
    return getEmbeddedResource(this.offer, JobOfferLinkRel.Facility);
  }

  private getRoute(): string {
    if (this.isPackageJobOpening) {
      return 'package-contracts';
    }
    return isPermanent(this.offer) ? 'permanent-contracts' : 'contracts';
  }
}
