import { Component, Input } from '@angular/core';
import { AbstractButton } from '@libs/common-ui/buttons/components/abstract.button';
import { isNil } from 'lodash-es';

const buttonClassesMap: Map<string, string> = new Map();
buttonClassesMap.set('full-primary', 'btn-primary');
buttonClassesMap.set('full-alternative', 'full-alternative');
buttonClassesMap.set('full-primary-lowlight', 'btn-primary');
buttonClassesMap.set('outline-primary', 'btn-outline-primary');
buttonClassesMap.set('full-secondary', 'btn-secondary');
buttonClassesMap.set('full-secondary-low', 'full-secondary-low');
buttonClassesMap.set('outline-secondary', 'btn-outline-secondary');
buttonClassesMap.set('outline-danger', 'btn-outline-danger');
buttonClassesMap.set('outline-dark', 'btn-outline-dark');
buttonClassesMap.set('full-light-dark', 'full-light-dark');
buttonClassesMap.set('outline-black-white', 'outline-black-white');

@Component({
  selector: 'general-button',
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss']
})
export class GeneralButtonComponent extends AbstractButton {
  @Input() public backgroundStyle: 'outline' | 'full' = 'full';
  @Input() public padding: string = null;
  @Input() public size: 'small' | 'medium' | 'large' = 'medium';
  @Input() public color:
    | 'primary'
    | 'alternative'
    | 'primary-lowlight'
    | 'secondary'
    | 'secondary-low'
    | 'danger'
    | 'dark'
    | 'light-dark'
    | 'black-white' = 'primary';
  @Input() public icon: string = null;
  @Input() public iconPosition: 'left' | 'right' = 'left';
  @Input() public iconOutlined: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public borderStraight: boolean = false;
  @Input() lowerCase: boolean = false;
  @Input() public shape: 'round' | 'rectangular' = 'rectangular';
  @Input() public type: 'button' | 'submit' = 'button';
  @Input() public customClass: string = 'disabled-btn-loader';

  public handleClick($event: MouseEvent): void {
    if (!this.isLoading) {
      super.handleClick($event);
    }
  }

  public isButtonSubmit(): boolean {
    return this.type == 'submit';
  }

  public isIconOnLeft(): boolean {
    return !isNil(this.icon) && this.iconPosition == 'left';
  }

  public isIconOnRight(): boolean {
    return !isNil(this.icon) && this.iconPosition == 'right';
  }

  public getIconClasses(): string[] {
    return ['icon', this.iconOutlined ? 'material-icons-outlined' : 'material-icons'];
  }

  public getButtonStyle(): { [key: string]: string } {
    return {
      padding: this.padding
    };
  }

  public getButtonClasses(): string[] {
    const classes: string[] = ['btn', this.shape, this.getButtonTypeClass(), this.getButtonSizeClass()];
    if (this.highlight) {
      classes.push('active');
    }
    if (this.lowerCase) {
      classes.push('lower-case');
    }
    if (this.color == 'primary-lowlight') {
      classes.push('primary-lowlight');
    }
    if (this.color == 'black-white') {
      classes.push('black-white');
    }
    if (this.color == 'alternative') {
      classes.push('alternative');
    }
    if (this.icon != null && this.text != null) {
      classes.push('icon-plus-text');
    }
    if (this.isLoading) {
      classes.push('loading');
    }
    if (this.borderStraight) {
      classes.push('border-straight');
    }
    if (this.shape === 'round') {
      classes.push('round');
    }
    return classes;
  }

  private getButtonTypeClass(): string {
    const combination: string = this.backgroundStyle + '-' + this.color;
    const buttonClass: string = buttonClassesMap.get(combination);
    if (buttonClass != null) {
      return buttonClass;
    } else {
      console.warn(`Button class ${combination} is undefined!!`);
      return 'btn-primary';
    }
  }

  private getButtonSizeClass(): string {
    switch (this.size) {
      case 'large':
        return 'btn-lg';
      case 'small':
        return 'btn-sm';
      default:
        return 'btn-md';
    }
  }
}
