import { Component, OnInit } from '@angular/core';
import {
  CloseAgencyOffer,
  DeleteAgencyOffer,
  LoadAgencyOfferWithContracts,
  ReopenAgencyOffer,
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Router } from '@angular/router';
import {
  isDateInThePastOrToday
} from '@libs/shared/helpers/date-utils';
import {
  getLink,
  getUrl
} from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { AbstractOfferDetailsComponent } from '../abstract-offer-details.component';
import { isNil } from 'lodash-es';
import { PackageContractLinkRel } from '@libs/shared/linkrels/package-contract.linkrel';
import { RequestOverviewState } from '../../../state/request-overview-state.model';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';

@Component({
  selector: 'staffnow-agency-offer-details',
  templateUrl: './agency-offer-details.component.html',
  styleUrls: [
    '../facility-job-offer-details.component.scss',
    './agency-offer-details.component.scss'
  ]
})
export class AgencyOfferDetailsComponent
  extends AbstractOfferDetailsComponent
  implements OnInit {
  public contracts: Array<AgencyOfferContractOutDto> = [];

  get isAgencyOfferEnded(): boolean {
    return isDateInThePastOrToday(this.offer?.periodTo);
  }

  get calculateRemainingPositions(): number {
    const remaining =
      this.jobOffer?.vacancies - this.jobOffer?.fullyMatchedSize;
    return remaining < 0 ? 0 : remaining;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirmService: ModalService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(
          LoadAgencyOfferWithContracts({
            refNumber: params['agencyOfferRefNumber']
          })
        );
      })
    );
  }

  protected updateFromState(state: RequestOverviewState) {
    super.updateFromState(state);
    this.contracts = state.contracts as Array<AgencyOfferContractOutDto>;
  }

  get canDeleteAgencyOffer(): boolean {
    return !isNil(this.offer?._links[RequestOverviewLinkRel.DeleteAgencyOffer]);
  }


  get canEditAgencyOffer(): boolean {
    return !isNil(this.offer?._links[RequestOverviewLinkRel.EditAgencyOffer]);
  }

  get canCloseAgencyOffer(): boolean {
    return !isNil(this.offer?._links[RequestOverviewLinkRel.CloseAgencyOffer]);
  }

  get canReopenAgencyOffer(): boolean {
    return !isNil(this.offer?._links[RequestOverviewLinkRel.ReopenAgencyOffer]);
  }

  public openEditAgencyOfferForm(): void {
    const agencyOffer = this.agencyOffer;
    this.router
      .navigate(['/agency-offer', agencyOffer.packageContractId], {
        state: {
          preselectedFilters: {
            ...this.agencyOffer,
            ameTitleId: this.agencyOffer.ameTitle.id
          },
          location: this.offer.location,
          isEdit: true,
          startDate: agencyOffer.periodFrom,
          endDate: agencyOffer.periodTo,
          breadcrumbs: [
            'My Agency Job Openings',
            'Agency Job Opening Details',
            'Edit Agency Job Opening'
          ],
          mroUuid: agencyOffer.mroUuid,
          packageContractId: agencyOffer.packageContractId,
          editUrl: getLink(
            agencyOffer,
            PackageContractLinkRel.EditAgencyOffer
          ),
          getMroFacilityUrl: getLink(
            agencyOffer,
            PackageContractLinkRel.GetMroFacility
          )
        }
      })
      .then();
  }

  public handleDeleteAgencyOffer(): void {
    this.confirmService.openConfirmModal(
      'SYSTEM.INFO.DELETE_JO_CONFIRMATION',
      () => {
        this.store.dispatch(DeleteAgencyOffer({
          url: getUrl(this.agencyOffer, RequestOverviewLinkRel.DeleteAgencyOffer),
          isBackoffice: false,
          refNumber: this.agencyOffer.refNumber
        }));
      }
    );
  }

  public closeAgencyOffer(): void {
    this.confirmService.openConfirmModal(
      'SYSTEM.INFO.CLOSE_JO', () =>
      this.store.dispatch(CloseAgencyOffer({
        refNumber: this.agencyOffer.refNumber,
        url: getUrl(this.agencyOffer, RequestOverviewLinkRel.CloseAgencyOffer),
        isBackoffice: false
      }))
    );
  }

  public reopenAgencyOffer(): void {
    this.confirmService.openConfirmModal(
      'SYSTEM.INFO.REOPEN_JO', () =>
      this.store.dispatch(ReopenAgencyOffer({
        refNumber: this.agencyOffer.refNumber,
        url: getUrl(this.agencyOffer, RequestOverviewLinkRel.ReopenAgencyOffer),
        isBackoffice: false
      }))
    );
  }
}
