import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'multiple-options-select',
  templateUrl: './multiple-options-select.component.html',
  styleUrls: ['./multiple-options-select.component.scss']
})
export class MultipleOptionsSelectComponent {
  @Input() public elements: any[] = [];
  @Input() public selectedElements: any[] = [];
  @Input() public bindLabel: string = 'name';
  @Input() public labelForId: string = null;
  @Input() public placeholder: string = null;
  @Input() public searchable: boolean = false;
  @Input() public closeOnSelect: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public valid: boolean = false;
  @Input() public invalid: boolean = false;
  @Input() public missingDataWarning: boolean = false;

  @Output() public valueChanged = new EventEmitter();

  public emitChange(): void {
    this.valueChanged.emit(this.selectedElements);
  }

  public toggleItem(item$: any): void {
    item$.selected = !item$.selected;
  }
}
