import { Component, Input } from '@angular/core';
import {
  AgencyOfferOutDto,
  isFixedPrice,
  isPermanent,
  OfferOutDto,
  OfferPriority,
  OfferType,
  PackageOfferDto,
  PermanentOfferDto
} from '@libs/shared/models/offer.model';
import { getEmbeddedResource, getUrl, hasEmbeddedResource, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { JobOfferLinkRel } from '@libs/shared/linkrels/job-offer.linkrel';
import { isEuropeDeployment } from '@libs/shared/bms-common/environment/environment.loader';
import { ActivatedRoute } from '@angular/router';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { BrandName } from '@libs/shared/bms-common/environment/environment.model';
import { Store } from '@ngrx/store';
import { InformationBannerDirection } from '@libs/common-ui/information-banner/model/information-banner-direction.model';
import { InformationBannerColor } from '@libs/common-ui/information-banner/model/information-banner-color.model';
import { AdditionalBenefit } from '@libs/shared/models/additional-benefit.model';

type notFixedPriceOffer = OfferOutDto | PermanentOfferDto | AgencyOfferOutDto;

@Component({
  selector: 'staffnow-job-offer-info',
  templateUrl: './job-offer-info.component.html',
  styleUrls: ['./job-offer-info.component.scss']
})
export class JobOfferInfoComponent {
  @Input() public offer: OfferOutDto | PermanentOfferDto | PackageOfferDto | AgencyOfferOutDto = null;
  @Input() public isSharedOfferDto: boolean = false;
  @Input() public displayOfferLocation: boolean = false;
  @Input() public applicationExists: boolean = false;
  @Input() public applicationExistsMessage: string = '';

  public readonly OFFER_TYPE = OfferType;
  protected isStaffnow: boolean = isEuropeDeployment();
  protected isElaunchnowOrBackOffice: boolean;
  private brandName: string;

  constructor(
    private readonly store: Store<any>,
    public activatedRoute: ActivatedRoute
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.brandName = env.brandName;
      if (this.brandName === BrandName.eLAUNCHNow) {
        this.isElaunchnowOrBackOffice = true;
      }
    });
  }

  get isFixedPriceOffer(): boolean {
    return isFixedPrice(this.offer);
  }

  get isPermanentOffer(): boolean {
    return isPermanent(this.offer);
  }

  get facilityLocation() {
    if (this.displayOfferLocation && this.offer?.location) {
      return this.offer?.location.name;
    }
    return this.hasFacility ? this.facility.location : null;
  }

  get facility(): any {
    return getEmbeddedResource(this.offer as Resource, JobOfferLinkRel.Facility);
  }

  private get hasFacility(): boolean {
    return hasEmbeddedResource(this.offer as Resource, JobOfferLinkRel.Facility);
  }

  get pictureUrl(): string {
    return hasLink(this.facility, JobOfferLinkRel.FacilityImage)
      ? getUrl(this.facility, JobOfferLinkRel.FacilityImage)
      : null;
  }

  get facilityName(): string {
    return this.hasFacility ? this.facility.name : null;
  }

  get offerPriority(): OfferPriority | null {
    return 'priority' in this.offer ? this.offer.priority : null;
  }

  get workShifts(): string {
    return (this.offer as OfferOutDto)?.workShifts?.map(workShift => workShift.name).join(', ');
  }

  get licenses(): string {
    return (this.offer as OfferOutDto)?.licenses?.map(license => license.name).join(', ');
  }

  get isClosed(): boolean {
    return this.isPermanentOffer ? (this.offer as PermanentOfferDto).closed : this.offer.isClosed;
  }

  get periodFrom(): string {
    return (this.offer as OfferOutDto | PackageOfferDto | AgencyOfferOutDto).periodFrom;
  }

  get periodTo(): string {
    return (this.offer as OfferOutDto | PackageOfferDto | AgencyOfferOutDto).periodTo;
  }

  get startDate(): Date {
    return (this.offer as PermanentOfferDto).startDate;
  }

  get specification(): string {
    return (this.offer as PackageOfferDto).specification;
  }

  get shortPresentation(): string {
    return (this.offer as notFixedPriceOffer).shortPresentation;
  }

  get certifications(): string[] {
    return (this.offer as notFixedPriceOffer).certifications;
  }

  get additionalBenefits(): AdditionalBenefit[] {
    return (this.offer as notFixedPriceOffer).additionalBenefits;
  }

  get hasIndicativeRate(): boolean {
    return (this.offer as OfferOutDto | AgencyOfferOutDto).hasIndicativeRate;
  }

  protected readonly InformationBannerDirection = InformationBannerDirection;
  protected readonly InformationBannerColor = InformationBannerColor;
}
