import { InjectionToken } from '@angular/core';
import { EnvironmentLoaded } from './environment.actions';
import { environmentReducer } from './environment.reducer';
import {
  ApiEnvironmentState,
  AppRegionEnum,
  BrandName,
  EnvironmentState
} from '@libs/shared/bms-common/environment/environment.model';

const BRAND_NAME_KEY = '__brand_name__';
const CUSTOMER_SUPPORT_EMAIL_KEY = '__customer_support_email__';
const SECOND_CUSTOMER_SUPPORT_EMAIL_KEY = '__second_customer_support_email__';
const INFO_EMAIL_KEY = '__info_email__';
const ENVIRONMENT_KEY = '__env__';

export const ENVIRONMENT_CONFIG = new InjectionToken('environmentConfig');

export function loadEnvironment(url: string): Promise<ApiEnvironmentState> {
  return fetch(url).then(response => response.json());
}

export function saveEnvironment(env: EnvironmentState): void {
  window[ENVIRONMENT_KEY] = env;
}

export function saveBrandName(brandName: BrandName): void {
  window[BRAND_NAME_KEY] = brandName;
}

export function saveCustomerSupportEmail(email: string): void {
  window[CUSTOMER_SUPPORT_EMAIL_KEY] = email;
}

export function saveSecondCustomerSupportEmail(email: string): void {
  window[SECOND_CUSTOMER_SUPPORT_EMAIL_KEY] = email;
}

export function saveInfoEmail(email: string): void {
  window[INFO_EMAIL_KEY] = email;
}

export function getBrandName(): BrandName {
  return window[BRAND_NAME_KEY] as BrandName;
}

export function getEnvironment(): EnvironmentState {
  return window[ENVIRONMENT_KEY] as EnvironmentState;
}

export function isUsaDeployment(): boolean {
  return getBrandName() === BrandName.eLAUNCHNow;
}

export function isEuropeDeployment(): boolean {
  return getBrandName() === BrandName.StaffNow;
}

export function isUsaRegion(): boolean {
  return getRegion() === AppRegionEnum.USA.toString();
}

export function getRegion(): string {
  return window.localStorage?.userRegion;
}

export function getEnvironmentInitialState(): EnvironmentState {
  return environmentReducer(undefined, EnvironmentLoaded({ payload: getEnvironment() }));
}
