import { Component, Input, OnDestroy } from '@angular/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { isPlatformIos, isPlatformWeb } from '@libs/shared/helpers/capacitor';
import { Store } from '@ngrx/store';
import { AuthenticationErrorType, AuthenticationService } from '@libs/auth/services/authentication.service';
import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { Router } from '@angular/router';
import { getStaticEnvironment } from '@libs/shared/bms-common/environment/environment.selector';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EnvironmentState } from '@libs/shared/bms-common/environment/environment.model';
import { AuthenticateOptions, BiometricAuth } from '@aparajita/capacitor-biometric-auth';
import { SecureStorage } from '@aparajita/capacitor-secure-storage';
import { isEmpty } from 'lodash-es';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'staffnow-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnDestroy {
  @Input() privacyPolicyUrl: string;
  @Input() termsAndConditionsUrl: string;

  wrongCredentials: boolean = false;
  unverifiedEmail: boolean = false;

  loading: boolean = false;
  email: string;
  password: string;
  googleRecaptchaSiteKey: string;

  private appUrl: string = '';
  private apiRoot: ApiRootResource;
  private subscription: Subscription = new Subscription();

  constructor(
    private authenticationService: AuthenticationService,
    private toastMessageService: ToastMessageService,
    private router: Router,
    private store: Store<any>,
    private modalService: ModalService
  ) {
    this.subscription.add(
      this.store.pipe(getStaticEnvironment).subscribe((env: EnvironmentState) => {
        this.googleRecaptchaSiteKey = env.googleRecaptchaSiteKey;
        this.appUrl = env.brandConfig.appUrl;
      })
    );

    this.subscription.add(
      this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
        this.apiRoot = apiRoot;
        if (this.needsToFillKnowledgeSource() && this.loading) {
          this.modalService.openTechnicianSourceSelectionDialog();
        }
      })
    );

    if (!isPlatformWeb()) {
      BiometricAuth.checkBiometry().then(result => {
        if (result.isAvailable) {
          SecureStorage.getItem(this.appUrl).then(item => {
            const credentials = item.split(' ');
            if (!isEmpty(credentials) && credentials.length === 2) {
              const authenticateOptions: AuthenticateOptions = {
                reason: 'For easier login',
                iosFallbackTitle: 'Login',
                androidTitle: 'Login',
                allowDeviceCredential: true
              };
              BiometricAuth.authenticate(authenticateOptions).then(() => {
                this.email = credentials[0];
                this.password = credentials[1];
                this.login();
              });
            }
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get canLogin(): boolean {
    return !!this.email && this.email.length > 0 && !!this.password && this.password.length > 0;
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.email, this.password).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async () => {
        if (!isPlatformWeb()) {
          SecureStorage.setItem(this.appUrl, `${this.email} ${this.password}`).then();
        }
        if (isPlatformIos()) {
          await SavePassword.promptDialog({
            username: this.email,
            password: this.password
          });
        }
      },
      (error: AuthenticationErrorType) => {
        if (error === AuthenticationErrorType.USERNAME_OR_PASSWORD_INCORRECT) {
          this.wrongCredentials = true;
        } else if (error === AuthenticationErrorType.VERIFICATION_NEEDED) {
          this.unverifiedEmail = true;
        } else {
          this.toastMessageService.fail('Error trying to login.');
        }
        this.loading = false;
      }
    );
  }

  resendVerificationEmail(): void {
    this.router.navigate(['resend-verification-email'], { queryParams: { email: this.email } });
  }

  private needsToFillKnowledgeSource(): boolean {
    return hasLink(this.apiRoot, ApiRootLinkRel.UpdateTechnicianKnowledgeSource);
  }
}
